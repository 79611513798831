import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import URLS from '../../routing/urls';
import MediaPlanBody from './media_plan_body';
import MediaPlanStatus from './media_plan_status';
import { VrrBreadCrumb } from '../../../micro_front_end/shared_components';
import { getAccountId } from '../../../redux/userPreferences/actions/userPreferencesActions';
import {
  fetchMediaPlan,
  getMediaPlan
} from '../../../redux/mediaPlanGallery/mediaPlanGalleryAction';
import { TMediaPlanDetails } from './types';
import {
  TVrrBreadcrumb,
  TVrrBreadcrumbItem
} from '../../shared_components/vrr_breadcrumb/types';
import './MediaPlanDetails.scss';

const MediaPlanDetails: React.FC = () => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const history = useNavigate();
  const params = useParams() as any;

  const accountId = useSelector(getAccountId);
  const mediaPlan = useSelector(getMediaPlan);

  const [currentAccountId, setCurrentAccountId] = useState<string | null>(null);
  const [mediaPlanDetails, setMediaPlanDetails] = useState<
    TMediaPlanDetails | undefined
  >(undefined);

  useEffect(() => {
    if (accountId !== currentAccountId) {
      setCurrentAccountId(accountId);
    }
  }, [accountId]);

  useEffect(() => {
    if (currentAccountId?.length) {
      dispatch(
        fetchMediaPlan({
          mediaPlanId: params.mediaPlanId
        })
      );
      setMediaPlanDetails(undefined);
    }
  }, [currentAccountId]);

  useEffect(() => {
    if (accountId !== currentAccountId && accountId?.length) {
      if (currentAccountId?.length) {
        history({
          pathname: URLS.MEDIA_PLAN_GALLERY
        });
      }
      setCurrentAccountId(accountId);
    }
  }, [accountId, currentAccountId]);

  useEffect(() => {
    setMediaPlanDetails(mediaPlan);
  }, [mediaPlan]);

  const breadcrumbItems: TVrrBreadcrumb['items'] = [
    {
      label: t('media_plan.breadcrumb.main').toUpperCase(),
      id: '-1'
    },
    {
      label: mediaPlanDetails?.name ?? '-',
      id: mediaPlanDetails?.id?.toString() ?? '0'
    }
  ];

  const onClickMediaPlanDetailsItem = (item: TVrrBreadcrumbItem) => {
    if (item.id === breadcrumbItems[0].id) {
      history({
        pathname: URLS.MEDIA_PLAN_GALLERY
      });
    }
  };

  const onSave = () => {
    history({
      pathname: URLS.MEDIA_PLAN_GALLERY
    });
  };

  return (
    <>
      <VrrBreadCrumb
        items={breadcrumbItems}
        onClickItem={onClickMediaPlanDetailsItem}
      />
      <div className="media-plan-details-container-wrapper">
        <MediaPlanStatus
          currentStatus={mediaPlanDetails?.status ?? 'draft'}
          colorStep={mediaPlanDetails?.status === 'completed' ? 'cyan' : 'grey'}
          currentStep={mediaPlanDetails?.completed_steps ?? 1}
          onClickAction={onSave}
        />
      </div>
      <div className="media-plan-details-container-wrapper">
        {mediaPlanDetails && (
          <MediaPlanBody mediaPlanDetails={mediaPlanDetails} />
        )}
      </div>
    </>
  );
};

export default MediaPlanDetails;
