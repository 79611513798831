import { MutableRefObject } from 'react';
import * as d3 from 'd3';
import { TBudgets } from '../inputs/types';

const FUNNEL_COLORS = {
  top: '#2cd3bf',
  middle: '#ff9705',
  bottom: '#6c4cda',
  unspecified: '#464767',
  default: '#f5f6fa'
};

export const prepareChart = (ref: MutableRefObject<any>, values?: TBudgets) => {
  const data =
    values && !Object.values(values).every((item) => item === 0)
      ? [
          {
            value: values.top,
            color: FUNNEL_COLORS.top
          },
          {
            value: values.middle,
            color: FUNNEL_COLORS.middle
          },
          {
            value: values.bottom,
            color: FUNNEL_COLORS.bottom
          },
          {
            value: values.unspecified,
            color: FUNNEL_COLORS.unspecified
          }
        ]
      : [
          {
            value: 100,
            color: FUNNEL_COLORS.default
          }
        ];
  const width = 200;
  const height = Math.min(width, 200);
  const radius = Math.min(width, height) / 2;

  const pie = d3
    .pie()
    .padAngle(1 / radius)
    .sort(null)
    .value((d: any) => d.value);

  const arc: any = d3
    .arc()
    .innerRadius(radius * 0.67)
    .outerRadius(radius - 1);

  const svgElement = d3.select(ref.current);

  svgElement
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', [-width / 2, -height / 2, width, height])
    .attr('style', 'max-width: 100%; height: auto;');

  svgElement.selectAll('path').remove();

  svgElement
    .selectAll('g')
    .data(pie(data as any))
    .join('path')
    .attr('fill', (d: any) => d.data.color as any)
    .attr('stroke', 'white')
    .attr('d', arc);

  svgElement
    .append('circle')
    .attr('cx', 0)
    .attr('cy', 0)
    .attr('r', 20)
    .attr('fill', '#e93f3b');
};
