import React, { MutableRefObject, useEffect, useRef } from 'react';
import { prepareChart } from './services';
import { TScoringChart } from './type';

const ScoringChart: React.FC<TScoringChart> = ({ color, percent }) => {
  const ref: MutableRefObject<any> = useRef();

  useEffect(() => {
    prepareChart(ref, Math.ceil(percent), color);
  }, [percent, color]);

  return <svg ref={ref} />;
};

export default ScoringChart;
