const BASE_URL = `${process.env.REACT_APP_SERVER_URL}/api/v1/`;

const getSourceUrl = (
  accountId: string,
  videoId: string,
  token: string,
  userId: string
) => {
  return `ui/ad_accounts/${accountId}/videos/${videoId}/webm?token=${token}&user_id=${userId}`;
};

export const prepareSource = (
  accountId: string,
  videoId: string,
  token: string,
  userId: string
): string => {
  return `${BASE_URL}${getSourceUrl(accountId, videoId, token, userId)}`;
};
