import React from 'react';
import { ModalFooter } from 'reactstrap';
import './BoostModalFooter.scss';
import BoostModalActions from './BoostModalActions';
import { TBoostModalFooter } from './types';

const BoostModalFooter: React.FC<TBoostModalFooter> = ({
  onCancel,
  onClickUpdate,
  disabled
}: TBoostModalFooter) => {
  return (
    <ModalFooter className="modal-boost-footer">
      <div className="modal-boost-footer-actions">
        <BoostModalActions
          onCancel={onCancel}
          onClickUpdate={onClickUpdate}
          disabled={disabled}
        />
      </div>
    </ModalFooter>
  );
};

export default BoostModalFooter;
