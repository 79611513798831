import React from 'react';
import { useTranslation } from 'react-i18next';
import Page from '../page';
import { PRIVACY_SECTIONS } from './services';

const Privacy: React.FC = () => {
  const { t } = useTranslation();

  return <Page sections={PRIVACY_SECTIONS} pageTitle={t('privacy.title')} />;
};

export default Privacy;
