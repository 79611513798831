import React from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledCollapse, Nav } from 'reactstrap';
import { TRoute } from '../../../routing/types';
import { TMenuItem } from '../types';
import './ParentItem.scss';

const ParentItem: React.FC<TMenuItem> = ({
  mainRoute,
  subRoutes,
  id
}: TMenuItem) => {
  const { mini, isActive, icon, name } = mainRoute;
  return (
    <>
      <li key={id} className={`list-bloc ${isActive ? 'active' : ''}`}>
        <a
          id={`collapse_${id}`}
          className="collapse"
          data-toggle="collapse"
          href={`collapse_${id}`}
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          {icon?.length ? (
            <>
              <i className={icon} />
              <p>{name}</p>
            </>
          ) : (
            <span className="sidebar-mini-icon">
              <i
                style={{
                  backgroundImage: `url(${mini?.image})`,
                  width: mini?.width,
                  height: mini?.height
                }}
              />
              <p>{name}</p>
            </span>
          )}
        </a>
      </li>
      <UncontrolledCollapse toggler={`collapse_${id}`}>
        <div>
          {subRoutes?.map((item: TRoute, index: number) => {
            if (item?.url) {
              return (
                <div key={`${id}-${item.name}`} className="sub-item-list">
                  <Nav
                    key={item.name ?? index.toString()}
                    className="sub-item-list"
                  >
                    <li key={id} className={item.isActive ? 'active' : ''}>
                      <Link
                        className="sidebar-sub-item"
                        to={item.url}
                        aria-expanded
                      >
                        <span>
                          <i className={item.icon} />
                          <p className="sub-item-list-paragraph">{item.name}</p>
                        </span>
                      </Link>
                    </li>
                  </Nav>
                </div>
              );
            }
            return null;
          })}
        </div>
      </UncontrolledCollapse>
    </>
  );
};

export default ParentItem;
