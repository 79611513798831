import React, { BaseSyntheticEvent, useState } from 'react';
import { Input, InputGroup, InputGroupText } from 'reactstrap';
import VrrDropdown from '../vrr_dropdown';
import { TVrrSearch } from './types';
import { IListState } from '../vrr_dropdown/types';
import './VrrSearch.scss';

const VrrSearch: React.FC<TVrrSearch> = ({
  searchList = [],
  listDisabled = false,
  onSearchAction,
  withIcon = true
}) => {
  const [filterValue, setFilterValue] = useState<string | undefined>(
    searchList[0]?.value
  );
  const [searchValue, setSearchValue] = useState();

  const filterAction = (e: IListState) => {
    setFilterValue(e?.value);
    if (onSearchAction) onSearchAction(searchValue, e?.value);
  };

  const searchAction = (e: BaseSyntheticEvent) => {
    setSearchValue(e.currentTarget.value);
    if (onSearchAction) onSearchAction(e.currentTarget.value, filterValue);
  };
  return (
    <InputGroup className="vrr-search-input-group">
      {withIcon && (
        <InputGroupText className="vrr-search-icon-input">
          <i className="tim-icons icon-zoom-split" />
        </InputGroupText>
      )}
      <Input onChange={searchAction} className="vrr-search-text-input" />
      {!!searchList?.length && (
        <VrrDropdown
          isDisabled={listDisabled}
          handleChangeAction={filterAction}
          currentValue={filterValue}
          list={searchList}
        />
      )}
    </InputGroup>
  );
};
export default VrrSearch;
