import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import apiVRR from '../../libs/axios/apis';
import endPoints from '../../services/endpoints';

export const fetchCampaign = createAsyncThunk(
  'management/fetchCampaign',
  async ({ startDate, endDate, provider, id }: any) => {
    return apiVRR().get(endPoints.MANAGEMENT.CAMPAINGN, {
      params: {
        start: startDate,
        end: endDate,
        provider,
        id
      }
    });
  }
);

export const fetchTableLines = createAsyncThunk(
  'management/fetchTableLines',
  async ({ streamID }: any) => {
    return apiVRR().get(endPoints.MANAGEMENT.STREAM, {
      params: {
        id: streamID
      }
    });
  }
);

export const getCampaign = (state: RootState): any => state.management.campaign;

export const getTableData = (state: RootState): any =>
  state.management.tableData;
