import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import GeneralInformations from '../../../components/UserProfile/GeneralInformations';
import {
  fetchUserProfile,
  getUserProfile,
  updateUserProfile
} from '../../../redux/userProfile/userProfileActions';
import { getUserId } from '../../../redux/app/appActions';
import { ISaveUserProfile } from 'redux/userProfile/types';

const GeneralInformationContainer: React.FC = () => {
  const dispatch: any = useDispatch();
  const currentUserProfile = useSelector(getUserProfile);
  const userId = useSelector(getUserId);

  useEffect(() => {
    if (userId && !currentUserProfile) {
      dispatch(fetchUserProfile());
    }
  }, [userId, currentUserProfile]);

  const onSave = (user: ISaveUserProfile) => {
    if (user) dispatch(updateUserProfile(user));
  };

  return (
    <GeneralInformations
      currentUserProfile={currentUserProfile}
      onSaveUserProfile={onSave}
    />
  );
};

export default GeneralInformationContainer;
