import React, { useState } from 'react';
import { ArrowDown, ArrowUp } from 'react-bootstrap-icons';
import VrrDropdown from '../vrr_dropdown';
import { SORT_BY } from './constants';
import { TVrrSortBy, TVrrSortByValue } from './types';
import './VrrSortBy.scss';

export const VrrSortBy: React.FC<TVrrSortBy> = ({
  sortOptions,
  isDisabled,
  placeHolder,
  onSortAction,
  defaultSortOrder = SORT_BY.asc
}: TVrrSortBy) => {
  const [sortOrder, setSortOrder] = useState(defaultSortOrder);
  const [sortValue, setSortValue] = useState(
    sortOptions[0].value ?? SORT_BY.asc
  );

  const onLabelChange = (value: TVrrSortByValue | null) => {
    setSortValue(value?.value ?? sortOptions[0].value);
    if (onSortAction)
      onSortAction(value?.value ?? sortOptions[0].value, sortOrder);
  };

  const onArrowChange = () => {
    const newSortOrder = sortOrder === SORT_BY.asc ? SORT_BY.desc : SORT_BY.asc;
    setSortOrder(newSortOrder);
    if (onSortAction) onSortAction(sortValue, newSortOrder);
  };

  return (
    <>
      <div className="vrr-sort-container">
        <VrrDropdown
          list={sortOptions}
          handleChangeAction={onLabelChange}
          isDisabled={isDisabled}
          placeholder={placeHolder}
          currentValue={sortValue}
        />
        <div id="cy-vrr-sort-by-arrow" onClick={onArrowChange}>
          {sortOrder === SORT_BY.asc ? (
            <ArrowUp className="vrr-sort-arrow" />
          ) : (
            <ArrowDown className="vrr-sort-arrow" />
          )}
        </div>
      </div>
    </>
  );
};

export default VrrSortBy;
