import { useDispatch } from 'react-redux';
import {
  preparePreferences,
  updateUserPreferences
} from '../../redux/userPreferences/actions/userPreferencesAPIs';

const useVrrSettings = () => {
  const dispatch = useDispatch();
  return (value: any, notification: boolean) => {
    dispatch(
      updateUserPreferences({ node: 'vrr', value, notification }) as any
    );
  };
};

const usePrepareSettings = () => {
  const dispatch = useDispatch();
  return (mainNode: 'overview' | 'insights', settings: any) => {
    dispatch(
      preparePreferences({
        mainNode,
        ...settings
      }) as any
    );
  };
};

const useOverviewSettings = () => {
  const settings = usePrepareSettings();
  return (node: 'global_metrics' | 'global_insights', newSetting: any) => {
    settings('overview', {
      node,
      newSetting
    });
  };
};

const useInsightsSettings = () => {
  const settings = usePrepareSettings();
  return (node: 'filters' | 'cards', newSetting: any) => {
    settings('insights', {
      node,
      newSetting
    });
  };
};

const useSettings = (): any => {
  const vrr = useVrrSettings();
  const overview = useOverviewSettings();
  const insights = useInsightsSettings();
  return {
    vrr,
    overview,
    insights
  };
};

export default useSettings;
