import React from 'react';
import { TSummary } from './types';
import './Summary.scss';
import { useTranslation } from 'react-i18next';

const Summary: React.FC<TSummary> = ({ sections }) => {
  const { t } = useTranslation();

  return (
    <div className="gdpr-summary">
      {sections.map((section) => (
        <li key={section.id}>
          <a href={`#${section.id}`} className="gdpr-section">
            {t(section.title)}
          </a>
          {section.subtitles?.map((subtitle) => (
            <li key={subtitle.id} className="gdpr-section-child">
              <a href={`#${subtitle.id}`}>{t(subtitle.title)}</a>
            </li>
          ))}
        </li>
      ))}
    </div>
  );
};

export default Summary;
