import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'reactstrap';
import DifferenceCard from '../DifferenceCard';
import './PublishModal.scss';
import { TPublishModal } from './types';

const PublishModal: React.FC<TPublishModal> = ({
  card,
  onSubmit,
  onClick,
  symbol,
  isOpen = false,
  onCancel
}: TPublishModal) => {
  const { t } = useTranslation();
  const publishAction = () => {
    if (onSubmit) onSubmit(card?.id, card?.status);
  };

  return (
    <Modal isOpen={isOpen} toggle={onCancel} size="lg" centered>
      <div>
        <div className="publish-modal-header">
          <Button className="btn-round btn-icon header-icon" color="default">
            <i className="tim-icons icon-time-alarm" />
          </Button>
          <h4 className="header-title">
            {t('components.insights.publish.title')}
          </h4>
        </div>
        <div className="publish-modal-body">
          <h5>{t('components.insights.publish.body.title')}</h5>
          <div>
            <h6 className="difference-title">
              {t('components.insights.publish.body.difference')}
            </h6>
            <DifferenceCard
              cardId={card?.id}
              status={card?.status}
              cardColor={card?.mainColor}
              name={card.kpiName}
              symbol={symbol}
              value={card.value}
              tag={card.tag}
              onClickCard={onClick}
            />
          </div>
        </div>
        <div className="publish-modal-footer">
          <div>
            <Button type="button" className="btn-link" onClick={onCancel}>
              {t('components.insights.publish.buttons.cancel')}
            </Button>
            <Button
              id="cy-publish-modal-action"
              type="submit"
              onClick={publishAction}
            >
              {t(
                `components.insights.publish.buttons.submit.${
                  card?.status || 'unpublished'
                }`
              )}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PublishModal;
