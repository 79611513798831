import React from 'react';
import { TDefaultSection } from './types';
import './styles/MediaPlanTargetsSection.scss';

const DefaultSection: React.FC<TDefaultSection> = ({
  data
}: TDefaultSection) => {
  const display = () => {
    const items: JSX.Element[] = [];
    for (let i = 0; i < 4; i++) {
      items.push(
        <div
          key={`media-plan-targets-section-${i}`}
          className={`media-plan-targets-section-default ${
            data?.[i] ? 'with-data' : 'no-data'
          }`}
        >
          {data?.[i] || '-'}
        </div>
      );
    }
    return items;
  };
  return <>{display()}</>;
};

export default DefaultSection;
