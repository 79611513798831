import React from 'react';
import { useTranslation } from 'react-i18next';
import Page from '../page';
import { TERMS_SECTIONS } from './services';

const Terms: React.FC = () => {
  const { t } = useTranslation();
  return <Page sections={TERMS_SECTIONS} pageTitle={t('terms.title')} />;
};

export default Terms;
