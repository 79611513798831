import React from 'react';
import { useSelector } from 'react-redux';
import MetricDetail from '../metric_detail';
import VrrCircleLoader from '../../../shared_components/vrr_circle_loader';
import { getPinedMetrics } from '../../../../redux/userPreferences/actions/userPreferencesActions';
import { getAllMetrics } from '../../../../redux/overview/metrics/MetricsActions';
import { TSetOfMetrics } from '../../../../redux/overview/metrics/types';
import './MetricPins.scss';

const MetricPins: React.FC<any> = () => {
  const pinedMetrics = useSelector(getPinedMetrics);
  const metrics = useSelector(getAllMetrics);
  return (
    <div className="metric-pins">
      {Array.isArray(pinedMetrics) &&
        pinedMetrics?.map((metric) => (
          <VrrCircleLoader
            key={metric}
            isEnable={!metrics[metric as keyof TSetOfMetrics]}
          >
            <MetricDetail type="pin" metric={metric} />
          </VrrCircleLoader>
        ))}
    </div>
  );
};

export default MetricPins;
