import React from 'react';
import { useTranslation } from 'react-i18next';
import { VrrButton, VrrFilter } from '../../../shared_components';
import { SEARCH_LIST, SORT_OPTIONS } from './services';
import { TMediaPlanFilter } from './types';
import './MediaPlanFilter.scss';

const MediaPlanFilter: React.FC<TMediaPlanFilter> = ({
  onSortAction,
  onSearchAction,
  onNewMediaPlan
}) => {
  const { t } = useTranslation();

  return (
    <VrrFilter
      classNames="media-plan-filter"
      sortProps={{
        sortOptions: SORT_OPTIONS,
        isDisabled: true,
        onSortAction
      }}
      searchProps={{
        searchList: SEARCH_LIST,
        listDisabled: true,
        onSearchAction
      }}
      otherActions={
        <VrrButton
          children={t('media_plan_gallery.media_plan_filter.actions.setup')}
          onClick={onNewMediaPlan}
        />
      }
    />
  );
};

export default MediaPlanFilter;
