import React from 'react';
import { useTranslation } from 'react-i18next';
import { VrrCard } from '../../../../shared_components';
import { renderBody, renderFooter } from './services';
import { getCurrentCurrency } from '../../../../../services/services';
import { TCurrencyOptions } from '../../../../services/formatters/types';
import { TCardMetric } from './types';
import './CardMetric.scss';

const CardMetric: React.FC<TCardMetric> = ({
  metric,
  onClickAction
}: TCardMetric) => {
  const { t } = useTranslation();
  const localOptions: TCurrencyOptions = {
    thousandsSeparator: t('local.separators.thousands'),
    decimalSeparator: t('local.separators.decimal'),
    symbol: getCurrentCurrency(),
    significantDigits: 2
  };
  const cls = (applyColor: boolean, color = 'grey') => {
    const newColor = applyColor ? color : 'grey';
    return `top-border-card ${newColor}`;
  };

  return (
    <VrrCard
      className={`card-stats global-metrics ${
        metric.selected && metric.type !== 'spent' ? 'card-stats-selected' : ''
      }`}
      {...(metric.selected &&
        metric.type !== 'spent' && {
          onClick: () => {
            if (onClickAction)
              onClickAction(
                metric.name.value as string,
                metric?.color ?? 'grey'
              );
          }
        })}
      cardHeader={
        <div
          className={`${cls(
            metric.type === 'spent' || !!metric?.selected,
            metric.type !== 'spent' ? metric?.color : 'red'
          )}`}
        />
      }
      cardBody={{
        element: (
          <div className="body-card">
            {renderBody(metric, localOptions, t, !!metric?.selected)}
          </div>
        )
      }}
      cardFooter={renderFooter(metric, localOptions)}
    />
  );
};

export default CardMetric;
