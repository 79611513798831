import React, { useState, useEffect } from 'react';
import './GeneralInformations.scss';
import { Row, Col, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import EditForm from './EditForm';
import useNotification from '../../../libs/notifications/useNotification';
import { IGeneralInformations } from './types';
import { ISaveUserProfile, IUserProfile } from 'redux/userProfile/types';

const GeneralInformations: React.FC<IGeneralInformations> = ({
  currentUserProfile,
  onSaveUserProfile
}: IGeneralInformations) => {
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation();
  const { email, username } = currentUserProfile;
  const notif = useNotification();

  let prevUserProfilRef: IUserProfile;

  useEffect(() => {
    if (prevUserProfilRef && prevUserProfilRef.username !== username) {
      notif(
        'success',
        t('components.general_informations.notification.update_sucess')
      );
    }
    prevUserProfilRef = currentUserProfile;
  }, [currentUserProfile]);

  const editionMode = () => {
    setIsEditing(!isEditing);
  };

  const onSave = (userParam: ISaveUserProfile) => {
    setIsEditing(false);
    onSaveUserProfile(userParam);
  };

  const staticContent = () => {
    return (
      <>
        <Row className="user-general-infos-row">
          <Col xs="6">
            <p>{t('components.general_informations.email_address.title')}</p>
          </Col>
          <Col xs="6">
            <p id="cy-user-email">{email}</p>
          </Col>
        </Row>
        <Row className="user-general-infos-row">
          <Col xs="6">
            <p>{t('components.general_informations.username.title')}</p>
          </Col>
          <Col xs="6">
            <p id="cy-user-username">{username}</p>
          </Col>
        </Row>
      </>
    );
  };

  const editContent = () => (
    <EditForm onSave={onSave} user={currentUserProfile} />
  );

  const infosContent = () => {
    return isEditing ? editContent() : staticContent();
  };

  return (
    <div
      data-cy-id="user-profile-general-informations"
      className="content user-general-informations"
    >
      <div className="user-general-infos-main-row">
        <h4 className="user-general-infos-title">
          {t('components.general_informations.title').toUpperCase()}
        </h4>
        <Button
          data-cy-id="user-profile-edit-profile"
          onClick={editionMode}
          className="edit-switch"
        >
          <i
            className={`tim-icons ${
              isEditing ? 'icon-simple-remove' : 'icon-pencil'
            }`}
          />
        </Button>
      </div>
      <div className="user-general-infos-container">{infosContent()}</div>
    </div>
  );
};

export default GeneralInformations;
