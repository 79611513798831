import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader } from 'reactstrap';
import BudgetList from './BudgetList';
import './BoostModal.scss';
import { ModalBody } from 'reactstrap';
import BoostModalService from './BoostModal.services';
import BoostChart from './BoostChart';
import BoostModalFooter from './BoostModalFooter';
import { Moment } from 'moment';
import { TBoostModalValues, TBoostModal } from './types';
import { Row, Col } from 'reactstrap';
import BoostModalDates from './BoostModalFooter/BoostModalDates';
import moment from 'moment';
import VrrCircleLoader from 'micro_front_end/shared_components/vrr_circle_loader';

const BoostModal: React.FC<TBoostModal> = ({
  isOpen,
  apiData,
  splitTestsList,
  currentSplitTestId,
  onCancel,
  updateBudgets,
  setCurrentSplitTestId
}: TBoostModal) => {
  const { t } = useTranslation();

  const modalData = apiData.payload;
  const [budgetValues, setBudgetValues] = useState<TBoostModalValues | null>(
    modalData
  );
  const [isBudgetUpdated, setIsBudgetUpdated] = useState<boolean>(false);

  const setPayload = (value: number, type: string) => {
    if (budgetValues && modalData) {
      const payload = BoostModalService.updateAndRecalculateValues(
        value,
        type,
        budgetValues
      );

      setIsBudgetUpdated(
        BoostModalService.hasBudgetUpdates(modalData, payload)
      );

      return setBudgetValues(payload);
    }
  };

  const handleEndDateChanged = (date: Moment) => {
    setPayload(date.valueOf(), 'endDate');
  };

  const onClickUpdate = () => {
    if (isBudgetUpdated && budgetValues) {
      updateBudgets(budgetValues);
      setIsBudgetUpdated(false);
      onCancel();
    }
  };

  const onCancelPress = () => {
    setBudgetValues(apiData.payload);
    onCancel();
  };

  const disableFields =
    apiData.status === 'rejected' ||
    moment().isAfter(budgetValues?.endDate) ||
    currentSplitTestId === 'all';

  useEffect(() => {
    setBudgetValues(modalData);
  }, [apiData]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={onCancelPress}
      size="xl"
      centered
      scrollable
      className="modal-boost"
      returnFocusAfterClose={false}
    >
      <ModalHeader>
        <div>
          <div className="modal-boost-title-container">
            <div className="modal-boost-icon-container">
              <i className="tim-icons icon-spaceship modal-boost-icon" />
            </div>
            <h4 className="modal-boost-title">
              {t('components.budget.boost.modal.title')}
            </h4>
          </div>
        </div>
      </ModalHeader>
      <ModalBody className="modal-boost-body">
        <VrrCircleLoader isEnable={apiData.status === 'pending'}>
          {budgetValues && (
            <>
              <section className="modal-boost-dates">
                <BoostModalDates
                  data={budgetValues}
                  splitTestsList={splitTestsList}
                  currentSplitTestId={currentSplitTestId}
                  onEndDateChanged={handleEndDateChanged}
                  setCurrentSplitTestId={setCurrentSplitTestId}
                />
              </section>
              <Row className="modal-boost-body-container">
                <Col className="boostchart-container" md="12" lg="5" xxl="6">
                  <BoostChart values={budgetValues} />
                </Col>
                <Col className="budgetlist-container" md="12" lg="7" xxl="6">
                  <BudgetList
                    data={BoostModalService.assignateValuesToList(budgetValues)}
                    disabled={disableFields}
                    currentSplitTestId={currentSplitTestId}
                    handleChange={setPayload}
                  />
                </Col>
              </Row>
            </>
          )}
        </VrrCircleLoader>
      </ModalBody>
      <BoostModalFooter
        onCancel={onCancelPress}
        onClickUpdate={onClickUpdate}
        disabled={
          apiData.status === 'rejected' ||
          !isBudgetUpdated ||
          currentSplitTestId === 'all'
        }
      />
    </Modal>
  );
};

export default BoostModal;
