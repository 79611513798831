import React, { useEffect, useState } from 'react';
import PublishModal from '../../../components/Insights/PublishModal';
import DifferenceCard from '../../../components/Insights/DifferenceCard';
import { getCurrentCurrency } from '../../../services/services';
import {
  changeHideStatus,
  changePublishStatus
} from '../CardListContainer/services';
import { TCardsContainer } from './types';
import { TCardListActions } from 'components/Insights/CardList/types';
import { TCard } from 'components/Insights/PublishModal/types';
import { TDifferenceCard } from 'components/Insights/DifferenceCard/types';

const CardsContainer: React.FC<TCardsContainer & TCardListActions> = ({
  cardSettings,
  card,
  changeStatusAction,
  filters
}) => {
  const colors = ['mauve', 'turquoise', 'orange', 'red'];
  const cardMainColor = 'grey';
  const [tagColor, setTagColor] = useState<string>('grey');
  const [open, setOpen] = useState<boolean>(false);
  const [currentCard, setCurrentCard] = useState<TCard>();
  const symbol = getCurrentCurrency();

  useEffect(() => {
    if (colors.length > 0) {
      const color = colors[Math.floor(Math.random() * colors.length)];
      const index = colors.indexOf(color);
      colors.splice(index, 1);
      setTagColor(colors[Math.floor(Math.random() * colors.length)]);
    }
  }, []);

  const openModal = (cardInfos: TCard) => {
    setCurrentCard(cardInfos);
    setOpen(true);
  };

  const changeStatus = (cardId: string, currentStatus: string) => {
    const newStatus = changeHideStatus(currentStatus);
    if (changeStatusAction) changeStatusAction(cardId, newStatus);
  };

  const publishCard = (cardId: string, currentStatus: string) => {
    const newStatus = changePublishStatus(currentStatus);
    if (changeStatusAction) changeStatusAction(cardId, newStatus);
  };

  const submitAction = (cardId: string, currentStatus: string) => {
    const newStatus = changePublishStatus(currentStatus);
    if (changeStatusAction) changeStatusAction(cardId, newStatus);
    setOpen(false);
  };

  const getChildrenByType = () => {
    if (cardMainColor && tagColor) {
      switch (cardSettings?.type) {
        case 'comparison':
        case 'difference':
        default:
          return (
            <DifferenceCard
              cardId={card.id}
              name={card.kpi}
              status={cardSettings?.status ?? 'unpublished'}
              cardColor={cardMainColor as TDifferenceCard['cardColor']}
              value={card.ratio}
              symbol={symbol}
              tag={
                {
                  name: card.tag,
                  color: tagColor,
                  averageVideosDuration: filters?.duration?.toString()
                } as TDifferenceCard['tag']
              }
              onClickCard={openModal}
              onClickPublish={publishCard}
              onClickButton={changeStatus}
            />
          );
      }
    }
    return null;
  };

  return (
    <>
      {getChildrenByType()}
      {currentCard && (
        <PublishModal
          isOpen={open}
          card={currentCard}
          symbol={symbol}
          onCancel={() => setOpen(false)}
          onSubmit={submitAction}
        />
      )}
    </>
  );
};

export default CardsContainer;
