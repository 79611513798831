import { TCategoryData } from './audience_category/types';
import { TTarget } from '../../../../../../../../types';

export const isSelectAll = (
  selectedAudiences: TTarget[] | undefined,
  audienceCategories: TCategoryData[]
): boolean => {
  const selectedIDs = selectedAudiences?.map((audience) => audience.id);
  return audienceCategories.every((category) =>
    selectedIDs?.includes(category.parentCategory.criteriaId)
  );
};
