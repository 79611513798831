import React from 'react';
import { TSelection } from '../../../../../shared_components/vrr_selections/types';
import CardMetric from '../../card_metric';
import { TSelectionMetric } from '../../card_metric/types';

export const prepareMetricsList = (
  metrics?: TSelectionMetric[]
): TSelection[] => {
  const newMetrics: Array<TSelectionMetric & TSelection> = [];
  metrics?.forEach((metric) => {
    newMetrics.push({
      id: metric.name.value as string,
      element: <CardMetric metric={metric} />,
      ...metric
    });
  });
  return newMetrics;
};

export const prepareNewMetrics = (
  selectedMetrics: TSelectionMetric[],
  metrics?: TSelectionMetric[]
): TSelectionMetric[] => {
  const newSelectedMetrics = selectedMetrics.map(
    (metric) => metric.name.value as string
  );
  return (
    metrics?.map((metric) => {
      return {
        ...metric,
        selected: newSelectedMetrics.includes(metric.name.value as string)
      };
    }) ?? []
  );
};
