import React from 'react';
import { Link } from 'react-router-dom';
import { TVrrLink } from './types';

const VrrLink: React.FC<TVrrLink> = ({ children, title, link }) => {
  return (
    <Link to={link} target="_blank" title={title} rel="noopener noreferrer">
      {children}
    </Link>
  );
};

export default VrrLink;
