import URLS from '../../../micro_front_end/routing/urls';
import ConnectedAccount from '../../../components/UserProfile/ConnectedAccount';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchAccounts, getAccounts } from '../../../redux/app/appActions';
import {
  getConnectedAccountURL,
  isConnectedToAccount,
  addConnectedAccount,
  fetchConnectedAccountCallback,
  fetchConnectedAccountURL,
  removeConnectedAccount
} from '../../../redux/userProfile/userProfileActions';
import useSettings from '../../../libs/settings/useSettings';
import { resetOverview } from '../../../redux/overview/overviewSlice';
import { TConnectedAccountC } from './types';

const ConnectedAccountC: React.FC<TConnectedAccountC> = ({
  connectedAccountURL,
  isConnected,
  accountList,
  getConnectedAccountURLAction,
  getConnectedAccountCallbackAction,
  addConnectedAccountAction,
  removeConnectedAccountAction,
  fetchAccountList,
  clearOverviewData
}: TConnectedAccountC) => {
  const { vrr } = useSettings();

  useEffect(() => {
    const codeByGoogle = {
      code: new URL(window.location.href).searchParams.get('code')
    };

    if (codeByGoogle.code === null) {
      getConnectedAccountURLAction();
    } else {
      getConnectedAccountCallbackAction(codeByGoogle as { code: string });
    }

    // Fill account dropdown and account list after logging into Google
    if (accountList.length === 0) {
      fetchAccountList('all');
    }
  }, []);

  // Remove data stored in redux for overview when account_dropdown is empty
  useEffect(() => {
    if (accountList.length === 0) {
      vrr({ current_account_id: null });
      clearOverviewData();
    }
  }, [accountList]);

  useEffect(() => {
    if (isConnected) {
      window.location.href = `${window.location.origin}${URLS.USER_PROFILE}?subRoute=connectedAccount`;
    }
  }, [isConnected]);

  return (
    <ConnectedAccount
      list={
        Array.isArray(connectedAccountURL) ? accountList : connectedAccountURL
      }
      addConnectedAccountAction={addConnectedAccountAction}
      removeConnectedAccountAction={removeConnectedAccountAction}
    />
  );
};

const mapStateToProps = (state: any) => ({
  connectedAccountURL: getConnectedAccountURL(state),
  isConnected: isConnectedToAccount(state),
  accountList: getAccounts(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  getConnectedAccountURLAction: () => dispatch(fetchConnectedAccountURL()),
  addConnectedAccountAction: (list: Array<number>) =>
    dispatch(addConnectedAccount(list)),
  removeConnectedAccountAction: (list: Array<number>) =>
    dispatch(removeConnectedAccount(list)),
  getConnectedAccountCallbackAction: (code: { code: string }) =>
    dispatch(fetchConnectedAccountCallback({ code })),
  fetchAccountList: (p: string) => dispatch(fetchAccounts(p)),
  clearOverviewData: () => dispatch(resetOverview())
});

const ConnectedAccountContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedAccountC);

export default ConnectedAccountContainer;
