import sharedEN from './shared/sharedEN.json';
import sharedFR from './shared/sharedFR.json';
import menuEN from './menu/menuEN.json';
import menuFR from './menu/menuFR.json';
import mainNavbarEN from './main_navbar/mainNavbarEN.json';
import mainNavbarFR from './main_navbar/mainNavbarFR.json';
import notificationCenterEN from './notification_center/notificationCenterEN.json';
import notificationCenterFR from './notification_center/notificationCenterFR.json';
import launcherEN from './launcher/launcherEN.json';
import launcherFR from './launcher/launcherFR.json';
import loginEN from './login/loginEN.json';
import loginFR from './login/loginFR.json';
import footerEN from './footer/footerEN.json';
import footerFR from './footer/footerFR.json';
import { MEDIA_PLAN_EN, MEDIA_PLAN_FR } from './media_plan';
import {
  CREATIVE_ANALYTICS_EN,
  CREATIVE_ANALYTICS_FR
} from './creative_analytics';
import { CONNECTED_ACCOUNT_EN, CONNECTED_ACCOUNT_FR } from './user_profile';
import { OVERVIEW_EN, OVERVIEW_FR } from './overview';
import { GDPR_EN, GDPR_FR } from './gdpr';

const EN = {
  ...sharedEN,
  ...menuEN,
  ...mainNavbarEN,
  ...notificationCenterEN,
  ...launcherEN,
  ...loginEN,
  ...footerEN,
  ...OVERVIEW_EN,
  ...CREATIVE_ANALYTICS_EN,
  ...CONNECTED_ACCOUNT_EN,
  ...MEDIA_PLAN_EN,
  ...GDPR_EN
};
const FR = {
  ...sharedFR,
  ...menuFR,
  ...mainNavbarFR,
  ...notificationCenterFR,
  ...launcherFR,
  ...loginFR,
  ...footerFR,
  ...OVERVIEW_FR,
  ...CREATIVE_ANALYTICS_FR,
  ...CONNECTED_ACCOUNT_FR,
  ...MEDIA_PLAN_FR,
  ...GDPR_FR
};

export { EN, FR };
