import usePermissions from './usePermissions';
import { getFeatureByPath } from '../../routing/services';

const useAuthorize = (
  requires: string[] | string,
  authorizationStrategy?: any
): boolean => {
  const { permissions } = usePermissions();

  return authorizationStrategy
    ? authorizationStrategy()
    : getFeatureByPath(permissions, requires as string);
};

export default useAuthorize;
