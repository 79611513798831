import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NotificationList from './notification_list';
import {
  fetchNotifications,
  getNotifications,
  updateNotification
} from '../../../redux/app/appActions';
import { TNotification } from '../../../redux/types';
import { sortByDate } from './notification_list/services';
import { getNoReadNotifications } from './services';
import { VrrButton } from '../../shared_components';
import URLS from '../../routing/urls';
import { ReactComponent as NotificationIcon } from '../../../assets/vrr/images/notif.svg';
import { ReactComponent as NotificationIconAlert } from '../../../assets/vrr/images/notification-alert.svg';
import './NotificationWidget.scss';

const NotificationWidget: React.FC = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const dispatch: any = useDispatch();
  const notif = useSelector(getNotifications);
  const [notifications, setNotifications] = useState<
    TNotification[] | undefined
  >(undefined);
  const [withUnreadNotifications, setWithUnreadNotifications] =
    useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchNotifications());
  }, []);

  useEffect(() => {
    if (!!notif?.length) {
      setNotifications(sortByDate(notif));
    }
  }, [notif]);

  useEffect(() => {
    if (notifications?.length) {
      setWithUnreadNotifications(getNoReadNotifications(notifications));
    }
  }, [notifications]);

  const onUpdate = (id: number) => {
    if (id) {
      dispatch(updateNotification(id));
    }
  };

  return (
    <UncontrolledDropdown nav>
      <DropdownToggle caret nav>
        {withUnreadNotifications ? (
          <NotificationIconAlert className="notification-icon" />
        ) : (
          <NotificationIcon className="notification-icon" />
        )}
      </DropdownToggle>
      <DropdownMenu end className="notification-container">
        <div className="notification-header">
          <h5>{t('main_navbar.notifications.header')}</h5>
          <VrrButton
            className="btn-link notification-all"
            onClick={() => history(URLS.NOTIFICATION_CENTER)}
          >
            {t('main_navbar.notifications.all')}
          </VrrButton>
        </div>
        <div className="notification-body">
          {notifications?.length ? (
            <NotificationList
              notifications={notifications}
              onClickAction={onUpdate}
            />
          ) : (
            <i>
              <h5>{t('main_navbar.notifications.empty')}</h5>
            </i>
          )}
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default NotificationWidget;
