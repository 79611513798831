import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import './BoostChart.scss';
import { TBoostChart } from './types';

const BoostChart: React.FC<TBoostChart> = ({ values }) => {
  const { t } = useTranslation();

  return (
    <Row className={'boostchart'}>
      <Col
        className={'boostchart'}
        xs={{
          offset: 1,
          size: 10
        }}
        md={{
          offset: 2,
          size: 8
        }}
        xl="12"
      >
        <Pie
          data-cy-id="overview-boost"
          data={{
            labels: [
              t('components.budget.boost.budget_list.total_budget'),
              t('components.budget.boost.budget_list.available_budget'),
              t('components.budget.boost.budget_list.spent_budget')
            ],
            datasets: [
              {
                label: '#1',
                data: [100, 0, 0],
                backgroundColor: ['rgb(255, 68, 56)'],
                hoverOffset: 4,
                borderWidth: 0
              },
              {
                label: '#2',
                data: [0, +values.availableBudget, +values.spentBudget],
                backgroundColor: [
                  undefined,
                  'rgb(36, 215, 192)',
                  'rgb(113, 76, 224)'
                ],
                hoverOffset: 4,
                borderWidth: 0
              },
              {
                label: '#3',
                data: [100, 0, 0],
                backgroundColor: ['rgb(255, 255, 255)']
              },
              {
                label: '#4',
                data: [100, 0, 0],
                backgroundColor: ['rgb(255, 255, 255)']
              }
            ]
          }}
          options={{
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                filter: (tooltipItem) => {
                  return (
                    (tooltipItem.datasetIndex === 0 ||
                      tooltipItem.datasetIndex === 1) &&
                    (tooltipItem.dataIndex === 0 ||
                      tooltipItem.dataIndex === 1 ||
                      tooltipItem.dataIndex === 2)
                  );
                }
              }
            }
          }}
        />
      </Col>
    </Row>
  );
};

export default BoostChart;
