import React from 'react';
import { TMenuItem } from './types';
import ParentItem from './parent_item';
import Item from './item';

const MenuItem: React.FC<TMenuItem> = ({
  mainRoute,
  subRoutes,
  id
}: TMenuItem) => {
  return subRoutes ? (
    <ParentItem id={id} mainRoute={mainRoute} subRoutes={subRoutes} />
  ) : (
    <Item id={id} route={mainRoute} />
  );
};

export default MenuItem;
