const URLS = {
  HOME: '/',
  SIGNIN: '/signin',
  FORGOT_PASSWORD: '/forgot_password',
  USER_PROFILE: '/user/all',
  OVERVIEW: '/ads_manager/overview',
  ADMIN: '/admin',
  NOT_FOUND: '/not_found',
  OVERVIEW_ALL: '/ads_manager/overview/all',
  OVERVIEW_YOUTUBE: '/ads_manager/overview/youtube',
  MANAGEMENT_ALL: '/ads_manager/management/all',
  MANAGEMENT_YOUTUBE: '/ads_manager/management/youtube',
  INSIGHTS: '/ads_manager/insights/youtube',
  CREATIVE_ANALYTICS: '/ads_manager/creative_analytics/gallery/youtube',
  LAUNCHER: '/ads_manager/launcher/:accountId/:launcherId/youtube',
  MEDIA_PLAN_GALLERY: '/ads_manager/media_plan/gallery/youtube',
  MEDIA_PLAN: '/ads_manager/media_plan/detail/:accountId/:mediaPlanId/youtube',
  VIDEO: '/ads_manager/creative_analytics/detail/:accountId/:videoId/youtube',
  NOTIFICATION_CENTER: '/notifications',
  PLAYER_DEMO: '/player_demo',
  TERMS: '/terms',
  COOKIES: '/cookies',
  PRIVACY: '/privacy'
};

export default URLS;
