import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TCreativeVideoDetailsSliceInitialState } from './types';
import {
  fetchVideoDetails,
  fetchVideoDetailsOld
} from './creativeVideoDetailsAction';

const initialState: TCreativeVideoDetailsSliceInitialState = {
  videoDetailsOld: undefined,
  videoDetails: undefined,
  videoWebm: undefined
};

const creativeVideoDetailsSlice = createSlice({
  name: 'creativeVideoDetails',
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchVideoDetailsOld.pending, (state) => {
      return {
        ...state,
        videoDetailsOld: undefined
      };
    });
    builder.addCase(
      fetchVideoDetailsOld.fulfilled,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          videoDetailsOld: action.payload || {}
        };
      }
    );
    builder.addCase(fetchVideoDetailsOld.rejected, (state) => {
      return {
        ...state,
        videoDetailsOld: undefined
      };
    });
    builder.addCase(fetchVideoDetails.pending, (state) => {
      return {
        ...state,
        videoDetails: undefined
      };
    });
    builder.addCase(
      fetchVideoDetails.fulfilled,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          videoDetails: action.payload || {}
        };
      }
    );
    builder.addCase(fetchVideoDetails.rejected, (state) => {
      return {
        ...state,
        videoDetails: undefined
      };
    });
  }
});

export default creativeVideoDetailsSlice.reducer;
