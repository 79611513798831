import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EMAIL_PATTERN } from '../Email/Email';
import './MultiEmails.scss';
import { TMultiEmails } from './types';

const MultiEmails: React.FC<TMultiEmails> = ({
  onChangeAction,
  idValue
}: TMultiEmails) => {
  const { t } = useTranslation();
  const [emails, setEmails] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');

  const findEmailAddress = (value: string, isEnter?: boolean) => {
    const validEmails: string[] = [];
    let input = '';
    const comma = /[ ,;]/g;

    const isEmail = (val: any) => EMAIL_PATTERN.test(val);

    const addEmails = (email: string) => {
      const e: string[] = emails;
      const isExists = e.find((em) => em === email);
      if (!isExists) {
        validEmails.push(email);
      }
    };

    if (value !== '') {
      if (comma.test(value)) {
        const splitData = value.split(comma).find((n) => {
          return n !== '' && n !== undefined && n !== null;
        });

        if (splitData && isEmail(splitData)) {
          addEmails(splitData);
        }
      } else if (isEnter && isEmail(value)) {
        addEmails(value);
      } else {
        input = value;
      }
    }

    const finalEmails = [...emails, ...validEmails];
    if (onChangeAction) onChangeAction(finalEmails);

    setEmails(finalEmails);
    setInputValue(input);
  };

  const onChangeInputValue = (value: string) => {
    findEmailAddress(value);
  };

  const removeEmail = (index: number) => {
    const em = [...emails];
    const finalEmails = [...em.slice(0, index), ...em.slice(index + 1)];

    if (onChangeAction) onChangeAction(finalEmails);

    setEmails(finalEmails);
  };

  const handleOnKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
      case 'Tab':
        e.preventDefault();
        break;
      case 'Backspace':
        if (!e.currentTarget.value) {
          removeEmail(emails.length - 1);
        }
        break;
      default:
    }
  };

  const handleOnKeyup = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
      case 'Tab':
        findEmailAddress(e.currentTarget.value, true);
        break;
      default:
    }
  };

  const handleOnChange = (e: React.SyntheticEvent<HTMLInputElement>) =>
    onChangeInputValue(e.currentTarget.value);

  const handleOnBlur = (e: React.SyntheticEvent<HTMLInputElement>) => {
    findEmailAddress(e.currentTarget.value, true);
  };

  const getLabel = (email: string, index: number) => {
    return (
      <div className="label-email" key={index}>
        <div className="email">{email}</div>
        <button
          type="button"
          className="remove"
          onClick={() => removeEmail(index)}
        >
          ×
        </button>
      </div>
    );
  };

  return (
    <div
      className={`multi-email ${
        inputValue === '' && emails.length === 0 ? 'empty' : ''
      }`}
    >
      {emails.map((email: string, index: number) => getLabel(email, index))}
      <input
        data-cy-id="overview-share-input"
        id={idValue}
        type="text"
        placeholder={t('components.share.modal.email_input_placeholder')}
        value={inputValue}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onKeyDown={handleOnKeydown}
        onKeyUp={handleOnKeyup}
      />
    </div>
  );
};

export default MultiEmails;
