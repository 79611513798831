import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ModalHeader } from 'reactstrap';
import RedirectionTable from './redirection_table';
import { VrrModal, VrrButton } from '../../../../../../shared_components';
import {
  editMediaPlanDetails,
  getIsComplete,
  getMediaPlanRedirections
} from '../../../../../../../redux/mediaPlanGallery/mediaPlanGalleryAction';
import { TRedirectionModal } from './types';
import { TRedirectionRow } from './redirection_table/types';
import { TMediaPlanDetails } from '../../../../types';
import './RedirectionModal.scss';

const RedirectionModal: React.FC<TRedirectionModal> = ({
  isOpen,
  onCancelAction
}: TRedirectionModal) => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();
  const childRef = useRef(null);
  const mediaPlanRedirections = useSelector(getMediaPlanRedirections);
  const isComplete = useSelector(getIsComplete);

  const [currentValues, setCurrentValues] = useState<
    TMediaPlanDetails['redirections'] | undefined
  >(undefined);

  useEffect(() => {
    if (isOpen) {
      setCurrentValues(mediaPlanRedirections);
    }
  }, [isOpen]);

  const onCancel = () => {
    setCurrentValues(undefined);
    if (onCancelAction) {
      onCancelAction();
    }
  };

  useEffect(() => {
    if (isComplete) {
      onCancel();
    }
  }, [isComplete]);

  const addLineAction = () => {
    (childRef.current as any).addNewLine();
  };

  const onSubmitAction = () => {
    if (currentValues) {
      dispatch(editMediaPlanDetails({ redirections: currentValues }));
    }
  };

  const onChange = (values: TRedirectionRow[]) => {
    setCurrentValues(
      values?.map((value) => {
        return {
          id: value.id,
          final_url: value.value
        };
      })
    );
  };

  return (
    <VrrModal
      className="redirection-modal"
      isOpen={isOpen}
      icon={<i className="tim-icons icon-spaceship circle-icon" />}
      modalHeader={
        <ModalHeader className="redirection-modal-header">
          <span>
            <i className="tim-icons icon-spaceship circle-icon" />
          </span>
          <span>
            {t('media_plan_edit.media_plan_assets.redirections.modal.title')}
          </span>
          <VrrButton
            dataCy="media-plan-detail-edit-redirections-add-button"
            className="btn-round redirection-modal-add"
            onClick={addLineAction}
            icon={{
              iconLabel: 'tim-icons icon-simple-add'
            }}
          >
            {t('media_plan_edit.media_plan_assets.redirections.modal.add')}
          </VrrButton>
        </ModalHeader>
      }
      modalBody={
        <div
          className="redirection-modal-body"
          data-cy-id="media-plan-detail-edit-redirections"
        >
          <RedirectionTable
            onChangeAction={onChange}
            redirections={currentValues}
            ref={childRef}
          />
        </div>
      }
      toggleModal={onCancel}
      toggleValidation={onSubmitAction}
      dataCyCancelButton="media-plan-detail-edit-close-button"
    />
  );
};

export default RedirectionModal;
