import React from 'react';
import { useTranslation } from 'react-i18next';
import Page from '../page';
import { COOKIES_SECTIONS } from './services';

const Cookies: React.FC = () => {
  const { t } = useTranslation();

  return <Page sections={COOKIES_SECTIONS} pageTitle={t('cookies.title')} />;
};

export default Cookies;
