import React, { BaseSyntheticEvent } from 'react';
import { Input } from 'reactstrap';
import { ChevronCompactLeft, ChevronCompactRight } from 'react-bootstrap-icons';
import i18next from 'i18next';
import { VrrButton } from '../../../../shared_components';
import VideoCell from './video_cell/VideoCell';
import {
  THeader,
  TTableBody,
  TTableRow
} from '../../../../shared_components/vrr_table/types';
import {
  TCreativeTag,
  TCreativeVideo
} from '../../../../../redux/creativeAnalytics/creativeGallery/types';

export const MAX_COLUMN = 4;

export const TAGS_TYPES = [
  'visual_component',
  'auditive_component',
  'sequence',
  'automatic'
];

export const prepareHeader = (
  videosPage: TCreativeVideo[],
  currentPage: number,
  isLastPage: boolean,
  totalVideo: number,
  onChangePageAction: (slide: 'next' | 'previous') => void
): THeader['element'][] => {
  const header: THeader['element'][] = [
    {
      id: '',
      rowClassName: 'previous-page-cell',
      component:
        totalVideo > MAX_COLUMN ? (
          <VrrButton
            className="btn-link previous-page"
            disabled={currentPage === 1}
            onClick={() => onChangePageAction('previous')}
          >
            <ChevronCompactLeft />
          </VrrButton>
        ) : (
          <></>
        )
    }
  ];
  videosPage.forEach((video) => {
    header.push({
      id: video.VRRId.toString(),
      component: (
        <VideoCell
          id={video.VRRId}
          title={video.title}
          thumbnail={video.thumbnailUrl}
          url={video.url}
        />
      )
    });
  });

  if (totalVideo > MAX_COLUMN) {
    header.push({
      id: '',
      rowClassName: 'next-page-cell',
      component: (
        <VrrButton
          className="btn-link next-page"
          disabled={isLastPage}
          onClick={() => onChangePageAction('next')}
        >
          <ChevronCompactRight />
        </VrrButton>
      )
    });
  }

  return header;
};

const getLine = (
  rowId: number,
  tag?: TCreativeTag,
  videos?: TCreativeVideo[],
  changeAction?: (tagId: number, videoId: number, isChecked: boolean) => void
): TTableRow['cellElements'] => {
  const isDisabled = tag?.category === TAGS_TYPES[3];
  const line: TTableRow['cellElements'] = [
    {
      headerId: '',
      id: '0',
      component: <p>{tag?.name}</p>,
      value: ''
    }
  ];

  videos?.forEach((video) => {
    line.push({
      headerId: 'checkbox',
      id: video.VRRId.toString(),
      component: (
        <div className="tag-checkbox">
          <Input
            type="checkbox"
            id={`${tag?.id}-${rowId.toString()}-${video.VRRId}`}
            checked={video?.tagsId.includes(tag?.id as number)}
            className="tag-input"
            readOnly
            onChange={(e: BaseSyntheticEvent) => {
              if (changeAction && tag)
                changeAction(tag?.id, video.VRRId, e.target.checked);
            }}
            disabled={isDisabled}
          />
          <label
            htmlFor={`${tag?.id}-${rowId.toString()}-${video.VRRId}`}
            className="check tag-label"
          >
            <span
              className={`color-checkbox ${isDisabled ? 'is-disabled' : ''}`}
            />
          </label>
        </div>
      ),
      value: ''
    });
  });

  return line;
};

const getTitleLine = (
  name: string,
  videos?: TCreativeVideo[]
): TTableRow['cellElements'] => {
  const line: TTableRow['cellElements'] = [
    {
      headerId: '',
      id: '0',
      component: (
        <p data-cy-id="add-tag-form" className="tag-title">
          {name}
        </p>
      ),
      value: '',
      rowClassName: 'row-tag-title'
    }
  ];

  videos?.forEach(() => {
    line.push({
      headerId: '',
      id: '0',
      component: <div className="tag-title" />,
      value: '',
      rowClassName: 'row-tag-title'
    });
  });

  return line;
};

const prepareTag = (tags?: TCreativeTag[], type?: string) => {
  return tags?.filter(
    (tag) =>
      tag.tag_type === type ||
      (tag.category === TAGS_TYPES[3] && type === TAGS_TYPES[3])
  );
};

export const prepareBody = (
  changeAction: (tagId: number, videoId: number, isChecked: boolean) => void,
  videos?: TCreativeVideo[],
  tags?: TCreativeTag[]
): TTableBody['rowElements'] => {
  const newBody: TTableBody['rowElements'] = [];
  TAGS_TYPES.map((type) => {
    const newTags = prepareTag(tags, type);
    if (newTags?.length) {
      newBody.push(
        getTitleLine(
          i18next.t(`creative_gallery.creative_tag.types.${type}`),
          videos
        )
      );
      newTags?.forEach((tag, index) => {
        newBody.push(getLine(index, tag, videos, changeAction));
      });
    }
  });

  return newBody;
};
