import React from 'react';
import { Modal } from 'reactstrap';
import UserPasswordContainer from '../../containers/UserProfile/UserPassword/UserPasswordContainer';
import { TRestriction } from './types';

const Restriction: React.FC<TRestriction> = ({ isOpen }: TRestriction) => {
  return (
    <Modal isOpen={isOpen} centered backdrop="static" scrollable>
      <UserPasswordContainer isRestricted={isOpen} />
    </Modal>
  );
};

export default Restriction;
