import URLS from '../routing/urls';
import { TRoute } from '../routing/types';
import { TMenu } from './types';

export const isParentCurrentRoute = (childrenRoutes: TRoute[]): boolean => {
  const { pathname } = window.location;
  const validUrl = childrenRoutes.filter((route) => {
    return pathname === route.url;
  });
  return validUrl.length > 0;
};

export const isCurrentRoute = (url: string): boolean => {
  const { pathname } = window.location;
  return pathname === url;
};

export const overviewMenu: TRoute[] = [
  {
    url: URLS.OVERVIEW_ALL,
    name: 'All',
    isActive: isCurrentRoute(URLS.OVERVIEW_ALL)
  },
  {
    url: URLS.OVERVIEW_YOUTUBE,
    name: 'Youtube',
    isActive: isCurrentRoute(URLS.OVERVIEW_YOUTUBE)
  }
];

export const managementMenu: TRoute[] = [
  {
    url: URLS.MANAGEMENT_ALL,
    name: 'All',
    isActive: isCurrentRoute(URLS.MANAGEMENT_ALL)
  },
  {
    url: URLS.MANAGEMENT_YOUTUBE,
    name: 'Youtube',
    isActive: isCurrentRoute(URLS.MANAGEMENT_YOUTUBE)
  }
];

export const getMenu = () => {
  const menu: TMenu[] = [
    {
      rank: 1,
      mainRoute: {
        url: URLS.USER_PROFILE,
        name: 'User profile',
        icon: 'tim-icons icon-settings-gear-63',
        isActive: isCurrentRoute(URLS.USER_PROFILE)
      },
      id: 'userProfile'
    },
    {
      rank: 2,
      requires: 'overview.access',
      mainRoute: {
        name: 'Overview',
        icon: 'tim-icons icon-chart-pie-36',
        isActive: isParentCurrentRoute(overviewMenu)
      },
      subRoutes: overviewMenu,
      id: 'overview'
    },
    {
      rank: 3,
      requires: 'insights.access',
      mainRoute: {
        url: URLS.INSIGHTS,
        name: 'Insights Video',
        icon: 'tim-icons icon-video-66',
        isActive: isCurrentRoute(URLS.INSIGHTS)
      },
      id: 'insights'
    },
    {
      rank: 4,
      requires: 'creative.access',
      mainRoute: {
        url: URLS.CREATIVE_ANALYTICS,
        name: 'Creative Gallery',
        icon: 'tim-icons icon-molecule-40',
        isActive:
          isCurrentRoute(URLS.CREATIVE_ANALYTICS) ||
          isCurrentRoute(URLS.LAUNCHER)
      },
      id: 'creative'
    },
    {
      rank: 5,
      requires: 'manage.access',
      mainRoute: {
        name: 'Management',
        icon: 'tim-icons icon-tv-2'
      },
      subRoutes: managementMenu,
      id: 'management'
    },
    {
      rank: 6,
      requires: 'media_plan.access',
      mainRoute: {
        url: URLS.MEDIA_PLAN_GALLERY,
        name: 'Media Plan',
        icon: 'icon-media-plan',
        isActive: isCurrentRoute(URLS.MEDIA_PLAN_GALLERY)
      },
      id: 'media_plan_gallery'
    }
  ];

  menu.sort((a, b) => a.rank - b.rank);
  return menu;
};
