import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalBody } from 'reactstrap';
import CtaTable from './cta_table';
import CtaModalHeader from './cta_modal_header';
import { VrrModal } from '../../../../../../shared_components';
import {
  getMediaPlanCallToActions,
  editMediaPlanDetails
} from '../../../../../../../redux/mediaPlanGallery/mediaPlanGalleryAction';
import { TCtaModal } from './types';
import { TCallToActionRow } from './cta_table/types';
import { TMediaPlanDetails } from '../../../../types';
import './CtaModal.scss';

export const CtaModal: React.FC<TCtaModal> = ({
  isOpen,
  onCancelAction
}: TCtaModal) => {
  const dispatch: any = useDispatch();
  const mediaPlanCallToActions = useSelector(getMediaPlanCallToActions);

  const [currentCtas, setCurrentCtas] = useState<
    TMediaPlanDetails['call_to_actions'] | undefined
  >(undefined);
  const childRef = useRef(null);

  useEffect(() => {
    if (mediaPlanCallToActions) {
      setCurrentCtas(mediaPlanCallToActions);
    }
  }, [mediaPlanCallToActions]);

  const addLineAction = () => {
    (childRef.current as any).addNewLine();
  };

  const onCancel = () => {
    setCurrentCtas(mediaPlanCallToActions);
    if (onCancelAction) {
      onCancelAction();
    }
  };

  const onConfirmCta = (ctas: TCallToActionRow[]) => {
    setCurrentCtas(
      ctas?.map((cta) => {
        return {
          id: cta.id,
          title: cta.title,
          button: cta.button
        };
      })
    );
  };

  const onSubmitAction = () => {
    if (currentCtas) {
      dispatch(editMediaPlanDetails({ callToActions: currentCtas }));
    }
    if (onCancelAction) {
      onCancelAction();
    }
  };

  return (
    <VrrModal
      className="cta-modal"
      isOpen={isOpen}
      modalHeader={<CtaModalHeader addLineAction={addLineAction} />}
      modalBody={
        <ModalBody
          className="cta-modal-body"
          data-cy-id="media-plan-detail-edit-cta"
        >
          <CtaTable
            callToActions={currentCtas}
            ref={childRef}
            onChangeAction={onConfirmCta}
          />
        </ModalBody>
      }
      toggleModal={onCancel}
      toggleValidation={onSubmitAction}
      scrollable
      dataCyCancelButton="media-plan-detail-edit-close-button"
    />
  );
};

export default CtaModal;
