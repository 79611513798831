import { THeaderFilter, TTableBody } from './types';
import { SORT_BY } from '../vrr_sort_by/constants';

export const DEFAULT_LIMIT_ROW = 10;

export const sortByColumn = (
  data: TTableBody['rowElements'],
  key: string,
  sortType: string,
  limitRow?: number
): TTableBody['rowElements'] => {
  const clonedData = [...data];

  if (!key.length || sortType === SORT_BY.default) {
    if (limitRow) {
      return clonedData.slice(0, limitRow);
    }
    return clonedData;
  }

  const dataToSort = clonedData.sort((array1, array2) => {
    let dataSorted = 1;
    array1.forEach((element1) => {
      if (element1.headerId === key) {
        array2.forEach((element2) => {
          if (element2.headerId === key) {
            const elem1: number = parseFloat(element1?.value?.toString());
            const elem2: number = parseFloat(element2?.value?.toString());

            const dataType: string = Number.isNaN(elem1) ? 'string' : 'numeric';

            const sortBySortTypeAndDataType: {
              [key: string]: { [key: string]: number };
            } = {
              ASC: {
                string: element1?.value.localeCompare(element2?.value),
                numeric: elem1 < elem2 ? -1 : 1
              },
              DESC: {
                string: element2?.value.localeCompare(element1?.value),
                numeric: elem1 < elem2 ? 1 : -1
              }
            };

            dataSorted = sortBySortTypeAndDataType[sortType][dataType];
          }
        });
      }
    });
    return dataSorted;
  });

  if (limitRow) {
    return dataToSort.slice(0, limitRow);
  }

  return dataToSort;
};

export const updateSearchValues = (
  allValues: THeaderFilter[],
  newHeader: string | undefined,
  newValue: string | undefined
): THeaderFilter[] => {
  const newAllValues = [...allValues];
  if (!newValue?.length) {
    return newAllValues.filter((value) => value.header !== newHeader);
  }

  if (
    !newAllValues.find((value) => value.header === newHeader) &&
    newHeader?.length &&
    newValue?.length
  ) {
    newAllValues.push({
      header: newHeader,
      value: newValue
    });
    return newAllValues;
  }

  return newAllValues?.map((value) => {
    if (value.header === newHeader) {
      return {
        header: value.header,
        value: newValue
      };
    }
    return value;
  });
};

const filterByColumn = (
  allSearch: THeaderFilter[],
  data: TTableBody['rowElements'],
  searchIndex: number
): TTableBody['rowElements'] => {
  let findRow: TTableBody['rowElements'] = [];
  data.forEach((row) =>
    row.forEach((cell) => {
      if (
        cell.headerId === allSearch[searchIndex]?.header &&
        cell.value
          ?.toLowerCase()
          .includes(allSearch[searchIndex]?.value?.toLowerCase())
      ) {
        findRow.push(row);
      }
    })
  );

  if (allSearch.length > searchIndex + 1) {
    findRow = filterByColumn(allSearch, findRow, searchIndex + 1);
  }

  return findRow;
};

export const filterAction = (
  data: TTableBody['rowElements'],
  allSearch: THeaderFilter[]
): TTableBody['rowElements'] => {
  if (allSearch?.length) {
    return filterByColumn(allSearch, data, 0);
  }
  return data;
};
