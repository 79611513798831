import React from 'react';
import { VrrCard } from '../../../../shared_components';
import './MetricDetailPin.scss';

const MetricDetailPin: React.FC<any> = ({ body, header }) => {
  return (
    <VrrCard
      className="metric-detail-pin"
      cardHeader={header}
      cardBody={{
        element: body
      }}
    />
  );
};
export default MetricDetailPin;
