import React from 'react';
import { VrrSearch } from 'micro_front_end/shared_components';
import { formatFunnelSearchList } from './services';
import { TSearchCategories } from './types';
import './SearchCategories.scss';

const SearchCategories: React.FC<TSearchCategories> = ({ onSearchAction }) => {
  return (
    <header className="search-section">
      <VrrSearch
        searchList={formatFunnelSearchList()}
        onSearchAction={onSearchAction}
      />
    </header>
  );
};

export default SearchCategories;
