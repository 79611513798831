import React from 'react';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { PrivateComponent } from '../../../micro_front_end/permissions';
import './Menu.scss';
import { IMenu } from './types';

const Menu: React.FC<IMenu> = ({
  menus,
  onClickAction,
  selectedElement
}: IMenu) => {
  const { t } = useTranslation();

  return (
    <div className="user-profile-menu-container">
      {menus?.map((menu) => (
        <PrivateComponent
          requires={menu.requires}
          key={menu.id}
          authorizationStrategy={menu.authorizedStrategy}
        >
          <Button
            data-cy-id={menu.cyId}
            key={menu.id}
            onClick={() => onClickAction(menu.id)}
            className={`${menu.className} ${
              selectedElement === menu.id ? 'selected' : ''
            }`}
          >
            <span className="label-button">
              <i className={`tim-icons ${menu.icon}`} /> {t(menu.name)}
            </span>
          </Button>
        </PrivateComponent>
      ))}
    </div>
  );
};

export default Menu;
