const CREATIVE_GALLERY_ENDPOINTS = {
  AD_ACCOUNT_FOLDERS: (ad_account_id: string): string =>
    `/ad_accounts/${ad_account_id}/folders`,
  AD_ACCOUNT_SPECIFIC_FOLDER: (
    ad_account_id: string,
    folder_id: string
  ): string => `/ad_accounts/${ad_account_id}/folders/${folder_id}`,
  AD_ACCOUNT_CREATE_FOLDER: (ad_account_id: string): string =>
    `/ad_accounts/${ad_account_id}/folders`,
  AD_ACCOUNT_VIDEO: (ad_account_id: string, video_id: string): string =>
    `/ad_accounts/${ad_account_id}/videos/${video_id}`,
  AD_ACCOUNT_ARCHIVE: (ad_account_id: string): string =>
    `/ad_accounts/${ad_account_id}/archive`,
  AD_ACCOUNT_IMPORT_VIDEOS: (ad_account_id: string): string =>
    `/ad_accounts/${ad_account_id}/videos/import`,
  IMPORT_VIDEOS: (ad_account_id: string): string =>
    `/ad_accounts/${ad_account_id}/videos/advanced_import`
};

export default CREATIVE_GALLERY_ENDPOINTS;
