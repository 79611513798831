import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import useSettings from '../../../libs/settings/useSettings';
import { getPeriod } from '../../../redux/userPreferences/actions/userPreferencesActions';
import RangeDate from './range_date/RangeDate';
import { TDateRange } from './types';

const DateTimePicker: React.FC = () => {
  const { vrr } = useSettings();
  const datePickerPeriod = useSelector(getPeriod);

  const onUpdate = (datePeriod: TDateRange) => {
    vrr({
      period: {
        from: moment(datePeriod.from).startOf('day'),
        to: moment(datePeriod.to).startOf('day')
      }
    });
  };

  return (
    !!datePickerPeriod?.from &&
    !!datePickerPeriod?.to && (
      <RangeDate
        onUpdate={onUpdate}
        datePickerPeriod={
          datePickerPeriod
            ? {
                from: moment(datePickerPeriod.from),
                to: moment(datePickerPeriod.to)
              }
            : undefined
        }
        format="YYYY-MM-DD"
        className="cy-datepicker-dropdown"
      />
    )
  );
};

export default DateTimePicker;
