import React from 'react';
import { VrrButton } from '../../../../../../../shared_components';
import { TRedirectionEditActions } from './types';

const RedirectionEditActions: React.FC<TRedirectionEditActions> = ({
  onCancel,
  onSubmit,
  disabled = false
}: TRedirectionEditActions) => {
  return (
    <div className="redirection-table-actions">
      <VrrButton
        className="redirection-submit-action"
        icon={{ iconLabel: 'tim-icons icon-check-2' }}
        onClick={onSubmit}
        disabled={disabled}
      />
      <VrrButton
        className="btn-link redirection-cancel-action"
        icon={{ iconLabel: 'tim-icons icon-simple-remove' }}
        onClick={onCancel}
      />
    </div>
  );
};

export default RedirectionEditActions;
