import React from 'react';
import VideoPreview from './video_preview';
import BrandPreview from './brand_preview';
import { prepareRemovePreviews, prepareUpdatePreviews } from './services';
import { TVideoPreview } from './types';
import {
  TCreativeImportVideo,
  TPreview
} from '../../../../../../redux/creativeAnalytics/creativeImportVideo/types';
import './Preview.scss';

const Preview: React.FC<TVideoPreview> = ({
  brand,
  previews,
  onChangePreview,
  onChangeBrand
}) => {
  const onRemove = (index: number) => {
    const newPreviews = prepareRemovePreviews(index, previews);
    if (onChangePreview) onChangePreview(newPreviews);
  };

  const onChangePreviews = (
    node: keyof TPreview,
    value: TPreview['details'],
    index: number
  ) => {
    const newPreviews = prepareUpdatePreviews(node, value, index, previews);
    if (onChangePreview) onChangePreview(newPreviews);
  };

  return (
    <>
      <BrandPreview
        brand={brand}
        onChangeAction={(newBrand: TCreativeImportVideo['brand']) => {
          if (onChangeBrand) onChangeBrand(newBrand);
        }}
      />
      <hr />
      {previews?.map((preview: TPreview, index: number) => (
        <div key={preview.video?.video_id}>
          <VideoPreview
            videoIndex={index}
            details={preview.details}
            onChangeAction={(details: TPreview['details']) =>
              onChangePreviews('details', details, index)
            }
            {...(previews?.length > 1 && { onRemoveAction: onRemove })}
            {...preview.video}
          />
        </div>
      ))}
    </>
  );
};

export default Preview;
