import React from 'react';
import i18next from 'i18next';
import { NavigateFunction } from 'react-router-dom';
import URLS from '../routing/urls';
import { TVrrBreadcrumb } from '../shared_components/vrr_breadcrumb/types';

export const BREADCRUMB: TVrrBreadcrumb['items'] = [
  {
    label: i18next.t('creative_video_details.breadcrumb.main')?.toUpperCase(),
    id: '-1'
  },
  {
    label: i18next.t('launcher.breadcrumb.main')?.toUpperCase(),
    id: '0'
  }
];

export const goGallery = (
  history: NavigateFunction,
  callback: React.Dispatch<React.SetStateAction<number | undefined>>
) => {
  callback(0);
  history({
    pathname: URLS.CREATIVE_ANALYTICS
  });
};
