import React, { useEffect, useState } from 'react';
import Table from '../ExtendTable/ExtendTable';
import { ManagementCampaignInterface, TManageTableCell } from './types';

const ManagementCampaign: React.FC<ManagementCampaignInterface> = ({
  dataTable,
  headersTable,
  loadingData
}: ManagementCampaignInterface) => {
  const [resultDataTable, setResultDataTable] = useState<
    Array<{
      [key: string]: string | number | undefined;
    }>
  >([]);

  useEffect(() => {
    setResultDataTable([]);
    if (dataTable?.length > 0) {
      const newResultDataTable = [...resultDataTable];
      dataTable.map((dataTableRow: TManageTableCell) => {
        const resultItemDataTable: any = {};
        Object.keys(dataTableRow)?.forEach((accessor) => {
          resultItemDataTable[accessor] = dataTableRow[accessor]?.value || '-';
        });
        newResultDataTable.push(resultItemDataTable);
      });
      setResultDataTable(newResultDataTable);
    }
  }, [dataTable]);

  return (
    <Table
      dataTable={resultDataTable}
      headersTable={headersTable}
      loadingData={loadingData}
    />
  );
};

export default ManagementCampaign;
