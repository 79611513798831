import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CardDeck } from 'reactstrap';
import GlobalMetricsModal from './global_metrics_modal';
import LoaderGlobalMetric from '../loader_global_metric';
import { VrrAddCard } from '../../../shared_components';
import { prepareMetrics } from './services';
import { TDeckMetrics } from './types';
import { TSelectionMetric } from './card_metric/types';
import './DeckMetrics.scss';

const DeckMetrics: React.FC<TDeckMetrics> = ({
  metrics,
  onSubmitAction,
  onClickAction
}) => {
  const { t } = useTranslation();
  const [openForm, setOpenForm] = useState(false);

  const onClickAdd = () => setOpenForm(!openForm);

  const onSubmit = (newMetrics?: TSelectionMetric[]) => {
    if (onSubmitAction && newMetrics) onSubmitAction(newMetrics);
    onClickAdd();
  };

  return (
    <div className="deck-metrics">
      {!metrics ? (
        <LoaderGlobalMetric />
      ) : (
        <CardDeck className="selected-metrics">
          {prepareMetrics(metrics, onClickAction)}
          <VrrAddCard
            dataCy="overview-add-global-button"
            label={t('global_metrics.deck_metrics.add')}
            onClickAction={onClickAdd}
          />
        </CardDeck>
      )}
      <GlobalMetricsModal
        isOpen={openForm}
        onSubmitAction={onSubmit}
        onCancelAction={onClickAdd}
      />
    </div>
  );
};

export default DeckMetrics;
