import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { editLauncher, newLaunch } from './launcherActions';
import { TLauncher } from './types';

const initialState: TLauncher = {
  launcherDetails: undefined
};

const launcherSlice = createSlice({
  name: 'launcher',
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      newLaunch.fulfilled,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          launcherDetails: action.payload
        };
      }
    );
    builder.addCase(
      editLauncher.fulfilled,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          launcherDetails: action.payload
        };
      }
    );
  }
});

export default launcherSlice.reducer;
