import React from 'react';
import { Container } from 'reactstrap';
import NotFound from '../../components/NotFound';

const ErrorPage: React.FC = () => {
  return (
    <Container>
      <NotFound />
    </Container>
  );
};

export default ErrorPage;
