import React, { useEffect, useState } from 'react';
import { Card } from 'reactstrap';
import MediaPlanTargetsSection from './MediaPlanTargetsSection';
import {
  SECTIONS,
  TMediaPlanTargets,
  TMediaPlanTargetsSection,
  TTargetsValues
} from './types';
import './styles/MediaPlanTargets.scss';

const MediaPlanTargets: React.FC<TMediaPlanTargets> = ({
  targetsValues,
  onClickEditButton
}: TMediaPlanTargets) => {
  const [sections, setSections] = useState(
    SECTIONS as TMediaPlanTargetsSection[]
  );

  const addSectionValues = () => {
    const sectionWithValues = sections.map((section) => {
      return {
        ...section,
        values: targetsValues?.[section.titleId as keyof TTargetsValues]
      };
    });

    setSections(sectionWithValues);
  };

  useEffect(() => {
    if (targetsValues) {
      addSectionValues();
    }
  }, [targetsValues]);

  return (
    <Card className="media-plan-targets-card">
      {sections.map((section, index) => (
        <MediaPlanTargetsSection
          key={`media-plan-targets-${index.toString()}`}
          onClickEditButton={onClickEditButton}
          {...section}
        />
      ))}
    </Card>
  );
};

export default MediaPlanTargets;
