import React from 'react';
import { TTargets, TTargetsSection } from './types';
import { useTranslation } from 'react-i18next';
import './styles/MediaPlanTargetsSection.scss';

const TargetsSection: React.FC<TTargetsSection> = ({
  targets
}: TTargetsSection) => {
  const { t } = useTranslation();

  return (
    <>
      {targets &&
        Object.keys(targets)?.map((funnel) => {
          const value = (targets as TTargets)[funnel as keyof TTargets];
          return (
            <div
              key={`media-plan-targets-section-funnel-${funnel}`}
              className={`media-plan-targets-section-targets ${
                value > 0 ? 'with-data' : 'no-data'
              } ${funnel}`}
            >
              <span className="targets-count">{value > 0 ? value : '-'}</span>
              {t(
                'media_plan_details.media_plan_targets.media_plan_targets_section.funnel',
                {
                  funnel: funnel
                }
              )}
            </div>
          );
        })}
    </>
  );
};

export default TargetsSection;
