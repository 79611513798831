import React from 'react';
import { useTranslation } from 'react-i18next';
import { TGendersSection } from './types';
import { ReactComponent as FemaleSVG } from '../../../../../assets/vrr/images/icon_woman.svg';
import { ReactComponent as MaleSVG } from '../../../../../assets/vrr/images/icon_men.svg';
import './styles/MediaPlanTargetsSection.scss';

const GendersSection: React.FC<TGendersSection> = ({
  genders
}: TGendersSection) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        key={`media-plan-targets-section-gender-male`}
        className={`media-plan-targets-section-gender ${
          genders?.includes('male') ? 'with-data' : 'no-data'
        } male`}
      >
        <MaleSVG />
        {genders?.includes('male')
          ? t(
              `media_plan_details.media_plan_targets.media_plan_targets_section.genders.male`
            )
          : '-'}
      </div>
      <div
        key={`media-plan-targets-section-gender-female`}
        className={`media-plan-targets-section-gender ${
          genders?.includes('female') ? 'with-data' : 'no-data'
        } female`}
      >
        <FemaleSVG />
        {genders?.includes('female')
          ? t(
              `media_plan_details.media_plan_targets.media_plan_targets_section.genders.female`
            )
          : '-'}
      </div>
    </>
  );
};

export default GendersSection;
