import { RootState } from '../../store';
import { prepareSettingsByCurrentAccount } from '../../../services/services';
import { TDateRange } from '../../../micro_front_end/nav_bar/date_time_picker/types';
import {
  TAllMetricsPreferences,
  TMetricsPreferences,
  TGlobalMetric,
  TGlobalMetricPreferenceValue,
  TInsightsCards,
  TInsightsFilters,
  TInsightsFiltersPreferenceValue,
  TLabelsListPreference
} from '../types';
import { TAccountLabel } from '../../userProfile/types';

export const updatePreferences = (
  account: string,
  newSetting:
    | TGlobalMetricPreferenceValue
    | TMetricsPreferences
    | TInsightsFiltersPreferenceValue,
  nodeSettings: TGlobalMetric[] | TAllMetricsPreferences[] | TInsightsFilters[]
): TGlobalMetric[] | TAllMetricsPreferences[] => {
  return (
    nodeSettings as Array<
      TGlobalMetric | TAllMetricsPreferences | TInsightsFilters
    >
  ).map(
    (setting: TGlobalMetric | TAllMetricsPreferences | TInsightsFilters) => {
      if (setting?.current_account_id === account) {
        return {
          ...setting,
          ...newSetting
        };
      }
      return setting;
    }
  );
};

export const createPreferences = (
  account: string,
  newSetting: TGlobalMetricPreferenceValue | TMetricsPreferences,
  nodeSettings: TGlobalMetric[] | TAllMetricsPreferences[]
): TGlobalMetric[] | TAllMetricsPreferences[] => {
  const newSettingCompleted = {
    current_account_id: account,
    ...newSetting
  } as TGlobalMetric | TAllMetricsPreferences;
  const nodeSettingsUpdated = [...nodeSettings];

  nodeSettingsUpdated.push(newSettingCompleted);

  return nodeSettingsUpdated;
};

export const getInsightsCardsByCurrentAccount = (
  state: RootState
): TInsightsCards | null => {
  const { current_account_id } = state.userPreferences.settings.vrr;
  const { cards } = state.userPreferences.settings.insights;

  return prepareSettingsByCurrentAccount(
    current_account_id,
    cards
  ) as TInsightsCards;
};

export const getLanguage = (state: RootState): string =>
  state.userPreferences.settings.vrr.language;

export const getPeriod = (state: RootState): TDateRange | null =>
  state.userPreferences.settings.vrr.period as TDateRange;

export const getAccountId = (state: RootState): string | null =>
  state.userPreferences.settings.vrr.current_account_id;

export const getGMSettingsByCurrentAccount = (
  state: RootState
): TGlobalMetric | undefined => {
  const { current_account_id } = state.userPreferences.settings.vrr;
  const { global_metrics } = state.userPreferences.settings.overview;

  return prepareSettingsByCurrentAccount(
    current_account_id,
    global_metrics
  ) as TGlobalMetric;
};

export const getGISettingsByCurrentAccount = (
  state: RootState
): TAllMetricsPreferences | null => {
  const { current_account_id } = state.userPreferences.settings.vrr;
  const { global_insights } = state.userPreferences.settings.overview;

  return prepareSettingsByCurrentAccount(
    current_account_id,
    global_insights
  ) as TAllMetricsPreferences;
};

export const getInsightsFiltersByCurrentAccount = (
  state: RootState
): TInsightsFilters | null => {
  const { current_account_id } = state.userPreferences.settings.vrr;
  const { filters } = state.userPreferences.settings.insights;

  return prepareSettingsByCurrentAccount(
    current_account_id,
    filters
  ) as TInsightsFilters;
};

export const getCustomLabelsListByAccount = (
  state: RootState,
  account_id?: string
): TAccountLabel[] | null => {
  const chosen_account =
    account_id ?? state.userPreferences.settings.vrr.current_account_id;
  return state.userPreferences.settings.vrr.custom_labels_list !== null &&
    chosen_account !== null
    ? state.userPreferences.settings.vrr.custom_labels_list[
        chosen_account as keyof TLabelsListPreference
      ]
    : null;
};

export const getCustomLabels = (
  state: RootState
): TLabelsListPreference | null | undefined => {
  return state.userPreferences.settings.vrr.custom_labels_list;
};

export const getMetricSelections = (state: RootState): string[] | undefined => {
  const currentMetricsSettings: TAllMetricsPreferences | null =
    getGISettingsByCurrentAccount(state);
  return currentMetricsSettings?.metricSelections;
};

export const getKpiSelections = (state: RootState): string[] | undefined => {
  const currentMetricsSettings: TAllMetricsPreferences | null =
    getGISettingsByCurrentAccount(state);
  return currentMetricsSettings?.kpiSelections;
};

export const getMainKpi = (state: RootState): string | undefined => {
  const currentMetricsSettings: TAllMetricsPreferences | null =
    getGISettingsByCurrentAccount(state);
  return currentMetricsSettings?.mainKpi;
};

export const getMainKpiSort = (state: RootState): string | undefined => {
  const currentMetricsSettings: TAllMetricsPreferences | null =
    getGISettingsByCurrentAccount(state);
  return currentMetricsSettings?.mainKpiSort;
};

export const getPinedMetrics = (state: RootState): string[] | undefined => {
  const currentMetricsSettings: TAllMetricsPreferences | null =
    getGISettingsByCurrentAccount(state);
  return currentMetricsSettings?.pinnedMetrics;
};
