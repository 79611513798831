import { METRICS } from './metric_selection_card/services';
import { TMetricSelectionCard } from './metric_selection_card/types';
import MetricSelectionCard from './metric_selection_card';
import { TSelection } from '../../../../shared_components/vrr_selections/types';

export const prepareMetricsList = (): TSelection[] => {
  return Object.keys(METRICS).map((metric) => {
    return {
      id: metric,
      element: (
        <MetricSelectionCard
          id={metric as TMetricSelectionCard['id']}
          target="list"
        />
      )
    };
  });
};

export const prepareSelectedMetricsList = (
  selectedMetricsList: string[]
): TSelection[] => {
  return !!selectedMetricsList?.length
    ? selectedMetricsList?.map((metric) => {
        return {
          id: metric,
          element: (
            <MetricSelectionCard
              id={metric as TMetricSelectionCard['id']}
              target="selected"
            />
          )
        };
      })
    : [];
};
