import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import apiVRR from '../../libs/axios/apis';
import endPoints from '../../services/endpoints';

export const fetchInsights = createAsyncThunk(
  'insights/fetchInsights',
  async (_, { getState }) => {
    const settings = {
      ...(getState() as any).userPreferences.settings.vrr
    };

    return apiVRR().get(endPoints.INSIGHTS.LIST, {
      params: {
        id: settings.current_account_id,
        start: settings.period.from,
        end: settings.period.to,
        label: (getState() as any).app.funnel
      }
    });
  }
);

export const fetchFilters = createAsyncThunk(
  'insights/fetchFilters',
  async (_, { getState }) => {
    const settings = {
      ...(getState() as any).userPreferences.settings.vrr
    };

    return apiVRR().get(endPoints.INSIGHTS.FILTERS, {
      params: {
        id: settings.current_account_id,
        start: settings.period.from,
        end: settings.period.to,
        label: (getState() as any).app.funnel
      }
    });
  }
);

export const getInsights = (state: RootState): any => state.insights.list;

export const getFilters = (state: RootState): any => state.insights.filters;
