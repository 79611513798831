import { createSlice } from '@reduxjs/toolkit';
import {
  fetchMetricFulfilled,
  fetchMetricPending,
  fetchMetricRejected
} from './MetricsActions';
import { TMetricsInitialState } from './types';

export const initialState: TMetricsInitialState = {
  allMetrics: {
    age: undefined,
    gender: undefined,
    audience: undefined,
    device: undefined,
    redirection: undefined,
    video: undefined,
    zone: undefined,
    taxonomy: undefined,
    cta: undefined,
    funnel: undefined
  }
};

export const metricsSlice = createSlice({
  name: 'metrics',
  initialState: { ...initialState },
  reducers: {
    resetMetrics: () => {
      return {
        ...initialState
      };
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(fetchMetricFulfilled, (state, action) => {
      const insight =
        action.type.split('/')[2] === 'call_to_action'
          ? 'cta'
          : action.type.split('/')[2];
      return {
        ...state,
        allMetrics: {
          ...state.allMetrics,
          [insight]: {
            header: (action as any).payload.data.data.header,
            data:
              (action as any).payload.data.data.data[
                action.type.split('/')[2]
              ] || []
          }
        }
      };
    });
    builder.addMatcher(fetchMetricPending, (state, action) => {
      const insight =
        action.type.split('/')[2] === 'call_to_action'
          ? 'cta'
          : action.type.split('/')[2];
      return {
        ...state,
        allMetrics: {
          ...state.allMetrics,
          [insight]: undefined
        }
      };
    });
    builder.addMatcher(fetchMetricRejected, (state, action) => {
      const insight =
        action.type.split('/')[2] === 'call_to_action'
          ? 'cta'
          : action.type.split('/')[2];
      return {
        ...state,
        allMetrics: {
          ...state.allMetrics,
          [insight]: {
            header: [],
            data: []
          }
        }
      };
    });
  }
});

export default metricsSlice.reducer;
