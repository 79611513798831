import React from 'react';
import VrrButton from '../vrr_button';
import './VrrTag.scss';
import { TVrrTag } from './types';

export const VrrTag: React.FC<TVrrTag> = ({
  label,
  id,
  color = 'cyan',
  icon,
  onClickAction
}: TVrrTag) => {
  return (
    <div className={`vrr_tag ${color}`}>
      <p>{label}</p>
      {icon && onClickAction && (
        <VrrButton
          className="btn-link"
          icon={{
            iconLabel: `${icon}`
          }}
          onClick={() => onClickAction(id)}
        />
      )}
    </div>
  );
};

export default VrrTag;
