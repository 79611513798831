import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './en.json';
import { FR, EN } from '../micro_front_end/translations';
import fr from './fr.json';
import LANG from './i18nConstants';

const resources = {
  en: {
    translation: {
      ...en,
      ...EN
    }
  },
  fr: {
    translation: {
      ...fr,
      ...FR
    }
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnNull: false,
    resources,
    fallbackLng: [...LANG],
    interpolation: {
      escapeValue: false,
      formatSeparator: '.'
    }
  });

export default i18n;
