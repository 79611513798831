import React from 'react';
import { generateBody } from './services';
import { TLauncherBody } from './types';

const LauncherBody: React.FC<TLauncherBody> = ({
  launch,
  currentStep,
  onChangeAction
}) => {
  return (
    <div className="launcher-body">
      {generateBody(currentStep, launch, onChangeAction)}
    </div>
  );
};

export default LauncherBody;
