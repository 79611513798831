import { createAsyncThunk } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { RootState } from '../store';
import { notify, updateAccount } from '../app/appSlice';
import { resetCurrentAccountId } from '../userPreferences/userPreferencesSlice';
import apiVRR from '../../libs/axios/apis';
import endPoints from '../../services/endpoints';
import { prepareAccountList } from '../../services/services';
import {
  ISaveUserProfile,
  ISaveUserProfileNewPassword,
  TAccountLabel
} from './types';
import { TAvailableAccount } from 'containers/UserProfile/ConnectedAccount/types';

export const fetchUserProfile = createAsyncThunk(
  'user/fetchProfile',
  async (_, { getState }) => {
    const userId = (getState() as any).app.vrr.id;
    const response = await apiVRR().get(endPoints.USER.PROFILE(userId));
    return response.data.data;
  }
);

export const updateUserProfile = createAsyncThunk(
  'user/updateProfile',
  async ({ email, username }: ISaveUserProfile, { getState }) => {
    const userProfileStates = {
      email,
      username
    };
    const userId = (getState() as any).app.vrr.id;
    await apiVRR().put(endPoints.USER.PROFILE(userId), userProfileStates);
    return userProfileStates;
  }
);

export const updateUserProfilePassword = createAsyncThunk(
  'user/updatePassword',
  async (
    { oldPassword, newPassword }: ISaveUserProfileNewPassword,
    { dispatch, getState }
  ) => {
    const userId = (getState() as any).app.vrr.id;
    const response = await apiVRR().put(endPoints.USER.PROFILE(userId), {
      oldPassword,
      newPassword
    });
    if (response.status >= 200 && response.status < 400) {
      dispatch(notify({ type: 'success' }));
    }
    return response;
  }
);

export const fetchConnectedAccountURL = createAsyncThunk(
  'user/fetchConnectedAccountURL',
  async (_, { getState }) => {
    const userId = (getState() as any).app.vrr.id;
    return apiVRR().get(
      endPoints.ACCOUNTS_ENDPOINTS.CONNECTED_ACCOUNT_PROMPT(userId)
    );
  }
);

export const fetchAvailableAccounts = createAsyncThunk(
  'user/fetchAvailableAccounts',
  async (_, { getState }) => {
    const userId = (getState() as any).app.vrr.id;

    return apiVRR().get(endPoints.USER.GET_ACCOUNTS(userId), {
      params: {
        available: true,
        refresh: true
      }
    });
  }
);

export const fetchAccountLabels = createAsyncThunk(
  'account/fetchAccountLabels',
  async (accountID: string) => {
    const response = await apiVRR().get(
      endPoints.ACCOUNTS_ENDPOINTS.LABELS(accountID)
    );

    return response.data.data;
  }
);

export const refreshAccountLabels = createAsyncThunk(
  'user/refreshAccountLabels',
  async (accountID: string, { dispatch }) => {
    const response = await apiVRR().post(
      endPoints.ACCOUNTS_ENDPOINTS.REFRESH_LABELS(accountID)
    );
    if (response.status >= 200 && response.status < 400) {
      dispatch(
        notify({
          type: 'success',
          options: {
            message: i18n.t(
              `components.connected_account.modal.notifications.refresh_success`
            )
          }
        })
      );
    }

    return response.data.data;
  }
);

export const addConnectedAccount = createAsyncThunk(
  'user/addConnectedAccount',
  async (list: Array<number>, { dispatch, getState }) => {
    const userId = (getState() as any).app.vrr.id;
    const response = await apiVRR().post(
      endPoints.ACCOUNTS_ENDPOINTS.CONNECTED_ACCOUNT_ADD_ACCOUNT(userId),
      {
        account_ids: list
      }
    );
    if (response.status >= 200 && response.status < 400) {
      dispatch(notify({ type: 'success' }));
      dispatch(
        updateAccount(prepareAccountList(response.data.data.account_unvailable))
      );
    }
    return response;
  }
);

export const removeConnectedAccount = createAsyncThunk(
  'user/removeConnectedAccount',
  async (list: Array<number>, { dispatch, getState }) => {
    const userId = (getState() as any).app.vrr.id;
    const response = await apiVRR().post(
      endPoints.ACCOUNTS_ENDPOINTS.CONNECTED_ACCOUNT_DELETE_ACCOUNT(userId),
      {
        account_ids: list
      }
    );
    if (response.status >= 200 && response.status < 400) {
      dispatch(notify({ type: 'success' }));
      dispatch(
        updateAccount(prepareAccountList(response.data.data.account_unvailable))
      );

      if (
        list.includes(
          (
            getState() as any
          ).userPreferences.settings.vrr.current_account_id.toString()
        )
      ) {
        dispatch(resetCurrentAccountId());
      }
    }
    return response;
  }
);

export const fetchConnectedAccountCallback = createAsyncThunk(
  'user/fetchConnectedAccountCallback',
  async ({ code }: any, { getState }) => {
    const userId = (getState() as any).app.vrr.id;
    return apiVRR().get(
      endPoints.ACCOUNTS_ENDPOINTS.CONNECTED_ACCOUNT_CALLBACK(userId),
      {
        params: code
      }
    );
  }
);

export const getUserProfile = (state: RootState): any => {
  return state.userProfile;
};

export const getAccountLabels = (
  state: RootState
): TAccountLabel[] | undefined => {
  return state.userProfile.connectedAccount.accountLabels;
};

export const getConnectedAccountURL = (state: RootState): string => {
  return state.userProfile.connectedAccount.url;
};

export const isConnectedToAccount = (state: RootState): boolean => {
  return state.userProfile.connectedAccount.isConnected;
};

export const getAvailableAccounts = (
  state: RootState
): TAvailableAccount[] | undefined =>
  state.userProfile.connectedAccount.availableAccounts;

export const getOrganizationRole = (state: RootState): any =>
  state.userProfile.organizationRole;
