import React, { PropsWithChildren } from 'react';
import './RightBar.scss';

const RightBar: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="right-bar">
      <div className="btn-right-bar-container">{children}</div>
    </div>
  );
};

export default RightBar;
