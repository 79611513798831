import React from 'react';
import MediaPlanAssetsCard from './media_plan_assets_card';
import { TMediaPlanAssets } from './types';
import {
  TAssetsCardType,
  TMediaPlanAssetsCard
} from './media_plan_assets_card/types';
import './MediaPlanAssets.scss';

const MediaPlanAssets: React.FC<TMediaPlanAssets> = ({
  assets,
  onClickEditButton
}: TMediaPlanAssets) => {
  return (
    <>
      {Object.keys(assets).map((item, index: number) => {
        return (
          <MediaPlanAssetsCard
            key={`${index.toString()}`}
            type={item as TAssetsCardType}
            assets={
              assets[
                item as keyof TMediaPlanAssets['assets']
              ] as TMediaPlanAssetsCard['assets']
            }
            onClickEditButton={onClickEditButton}
          />
        );
      })}
    </>
  );
};

export default MediaPlanAssets;
