import React from 'react';
import { VrrButton } from '../../../../../../../shared_components';
import { TRedirectionActions } from './types';

const RedirectionActions: React.FC<TRedirectionActions> = ({
  onEdit,
  onRemove
}: TRedirectionActions) => {
  return (
    <div className="redirection-table-actions">
      <VrrButton
        className="btn-link redirection-edit-action"
        icon={{ iconLabel: 'tim-icons icon-pencil' }}
        onClick={onEdit}
      />
      <VrrButton
        className="btn-link redirection-remove-action"
        icon={{ iconLabel: 'tim-icons icon-simple-remove' }}
        onClick={onRemove}
      />
    </div>
  );
};

export default RedirectionActions;
