import { METRIC_GENERAL_KPIS } from 'micro_front_end/overview/metrics/metrics/metric_table/services';

const HEADERS_WITHOUT_TOOLTIPS: string[] = ['taxonomy', 'name'];

const conversionActionTranslation: { [key: string]: string } = {
  performance_contribution:
    'metrics.metric_detail_modal.table.tooltip.performance_contribution',
  value: 'metrics.metric_detail_modal.table.tooltip.revenue',
  average_order_value:
    'metrics.metric_detail_modal.table.tooltip.average_order_value',
  roas: 'metrics.metric_detail_modal.table.tooltip.roas',
  _clicks: 'metrics.metric_detail_modal.table.tooltip.conversion_action_click',
  _cp: 'metrics.metric_detail_modal.table.tooltip.conversion_action_cp',
  _rate: 'metrics.metric_detail_modal.table.tooltip.conversion_action_rate'
};

export const findTranslationKeyForTooltip = (
  headerType: string
): string | null => {
  let translationKey = null;

  if (!HEADERS_WITHOUT_TOOLTIPS.includes(headerType)) {
    if (METRIC_GENERAL_KPIS.includes(headerType)) {
      translationKey = `metrics.metric_detail_modal.table.tooltip.${headerType}`;
    } else {
      translationKey = conversionActionTranslation[headerType];

      if (!translationKey) {
        const convertionActionKey = `_${headerType.split('_').slice(-1)[0]}`;
        translationKey = conversionActionTranslation[convertionActionKey];
      }
      if (!translationKey) {
        translationKey =
          'metrics.metric_detail_modal.table.tooltip.conversion_action';
      }
    }
  }
  return translationKey;
};
