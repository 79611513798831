import {
  IListState,
  TChildrenListState,
  TChildrenOptionValues
} from 'micro_front_end/shared_components/vrr_dropdown/types';
import { TLabels } from 'redux/userPreferences/types';

export function adaptLabelListPreference(
  labelListParam: TLabels[],
  groupOptionName: string
): TChildrenListState[] {
  if (!labelListParam || !labelListParam.length) {
    return [];
  }
  const initialValue: TChildrenListState[] = [
    { label: groupOptionName, options: [] }
  ];

  const labelList = labelListParam?.reduce((acc, labelParam) => {
    acc[0].options.push({
      label: labelParam?.name?.toString(),
      value: labelParam.name
    });
    return acc;
  }, initialValue);

  return labelList || [];
}

export const findCurrentLabel = (
  labelList: TChildrenListState[],
  currentLabel: string
): IListState | null => {
  let findOption: TChildrenOptionValues | undefined;

  labelList?.forEach((label) => {
    label.options.forEach((option: TChildrenOptionValues) => {
      if (option.value === currentLabel) {
        findOption = option;
      }
    });
  });

  return findOption ?? null;
};
