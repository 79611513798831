import * as d3 from 'd3';
import { MutableRefObject } from 'react';
import { TColors } from './type';

const COLORS: TColors = {
  red: '#e93f3b',
  orange: '#ff9705',
  cyan: '#2cd3bf',
  mauve: '#6c4cda'
};

export const prepareChart = (
  ref: MutableRefObject<any>,
  percent: number,
  color?: string
): void => {
  const width = 200;
  const height = Math.min(width, 200);
  const radius = Math.min(width, height) / 2;
  const data = [
    { value: percent, color: COLORS[color as keyof TColors] },
    { value: 100 - percent, color: '#f5f6fa' }
  ];

  const arc: any = d3
    .arc()
    .innerRadius(radius * 0.67)
    .outerRadius(radius - 1);

  const pie = d3
    .pie()
    .padAngle(1 / radius)
    .sort(null)
    .value((d: any) => d.value);

  const svgElement = d3.select(ref.current);

  svgElement
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', [-width / 2, -height / 2, width, height])
    .attr('style', 'max-width: 100%; height: auto;');

  svgElement.selectAll('path').remove();

  svgElement
    .selectAll('g')
    .data(pie(data as any))
    .join('path')
    .attr('fill', (d: any) => d.data.color as any)
    .attr('stroke', 'white')
    .attr('stroke-width', 3)
    .attr('d', arc)
    .attr('transform', 'rotate(' + 180 + ')');

  svgElement
    .append('circle')
    .attr('cx', 0)
    .attr('cy', 0)
    .attr('r', 66)
    .attr('fill', 'white');

  svgElement
    .append('svg:text')
    .attr('dy', '.35em')
    .attr('text-anchor', 'middle')
    .attr('font-size', '35')
    .attr('fill', '#344675')
    .text(`${percent}%`);
};
