import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Input, Label } from 'reactstrap';
import { TGoalList, Goals } from './types';
import { ReactComponent as Info } from '../../../../../../../../assets/vrr/images/info_icon.svg';
import './GoalList.scss';

const GoalList: React.FC<TGoalList> = ({
  onSelectAction,
  selected
}: TGoalList) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(selected);

  const handleSelect = (option: Goals) => {
    onSelectAction(option);
    setChecked(option);
  };

  const goals: Goals[] = [
    'impressions',
    'views',
    'clicks',
    'visits',
    'add_to_cart',
    'sales',
    'leads',
    'app_install'
  ];

  return (
    <div className="goal-list-container">
      <div className="goal-list-header" data-cy="goal-list-options">
        <p>
          {t('media_plan_edit.media_plan_config.objective.goal_list.header')}
        </p>
        <Info className="goal-list-info-icon" />
      </div>
      <FormGroup
        check
        className="goal-list-options"
        data-cy="goal-list-options"
      >
        {goals.map((option) => {
          return (
            <Label
              key={`goal-list-option-${option}`}
              check
              data-cy="goal"
              data-testid={`${option}-goal`}
            >
              <Input
                type="checkbox"
                name={`checkbox-${option}`}
                checked={checked == option}
                onChange={() => handleSelect(option)}
              />
              {t(
                `media_plan_edit.media_plan_config.objective.goal_list.goals.${option}`
              )}
            </Label>
          );
        })}
      </FormGroup>
    </div>
  );
};

export default GoalList;
