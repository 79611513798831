import React from 'react';
import { TSectionTitle } from './types';
import './SectionTitle.scss';

const SectionTitle: React.FC<TSectionTitle> = ({
  title,
  iconClassName
}: TSectionTitle) => {
  return (
    <h4 className="section-title">
      <i className={iconClassName} />
      <span>{title}</span>
    </h4>
  );
};

export default SectionTitle;
