import React from 'react';
import VideoCardFooter from './video_card_footer';
import VideoCardHeader from './video_card_header';
import VideoCardBody from './video_card_body';
import { VrrCard } from '../../../../shared_components';
import { TVideoCard } from './types';
import './VideoCard.scss';

const VideoCard: React.FC<TVideoCard> = ({
  vrr_id,
  status,
  name,
  devices,
  thumbnail,
  score,
  onChangeAction
}) => {
  const onChange = (videoId: number, device: string, isActive: boolean) => {
    if (onChangeAction) onChangeAction(videoId, device, isActive);
  };

  return (
    <VrrCard
      className="video-card"
      cardHeader={<VideoCardHeader id={vrr_id} status={status} name={name} />}
      cardBody={{
        element: <VideoCardBody score={score} thumbnail={thumbnail} />
      }}
      cardFooter={
        <VideoCardFooter
          id={vrr_id}
          devices={devices}
          onChangeAction={(device: string, isActive: boolean) =>
            onChange(vrr_id, device, isActive)
          }
        />
      }
    />
  );
};

export default VideoCard;
