import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalHeader } from 'reactstrap';
import { useSelector } from 'react-redux';
import { VrrButton } from '../../../../shared_components';
import KPIDropdown from './kpi_dropdown';
import useSettings from '../../../../../libs/settings/useSettings';
import { prepareOptions } from './services';
import { METRICS } from '../../metric_modal/metric_selections/metric_selection_card/services';
import {
  getKpiSelections,
  getMainKpi,
  getPinedMetrics
} from '../../../../../redux/userPreferences/actions/userPreferencesActions';
import { getMetricKpis } from '../../../../../redux/overview/metrics/MetricsActions';
import { TMetricDetailHeaderModal } from './types';
import { TKPIOption } from './kpi_dropdown/types';
import './MetricDetailHeader.scss';

export const MetricDetailHeader: React.FC<TMetricDetailHeaderModal> = ({
  metric,
  withPin,
  onCloseAction
}) => {
  const { t } = useTranslation();
  const { overview } = useSettings();
  const [options, setOptions] = useState<TKPIOption[]>([]);
  const [values, setValues] = useState<TKPIOption[]>([]);
  const kpis = useSelector(getMetricKpis(metric)) ?? [];
  const kpiSelections = useSelector(getKpiSelections) ?? [];
  const mainKpi = useSelector(getMainKpi);
  const pinedMetrics = useSelector(getPinedMetrics) ?? [];

  useEffect(() => {
    if (kpiSelections?.length) {
      setValues(prepareOptions(kpiSelections));
    }
  }, [kpiSelections]);

  useEffect(() => {
    if (metric?.length && kpis?.length) {
      setOptions(prepareOptions(kpis));
    }
  }, [metric, kpis, mainKpi]);

  const onChange = (selections: TKPIOption[]) => {
    overview('global_insights', {
      kpiSelections: selections.map((kpi) => kpi.value)
    });
  };

  const onPin = () => {
    const newPins = [...pinedMetrics];
    if (Array.isArray(newPins) && !newPins.includes(metric)) {
      newPins.push(metric);
      overview('global_insights', {
        pinnedMetrics: newPins
      });
    }
    if (onCloseAction) onCloseAction();
  };

  const onClose = () => {
    if (onCloseAction) onCloseAction();
  };

  return (
    <div className="metric-detail-header">
      <ModalHeader className="metric-detail-header-title">
        <span>{METRICS[metric].icon}</span>
        <span>{t(METRICS[metric].label)}</span>
      </ModalHeader>
      <div className="metric-detail-header-actions">
        <KPIDropdown
          values={values}
          mainKpi={mainKpi}
          options={options}
          onChangeAction={onChange}
        />
        {!!withPin && (
          <VrrButton
            className="btn-round pin-action"
            onClick={onPin}
            icon={{
              iconLabel: 'tim-icons icon-pin'
            }}
          />
        )}
        <VrrButton
          dataCy={`overview-details-cancel-${withPin ? 'pin' : 'no-pin'}`}
          className="close-action"
          onClick={onClose}
          icon={{
            iconLabel: 'tim-icons icon-simple-remove'
          }}
        />
      </div>
    </div>
  );
};

export default MetricDetailHeader;
