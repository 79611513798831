import React from 'react';
import { TLoaderGallery } from './types';
import './LoaderGallery.scss';

const LoaderGallery: React.FC<TLoaderGallery> = ({ itemNumber = 8 }) => {
  return (
    <div className="loader-gallery">
      {Array.from({ length: itemNumber }, (_, i) => (
        <div className="loader-gallery-card" key={i} />
      ))}
    </div>
  );
};

export default LoaderGallery;
