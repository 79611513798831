import i18next from 'i18next';
import { IListState } from '../../../../../../../shared_components/vrr_dropdown/types';
import { TRank } from './types';
import { TMediaPlanDetails } from '../../../../../types';
import { getCurrentCurrency } from '../../../../../../../../services/services';

export const KPI_RATE: string[] = ['vtr', 'ctr'];
export const KPI_COST: string[] = ['cpv', 'cpc', 'cpa'];

export const KPI_LIST: IListState[] = [
  {
    value: '',
    label: ''
  },
  {
    value: 'cpv',
    label: 'CPV'
  },
  {
    value: 'cpc',
    label: 'CPC'
  },
  {
    value: 'cpa',
    label: 'CPA'
  },
  {
    value: 'roas',
    label: 'ROAS'
  },
  {
    value: 'vtr',
    label: 'VTR'
  },
  {
    value: 'ctr',
    label: 'CTR'
  }
];

export const MIN_MAX = [
  {
    value: 'with_max_of',
    label: i18next.t('media_plan_edit.media_plan_config.optimization.goals.max')
  },
  {
    value: 'with_min_of',
    label: i18next.t('media_plan_edit.media_plan_config.optimization.goals.min')
  }
];

export const getFirstLineGoals = () => [
  {
    value: '',
    label: ''
  },
  {
    value: 'as_low',
    label: i18next.t(
      'media_plan_edit.media_plan_config.optimization.goals.as_low'
    )
  },
  {
    value: 'as_high',
    label: i18next.t(
      'media_plan_edit.media_plan_config.optimization.goals.as_high'
    )
  },
  ...MIN_MAX
];

export const getSecondLineGoals = () => [
  {
    value: '',
    label: ''
  },
  {
    value: 'at_least',
    label: i18next.t(
      'media_plan_edit.media_plan_config.optimization.goals.at_least'
    )
  },
  {
    value: 'at_most',
    label: i18next.t(
      'media_plan_edit.media_plan_config.optimization.goals.at_most'
    )
  },
  ...MIN_MAX
];

export const prepareKpiList = (kpiToRemove?: string) => {
  if (kpiToRemove) {
    return KPI_LIST.filter((node) => node.value !== kpiToRemove);
  }
  return KPI_LIST;
};

export const prepareRank = (
  rank: TRank,
  type: keyof TRank,
  selection: string | number
) => {
  const newRank: TRank = { ...rank };

  if (
    !!(
      (selection !== ('with_min_of' as string) ||
        selection !== ('with_max_of' as string)) &&
      type === 'goal' &&
      rank.target
    ) ||
    rank.target === null
  ) {
    delete newRank.target;
  }
  return {
    ...newRank,
    [type]: selection
  } as TRank;
};

export const getOptimizationSummary = (
  optimization: TMediaPlanDetails['optimization']
) => {
  const line = optimization?.find((node) => node?.rank === 1);
  if (line) {
    const rate = KPI_RATE.includes(line.kpi as string) ? '%' : '';
    const currency = KPI_COST.includes(line.kpi as string)
      ? getCurrentCurrency()
      : rate;
    const goal =
      line.goal === 'with_max_of' || line.goal === 'with_min_of'
        ? `${line.target} ${currency}`
        : i18next.t(
            `media_plan_edit.media_plan_config.optimization.goals.${line.goal}`
          );
    const target =
      line.goal === 'with_max_of' || line.goal === 'with_min_of'
        ? i18next.t(
            'media_plan_details.media_plan_config.optimization.highlight.optimization.target'
          )
        : '';
    return (
      <>
        <p className="config-text highlighting-strong">{goal}</p>
        <p className="config-text sub-detail">
          {target}
          {line.kpi?.toUpperCase()}
        </p>
      </>
    );
  }
  return (
    <>
      <p className="config-text highlighting-strong">-</p>
      <p className="config-text sub-detail">-</p>
    </>
  );
};
