import { TRoute } from './types';
import { Route } from 'react-router-dom';
import { TFeatures } from '../../redux/types';
import { routesMap } from './mapping';
import { VrrCircleLoader } from '../shared_components';
import PrivateRoute from './PrivateRoute';
import Layout from '../layout';

export const getFeatureByPath = (features?: TFeatures, path?: string) => {
  if (!features || !path) {
    return false;
  }

  const keys = path.split('.');

  const result = keys.reduce((acc: any, key: string) => {
    if (acc && acc[key] !== undefined) {
      return acc[key];
    }
    return undefined;
  }, features);

  return result !== undefined ? result : false;
};

export const generateRoutes = (
  session?: string,
  features?: TFeatures,
  isLoading?: boolean
) => {
  return routesMap.map((item: TRoute) => {
    const {
      component: Component,
      url,
      requires,
      isPrivate,
      navBarElements,
      name,
      props
    }: TRoute = item;
    const componentProps: any = {
      path: url,
      url,
      requires,
      isPrivate,
      navBarElements,
      session,
      features,
      ...props
    };
    return (
      <Route
        path={url}
        key={url}
        element={
          isPrivate ? (
            <VrrCircleLoader isEnable={!!(isLoading && features === undefined)}>
              <PrivateRoute key={name} {...componentProps}>
                <Layout>
                  <Component
                    routerProps={{ ...componentProps }}
                    component={Component}
                  />
                </Layout>
              </PrivateRoute>
            </VrrCircleLoader>
          ) : (
            <Component
              routerProps={{ ...componentProps }}
              component={Component}
            />
          )
        }
      />
    );
  });
};
