import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TCreativeGalleryInitialState } from './types';
import {
  fetchFolder,
  fetchRoot,
  moveVideo,
  addFolder,
  updateFolder,
  archive,
  fetchVideoById,
  fetchAllTags
} from './actions/creativeGalleryAPIs';
import {
  advancedImportAction,
  saveVideos
} from '../creativeImportVideo/creativeImportVideoActions';

const initialState: TCreativeGalleryInitialState = {
  breadcrumb: undefined,
  folders: undefined,
  videos: undefined,
  tags: undefined,
  rootId: undefined,
  tagVideos: undefined
};

const creativeGallerySlice = createSlice({
  name: 'creativeGallery',
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchFolder.fulfilled,
      (state, action: PayloadAction<any>) => {
        const { videos, pathFolders } = action.payload;
        return {
          ...state,
          videos,
          breadcrumb: pathFolders,
          folders: undefined
        };
      }
    );
    builder.addCase(
      fetchRoot.fulfilled,
      (state, action: PayloadAction<any>) => {
        const { id, folders, videos } = action.payload;
        return {
          ...state,
          folders,
          videos,
          breadcrumb: undefined,
          rootId: id
        };
      }
    );
    builder.addCase(
      moveVideo.fulfilled,
      (state, action: PayloadAction<any>) => {
        const { folders, videos, pathFolders } = action.payload.data.data;
        return {
          ...state,
          folders,
          videos,
          breadcrumb: pathFolders
        };
      }
    );
    builder.addCase(
      addFolder.fulfilled,
      (state, action: PayloadAction<any>) => {
        const { videos, folders, pathFolders } = action.payload;
        return {
          ...state,
          videos,
          folders,
          breadcrumb: pathFolders
        };
      }
    );
    builder.addCase(
      updateFolder.fulfilled,
      (state, action: PayloadAction<any>) => {
        const { videos, folders, pathFolders } = action.payload;
        return {
          ...state,
          videos,
          folders,
          breadcrumb: pathFolders
        };
      }
    );
    builder.addCase(archive.fulfilled, (state, action: PayloadAction<any>) => {
      const { videos, folders, pathFolders } = action.payload;
      return {
        ...state,
        videos,
        folders,
        breadcrumb: pathFolders
      };
    });
    builder.addCase(
      fetchVideoById.fulfilled,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          tagVideos: action.payload
        };
      }
    );
    builder.addCase(
      fetchAllTags.fulfilled,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          tags: action.payload
        };
      }
    );
    builder.addCase(
      saveVideos.fulfilled,
      (state, action: PayloadAction<any>) => {
        const { videos, folders, pathFolder } = action.payload;
        return {
          ...state,
          videos,
          folders,
          breadcrumb: pathFolder
        };
      }
    );
    builder.addCase(
      advancedImportAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        const { videos, folders, pathFolder } = action.payload;
        return {
          ...state,
          videos,
          folders,
          breadcrumb: pathFolder
        };
      }
    );
  }
});

export default creativeGallerySlice.reducer;
