const MEDIA_PLAN_GALLERY_ENDPOINTS = {
  MEDIA_PLAN: (accountId: string): string =>
    `/ad_accounts/${accountId}/media_plans`,
  MEDIA_PLAN_DETAILS: (accountId: string, mediaPlanId: string): string =>
    `/ad_accounts/${accountId}/media_plans/${mediaPlanId}`,
  MEDIA_PLAN_PIXELS: (accountId: string): string =>
    `/ad_accounts/${accountId}/media_plans/pixels`,
  NEW_MEDIA_PLAN: (accountId: string): string =>
    `/ad_accounts/${accountId}/media_plans`
};

export default MEDIA_PLAN_GALLERY_ENDPOINTS;
