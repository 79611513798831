import { createSlice } from '@reduxjs/toolkit';
import { fetchInsights, fetchFilters } from './insightsActions';
import { TInsightsInitialState } from './types';

const initialState: TInsightsInitialState = {
  list: null,
  filters: { duration: null, kpis: [], tags: [] }
};

const insightsSlice = createSlice({
  name: 'insights',
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInsights.pending, (state) => {
      return {
        ...state,
        list: null
      };
    });
    builder.addCase(fetchInsights.fulfilled, (state, action) => {
      return {
        ...state,
        list: (action.payload as any).data.data.list
      };
    });
    builder.addCase(fetchFilters.fulfilled, (state, action) => {
      return {
        ...state,
        filters: (action.payload as any).data.data
      };
    });
  }
});

export default insightsSlice.reducer;
