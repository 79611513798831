import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, {
  components,
  GroupBase,
  InputActionMeta,
  MultiValue
} from 'react-select';
import zones from '../../../../../../../../libs/geo_targets/geoTargets.min.json';
import search from '../../../../../../../services/search';
import { TZoneDropdown } from './types';
import { IListState } from '../../../../../../../shared_components/vrr_dropdown/types';

const ZoneDropdown: React.FC<TZoneDropdown> = ({
  onChange,
  selectedZones
}: TZoneDropdown) => {
  const [searchZone, setSearchZone] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [zoneList, setZoneList] = useState<IListState[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<IListState[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedZones) {
      const newZoneList: IListState[] = [];
      (selectedZones as string[]).forEach((zone) => {
        const findZone = search(zones, 'criteria_id', zone);
        newZoneList.push({
          label: findZone[0].name,
          value: zone
        });
      });
      setSelectedOptions(newZoneList);
    }
  }, [selectedZones]);

  const DropdownIndicator = (props: any) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <i className="tim-icons icon-zoom-split" />
        </components.DropdownIndicator>
      )
    );
  };

  const Menu = (props: any) => {
    return searchZone.length < 3 ? null : (
      <components.Menu {...props}>{props.children}</components.Menu>
    );
  };

  const handleSearch = (searchQuery: string) => {
    if (searchQuery.trim().length < 3) {
      setZoneList([]);
      return;
    }
    let findZones: { criteria_id: string; name: string }[] = [];
    setIsLoading(true);
    try {
      findZones = search(zones, 'name', searchQuery);
    } finally {
      setZoneList(
        findZones.map((zone) => {
          return {
            label: zone.name,
            value: zone.criteria_id
          };
        })
      );
      setIsLoading(false);
    }
  };

  const handleInputChange = (inputText: string, meta: InputActionMeta) => {
    if (meta.action !== 'input-blur' && meta.action !== 'menu-close') {
      setSearchZone(inputText);
      handleSearch(inputText);
    }
  };

  const handleChange = (values: MultiValue<any>) => {
    setSelectedOptions(values as IListState[]);
    if (onChange) {
      onChange(values as IListState[]);
    }
  };

  return (
    <Select
      noOptionsMessage={() => t('components.dropdown.noOptionsMessage')}
      options={zoneList as GroupBase<any>['options']}
      onChange={handleChange}
      onInputChange={handleInputChange}
      inputValue={searchZone}
      value={selectedOptions}
      placeholder={t(
        'media_plan_edit.media_plan_targets.zone_modal.zone_dropdown.placeholder'
      )}
      className="vrr-dropdown"
      classNamePrefix="vrr-dropdown"
      isMulti
      isLoading={isLoading}
      components={{
        Menu,
        IndicatorSeparator: () => null,
        DropdownIndicator
      }}
    />
  );
};

export default ZoneDropdown;
