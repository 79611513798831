import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import BoostModal from '../../../components/Budget/Boost/BoostModal';
import { getAccountId } from '../../../redux/userPreferences/actions/userPreferencesActions';
import {
  fetchBudgets,
  fetchSplitTestDetails,
  fetchSplitTests,
  getBudgets,
  getSplitTests,
  updateSplitTestBudgets
} from '../../../redux/overview/overviewActions';
import { useTranslation } from 'react-i18next';
import { TBoostModalValues } from '../../../components/Budget/Boost/BoostModal/types';
import { UncontrolledTooltip as Tooltip } from 'reactstrap';
import RightBarButton from '../../../micro_front_end/overview/right_bar/right_bar_button';
import { TBoostModalC } from './types';

export const BoostModalC: React.FC<TBoostModalC> = ({
  currentAccountId,
  accountBudgets,
  splitTestsList,
  fetchAccountBudgets,
  fetchSplitTestsList,
  fetchSplitTestBudgets,
  saveSplitTestBudgets
}: TBoostModalC) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [currentSplitTestId, setCurrentSplitTestId] = useState<string>('all');

  useEffect(() => {
    if (currentAccountId) {
      fetchSplitTestsList(currentAccountId);
      setCurrentSplitTestId('all');
    }
  }, [currentAccountId]);

  useEffect(() => {
    if (currentAccountId && open) {
      if (currentSplitTestId === 'all') {
        fetchAccountBudgets(currentAccountId);
      } else {
        fetchSplitTestBudgets(currentAccountId, currentSplitTestId);
      }
    }
  }, [currentSplitTestId, open]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleUpdate = (updatedBudgetValues: TBoostModalValues) => {
    if (currentAccountId && currentSplitTestId !== 'all') {
      saveSplitTestBudgets(
        currentAccountId,
        currentSplitTestId,
        updatedBudgetValues
      );
    }
  };

  const disabled =
    splitTestsList && splitTestsList.length !== 0 ? '' : 'disabled';

  return (
    <>
      <div id={`boostModalButton`}>
        <RightBarButton
          cyId="overview-boost-button"
          onClick={handleClick}
          label={t('components.budget.boost.boost_button_label')}
          icon="tim-icons icon-spaceship"
          classNameValue={`boost-button ${disabled}`}
        />
      </div>
      {splitTestsList && (
        <BoostModal
          isOpen={open}
          apiData={accountBudgets}
          splitTestsList={splitTestsList}
          currentSplitTestId={currentSplitTestId}
          onCancel={handleCancel}
          updateBudgets={handleUpdate}
          setCurrentSplitTestId={setCurrentSplitTestId}
        />
      )}
      {accountBudgets && accountBudgets.status === 'rejected' && (
        <Tooltip
          id={`tooltipVrr-boostModalButton`}
          placement="left"
          target="boostModalButton"
          hideArrow
        >
          {t('components.budget.boost.boost_button_error')}
        </Tooltip>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentAccountId: getAccountId(state),
  accountBudgets: getBudgets(state),
  splitTestsList: getSplitTests(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchAccountBudgets: (accountId: string) =>
    dispatch(fetchBudgets({ accountId })),
  fetchSplitTestsList: (accountId: string) =>
    dispatch(fetchSplitTests({ accountId })),
  fetchSplitTestBudgets: (accountId: string, splitTestId: string) =>
    dispatch(fetchSplitTestDetails({ accountId, splitTestId })),
  saveSplitTestBudgets: (
    accountId: string,
    splitTestId: string,
    updatedBudgetValues: TBoostModalValues
  ) =>
    dispatch(
      updateSplitTestBudgets({ accountId, splitTestId, updatedBudgetValues })
    )
});

const BoostModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BoostModalC);

export default BoostModalContainer;
