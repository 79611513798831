export const ROOT_LOCATION = 'Origin\\';

export const PLATFORM = [
  { label: 'Youtube', value: 'youtube' },
  { label: 'Meta', value: 'meta' },
  { label: 'Tik Tok', value: 'tiktok' },
  { label: 'Other', value: 'other' }
];

export const OBJECTIVE = [
  { label: 'Branding', id: 'branding' },
  { label: 'Performance', id: 'performance' }
];

export const LANGUAGE = [
  { label: 'French', value: 'fr' },
  { label: 'English', value: 'en' }
];
