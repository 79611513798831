import React from 'react';
import { VrrButton } from '../../../../shared_components';
import { ArrowClockwise } from 'react-bootstrap-icons';
import { ModalHeader, UncontrolledTooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { TLabelModalHeader } from './types';
import './LabelModalHeader.scss';

const LabelModalHeader: React.FC<TLabelModalHeader> = ({
  onClickRefresh,
  disableRefresh
}: TLabelModalHeader) => {
  const { t } = useTranslation();

  return (
    <ModalHeader>
      <VrrButton
        className="btn-link"
        onClick={onClickRefresh}
        id="label-refresh-button"
        disabled={disableRefresh}
      >
        <ArrowClockwise />
      </VrrButton>
      <UncontrolledTooltip
        placement="bottom"
        target="label-refresh-button"
        hideArrow
      >
        {t(`connected_account.label_modal.refresh`)}
      </UncontrolledTooltip>
    </ModalHeader>
  );
};
export default LabelModalHeader;
