import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TSelection, TVrrSelections } from './types';
import { VrrButton } from '../index';
import './VrrSelections.scss';

const VrrSelections: React.FC<TVrrSelections> = ({
  selectedTitle,
  availableTitle,
  itemsList,
  selectedItemsList,
  onChange
}: TVrrSelections) => {
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState<TSelection[]>([]);
  const [availableItems, setAvailableItems] = useState<TSelection[]>([]);

  useEffect(() => {
    if (!!selectedItemsList?.length) {
      setSelectedItems(selectedItemsList);
    }
  }, [selectedItemsList]);

  const getAvailableItems = (selected: TSelection[]) => {
    const available = itemsList?.filter(
      (item) => !selected?.some((s) => s.id === item.id)
    );
    setAvailableItems(available);
  };

  useEffect(() => {
    if (itemsList || selectedItems) {
      getAvailableItems(selectedItems);
    }
  }, [itemsList, selectedItems]);

  const updateSelectedItems = (selected: TSelection[]) => {
    setSelectedItems(selected);
    getAvailableItems(selected);
    if (onChange) onChange(selected);
  };

  const onAdd = (item: TSelection) => {
    const selected = [...selectedItems];
    selected.push(item);
    updateSelectedItems(selected);
  };

  const onRemove = (item: TSelection) => {
    const selected = selectedItems.filter((i) => i.id !== item.id);
    updateSelectedItems(selected);
  };

  return (
    <div className="vrr-selections-container">
      <p>{selectedTitle ?? t('shared.vrr_selections.selected_title')}</p>
      <div className="vrr-selections-line">
        {selectedItems?.map((item) => {
          return (
            <div key={item.id} className="vrr-selections-item">
              {item.element}
              <VrrButton
                className="vrr-selections-delete"
                onClick={() => onRemove(item)}
                icon={{
                  iconLabel: 'tim-icons icon-simple-delete'
                }}
              />
            </div>
          );
        })}
      </div>
      <p>{availableTitle ?? t('shared.vrr_selections.available_title')}</p>
      <div className="vrr-selections-line">
        {availableItems?.map((available) => {
          return (
            <div key={available.id} className="vrr-selections-item">
              {available.element}
              <VrrButton
                className="vrr-selections-add"
                onClick={() => onAdd(available)}
                icon={{
                  iconLabel: 'tim-icons icon-simple-add'
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VrrSelections;
