import React from 'react';
import { useTranslation } from 'react-i18next';
import { FUNNEL_COLORS, FUNNELS, TMediaPlanCardBody } from './types';
import { capitalize } from '../../../services/capitalize';
import './styles/MediaPlanCardBody.scss';

const MediaPlanCardBody: React.FC<TMediaPlanCardBody> = ({
  offer,
  funnels,
  videosCount
}: TMediaPlanCardBody) => {
  const { t } = useTranslation();

  const displayFunnels = () => {
    return Object.values(FUNNELS)?.map((funnel: string, index: number) => {
      const isActive = funnels?.includes(funnel) ? 'active' : '';
      return (
        <span
          key={`funnel-${index.toString()}`}
          className={`media-plan-card-body-funnel ${isActive} ${FUNNEL_COLORS[funnel]}`}
        >
          {capitalize(
            t('media_plan.media_plan_card.body.funnel', {
              funnel
            })
          )}
        </span>
      );
    });
  };

  return (
    <div className="media-plan-card-body">
      <div className="media-plan-card-body-left">{displayFunnels()}</div>
      <div className="media-plan-card-body-right">
        <div className="media-plan-card-body-right-infos">
          <span className="media-plan-card-body-right-label">
            {t('media_plan.media_plan_card.body.offer')}
          </span>
          <span className="media-plan-card-offer">{offer}</span>
        </div>
        <div className="media-plan-card-body-right-infos">
          <span className="media-plan-card-body-right-label">
            {t('media_plan.media_plan_card.body.video_linked')}
          </span>
          <span>{videosCount}</span>
        </div>
      </div>
    </div>
  );
};

export default MediaPlanCardBody;
