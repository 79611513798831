import React from 'react';
import CardMetric from './card_metric';
import { TSelectionMetric } from './card_metric/types';
import { TDeckMetrics } from './types';

export const prepareMetrics = (
  metrics: TSelectionMetric[],
  onClickAction: TDeckMetrics['onClickAction']
): (JSX.Element | null)[] => {
  return metrics.map((metric) => {
    if (
      metric.type === 'spent' ||
      (metric.type !== 'spent' && !!metric.selected)
    ) {
      return (
        <CardMetric
          key={`metric-${metric.name.value}`}
          metric={metric}
          onClickAction={onClickAction}
        />
      );
    }

    return null;
  });
};
