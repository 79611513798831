import React from 'react';
import NotificationAlert from 'react-notification-alert';
import { INotification } from './types';

const Notification: React.FC<INotification> = ({
  forwardedRef
}: INotification) => {
  return <NotificationAlert ref={forwardedRef} />;
};

export default Notification;
