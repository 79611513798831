import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TargetBodyModal from './target_body_modal';
import { prepareValues } from './services';
import { VrrModal } from '../../../../../../shared_components';
import {
  editMediaPlanDetails,
  getMediaPlan,
  getMediaPlanTargets
} from '../../../../../../../redux/mediaPlanGallery/mediaPlanGalleryAction';
import { TEditTarget, TTargetModal } from './types';
import { TCustomTargets, TMediaPlanDetails } from '../../../../types';
import './TargetModal.scss';

export const TargetModal: React.FC<TTargetModal> = ({
  isOpen,
  onCancelAction
}) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const selections = useSelector(getMediaPlanTargets);
  const mediaPlan = useSelector(getMediaPlan);

  const [editTargets, setEditTargets] = useState<TEditTarget | undefined>(
    undefined
  );
  const [customSelections, setCustomSelections] = useState<
    TCustomTargets | undefined
  >(undefined);

  useEffect(() => {
    if (mediaPlan) {
      const {
        target_custom_intent,
        target_other,
        target_your_data_yt_users,
        target_your_data_app_users,
        target_your_data_clients
      } = mediaPlan as TMediaPlanDetails;

      setCustomSelections({
        target_custom_intent,
        target_other,
        target_your_data_yt_users,
        target_your_data_app_users,
        target_your_data_clients
      });
    }
  }, [mediaPlan]);
  const onChangeAction = (newTargets?: TEditTarget | TCustomTargets) => {
    const newEditTargets = prepareValues(editTargets, newTargets);
    setEditTargets(newEditTargets);
  };

  const onSubmitAction = () => {
    if (editTargets) {
      dispatch(editMediaPlanDetails(editTargets));
    }
    if (onCancelAction) {
      onCancelAction();
    }
  };

  return (
    <VrrModal
      className="target-modal"
      isOpen={isOpen}
      modalBody={
        <TargetBodyModal
          selections={selections}
          customSelections={customSelections}
          onChangeAction={onChangeAction}
        />
      }
      toggleModal={onCancelAction}
      title={t('media_plan_edit.media_plan_targets.target_modal.title')}
      icon={<i className="tim-icons icon-spaceship circle-icon" />}
      toggle={onCancelAction}
      toggleValidation={onSubmitAction}
      dataCyCancelButton="media-plan-detail-edit-close-button"
    />
  );
};

export default TargetModal;
