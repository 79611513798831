import React from 'react';
import { CardFooter, CardTitle, Col, Row } from 'reactstrap';
import i18next from 'i18next';
import { v4 as uuid } from 'uuid';
import { VrrTooltip } from '../../../../shared_components';
import formatDataService from '../../../../services/formatters/formatData';
import fmtC, {
  normalFormatter as fmtN
} from '../../../../services/formatters/formatNumber';
import { ReactComponent as views } from '../../../../../assets/vrr/images/views_icon.svg';
import { ReactComponent as cta } from '../../../../../assets/vrr/images/cta_icon.svg';
import { ReactComponent as taxonomy } from '../../../../../assets/vrr/images/funnel_icon.svg';
import { TDataMetric, TDataMetricSpent, TDataMetricStandard } from './types';
import { TCurrencyOptions } from '../../../../services/formatters/types';

const tooltipRender = (target: string, content: string) => {
  if (target.length && content.length) {
    return (
      <VrrTooltip id={`tooltipVrr-${target}`} placement="top" target={target}>
        {i18next.t(content)}
      </VrrTooltip>
    );
  }
};

const progressRenderDown = (value: string) => {
  const uniqueId = uuid();

  return (
    <>
      <div
        role="button"
        id={`progress-down-${uniqueId}`}
        className="card-progress down"
        onClick={(e: any) => e.stopPropagation()}
      >
        <i className="tim-icons icon-minimal-down" />
        {value} %
      </div>
      {tooltipRender(
        `progress-down-${uniqueId}`,
        'components.card_metric.tooltip.variation'
      )}
    </>
  );
};

const progressRenderUp = (value: string) => {
  const uniqueId = uuid();

  return (
    <>
      <div
        role="button"
        id={`progress-up-${uniqueId}`}
        className="card-progress up"
        onClick={(e: any) => e.stopPropagation()}
      >
        <i className="tim-icons icon-minimal-up" />
        {value} %
      </div>
      {tooltipRender(
        `progress-up-${uniqueId}`,
        'components.card_metric.tooltip.variation'
      )}
    </>
  );
};

const progressRender = (value: number, localOptions: TCurrencyOptions) => {
  const formattedValue = fmtN(value, localOptions);
  if (value >= 0) return progressRenderUp(formattedValue);

  return progressRenderDown(formattedValue);
};

const spentCardFooter = (
  data: TDataMetricSpent,
  localOptions: TCurrencyOptions
) => (
  <CardFooter>
    <hr />
    <Row>
      <Col xs="12">
        <div className="stats right-align">
          {i18next.t('components.card_metric.budget_available')}{' '}
          {fmtC(data.budget.value as number, localOptions)}
        </div>
      </Col>
    </Row>
  </CardFooter>
);

const impressionsCardFooter = (
  data: TDataMetricStandard,
  localOptions: TCurrencyOptions
) => (
  <CardFooter>
    <hr />
    <Row>
      <Col md="7">
        <div
          role="button"
          id="impression-cpm-value"
          className="stats font-for-stats"
          onClick={(e: any) => e.stopPropagation()}
        >
          {fmtC(data.ratioValue.value as number, localOptions)}
        </div>
        {tooltipRender(
          'impression-cpm-value',
          'components.card_metric.tooltip.cpm'
        )}
      </Col>
      <Col className="ml-auto" md="5">
        <div
          id="impression-variation-value"
          className="stats font-for-stats to-right"
        >
          {progressRender(data.progression.value as number, localOptions)}
        </div>
      </Col>
    </Row>
  </CardFooter>
);

const viewsCardFooter = (
  data: TDataMetricStandard,
  localOptions: TCurrencyOptions
) => (
  <CardFooter>
    <hr />
    <Row>
      <Col md="7">
        <div
          role="button"
          className="stats font-for-stats"
          onClick={(e: any) => e.stopPropagation()}
        >
          <div id="metric-views-footer-left" className="displayer">
            {fmtC(data.ratioValue.value as number, {
              ...localOptions,
              significantDigits: 3
            })}
            {' | '}
          </div>
          <div id="metric-views-footer-right" className="displayer">
            {fmtN(data.ratioPercentage.value as number, localOptions)}
            {' %'}
          </div>
          {tooltipRender(
            'metric-views-footer-left',
            'components.card_metric.tooltip.cpv'
          )}
          {tooltipRender(
            'metric-views-footer-right',
            'components.card_metric.tooltip.vtr'
          )}
        </div>
      </Col>
      <Col className="ml-auto" md="5">
        <div className="stats font-for-stats to-right">
          {progressRender(data.progression.value as number, localOptions)}
        </div>
      </Col>
    </Row>
  </CardFooter>
);

const clicksCardFooter = (
  data: TDataMetricStandard,
  localOptions: TCurrencyOptions
) => (
  <CardFooter>
    <hr />
    <Row>
      <Col md="7">
        <div
          role="button"
          className="stats font-for-stats"
          onClick={(e: any) => e.stopPropagation()}
        >
          <div id="metric-clicks-footer-left" className="displayer">
            {fmtC(data.ratioValue.value as number, localOptions)} |{' '}
          </div>
          <div id="metric-clicks-footer-right" className="displayer">
            {fmtN(data.ratioPercentage.value as number, localOptions)}
            {' %'}
          </div>
        </div>
        {tooltipRender(
          'metric-clicks-footer-left',
          'components.card_metric.tooltip.cpc'
        )}
        {tooltipRender(
          'metric-clicks-footer-right',
          'components.card_metric.tooltip.ctr'
        )}
      </Col>
      <Col className="ml-auto" md="5">
        <div className="stats font-for-stats to-right">
          {progressRender(data.progression.value as number, localOptions)}
        </div>
      </Col>
    </Row>
  </CardFooter>
);

const revenueCardFooter = (
  data: TDataMetricStandard,
  localOptions: TCurrencyOptions
) => {
  const { symbol, thousandsSeparator, decimalSeparator } = localOptions;

  return (
    <CardFooter>
      <hr />
      <Row>
        <Col md="7">
          <div
            role="button"
            className="stats font-for-stats"
            onClick={(e: any) => e.stopPropagation()}
          >
            <div id="metric-revenue-footer-left" className="displayer">
              {formatDataService.dataFormatter(
                data.ratioValue,
                symbol ?? '',
                thousandsSeparator,
                decimalSeparator
              )}{' '}
              |{' '}
            </div>
            <div id="metric-revenue-footer-right" className="displayer">
              {formatDataService.dataFormatter(
                data.ratioPercentage,
                '',
                thousandsSeparator,
                decimalSeparator
              )}
              {''}
            </div>
          </div>
          {tooltipRender(
            'metric-revenue-footer-left',
            'components.card_metric.tooltip.average_order_value'
          )}
          {tooltipRender(
            'metric-revenue-footer-right',
            'components.card_metric.tooltip.roas'
          )}
        </Col>
        <Col className="ml-auto" md="5">
          <div className="stats font-for-stats to-right">
            {progressRender(data.progression.value as number, localOptions)}
          </div>
        </Col>
      </Row>
    </CardFooter>
  );
};

const standardCardFooter = (
  data: TDataMetricStandard,
  localOptions: TCurrencyOptions
) => {
  const uniqueId = uuid();

  return (
    <CardFooter>
      <hr />
      <Row>
        <Col md="7">
          <div
            role="button"
            className="stats font-for-stats"
            onClick={(e: any) => e.stopPropagation()}
          >
            <div
              id={`metric-conversion-footer-left-${uniqueId}`}
              className="displayer"
            >
              {fmtC(data.ratioValue.value as number, localOptions)} |{' '}
            </div>
            <div
              id={`metric-conversion-footer-right-${uniqueId}`}
              className="displayer"
            >
              {fmtN(data.ratioPercentage.value as number, localOptions)}
              {' %'}
            </div>
          </div>
          {tooltipRender(
            `metric-conversion-footer-left-${uniqueId}`,
            'components.card_metric.tooltip.conversion_action_cp'
          )}
          {tooltipRender(
            `metric-conversion-footer-right-${uniqueId}`,
            'components.card_metric.tooltip.conversion_action_rate'
          )}
        </Col>
        <Col className="ml-auto" md="5">
          <div className="stats font-for-stats to-right">
            {progressRender(data.progression.value as number, localOptions)}
          </div>
        </Col>
      </Row>
    </CardFooter>
  );
};

export const renderFooter = (
  metric: TDataMetric,
  localOptions: TCurrencyOptions
) => {
  const newMetric = { ...metric };
  const { type, data } = newMetric;
  const dataSpent = data as TDataMetricSpent;
  const dataStd = data as TDataMetricStandard;
  let footer;

  switch (type) {
    case 'spent':
      footer = spentCardFooter(dataSpent, localOptions);
      break;
    case 'impressions':
      footer = impressionsCardFooter(dataStd, localOptions);
      break;
    case 'views':
      footer = viewsCardFooter(dataStd, localOptions);
      break;
    case 'clicks':
      footer = clicksCardFooter(dataStd, localOptions);
      break;
    case 'revenue':
      footer = revenueCardFooter(dataStd, localOptions);
      break;
    default:
      footer = standardCardFooter(dataStd, localOptions);
  }

  return footer;
};

const displayMainValue = (
  metric: TDataMetric,
  localOptions: TCurrencyOptions
) => {
  const { data } = metric;
  const { symbol, thousandsSeparator, decimalSeparator } = localOptions;
  const dataSpent = data as TDataMetricSpent;
  const dataStd = data as TDataMetricStandard;

  let retValue = '';
  if (dataSpent.amount) {
    retValue = formatDataService.dataFormatter(
      dataSpent.amount,
      symbol ?? '',
      thousandsSeparator,
      decimalSeparator
    ) as string;
  }
  if (dataStd.counter?.value !== undefined) {
    retValue = formatDataService.dataFormatter(
      dataStd.counter,
      symbol ?? '',
      thousandsSeparator,
      decimalSeparator
    ) as string;
  }
  return retValue;
};

export const renderBody = (
  metric: TDataMetric,
  localOptions: TCurrencyOptions,
  t: any,
  applyColor: boolean
) => {
  const newMetric = { ...metric };
  const { name, type } = newMetric;

  const metricName = (name.value as string).replace(/\W/g, '-').toLowerCase();

  const colorToApply = applyColor && metric?.color ? metric.color : 'grey';

  if (['views', 'cta', 'taxonomy'].includes(metric.type)) {
    const components: any = { views, cta, taxonomy };
    const IconSVG = components[type] || false;

    const updatedClass = `gm-icon ${colorToApply}`;
    newMetric.icon = IconSVG && <IconSVG className={updatedClass} />;
  }

  return (
    <div className="body-metric-card">
      <section className="metric-icon">
        {newMetric.icon || (
          <div
            className={`info-icon text-center icon-success ${
              newMetric.type !== 'spent' ? colorToApply : 'red'
            }`}
          >
            <i className="tim-icons icon-chat-33" />
          </div>
        )}
      </section>
      <section className="metric-value">
        <p id={`card-category-${metricName}`} className="card-category">
          {type !== 'others'
            ? t(`components.card_metric.title.${type}`)
            : name.value}
        </p>
        {tooltipRender(
          `card-category-${metricName}`,
          t(
            type !== 'others'
              ? `components.card_metric.tooltip.${type}`
              : 'components.card_metric.tooltip.conversion_action'
          )
        )}

        <CardTitle tag="h3">
          {displayMainValue(newMetric, localOptions)}
        </CardTitle>
      </section>
    </div>
  );
};
