import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OptimizationBody from './optimization_body';
import OptimizationHeader from './optimization_header';
import { VrrModal } from '../../../../../../shared_components';
import { validate } from './services';
import {
  editMediaPlanDetails,
  getMediaPlan
} from '../../../../../../../redux/mediaPlanGallery/mediaPlanGalleryAction';
import { TOptimizationModal } from './types';
import { TRank } from './optimization_body/types';
import './OptimizationModal.scss';

const OptimizationModal: React.FC<TOptimizationModal> = ({
  isOpen,
  onCancelAction
}) => {
  const dispatch: any = useDispatch();
  const mediaPlan = useSelector(getMediaPlan);
  const [newRank, setNewRank] = useState(false);
  const [lines, setLines] = useState<TRank[] | undefined>(undefined);

  useEffect(() => {
    if (isOpen && !!mediaPlan?.optimization?.length) {
      setLines(mediaPlan?.optimization);
    }
  }, [mediaPlan, isOpen]);

  const onCancel = () => {
    if (onCancelAction) onCancelAction();
  };

  const onSubmitAction = () => {
    dispatch(editMediaPlanDetails({ optimization: lines ?? [] }));
    onCancel();
  };

  const addNewLine = () => {
    setNewRank(true);
  };

  const removeLine = (newLines: TRank[]) => {
    setLines(newLines);
    setNewRank(false);
  };

  const changeLine = (newLines: TRank[]) => {
    setLines(newLines);
  };

  const clearLines = () => {
    setNewRank(false);
    setLines(undefined);
  };

  return (
    <VrrModal
      className="optimization-modal"
      isOpen={isOpen}
      modalHeader={
        <OptimizationHeader
          canAdd={!newRank}
          onClickAction={addNewLine}
          onClearAction={clearLines}
        />
      }
      modalBody={
        <div data-cy-id="media-plan-detail-edit-optimization">
          <OptimizationBody
            line1={lines?.find((line) => line.rank === 1)}
            line2={lines?.find((line) => line.rank === 2)}
            addNewRank={newRank}
            onRemoveAction={removeLine}
            onChangeAction={changeLine}
          />
        </div>
      }
      toggleModal={onCancel}
      toggleValidation={onSubmitAction}
      enabledValidationButton={validate(lines)}
      dataCyCancelButton="media-plan-detail-edit-close-button"
    />
  );
};

export default OptimizationModal;
