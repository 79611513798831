import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { VrrButton, VrrModal } from '../../../shared_components';
import ImportHeader from './import_header';
import ImportBody from './import_body';
import {
  checkValidation,
  preparePreviews,
  prepareUrls,
  prepareVideos,
  purgePreviews
} from './services';
import {
  advancedImportAction,
  getPreviews,
  advancedPreviewAction,
  getIsComplete
} from '../../../../redux/creativeAnalytics/creativeImportVideo/creativeImportVideoActions';
import { initializePreviews } from '../../../../redux/creativeAnalytics/creativeImportVideo/creativeImportVideoSlice';
import {
  TCreativeImportVideo,
  TImportUrl,
  TPreview
} from '../../../../redux/creativeAnalytics/creativeImportVideo/types';
import { TImportBody } from './import_body/types';

const ImportVideo: React.FC = () => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const [urls, setUrls] = useState<TImportUrl[]>([
    { linkValue: '', id: uuid() }
  ]);
  const [isUrlForm, setIsUrlForm] = useState(true);
  const [previews, setPreviews] = useState<TPreview[]>([]);
  const [brand, setBrand] = useState<TCreativeImportVideo['brand']>(undefined);
  const [isOpen, setIsOpen] = useState(false);

  const videoPreviews = useSelector(getPreviews);
  const isComplete = useSelector(getIsComplete);

  useEffect(() => {
    if (!!videoPreviews?.length) {
      const newPreviews = preparePreviews(videoPreviews);
      setPreviews(newPreviews);
    }
  }, [videoPreviews]);

  useEffect(() => {
    setIsUrlForm(previews?.length === 0);
  }, [previews]);

  const onCancel = () => {
    setUrls([{ linkValue: '', id: uuid() }]);
    setPreviews([]);
    setIsUrlForm(true);
    setIsOpen(false);
    dispatch(initializePreviews());
  };

  useEffect(() => {
    if (isComplete) {
      onCancel();
    }
  }, [isComplete]);

  const onSubmitUrl = () => {
    const videos = prepareVideos(urls);
    dispatch(advancedPreviewAction(videos));
  };

  const onSubmit = () => {
    const newPreviews = purgePreviews(previews);
    dispatch(advancedImportAction({ brand: brand, videos: newPreviews }));
  };

  const onChangeBrand = (newBrand: TCreativeImportVideo['brand']) => {
    setBrand(newBrand);
  };

  const onChangePreview = (newPreviews: TPreview[]) => {
    setPreviews(newPreviews);
  };

  const onChangeUrl = (links: TImportUrl[]) => {
    setUrls(links);
  };

  const addNewLine = () => {
    const newUrls = prepareUrls(urls);
    setUrls(newUrls);
  };

  const isValidate = (): boolean => {
    const isValidateUrlForm =
      isUrlForm && urls.some((url) => !!url.linkValue?.length);
    const isValidatePreview = !isUrlForm && checkValidation(brand, previews);
    return isValidateUrlForm || isValidatePreview;
  };

  return (
    <>
      <VrrButton
        dataCy="import-video-button"
        className="action-bar-button import-video-button"
        onClick={() => setIsOpen(true)}
      >
        {t('creative_gallery.import_video.header')}
      </VrrButton>
      <VrrModal
        isOpen={isOpen}
        modalHeader={
          <ImportHeader showButton={isUrlForm} onAddAction={addNewLine} />
        }
        modalBody={
          <ImportBody
            isUrlForm={isUrlForm}
            urls={urls}
            previews={previews as TImportBody['previews']}
            onChangeUrl={onChangeUrl}
            onChangePreview={onChangePreview}
            onChangeBrand={onChangeBrand}
          />
        }
        toggleModal={onCancel}
        {...(isOpen && { enabledValidationButton: isValidate() })}
        toggleValidation={isUrlForm ? onSubmitUrl : onSubmit}
        dataCyCancelButton="import-video-cancel"
      />
    </>
  );
};

export default ImportVideo;
