import { TLabelsListPreference } from '../../../../redux/userPreferences/types';
import { TAccountLabel } from '../../../../redux/userProfile/types';

export const prepareCustomLabelsList = (
  account: string,
  newLabels: TAccountLabel[],
  customLabels?: TLabelsListPreference | null
): any => {
  let newCustomLabels = { ...customLabels };
  if (customLabels && Object.keys(customLabels).includes(account)) {
    if (!newLabels.length) {
      delete newCustomLabels[account];
    } else {
      newCustomLabels[account] = [...newLabels];
    }
  } else {
    newCustomLabels = {
      ...newCustomLabels,
      [account]: [...newLabels]
    };
  }
  return newCustomLabels;
};
