const PREFIX = '/users';

const getEndPoint = (url: string) => `${PREFIX}/${url}`;
const getUserEndPoint = (userId: number, url: string) =>
  `${PREFIX}/${userId.toString()}/${url}`;

const USER_ENDPOINTS = {
  GET_ACCOUNTS: (userId: number): string =>
    getUserEndPoint(userId, 'ad_accounts'),
  USER_PREFERENCES: getEndPoint('preferences'),
  SIGNIN: getEndPoint('tokens/sign_in'),
  PROFILE: (userId: string): string => getEndPoint(userId),
  FORGOT_PASSWORD: getEndPoint('tokens/forgot_password'),
  NOTIFICATIONS: '/notifications'
};

export default USER_ENDPOINTS;
