import product1 from '../../../../../assets/vrr/images/media-plan-assets/product1.jpg';
import product2 from '../../../../../assets/vrr/images/media-plan-assets/product2.jpg';
import product3 from '../../../../../assets/vrr/images/media-plan-assets/product3.jpg';
import product4 from '../../../../../assets/vrr/images/media-plan-assets/product4.jpg';
import product5 from '../../../../../assets/vrr/images/media-plan-assets/product5.jpg';

export const PRODUCT_IMAGES = [
  product1,
  product2,
  product3,
  product4,
  product5
];
