import React from 'react';
import { Container, Col } from 'reactstrap';
import ForgotPasswordContainer from '../../containers/ForgotPassword';
import './ForgotPassword.scss';

const ForgotPassword: React.FC = () => {
  return (
    <>
      <div className="content-forgot-password-card">
        <Container>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <ForgotPasswordContainer />
          </Col>
        </Container>
      </div>
    </>
  );
};

export default ForgotPassword;
