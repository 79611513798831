import React from 'react';
import i18next from 'i18next';
import RedirectionActions from './RedirectionActions';
import RedirectionEditActions from './RedirectionEditActions';
import RedirectionInput from './RedirectionInput';
import { VrrTooltip } from '../../../../../../../shared_components';
import { TRedirectionRow } from './types';
import { TUrlError } from '../../../../../../../../redux/creativeAnalytics/creativeImportVideo/types';
import {
  THeader,
  TTableBody,
  TTableRow
} from '../../../../../../../shared_components/vrr_table/types';
import { ReactComponent as Info } from '../../../../../../../../assets/vrr/images/info_icon.svg';

export const NEW_INDEX = '-1';

export const prepareHeader = (): THeader['element'][] => {
  const tooltip = 'redirection-tooltip-icon-id';
  return [
    {
      id: 'redirection',
      component: (
        <div>
          <p>
            {i18next.t(
              'media_plan_edit.media_plan_assets.redirections.table.header.redirection'
            )}
          </p>
          <Info id={tooltip} className="redirection-tooltip-icon" />
          <VrrTooltip target={tooltip} children="Lorem ipsum" />
        </div>
      )
    }
  ];
};

const editLine = (
  line: TRedirectionRow,
  changeAction: (value: string) => void,
  submitEditAction: (id: string) => void,
  cancelEditAction: () => void
): TTableRow['cellElements'] => [
  {
    id: line.rowId,
    component: (
      <RedirectionInput
        value={line.value}
        onChange={(value: string) => changeAction(value)}
      />
    ),
    value: line.value,
    headerId: 'redirection'
  },
  {
    id: line.rowId,
    component: (
      <RedirectionEditActions
        onCancel={cancelEditAction}
        onSubmit={() => submitEditAction(line.rowId)}
      />
    ),
    value: '',
    headerId: ''
  }
];

const addLine = (
  newIndex: string,
  changeAction: (value: string) => void,
  submitEditAction: (id: string) => void,
  cancelEditAction: () => void
): TTableRow['cellElements'] => {
  return editLine(
    { value: '', rowId: newIndex, id: null },
    changeAction,
    submitEditAction,
    cancelEditAction
  );
};

const readLine = (
  line: TRedirectionRow,
  editAction: (id: string) => void,
  removeAction: (id: string) => void,
  error?: string
): TTableRow['cellElements'] => [
  {
    id: line.rowId,
    component: (
      <>
        <p>{line.value}</p>
        <span className="redirection-error">{error}</span>
      </>
    ),
    value: line.value,
    headerId: 'redirection'
  },
  {
    id: line.rowId,
    component: (
      <RedirectionActions
        onEdit={() => editAction(line.rowId)}
        onRemove={() => removeAction(line.rowId)}
      />
    ),
    value: '',
    headerId: ''
  }
];

const getNewIndex = (redirections: TRedirectionRow[]) => {
  const array = redirections?.map((row) => parseInt(row.rowId));
  const newIndex = array.length ? Math.max(...array) + 1 : 0;
  return newIndex.toString();
};

const getErrors = (errors?: TUrlError[], url?: string): string | undefined => {
  let err: string | undefined;
  if (errors && !!errors?.length) {
    errors?.forEach((error) => {
      if (error.url === url) {
        err = error.reason;
      }
    });
  }
  return err;
};

export const prepareBody = (
  redirections: TRedirectionRow[],
  editAction: (id: string) => void,
  removeAction: (id: string) => void,
  changeAction: (value: string) => void,
  submitEditAction: (id: string) => void,
  cancelEditAction: () => void,
  editId?: string,
  errors?: TUrlError[]
): TTableBody['rowElements'] => {
  const newBody: TTableBody['rowElements'] = [];
  redirections?.forEach((redirection) => {
    newBody.push(
      editId === redirection.rowId
        ? editLine(
            redirection,
            changeAction,
            submitEditAction,
            cancelEditAction
          )
        : readLine(
            redirection,
            editAction,
            removeAction,
            getErrors(errors, redirection.value)
          )
    );
  });

  if (editId === NEW_INDEX)
    newBody.push(
      addLine(
        getNewIndex(redirections),
        changeAction,
        submitEditAction,
        cancelEditAction
      )
    );

  return newBody;
};
