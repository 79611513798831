import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PlayerSection from './player_section';
import InformationSection from './information_section';
import QualityMetricSection from './quality_metric_section';
import VideoScoringSection from './video_scoring_section';
import { VrrBreadCrumb } from '../../shared_components';
import URLS from '../../routing/urls';
import {
  fetchVideoDetails,
  getVideoDetails
} from '../../../redux/creativeAnalytics/creativeVideoDetails/creativeVideoDetailsAction';
import { getAccountId } from '../../../redux/userPreferences/actions/userPreferencesActions';
import {
  TCreativeVideoDetailsAPI,
  TInformations
} from '../../../redux/creativeAnalytics/creativeVideoDetails/types';
import { TVrrBreadcrumb } from '../../shared_components/vrr_breadcrumb/types';
import { TQualityMetricSection } from './quality_metric_section/types';
import './CreativeVideoDetails.scss';

export const CreativeVideoDetails: React.FC = () => {
  const [currentAccountId, setCurrentAccountId] = useState<string | undefined>(
    undefined
  );
  const [currentVideoDetails, setCurrentVideoDetails] = useState<
    TCreativeVideoDetailsAPI | undefined
  >(undefined);
  const accountId = useSelector(getAccountId);
  const videoDetails = useSelector(getVideoDetails);
  const history = useNavigate();
  const { t } = useTranslation();
  const params = useParams() as any;
  const dispatch = useDispatch() as any;

  const goGallery = () => {
    history({
      pathname: URLS.CREATIVE_ANALYTICS
    });
  };

  useEffect(() => {
    if (!!accountId?.length && !currentVideoDetails) {
      dispatch(fetchVideoDetails({ accountId, videoId: params.videoId }));
    }

    if (accountId !== currentAccountId && !!accountId?.length) {
      if (currentAccountId?.length) {
        goGallery();
      }
      setCurrentAccountId(accountId);
    }
  }, [accountId]);

  useEffect(() => {
    if (videoDetails) {
      setCurrentVideoDetails(videoDetails);
    }
    return () => {
      setCurrentVideoDetails(undefined);
    };
  }, [videoDetails]);

  const breadcrumbItems: TVrrBreadcrumb['items'] = [
    {
      label: t('creative_video_details.breadcrumb.main').toUpperCase(),
      id: '-1'
    },
    {
      label: t('creative_video_details.breadcrumb.item').toUpperCase(),
      id: currentVideoDetails?.video_id?.toString() ?? '0'
    }
  ];

  return (
    <>
      <VrrBreadCrumb items={breadcrumbItems} onClickItem={goGallery} />
      <main className="creative-video-details">
        <section className="top-section">
          <section className="player-section section-container">
            <PlayerSection
              videoId={currentVideoDetails?.video_id?.toString()}
              accountId={currentAccountId}
              {...(currentVideoDetails?.player as TCreativeVideoDetailsAPI['player'])}
            />
          </section>
          <div className="right-section">
            <section className="section-container">
              <InformationSection
                {...(currentVideoDetails?.informations as TInformations)}
              />
            </section>
            <section className="section-container">
              <VideoScoringSection
                percent={currentVideoDetails?.scoring ?? 0}
              />
            </section>
          </div>
        </section>
        <section className="bottom-section">
          <section className="section-container">
            <QualityMetricSection
              {...(currentVideoDetails?.metrics as TQualityMetricSection)}
            />
          </section>
        </section>
      </main>
      <div className="spacer" />
    </>
  );
};

export default CreativeVideoDetails;
