import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Container, Row, Col, List, ListInlineItem } from 'reactstrap';
import LANG from '../../../i18n/i18nConstants';
import i18n from '../../../i18n/i18n';
import useSettings from '../../../libs/settings/useSettings';
import { getLanguage } from '../../../redux/userPreferences/actions/userPreferencesActions';
import classNames from 'classnames';
import './MenuLanguage.scss';

const MenuLanguage: React.FC = () => {
  const languageSettings = useSelector(getLanguage);
  const [language, setLanguage] = useState(languageSettings);
  const { vrr } = useSettings();

  useEffect(() => {
    if (languageSettings !== '') {
      setLanguage(languageSettings);
    }
  }, [languageSettings]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  const changeLanguage: any = (lng: string) => {
    setLanguage(lng);
    vrr({ language: lng });
    moment.locale(lng);
  };

  return (
    <Container className="switch-language-container" fluid="sm">
      <Row>
        <Col lg={{ size: 10, offset: 1 }}>
          <List type="inline">
            {LANG.map((lng: string, index: number) => (
              <ListInlineItem
                key={lng}
                id={`cy-${lng}-language`}
                className={classNames(
                  'switch-language',
                  'text-white',
                  language === lng && 'selected-language'
                )}
                onClick={() => changeLanguage(lng)}
              >
                {index !== 0 && <span className="language-separator">/</span>}
                {lng.toUpperCase()}
              </ListInlineItem>
            ))}
          </List>
        </Col>
      </Row>
    </Container>
  );
};

export default MenuLanguage;
