import { TBoostModalValues } from './types';
import { BUDGETLIST } from './BudgetList/BudgetList.constante';
import moment from 'moment';
import { TBudgetListItemData } from './BudgetList/types';

const updateAndRecalculateValues = (
  value: number,
  type: string,
  data: TBoostModalValues
): TBoostModalValues => {
  const payload = { ...data };
  let difference = undefined;

  if (type == 'endDate') {
    payload.endDate = moment(value).toDate();
  } else {
    const splittedType = type.match(/.[a-z]+/g);
    const previous_value = eval(`payload.${type}`);
    const opposite = splittedType?.[0] == 'total' ? 'available' : 'total';

    difference = value - previous_value;
    payload.totalBudget += difference;
    payload.availableBudget += difference;
    eval(`payload.${opposite}${splittedType?.[1]} += difference`);
    eval(`payload.${type} = value`);
  }

  return payload;
};

const assignateValuesToList = (
  data: TBoostModalValues
): TBudgetListItemData[] => {
  const budgetListDouble = [...BUDGETLIST];
  budgetListDouble.map((item) => {
    item.value = data[item.payloadName as keyof TBoostModalValues] as number;
    item.error = !(item.value >= 0);

    item.subItems?.map((subItem) => {
      subItem.value = data[
        subItem.payloadName as keyof TBoostModalValues
      ] as number;
      subItem.error = !(subItem.value >= 0);
    });
  });
  return budgetListDouble;
};

const hasBudgetUpdates = (
  initialBudgetValues: TBoostModalValues,
  changedBudgetValues: TBoostModalValues
): boolean => {
  const comparedKeys = [
    'endDate',
    'totalTop',
    'totalMiddle',
    'totalBottom',
    'availableTop',
    'availableMiddle',
    'availableBottom'
  ];

  const filteredKeys: string[] = comparedKeys.filter((comparedKey: string) => {
    const initial =
      initialBudgetValues[comparedKey as keyof typeof initialBudgetValues];
    const changedBudget =
      changedBudgetValues[comparedKey as keyof typeof changedBudgetValues];
    if (comparedKey === 'endDate') {
      return (
        moment(initial).format('YYYY-MM-DD') !==
        moment(changedBudget).format('YYYY-MM-DD')
      );
    } else {
      return initial !== changedBudget;
    }
  });

  return filteredKeys.length > 0;
};

export const BoostModalService = {
  updateAndRecalculateValues,
  assignateValuesToList,
  hasBudgetUpdates
};

export default BoostModalService;
