import { configureStore } from '@reduxjs/toolkit';
import appReducer from './app/appSlice';
import userPreferencesReducer from './userPreferences/userPreferencesSlice';
import userProfileReducer from './userProfile/userProfileSlice';
import overviewReducer from './overview/overviewSlice';
import managementReducer from './management/managementSlice';
import insightsReducer from './insights/insightsSlice';
import creativeGalleryReducer from './creativeAnalytics/creativeGallery/creativeGallerySlice';
import mediaPlanGalleryReducer from './mediaPlanGallery/mediaPlanGallerySlice';
import creativeVideoDetailsReducer from './creativeAnalytics/creativeVideoDetails/creativeVideoDetailsSlice';
import creativeImportVideoReducer from './creativeAnalytics/creativeImportVideo/creativeImportVideoSlice';
import metricsReducer from './overview/metrics/MetricsSlices';
import launcherReducer from './launcher/launcherSlice';

const store = configureStore({
  reducer: {
    app: appReducer,
    userPreferences: userPreferencesReducer,
    userProfile: userProfileReducer,
    overview: overviewReducer,
    management: managementReducer,
    insights: insightsReducer,
    creativeGallery: creativeGalleryReducer,
    mediaPlanGallery: mediaPlanGalleryReducer,
    creativeVideoDetails: creativeVideoDetailsReducer,
    creativeImportVideo: creativeImportVideoReducer,
    metrics: metricsReducer,
    launcher: launcherReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
  devTools: true
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
