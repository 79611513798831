import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LauncherFooter from '../launcher_footer';
import SectionTitle from '../../../creative_analytics/creative_video_details/section_title';
import { VrrDropdown } from '../../../shared_components';
import { prepareMediaPlan, prepareMediaPlans } from './services';
import {
  fetchMediaPlan,
  fetchMediaPlans,
  getMediaPlans
} from '../../../../redux/mediaPlanGallery/mediaPlanGalleryAction';
import { editLauncher } from '../../../../redux/launcher/launcherActions';
import { TMediaPlanSelection } from './types';
import { IListState } from '../../../shared_components/vrr_dropdown/types';
import './MediaPlanSelection.scss';

const MediaPlanSelection: React.FC<TMediaPlanSelection> = ({
  mediaPlanId,
  onChangeAction
}) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const mediaPlans = useSelector(getMediaPlans);
  const [currentMediaPlan, setCurrentMediaPlan] = useState<
    IListState | undefined
  >(prepareMediaPlan(mediaPlanId));

  useEffect(() => {
    if (!mediaPlans) {
      dispatch(fetchMediaPlans());
    }
  }, [mediaPlans]);

  const onNext = () => {
    if (onChangeAction) onChangeAction(2);
    if (currentMediaPlan?.value) {
      dispatch(
        editLauncher({ mediaPlanId: parseInt(currentMediaPlan?.value, 10) })
      );
    }
  };

  const onChange = (value: IListState) => {
    setCurrentMediaPlan(value);
    dispatch(
      fetchMediaPlan({
        mediaPlanId: value.value
      })
    );
  };

  const onCancel = () => {
    if (onChangeAction) onChangeAction(0);
  };

  return (
    <div className="media-plan-selection">
      <div className="media-plan-selection-body">
        <SectionTitle
          title={t('launcher.media_plan_selection.title')}
          iconClassName="tim-icons icon-atom"
        />
        <div>
          <div className="media-plan-selection-label">
            <span>{t('launcher.media_plan_selection.label')}</span>
            <hr />
          </div>
          <VrrDropdown
            list={prepareMediaPlans(mediaPlans)}
            isMulti={false}
            currentValue={currentMediaPlan?.value}
            handleChangeAction={onChange}
          />
        </div>
      </div>
      <LauncherFooter
        currentStep={1}
        submitDisabled={!currentMediaPlan}
        onCancelAction={onCancel}
        onSubmitAction={onNext}
      />
    </div>
  );
};

export default MediaPlanSelection;
