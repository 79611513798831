import React, { BaseSyntheticEvent } from 'react';
import {
  THeader,
  TTableBody,
  TTableRow
} from '../../../../shared_components/vrr_table/types';
import i18next from 'i18next';
import { Input } from 'reactstrap';
import { TAccountLabel } from '../../../../../redux/userProfile/types';

export const prepareHeader = (): THeader['element'][] => {
  return [
    {
      id: 'checkbox',
      component: <p>#</p>
    },
    {
      id: 'name',
      component: (
        <p>
          {i18next
            .t('connected_account.labels_table.header.name')
            .toUpperCase()}
        </p>
      )
    }
  ];
};

const isSelectedLabel = (lineId: number, selectedLabels: TAccountLabel[]) => {
  return !!selectedLabels?.find((label) => label.id === lineId);
};

const getLine = (
  id: string,
  line: TAccountLabel,
  selectedLabels: TAccountLabel[],
  rowId: number,
  changeAction?: (value: TAccountLabel, isChecked: boolean) => void
): TTableRow['cellElements'] => {
  const isSelected =
    (line.canSelect && isSelectedLabel(line.id, selectedLabels)) ||
    !line.canSelect;

  return [
    {
      headerId: 'checkbox',
      id: rowId.toString(),
      component: (
        <Input
          id={`${id}-${rowId.toString()}`}
          type="checkbox"
          checked={isSelected}
          disabled={!line.canSelect}
          className="account-labels-input"
          onChange={(e: BaseSyntheticEvent) => {
            if (changeAction) changeAction(line, e.target.checked);
          }}
        />
      ),
      value: ''
    },
    {
      headerId: 'name',
      id: rowId.toString(),
      component: (
        <label
          className="account-labels-value"
          htmlFor={`${id}-${rowId.toString()}`}
        >
          <span>{line.name}</span>
        </label>
      ),
      value: line.name
    }
  ];
};

export const prepareBody = (
  id: string,
  labels: TAccountLabel[] | undefined,
  selectedLabels: TAccountLabel[],
  changeAction?: (value: TAccountLabel, isChecked: boolean) => void
): TTableBody['rowElements'] => {
  const newBody: TTableBody['rowElements'] = [];
  labels?.forEach((label, index: number) => {
    newBody.push(getLine(id, label, selectedLabels, index, changeAction));
  });

  return newBody;
};
