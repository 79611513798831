import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { OPTIONS, VALUE } from './services';
import { TChartOptions, TOptions } from './types';
import './ChartOptions.scss';

const ChartOptions: React.FC<TChartOptions> = ({ onClickAction }) => {
  const [active, setActive] = useState(VALUE);
  const { t } = useTranslation();

  const handleClickAction = (buttonId: TOptions['id']) => {
    setActive(buttonId);
    if (onClickAction) onClickAction(buttonId);
  };

  const renderButtonList = () => {
    return OPTIONS?.map((button) => (
      <Button
        key={button.id}
        size="sm"
        className={`btn-simple ${button.id === active ? 'active' : ''}`}
        onClick={() => handleClickAction(button.id)}
      >
        {t(button.name).toUpperCase()}
      </Button>
    ));
  };

  return <div className="data-types-buttons">{renderButtonList()}</div>;
};

export default ChartOptions;
