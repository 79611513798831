import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { Cookies } from 'react-cookie';
import { returnError } from './apis';

export default abstract class AbstractApi {
  protected baseURL: string;

  protected rqid: string;

  constructor(baseURL: string, rqid: string) {
    this.baseURL = baseURL;
    this.rqid = rqid;
  }

  public createHttpClient(): AxiosInstance {
    const api = this.initHttpClient();

    this.addRequestInterceptors(api);
    this.addResponseInterceptors(api);

    return api;
  }

  protected initHttpClient(): AxiosInstance {
    return axios.create({
      baseURL: this.baseURL,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      }
    });
  }

  protected addRequestInterceptors(api: AxiosInstance): any {
    api.interceptors.request.use(
      async (config: any) => {
        const cookies = new Cookies();
        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: cookies.get('access_token')
              ? `Bearer ${cookies.get('access_token')}`
              : null
          }
        };
      },
      (err) => Promise.reject(err)
    );
  }

  protected addResponseInterceptors(api: AxiosInstance): void {
    api.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },

      (error) => returnError(error)
    );
  }
}
