import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import './assets/CreativeTim/css/nucleo-icons.css';
import './assets/CreativeTim/css/black-dashboard-pro-react.min.css';
import './index.scss';
import './i18n/i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import { CookiesProvider } from 'react-cookie';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <CookiesProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
