import React from 'react';
import i18next from 'i18next';
import ReactDatetime from 'react-datetime';
import moment, { Moment } from 'moment';
import { FormGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ISimpleDate } from './types';
import 'moment/locale/fr';
import './SimpleDate.scss';

const SimpleDate: React.FC<ISimpleDate> = ({
  onDateChange,
  date = moment(),
  editable = true,
  dateValidator = () => {
    return true;
  }
}: ISimpleDate) => {
  const { t } = useTranslation();
  const format = i18next.language == 'en' ? 'MMM Do YYYY' : 'DD MMMM YYYY';

  return (
    <FormGroup>
      <ReactDatetime
        className="simple-date-picker"
        inputprops={{
          locale: i18next.language,
          className: 'form-control',
          placeholder: t('main_navbar.date_picker.placeholder_date')
        }}
        timeFormat={false}
        moment={moment.locale(i18next.language)}
        onChange={(newDate: Moment) => {
          if (onDateChange) onDateChange(newDate);
        }}
        value={date}
        dateFormat={format}
        isValidDate={dateValidator}
        inputProps={{
          disabled: !editable,
          readOnly: !editable
        }}
      />
    </FormGroup>
  );
};

export default SimpleDate;
