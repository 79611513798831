import i18next from 'i18next';
import MetricDetailTableHeaders from './metric_detail_table_headers';
import { getCurrentCurrency } from '../../../../../services/services';
import formatDataService from '../../../../services/formatters/formatData';
import { toLowerKebabCase } from 'micro_front_end/services/formatters/formatString';
import { TMetricDetailTable } from './types';
import { TMetricDetailHeaderModal } from '../metric_detail_header/types';
import { TMetricDataUnit } from '../../metrics/metric_table/types';
import { TTable } from '../../../../shared_components/vrr_table/types';

export const prepareHeaders = (
  headers: TMetricDetailTable['headers'],
  metric: TMetricDetailHeaderModal['metric'],
  mainKpi?: string,
  onClickHeader?: (kpi: string) => void
): TTable['header'] => {
  const handleOnClickHeader = (head: string) => {
    if (onClickHeader) onClickHeader(head);
  };

  return headers?.map((header) => {
    return {
      id: header,
      component: (
        <MetricDetailTableHeaders
          header={header}
          metric={metric}
          mainKpi={mainKpi}
          onClickHeader={handleOnClickHeader}
        />
      )
    };
  });
};

export const prepareBodies = (
  metrics: TMetricDetailTable['metricData'],
  headers: TMetricDetailTable['headers']
): TTable['body'] => {
  const symbol = getCurrentCurrency();
  const thousandsSeparator = i18next.t('local.separators.thousands');
  const decimalSeparator = i18next.t('local.separators.decimal');

  const bodies: TTable['body'] = [];

  metrics?.forEach((metric: { [key: string]: TMetricDataUnit }) => {
    bodies.push(
      headers?.map((header) => {
        const metricByHeader = metric[header];
        const formatedData = metricByHeader
          ? formatDataService.dataFormatter(
              metricByHeader,
              symbol,
              thousandsSeparator,
              decimalSeparator
            )
          : '-';
        return {
          id: toLowerKebabCase(metricByHeader?.value.toString() || '-'),
          component: (
            <p>
              {header !== 'name' ? formatedData : metricByHeader?.value || '-'}
            </p>
          ),
          value: metricByHeader?.value.toString() || '-',
          headerId: header
        };
      })
    );
  });

  return bodies;
};
