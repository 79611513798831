import CREATIVE_ENDPOINT from './creativeEndPoints';
import CREATIVE_GALLERY_ENDPOINTS from './creativeGalleryEndpoints';
import CREATIVE_VIDEO_DETAILS_END_POINTS from './creativeVideoDetailsEndPoints';

const CREATIVE_ANALYTICS_END_POINTS = {
  CREATIVE: { ...CREATIVE_ENDPOINT },
  CREATIVE_GALLERY: { ...CREATIVE_GALLERY_ENDPOINTS },
  CREATIVE_VIDEO_DETAILS: { ...CREATIVE_VIDEO_DETAILS_END_POINTS }
};

export default CREATIVE_ANALYTICS_END_POINTS;
