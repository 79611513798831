export type TMediaPlanCard = {
  onCardClick: () => void;
} & TMediaPlanCardHeader &
  TMediaPlanCardBody &
  TMediaPlanCardFooter;

export type TMediaPlanCardHeader = {
  name?: string;
  createdAt?: string;
};

export const FUNNELS = {
  top: 'top',
  middle: 'middle',
  bottom: 'bottom'
};

export const FUNNEL_COLORS = {
  [FUNNELS.top]: 'purple',
  [FUNNELS.middle]: 'orange',
  [FUNNELS.bottom]: 'red'
};

export type TMediaPlanCardBody = {
  offer?: string;
  funnels?: string[];
  videosCount?: number;
};

export type TMediaPlanCardFooter = {
  genders?: string[];
  ages?: string[];
  zones?: string[];
};
