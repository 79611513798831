import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterContainer from '../../containers/Insights/FilterContainer';
import CardListContainer from '../../containers/Insights/CardListContainer';

const InsightsPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="insights-video-page">
        <h4>{t('pages.insights.title').toUpperCase()}</h4>
      </div>
      <FilterContainer />
      <CardListContainer />
    </>
  );
};

export default InsightsPage;
