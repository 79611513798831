import { RootState } from '../../../store';
import {
  TCreativeGalleryFolder,
  TCreativeGalleryVideo,
  TCreativeTag,
  TCreativeVideo
} from '../types';

export const getFolders = (
  state: RootState
): TCreativeGalleryFolder[] | undefined => state.creativeGallery.folders;

export const getVideos = (
  state: RootState
): TCreativeGalleryVideo[] | undefined => state.creativeGallery.videos;

export const getBreadcrumb = (
  state: RootState
): TCreativeGalleryFolder[] | undefined => state.creativeGallery.breadcrumb;

export const getRootId = (state: RootState): number | undefined =>
  state.creativeGallery.rootId;

export const getTagVideos = (state: RootState): TCreativeVideo[] | undefined =>
  state.creativeGallery.tagVideos;

export const getAllTags = (state: RootState): TCreativeTag[] | undefined =>
  state.creativeGallery.tags;
