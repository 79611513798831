import React from 'react';
import { TMetricDetailTable } from './types';
import VrrTable from 'micro_front_end/shared_components/vrr_table';
import { prepareHeaders, prepareBodies } from './services';
import useSettings from '../../../../../libs/settings/useSettings';
import { useSelector } from 'react-redux';
import {
  getMainKpi,
  getMainKpiSort
} from '../../../../../redux/userPreferences/actions/userPreferencesActions';
import metricDetailTableStyles from './styles/MetricDetailTable.module.scss';
import './styles/MetricDetailTable.scss';
import { SORT_BY } from '../../../../shared_components/vrr_sort_by/constants';

const MetricDetailTable: React.FC<TMetricDetailTable> = ({
  headers,
  metricData,
  metric
}: TMetricDetailTable) => {
  const mainKpi: string | undefined = useSelector(getMainKpi);
  const mainKpiSort: string | undefined = useSelector(getMainKpiSort);
  const { overview } = useSettings();

  const handleMainKpiPreference = (kpiName: string) => {
    overview('global_insights', {
      mainKpi: kpiName
    });
  };

  const onSort = (id: string, type: string) => {
    if (id === mainKpi) {
      overview('global_insights', {
        mainKpiSort: type
      });
    }
  };

  return (
    <div className="metric-detail-modal-wrapper">
      <VrrTable
        header={prepareHeaders(
          headers,
          metric,
          mainKpi,
          handleMainKpiPreference
        )}
        body={prepareBodies(metricData, headers)}
        withSort
        defaultSort={{ id: mainKpi ?? '', type: mainKpiSort ?? SORT_BY.asc }}
        onSortAction={onSort}
        withFilter
        tableProps={{
          borderless: true,
          className: 'metric-detail-table',
          cssModule: metricDetailTableStyles
        }}
      />
    </div>
  );
};

export default MetricDetailTable;
