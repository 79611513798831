import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { VrrModal } from '../../../shared_components';
import MetricSelections from './metric_selections';
import { TMetricModal } from './types';
import { getMetricSelections } from '../../../../redux/userPreferences/actions/userPreferencesActions';
import './MetricModal.scss';
import useSettings from '../../../../libs/settings/useSettings';

const MetricModal: React.FC<TMetricModal> = ({
  isOpen,
  onCancelAction
}: TMetricModal) => {
  const metrics = useSelector(getMetricSelections);
  const [newMetrics, setNewMetrics] = useState(['video']);
  const { overview } = useSettings();

  useEffect(() => {
    if (isOpen && metrics) {
      setNewMetrics(metrics);
    } else {
      setNewMetrics(['video']);
    }
  }, [isOpen]);

  const onCancel = () => {
    if (onCancelAction) onCancelAction();
  };

  const onChange = (newMetricSelections: string[]) => {
    setNewMetrics(newMetricSelections);
  };

  const onSubmit = () => {
    overview('global_insights', {
      metricSelections: newMetrics
    });
    if (onCancelAction) onCancelAction();
  };

  return (
    <VrrModal
      className="metric-modal"
      isOpen={isOpen}
      modalBody={
        <div data-cy-id="overview-add-details">
          <MetricSelections
            onChangeAction={onChange}
            selectedMetricsList={newMetrics}
          />
        </div>
      }
      toggleModal={onCancel}
      toggleValidation={onSubmit}
      dataCyCancelButton="overview-add-details-cancel"
    />
  );
};

export default MetricModal;
