import React from 'react';
import { useTranslation } from 'react-i18next';
import AudienceCategoryLine from '../audience_category/audience_category_line';
import { toLowerKebabCase } from '../../../../../../../../../../../services/formatters/formatString';
import { TAudienceSelectAll } from './types';
import './AudienceSelectAll.scss';

const AudienceSelectAll: React.FC<TAudienceSelectAll> = ({
  audienceName,
  numberAudiences,
  isChecked,
  onChangeAction
}) => {
  const { t } = useTranslation();
  return (
    <p className="select-all-categories">
      <AudienceCategoryLine
        categoryLine={{
          name: t(
            'media_plan_edit.media_plan_targets.target_modal.funnels.audiences.select_all'
          ),
          criteriaId: toLowerKebabCase(audienceName)
        }}
        isChecked={isChecked}
        onChangeAction={onChangeAction}
      />
      <span className="category-count">{numberAudiences}</span>
      <span className="right-space"></span>
    </p>
  );
};

export default AudienceSelectAll;
