import { IItemUserPasswordService } from './types';

export const userPasswordService: IItemUserPasswordService = {
  passwordNotContainsACapitalized: {
    function: (passwordParam: string) => {
      const regexCapital = new RegExp('[A-Z]');
      return !regexCapital.test(passwordParam);
    },
    errorMessage: 'components.user_password.errors.no_capital_letter'
  },
  passwordNotContainsANumber: {
    function: (passwordParam: string) => {
      const regexNumber = new RegExp(`[0-9]`);
      return !regexNumber.test(passwordParam);
    },
    errorMessage: 'components.user_password.errors.no_number'
  },
  passwordNotContainsASpecialCharacter: {
    function: (passwordParam: string) => {
      const regexCharacter = new RegExp('_|[^w]');
      return !regexCharacter.test(passwordParam);
    },
    errorMessage: 'components.user_password.errors.no_special_character'
  },
  passwordLenghtIsToSmall: {
    function: (passwordParam: string) => {
      return passwordParam.length < 8;
    },
    errorMessage: 'components.user_password.errors.minimum_characters'
  },
  passwordHasSpace: {
    function: (passwordParam: string) => {
      const regexSpace = new RegExp(' ');
      return regexSpace.test(passwordParam);
    },
    errorMessage: 'components.user_password.errors.space'
  },
  passwordIsNotSameAsConfirmPassword: {
    function: (passwordParam: string, passwordConfirmParam: string) => {
      return !(passwordParam === passwordConfirmParam);
    },
    errorMessage: 'components.user_password.errors.password_match'
  }
};

const checkPasswordAndConfirmPassword = (
  passwordParam: string,
  passwordConfirmParam: string
): Array<string> => {
  const result: Array<string> = [];
  Object.keys(userPasswordService).forEach((value: string) => {
    const checkBoolean = userPasswordService[value].function(
      passwordParam,
      passwordConfirmParam
    );
    if (checkBoolean) {
      result.push(userPasswordService[value].errorMessage);
    }
  });
  return result;
};

export default checkPasswordAndConfirmPassword;
