import React, { useEffect, useState } from 'react';
import VrrTable from '../../../../shared_components/vrr_table';
import { prepareHeader, prepareBody } from './services';
import { TLabelsTable } from './types';
import './LabelsTable.scss';
import { TAccountLabel } from '../../../../../redux/userProfile/types';

const LabelsTable: React.FC<TLabelsTable> = ({
  id,
  labels,
  customLabels,
  onChangeAction
}: TLabelsTable) => {
  const [dataTable, setDataTable] = useState<TAccountLabel[]>([]);

  useEffect(() => {
    return () => {
      setDataTable([]);
    };
  }, []);

  useEffect(() => {
    if (!!customLabels?.length) {
      setDataTable(customLabels);
    }
  }, [customLabels]);

  const onChange = (value: TAccountLabel, isChecked: boolean) => {
    let newData = [...dataTable];
    if (isChecked) {
      newData.push(value);
    } else {
      newData = newData.filter((data) => {
        return data.id !== value.id;
      });
    }

    setDataTable(newData);
    if (onChangeAction) onChangeAction(newData);
  };

  return (
    <VrrTable
      cyId="user-profile-connected-account-add-label-list"
      header={prepareHeader()}
      body={prepareBody(id, labels, dataTable, onChange)}
      tableProps={{
        borderless: true,
        striped: true,
        className: 'labels-table'
      }}
    />
  );
};

export default LabelsTable;
