import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import VrrTableHeader from './VrrTableHeader';
import { THeaderFilter, TTable, TTableBody } from './types';
import VrrTableBody from './VrrTableBody';
import { SORT_BY } from '../vrr_sort_by/constants';
import {
  DEFAULT_LIMIT_ROW,
  filterAction,
  sortByColumn,
  updateSearchValues
} from './services';
import VrrTablePagination from './vrr_table_pagination';

const VrrTable: React.FC<TTable> = ({
  cyId,
  header,
  body,
  tableProps,
  withSort = false,
  defaultSort,
  onSortAction,
  withFilter = false,
  fixHeader = false,
  actions,
  limitRow,
  onClickLine,
  withPagination = false
}: TTable) => {
  const [sortData, setSortData] = useState({
    idToSort: '',
    sortType: SORT_BY.asc
  });
  const [bodySorted, setBodySorted] = useState<TTableBody['rowElements']>(body);
  const [bodyFiltered, setBodyFiltered] = useState<TTableBody['rowElements']>(
    []
  );
  const [searchValues, setSearchValues] = useState<THeaderFilter[]>([]);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    return () => {
      setSearchValues([]);
      setSortData({
        idToSort: '',
        sortType: SORT_BY.asc
      });
      setPage(1);
    };
  }, []);

  useEffect(() => {
    if (defaultSort) {
      setSortData({
        idToSort: defaultSort.id,
        sortType: defaultSort.type
      });
    }
  }, [defaultSort]);

  const updateBody = (
    data: TTableBody['rowElements'],
    sortId: string,
    sortType: string,
    limit?: number,
    currentPage?: number
  ) => {
    let newBody = sortByColumn(data, sortId, sortType, limit);
    if (withPagination) {
      const rowPerPage = limit ?? DEFAULT_LIMIT_ROW;
      newBody = newBody.slice(
        ((currentPage ?? 1) - 1) * rowPerPage,
        (currentPage ?? 1) * rowPerPage
      );
    }
    return setBodySorted(newBody);
  };

  useEffect(() => {
    if (body) {
      updateBody(body, sortData.idToSort, sortData.sortType, limitRow, page);
    }
  }, [body, sortData]);

  const onSort = (headerId: string) => {
    const newSort =
      headerId === sortData.idToSort && sortData.sortType === SORT_BY.asc
        ? SORT_BY.desc
        : SORT_BY.asc;
    setSortData({
      idToSort: headerId,
      sortType: newSort
    });
    if (onSortAction) onSortAction(headerId, newSort);
  };

  const onFilterAction = (
    searchValue: string | undefined,
    headerId: string | undefined
  ) => {
    const newSearch = updateSearchValues(searchValues, headerId, searchValue);
    setPage(1);
    setSearchValues(newSearch);
    const bodyFilter = filterAction(body, newSearch);
    setBodyFiltered(bodyFilter);
    updateBody(bodyFilter, sortData.idToSort, sortData.sortType, limitRow, 1);
  };

  const onChangePage = (newPage: number) => {
    setPage(newPage);
    updateBody(body, sortData.idToSort, sortData.sortType, limitRow, newPage);
  };

  return (
    <div data-cy-id={cyId}>
      {withPagination && !!body.length && (
        <VrrTablePagination
          dataLength={
            !!bodyFiltered?.length ? bodyFiltered?.length : body.length
          }
          onChangePage={onChangePage}
          page={page}
        />
      )}
      <Table {...tableProps}>
        <VrrTableHeader
          elements={header}
          isFixed={fixHeader}
          withActions={!!actions}
          {...(withSort && { onSort })}
          {...(withFilter && { onFilter: onFilterAction })}
        />
        <VrrTableBody
          rowElements={bodySorted}
          actions={actions}
          onClickLine={onClickLine}
        />
      </Table>
    </div>
  );
};

export default VrrTable;
