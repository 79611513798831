import React from 'react';
import ProgressBar from '../Loader/ProgressBar';
import ReactTable from '../../libs/reactTable/ReactTable';
import './ExtendTable.scss';

export interface ReactTablesInterface {
  headersTable: Array<{
    Header: string;
    accessor: string;
  }>;
  dataTable: Array<{
    [key: string]: string | number | JSX.Element | undefined;
  }>;
  loadingData?: {
    label: string;
    enabled: boolean;
    currentNumber: number;
    totalNumber: number;
  };
}

const ReactTables = ({
  headersTable,
  dataTable,
  loadingData
}: ReactTablesInterface): JSX.Element => (
  <ProgressBar
    currentNumber={loadingData?.currentNumber}
    total={loadingData?.totalNumber}
    label={loadingData?.label}
    enable={loadingData?.enabled === true}
  >
    <ReactTable data={[...dataTable]} columns={headersTable} />
  </ProgressBar>
);

export default ReactTables;
