enum LABEL_DATE_SELECTOR {
  LAST_7_DAYS = 'main_navbar.date_picker.last_seven_days',
  LAST_30_DAYS = 'main_navbar.date_picker.last_thirty_days',
  LAST_12_WEEKS = 'main_navbar.date_picker.last_twelve_weeks',
  LAST_1_YEAR = 'main_navbar.date_picker.last_one_years',
  SINCE_START_MONTH = 'main_navbar.date_picker.month_to_date',
  SINCE_START_QUARTER = 'main_navbar.date_picker.quarter_to_date',
  SINCE_START_YEAR = 'main_navbar.date_picker.years_to_date',
  DEFAULT = 'main_navbar.date_picker.since_at'
}

export default LABEL_DATE_SELECTOR;
