import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { VrrTable } from '../../../../../../../shared_components';
import { NEW_INDEX, prepareBody, prepareHeader } from './services';
import { getMediaPlanErrors } from '../../../../../../../../redux/mediaPlanGallery/mediaPlanGalleryAction';
import { TRedirectionRow, TRedirectionTable } from './types';
import redirectionTableStyles from './RedirectionTable.module.scss';

const RedirectionTable = (
  { redirections, onChangeAction }: TRedirectionTable,
  ref: any
) => {
  const errors = useSelector(getMediaPlanErrors);

  const [dataTable, setDataTable] = useState<TRedirectionRow[]>([]);
  const [lineToEdit, setLineToEdit] = useState<string | undefined>(undefined);
  const [newValue, setNewValue] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (redirections?.length) {
      setDataTable(
        redirections.map((r, index: number) => {
          return {
            id: r.id,
            value: r.final_url,
            rowId: index.toString()
          };
        })
      );
    }
  }, [redirections]);

  const addLine = () => {
    setLineToEdit(NEW_INDEX);
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        addNewLine() {
          addLine();
        }
      };
    },
    []
  );

  const removeLine = (id: string) => {
    const newDataTable = dataTable?.filter((row) => row.rowId !== id);
    setDataTable(newDataTable);
    onChangeAction(newDataTable);
  };

  const editLine = (id: string) => {
    setLineToEdit(id);
  };

  const isValid = () => {
    const valueExist = dataTable.find((row) => row.value === newValue);
    return newValue?.trim().length && !valueExist;
  };

  const submitLine = (id: string) => {
    if (isValid()) {
      let newDataTable = [...dataTable];
      newDataTable = newDataTable.map((row) => {
        if (row.rowId === id) {
          return {
            ...row,
            value: newValue ?? ''
          };
        }
        return row;
      });
      if (id && !newDataTable.find((row) => row.rowId === id)) {
        newDataTable.push({
          id: null,
          rowId: id,
          value: newValue ?? ''
        });
      }

      setDataTable(newDataTable);
      onChangeAction(newDataTable);
    }
    setLineToEdit(undefined);
    setNewValue(undefined);
  };

  const changeLine = (value: string) => {
    setNewValue(value);
  };

  const cancelLine = () => {
    setLineToEdit(undefined);
    setNewValue(undefined);
  };

  return (
    <VrrTable
      header={prepareHeader()}
      body={prepareBody(
        dataTable,
        editLine,
        removeLine,
        changeLine,
        submitLine,
        cancelLine,
        lineToEdit,
        errors
      )}
      tableProps={{
        borderless: true,
        className: 'redirection-table',
        cssModule: redirectionTableStyles
      }}
      withSort
    />
  );
};

export default React.forwardRef(RedirectionTable);
