import React from 'react';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IAvatarButton } from './types';
import AvatarDropdownItem from './avatar_dropdown_item';
import DefaultAvatar from '../../../assets/vrr/images/default-avatar.svg';
import URLS from '../../routing/urls';
import './AvatarButton.scss';

const AvatarButton: React.FC<IAvatarButton> = ({
  logoutAction,
  username
}: IAvatarButton) => {
  const { t } = useTranslation();
  const history = useNavigate();

  return (
    <UncontrolledDropdown id="cy-navbar-avatar" nav>
      <DropdownToggle
        caret
        color="default"
        data-toggle="dropdown"
        nav
        inputprops={{
          onClick: (e: React.ChangeEvent<HTMLInputElement>) =>
            e.preventDefault()
        }}
      >
        {username && <span>{`${username} `}</span>}
        <div className="photo">
          <img alt="avatar" src={DefaultAvatar} />
        </div>
      </DropdownToggle>
      <DropdownMenu className="dropdown-navbar" end tag="ul">
        <AvatarDropdownItem
          itemId="cy-navbar-avatar-menu-user-profile"
          onClick={() => history(URLS.USER_PROFILE)}
          classNameItem="nav-item"
        >
          {t('main_navbar.avatar_button.dropdown_menu.profile')}
        </AvatarDropdownItem>
        <DropdownItem divider tag="li" />
        <AvatarDropdownItem
          itemId="cy-navbar-avatar-menu-logout"
          onClick={logoutAction}
          classNameItem="nav-item cy-logout-action"
        >
          {t('main_navbar.avatar_button.dropdown_menu.log_out')}
        </AvatarDropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default AvatarButton;
