import { IListState } from 'micro_front_end/shared_components/vrr_dropdown/types';

export const getKpis = (
  kpis: IListState[],
  list: string[] | null
): IListState[] => {
  const newKpis: IListState[] = [...kpis];
  list?.forEach((kpi) => {
    newKpis.push({
      value: kpi,
      label: kpi
    });
  });
  return newKpis;
};

export default { getKpis };
