import { SORT_BY } from 'micro_front_end/shared_components/vrr_sort_by/constants';

export const sort = <T>(
  data: Array<{ name: string } & T>,
  order: string,
  key = 'name'
): Array<{ name: string } & T> => {
  const sortedArray = [...data];
  switch (order) {
    case SORT_BY.desc:
      sortedArray.sort((a: any, b: any) => {
        return b[key]?.localeCompare(a[key]);
      });
      break;
    case SORT_BY.asc:
    default:
      sortedArray.sort((a: any, b: any) => {
        return a[key]?.localeCompare(b[key]);
      });
      break;
  }
  return sortedArray;
};

export default sort;
