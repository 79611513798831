import React from 'react';
import { useTranslation } from 'react-i18next';
import { VrrTag } from '../../../../../../shared_components';
import { TTargetList } from './types';
import './TargetList.scss';

const TargetList: React.FC<TTargetList> = ({
  title,
  tags,
  onClickAction,
  onRemoveAllAction
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="target-list-container">
        <div className="target-list-header">
          <p>
            {t('media_plan_edit.media_plan_targets.target_list.header', {
              label: title,
              nbItems: tags.length
            })}
          </p>
          <p
            className="clear-btn"
            onClick={() => (onRemoveAllAction ? onRemoveAllAction() : null)}
          >
            {t('media_plan_edit.media_plan_targets.target_list.clear_all')}
          </p>
        </div>
        <div className="target-list-tags">
          {tags.map((tag) => {
            const { label, id } = tag;

            return (
              <VrrTag
                key={`target-list-tag-${id}-${label}`}
                label={label}
                id={id}
                icon="tim-icons icon-simple-remove"
                onClickAction={onClickAction}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TargetList;
