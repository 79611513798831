import termsEN from './terms/termsEN.json';
import termsFR from './terms/termsFR.json';
import privacyEN from './privacy/privacyEN.json';
import privacyFR from './privacy/privacyFR.json';
import cookiesFR from './cookies/cookiesFR.json';
import cookiesEN from './cookies/cookiesEN.json';

const GDPR_EN = {
  ...termsEN,
  ...privacyEN,
  ...cookiesEN
};
const GDPR_FR = {
  ...termsFR,
  ...privacyFR,
  ...cookiesFR
};

export { GDPR_EN, GDPR_FR };
