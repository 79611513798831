import creativeVideoDetailsEN from './creative_video_details/creativeVideoDetailsEN.json';
import creativeVideoDetailsFR from './creative_video_details/creativeVideoDetailsFR.json';
import sequencesRatingEN from './creative_video_details/sequences_rating/sequencesRatingEN.json';
import sequencesRatingFR from './creative_video_details/sequences_rating/sequencesRatingFR.json';
import elementsRatingEN from './creative_video_details/elements_rating/elementsRatingEN.json';
import elementsRatingFR from './creative_video_details/elements_rating/elementsRatingFR.json';
import creativeGalleryFR from './creative_gallery/creativeGalleryFR.json';
import creativeGalleryEN from './creative_gallery/creativeGalleryEN.json';

const CREATIVE_ANALYTICS_EN = {
  ...creativeGalleryEN,
  ...creativeVideoDetailsEN,
  ...sequencesRatingEN,
  ...elementsRatingEN
};
const CREATIVE_ANALYTICS_FR = {
  ...creativeGalleryFR,
  ...creativeVideoDetailsFR,
  ...sequencesRatingFR,
  ...elementsRatingFR
};

export { CREATIVE_ANALYTICS_EN, CREATIVE_ANALYTICS_FR };
