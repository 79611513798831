import React, { useEffect, useState } from 'react';
import { TMetricsGallery } from './types';
import { CardColumns } from 'reactstrap';
import MetricCard from '../metric_card';
import { metricDesignConst } from '../metric_card/constants';
import { TMetricCardContent, TMetricToDetail } from '../metric_card/types';
import VrrAddCard from 'micro_front_end/shared_components/vrr_add_card';
import { DEFAULT_MAIN_KPI } from '../../metric_detail/metric_detail_header/kpi_dropdown/services';
import MetricDetail from '../../metric_detail';
import MetricModal from '../../metric_modal';
import './MetricsGallery.scss';
import { TMetricKey, TSetOfMetrics } from 'redux/overview/metrics/types';
import { TMetricTable } from '../metric_table/types';

const MetricsGallery: React.FC<TMetricsGallery> = ({
  currentMetrics,
  metricsList,
  mainKpi
}: TMetricsGallery) => {
  const [data, setData] = useState<TSetOfMetrics>();
  const [open, setOpen] = useState<boolean>(false);
  const [openMetrics, setOpenMetrics] = useState<boolean>(false);
  const [currentSegment, setCurrentSegment] = useState<TMetricToDetail>();

  useEffect(() => {
    if (!!metricsList) {
      setData(metricsList);
    }
  }, [metricsList]);

  const openMetricDetails = (metricToDetail: TMetricToDetail) => {
    setCurrentSegment(metricToDetail);
    setOpen(true);
  };

  const closeModal = () => {
    setCurrentSegment(undefined);
    setOpen(false);
  };

  const makeMetricCardProps = (metric: TMetricKey): TMetricCardContent => {
    const metricData: TMetricTable['data'] = !!data
      ? ({ [metric]: data[metric]?.data } as TMetricTable['data'])
      : {};

    return {
      metricName: metric,
      MetricDesign: metricDesignConst[metric],
      kpiSelected: ['name', mainKpi ?? DEFAULT_MAIN_KPI],
      data: metricData
    };
  };

  return (
    <>
      <CardColumns className="card-columns" data-cy-id="overview-details">
        {data &&
          currentMetrics?.map((currentMetric: TMetricKey) => {
            return (
              <MetricCard
                key={currentMetric}
                metric={makeMetricCardProps(currentMetric)}
                onClickAction={openMetricDetails}
              />
            );
          })}
        <div className="metric-card" data-cy-id="overview-add-details-button">
          <VrrAddCard onClickAction={() => setOpenMetrics(true)} />
        </div>
      </CardColumns>
      {currentSegment && open && (
        <MetricDetail
          type="modal"
          metric={currentSegment.metricName}
          isOpen={open}
          onCloseAction={closeModal}
        />
      )}
      <MetricModal
        isOpen={openMetrics}
        onCancelAction={() => setOpenMetrics(false)}
      />
    </>
  );
};

export default MetricsGallery;
