import { TTable } from 'micro_front_end/shared_components/vrr_table/types';
import { TMetricData, TMetricDataUnit, TMetricTable } from './types';
import { TMetricDetailTable } from '../../metric_detail/metric_detail_table/types';
import { getCurrentCurrency } from 'services/services';
import i18next from 'i18next';
import formatDataService from 'micro_front_end/services/formatters/formatData';
import {
  toLowerKebabCase,
  toSnakeCase
} from 'micro_front_end/services/formatters/formatString';

export const METRIC_GENERAL_KPIS = [
  'taxonomy',
  'name',
  'spent',
  'impressions',
  'cpm',
  'views',
  'vtr',
  'cpv',
  'clicks',
  'ctr_imp',
  'cpc',
  'funnel'
];

export const filterDataOnKpisClaim = (
  data: TMetricTable['data'],
  kpis: TMetricTable['limitColumnKeys'],
  metricName: string
): TMetricData => {
  const clonedData = { ...data };
  const result: TMetricData = [];

  Object.values(clonedData[metricName.toLocaleLowerCase()]).forEach(
    (metricRow) => {
      const newPropertiesOfMetric: { [key: string]: TMetricDataUnit } = {};
      kpis?.forEach((kpi: string) => {
        if (metricRow[kpi]) {
          newPropertiesOfMetric[kpi] = metricRow[kpi];
        }
      });
      result.push(newPropertiesOfMetric);
    }
  );

  return result;
};

export const formatHeader = (head: string): string => {
  let text: string | null = toSnakeCase(head);
  if (text && METRIC_GENERAL_KPIS.includes(text)) {
    text = i18next.t(`metrics.metric_detail_modal.table.header.${text}`);
  } else {
    text = head;
  }
  return text;
};

export const prepareHeaders = (headers: [string, string]): TTable['header'] => {
  return headers?.map((header) => {
    return {
      id: header,
      component: <p>{formatHeader(header)}</p>
    };
  });
};

export const prepareBodies = (
  metrics: TMetricDetailTable['metricData'],
  headers: TMetricDetailTable['headers']
): TTable['body'] => {
  const symbol = getCurrentCurrency();
  const thousandsSeparator = i18next.t('local.separators.thousands');
  const decimalSeparator = i18next.t('local.separators.decimal');

  const bodies: TTable['body'] = [];

  metrics?.forEach((metric: { [key: string]: TMetricDataUnit }) => {
    bodies.push(
      headers?.map((header) => {
        const metricByHeader = metric[header];
        const formatedData = metricByHeader
          ? formatDataService.dataFormatter(
              metricByHeader,
              symbol,
              thousandsSeparator,
              decimalSeparator
            )
          : '-';
        return {
          id: toLowerKebabCase(metricByHeader?.value.toString() || '-'),
          component: <p title={formatedData as string}>{formatedData}</p>,
          value: metricByHeader?.value.toString() || '-',
          headerId: header
        };
      })
    );
  });

  return bodies;
};
