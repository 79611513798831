import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import ManagementCampaign from '../../components/ManagementCampaign';
import {
  getAccountId,
  getPeriod
} from '../../redux/userPreferences/actions/userPreferencesActions';
import {
  fetchCampaign,
  fetchTableLines,
  getCampaign,
  getTableData
} from '../../redux/management/managementActions';
import getCurrentProvider, {
  providers
} from '../../services/provider/getCurrentProvider';
import StateLoadingData from '../../components/ManagementCampaign/ManagementCampaign.constante';
import ManagementPageService from '../../components/ManagementCampaign/ManagementCampaign.service';
import { getCurrentCurrency } from '../../services/services';
import { IManagementCampaignC } from './types';

const ManagementCampaignC: React.FC<IManagementCampaignC> = ({
  datePickerPeriod = null,
  accountId = null,
  getCampaignAction,
  getTableLinesAction,
  campaign,
  tableData
}: IManagementCampaignC) => {
  const { t } = useTranslation();

  const [stateData, setStateData] = useState<StateLoadingData>(
    StateLoadingData.NONE
  );
  const [dataTable, setDataTable] = useState<
    Array<{
      [key: string]: {
        value: number | string;
        type: string;
        decimal: number;
      };
    }>
  >([]);
  const [headerDataTable, setHeaderDataTable] = useState<
    Array<{
      Header: string;
      accessor: string;
    }>
  >([]);
  const [streamID, setStreamID] = useState<string>();

  const [loadingData, setLoadingData] = useState<{
    enabled: boolean;
    label: string;
    currentNumber: number;
    totalNumber: number;
  }>({
    enabled: false,
    label: '',
    currentNumber: 0,
    totalNumber: 0
  });
  const [currentLoading, setCurrentLoading] = useState<number | undefined>(0);
  const [totalLoading, setTotalLoading] = useState<number | undefined>(
    undefined
  );
  const findProvider = () => {
    const provider = getCurrentProvider();
    if (providers.includes(provider)) {
      return provider;
    }
    return '';
  };

  const symbol = getCurrentCurrency();

  const initializeStates = () => {
    setDataTable([]);
    setCurrentLoading(0);
    setTotalLoading(undefined);
    setHeaderDataTable([]);
    setStateData(StateLoadingData.NONE);
    setStreamID('');
    setLoadingData({
      enabled: false,
      label: '',
      currentNumber: 0,
      totalNumber: 0
    });
  };

  useEffect(() => {
    const provider = findProvider();
    initializeStates();
    if (
      provider !== '' &&
      accountId &&
      datePickerPeriod?.from &&
      datePickerPeriod?.to
    ) {
      setStateData(StateLoadingData.INITIALISATION);
      getCampaignAction(
        moment(datePickerPeriod?.from, 'YYYY-MM-DD'),
        moment(datePickerPeriod?.to, 'YYYY-MM-DD'),
        provider,
        accountId
      );
    }
  }, [accountId, datePickerPeriod]);

  useEffect(() => {
    if (campaign !== null) {
      setHeaderDataTable(
        ManagementPageService.convertHeaderToHeaderExtendTable(campaign.header)
      );
      setStreamID(campaign.data);
      getTableLinesAction(campaign.data);
    }
  }, [campaign]);

  useEffect(() => {
    if (tableData !== null) {
      if (!totalLoading) {
        setTotalLoading(tableData.remain + tableData.length);
      }
      setStateData(StateLoadingData.LOADING_DATA);
      setCurrentLoading(currentLoading + tableData.length);
      if (tableData.content.length > 0) {
        setDataTable([
          ...ManagementPageService.formatManageData(
            tableData.content,
            symbol,
            t('local.separators.thousands'),
            t('local.separators.decimal')
          )
        ]);
      }
    }
  }, [tableData]);

  useEffect(() => {
    if (
      streamID &&
      currentLoading &&
      totalLoading &&
      currentLoading < totalLoading
    ) {
      getTableLinesAction(streamID);
    } else {
      setStateData(StateLoadingData.DATA_READY);
    }
  }, [streamID, currentLoading, totalLoading]);

  useEffect(() => {
    setLoadingData(
      ManagementPageService.getLoadingObject(
        stateData,
        currentLoading,
        totalLoading
      )
    );
  }, [stateData, currentLoading, totalLoading]);

  return (
    <>
      {datePickerPeriod && accountId && (
        <ManagementCampaign
          dataTable={dataTable}
          headersTable={headerDataTable}
          loadingData={loadingData}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  datePickerPeriod: getPeriod(state),
  accountId: getAccountId(state),
  campaign: getCampaign(state),
  tableData: getTableData(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  getCampaignAction: (
    startDate: Moment,
    endDate: Moment,
    provider: string,
    id: string
  ) => dispatch(fetchCampaign({ startDate, endDate, provider, id })),
  getTableLinesAction: (streamID: string) =>
    dispatch(fetchTableLines({ streamID }))
});

const ManagementCampaignContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagementCampaignC);

export default ManagementCampaignContainer;
