import React from 'react';
import { useTranslation } from 'react-i18next';
import { VrrButton } from '../../../shared_components';
import { TMediaPlanStatus } from './types';
import './MediaPlanStatus.scss';

const MediaPlanStatus: React.FC<TMediaPlanStatus> = ({
  currentStep,
  colorStep,
  currentStatus,
  onClickAction
}) => {
  const { t } = useTranslation();

  return (
    <div className="media-plan-status">
      <div className="status">
        <div className={`status-score ${colorStep}`}>{currentStep}/9</div>
        <span>
          {t('media_plan_details.media_plan_status.status', {
            status: currentStatus
          }).toUpperCase()}
        </span>
      </div>
      <div className="media-plan-save">
        <VrrButton className="media-plan-save" onClick={onClickAction}>
          {t('media_plan_details.media_plan_status.save')}
        </VrrButton>
      </div>
    </div>
  );
};

export default MediaPlanStatus;
