import React from 'react';
import { ListGroup } from 'reactstrap';
import './BudgetList.scss';
import { TBudgetList } from './types';
import { getCurrentCurrency } from '../../../../../services/services';
import BudgetListTitle from './BudgetListTitle/BudgetListTitle';

const BudgetList: React.FC<TBudgetList> = ({
  data,
  disabled,
  currentSplitTestId,
  handleChange
}: TBudgetList) => {
  const symbol = getCurrentCurrency();

  return (
    <ListGroup flush>
      {data.map((item) => {
        if (item.name !== 'weekly_budget') {
          return (
            <React.Fragment key={`budgetListItem-${item.name}-${item.value}`}>
              <BudgetListTitle
                data={item}
                disabled={disabled}
                currentSplitTestId={currentSplitTestId}
                handleChange={handleChange}
                symbol={symbol}
              />
            </React.Fragment>
          );
        }
      })}
    </ListGroup>
  );
};

export default BudgetList;
