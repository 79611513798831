import React from 'react';
import { connect } from 'react-redux';
import { updateUserProfilePassword } from '../../../redux/userProfile/userProfileActions';
import UserPassword from '../../../components/UserProfile/UserPassword';
import { IUserPasswordComponentContainer } from './types';

export const UserPasswordC: React.FC<IUserPasswordComponentContainer> = ({
  updatePassword,
  isRestricted
}: IUserPasswordComponentContainer) => {
  return (
    <UserPassword
      onUpdatePassword={updatePassword}
      isRestricted={isRestricted}
    />
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  updatePassword: (oldPassword: string, newPassword: string) => {
    dispatch(updateUserProfilePassword({ oldPassword, newPassword }));
  }
});

const UserPasswordContainer = connect(null, mapDispatchToProps)(UserPasswordC);

export default UserPasswordContainer;
