import {
  TDataFromJson,
  TFile,
  TFileInformation,
  TFilesByFunnel,
  TFunnel
} from './audience_list/types';
import affinities from '../../../../../../../../../libs/affinity_targets/affinityTargets.min.json';
import topics from '../../../../../../../../../libs/vertical_targets/verticalTargets.min.json';
import inMarkets from '../../../../../../../../../libs/in_market_targets/inMarketTargets.min.json';
import { TAudienceData } from './audience_list/audience/types';
import { TCategoryLine } from './audience_list/audience/audience_category/audience_category_line/types';
import { TCategoryData } from './audience_list/audience/audience_category/types';
import { TTarget } from '../../../../../../types';
import { toLowerKebabCase } from '../../../../../../../../services/formatters/formatString';

const prepareData = (file: TFile[], funnel: TFunnel): TDataFromJson[] => {
  return file.map((f) => {
    return {
      ...f,
      funnel
    };
  });
};

const TOP_FUNNELS: TFileInformation[] = [
  {
    audienceName: 'affinity audience',
    dataFromJson: prepareData(affinities, 'top')
  },
  {
    audienceName: 'topics audience',
    dataFromJson: prepareData(topics, 'top')
  }
];

const MIDDLE_FUNNELS: TFileInformation[] = [
  {
    audienceName: 'in market audience',
    dataFromJson: prepareData(inMarkets, 'middle')
  }
];

const filesByFunnel: TFilesByFunnel = {
  all: [...TOP_FUNNELS, ...MIDDLE_FUNNELS],
  top: [...TOP_FUNNELS],
  middle: [...MIDDLE_FUNNELS]
};

const formatSubCategories = (
  parentId: string,
  audienceFile: TDataFromJson[]
): TCategoryLine[] => {
  const filteredCategories: TDataFromJson[] = audienceFile.filter(
    (cat: TDataFromJson) => cat.parent_id === parentId
  );
  return filteredCategories.map((category) => {
    return {
      ...category,
      criteriaId: category.criteria_id
    } as TCategoryLine;
  });
};

const formatCategoryData = (audienceFile: TDataFromJson[]): TCategoryData[] => {
  const parents = audienceFile.filter((category) => category.parent_id === '0');
  return parents.map((parent: TDataFromJson) => {
    return {
      parentCategory: {
        ...parent,
        criteriaId: parent.criteria_id
      } as TCategoryLine,
      subCategories: formatSubCategories(parent.criteria_id, audienceFile)
    };
  });
};

export const getAudiencesByFunnel = (funnel: TFunnel): TAudienceData[] => {
  const files: TFileInformation[] = filesByFunnel[funnel];

  return files.map((file: TFileInformation) => {
    return {
      audienceName: file.audienceName,
      audienceCategories: formatCategoryData(file.dataFromJson),
      funnel
    };
  });
};

export const prepareAudiencesForSearch = (
  audienceList: TAudienceData[]
): TCategoryLine[] => {
  const formatAudienceList: TCategoryLine[] = [];

  audienceList.forEach((audience: TAudienceData) => {
    audience.audienceCategories.forEach((category: TCategoryData) => {
      formatAudienceList.push(category.parentCategory);
      category.subCategories.forEach((subCategory: TCategoryLine) => {
        formatAudienceList.push(subCategory);
      });
    });
  });

  return formatAudienceList;
};

const getAllFunnelParents = (selection: TTarget): TTarget[] => {
  const parents: TTarget[] = [];
  Object.values([...TOP_FUNNELS, ...MIDDLE_FUNNELS]).forEach((f) => {
    if (toLowerKebabCase(f.audienceName) === selection.id) {
      return f.dataFromJson.forEach((category) => {
        if (category.parent_id === '0') {
          parents.push({
            id: category.criteria_id,
            funnel: category.funnel,
            name: category.name
          } as TTarget);
        }
      });
    }
  });
  return parents;
};

export const prepareAllParent = (
  selections: TTarget[],
  parents: TTarget[],
  isChecked: boolean
): TTarget[] => {
  let newSelections: TTarget[] = [...selections];
  if (isChecked) {
    const parentIDs = parents.map((parent) => parent.id);
    newSelections = newSelections.filter(
      (selection) => !parentIDs.includes(selection.id)
    );
    newSelections = [...newSelections, ...parents];
  } else {
    parents.forEach(
      (parent) =>
        (newSelections = newSelections.filter((value) => {
          return value.id !== parent.id;
        }))
    );
  }
  return newSelections;
};

export const prepareSelection = (
  selections: TTarget[],
  selection: TTarget,
  isChecked: boolean
): TTarget[] => {
  let newSelections: TTarget[] = [...selections];
  if (isChecked) {
    newSelections.push(selection);
  } else {
    newSelections = newSelections.filter((value) => {
      return value.id !== selection.id;
    });
  }
  return newSelections;
};

export const prepareCurrentSelections = (
  selections: TTarget[] | undefined,
  selection: TTarget,
  isChecked: boolean
): TTarget[] => {
  let newSelections: TTarget[] = [...(selections ?? [])];
  const allParents = getAllFunnelParents(selection);
  if (!!allParents.length) {
    newSelections = prepareAllParent(newSelections, allParents, isChecked);
  } else {
    newSelections = prepareSelection(newSelections, selection, isChecked);
  }

  return newSelections;
};
