import React from 'react';
import { useTranslation } from 'react-i18next';
import { VrrSelections } from '../../../../../shared_components';
import { prepareMetricsList, prepareNewMetrics } from './services';
import { TGlobalMetricsSelections } from './types';
import { TSelectionMetric } from '../../card_metric/types';
import { TSelection } from '../../../../../shared_components/vrr_selections/types';
import './GlobalMetricsSelections.scss';

const GlobalMetricsSelections: React.FC<TGlobalMetricsSelections> = ({
  metrics,
  selectedMetricsList,
  onChangeAction
}) => {
  const { t } = useTranslation();
  const onChange = (selectedMetrics: TSelectionMetric[] | TSelection[]) => {
    const newMetrics = prepareNewMetrics(
      selectedMetrics as TSelectionMetric[],
      metrics
    );
    if (onChangeAction) onChangeAction(newMetrics);
  };

  return (
    <div className="global-metrics-selections">
      <VrrSelections
        itemsList={prepareMetricsList(metrics)}
        selectedItemsList={prepareMetricsList(selectedMetricsList)}
        selectedTitle={t(
          'global_metrics.global_metrics_selections.title.selected'
        ).toUpperCase()}
        availableTitle={t(
          'global_metrics.global_metrics_selections.title.available'
        ).toUpperCase()}
        onChange={onChange}
      />
    </div>
  );
};

export default GlobalMetricsSelections;
