import { TSection } from '../summary/types';

export const TERMS_SECTIONS: TSection[] = [
  {
    id: 'section0',
    title: 'terms.section0.title',
    content: 'terms.section0.content'
  },
  {
    id: 'section1',
    title: 'terms.section1.title',
    content: 'terms.section1.content'
  },
  {
    id: 'section2',
    title: 'terms.section2.title',
    content: 'terms.section2.content'
  },
  {
    id: 'section3',
    title: 'terms.section3.title',
    content: 'terms.section3.content'
  },
  {
    id: 'section4',
    title: 'terms.section4.title',
    subtitles: [
      {
        id: 'section41',
        title: 'terms.section41.title',
        content: 'terms.section41.content'
      },
      {
        id: 'section42',
        title: 'terms.section42.title',
        content: 'terms.section42.content'
      },
      {
        id: 'section43',
        title: 'terms.section43.title',
        content: 'terms.section43.content'
      },
      {
        id: 'section44',
        title: 'terms.section44.title',
        content: 'terms.section44.content'
      },
      {
        id: 'section45',
        title: 'terms.section45.title',
        content: 'terms.section45.content'
      },
      {
        id: 'section46',
        title: 'terms.section46.title',
        content: 'terms.section46.content'
      }
    ]
  },
  {
    id: 'section5',
    title: 'terms.section5.title',
    content: 'terms.section5.content'
  },
  {
    id: 'section6',
    title: 'terms.section6.title',
    content: 'terms.section6.content'
  },
  {
    id: 'section7',
    title: 'terms.section7.title',
    content: 'terms.section7.content'
  },
  {
    id: 'section8',
    title: 'terms.section8.title',
    content: 'terms.section8.content'
  },
  {
    id: 'section9',
    title: 'terms.section9.title',
    content: 'terms.section9.content'
  },
  {
    id: 'section10',
    title: 'terms.section10.title',
    content: 'terms.section10.content',
    subtitles: [
      {
        id: 'section101',
        title: 'terms.section101.title',
        content: 'terms.section101.content'
      }
    ]
  },
  {
    id: 'section11',
    title: 'terms.section11.title',
    content: 'terms.section11.content'
  },
  {
    id: 'section12',
    title: 'terms.section12.title',
    content: 'terms.section12.content'
  },
  {
    id: 'section13',
    title: 'terms.section13.title',
    subtitles: [
      {
        id: 'section131',
        title: 'terms.section131.title',
        content: 'terms.section131.content'
      },
      {
        id: 'section132',
        title: 'terms.section132.title',
        content: 'terms.section132.content'
      },
      {
        id: 'section133',
        title: 'terms.section133.title',
        content: 'terms.section133.content'
      },
      {
        id: 'section134',
        title: 'terms.section134.title',
        content: 'terms.section134.content'
      },
      {
        id: 'section135',
        title: 'terms.section135.title',
        content: 'terms.section135.content'
      },
      {
        id: 'section136',
        title: 'terms.section136.title',
        content: 'terms.section136.content'
      },
      {
        id: 'section137',
        title: 'terms.section137.title',
        content: 'terms.section137.content'
      },
      {
        id: 'section138',
        title: 'terms.section138.title',
        content: 'terms.section138.content'
      },
      {
        id: 'section139',
        title: 'terms.section139.title',
        content: 'terms.section139.content'
      },
      {
        id: 'section1310',
        title: 'terms.section1310.title',
        content: 'terms.section1310.content'
      },
      {
        id: 'section1311',
        title: 'terms.section1311.title',
        content: 'terms.section1311.content'
      }
    ]
  }
];
