import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MetricsGallery from '../metrics_gallery';
import {
  getCurrentProvider,
  providers
} from '../../../../../services/provider/getCurrentProvider';
import useSettings from '../../../../../libs/settings/useSettings';
import { DEFAULT_MAIN_KPI } from '../../metric_detail/metric_detail_header/kpi_dropdown/services';
import {
  getAccountId,
  getGISettingsByCurrentAccount,
  getPeriod
} from '../../../../../redux/userPreferences/actions/userPreferencesActions';
import {
  getAllMetrics,
  prepareInsights
} from '../../../../../redux/overview/metrics/MetricsActions';
import { getCurrentLabel } from '../../../../../redux/app/appActions';
import { TSetOfMetrics } from '../../../../../redux/overview/metrics/types';
import { TDateRange } from '../../../../nav_bar/date_time_picker/types';
import { TAllMetricsPreferences } from '../../../../../redux/userPreferences/types';

const Metrics: React.FC = () => {
  const dispatch: any = useDispatch();
  const allMetrics: TSetOfMetrics = useSelector(getAllMetrics);
  const accountId: string | null = useSelector(getAccountId);
  const period: TDateRange | null = useSelector(getPeriod);
  const funnel: string = useSelector(getCurrentLabel);
  const currentMetricsSettings: TAllMetricsPreferences | null = useSelector(
    getGISettingsByCurrentAccount
  );
  const [currentAccount, setCurrentAccount] = useState('');
  const [currentFunnel, setCurrentFunnel] = useState(funnel);
  const [currentPeriod, setCurrentPeriod] = useState(period);
  const [provider, setProvider] = useState('all');
  const { overview } = useSettings();

  useEffect(() => {
    const currentProvider = getCurrentProvider();
    if (providers.includes(currentProvider)) {
      setProvider(currentProvider);
    }
  }, []);

  useEffect(() => {
    if (
      currentAccount !== '' &&
      currentFunnel &&
      currentPeriod?.from &&
      currentPeriod?.to &&
      provider !== ''
    ) {
      dispatch(
        prepareInsights({
          accountId: currentAccount,
          period: currentPeriod,
          provider
        })
      );
    }
  }, [currentAccount, currentFunnel, currentPeriod, provider]);

  useEffect(() => {
    if (
      accountId !== null &&
      (currentAccount !== accountId ||
        currentFunnel !== funnel ||
        (currentPeriod?.from !== period?.from &&
          currentPeriod?.to !== period?.to))
    ) {
      setCurrentAccount(accountId);
      setCurrentFunnel(funnel);
      setCurrentPeriod(period);
      if (!currentMetricsSettings && accountId !== '') {
        overview('global_insights', {
          mainKpi: DEFAULT_MAIN_KPI
        });
      }
    }
  }, [accountId, funnel, period, currentMetricsSettings]);

  return (
    <MetricsGallery
      mainKpi={currentMetricsSettings?.mainKpi}
      metricsList={allMetrics}
      currentMetrics={currentMetricsSettings?.metricSelections ?? ['video']}
    />
  );
};

export default Metrics;
