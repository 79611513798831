import { getIcon } from '../../../../../services/services';
import { TMetricDesignConst } from './types';

export const metricDesignConst: TMetricDesignConst = {
  gender: {
    metricIcon: getIcon('gender', 'metric-card-kpi-custom-icon yellow') || (
      <i className="tim-icons metric-card-kpi-icon icon-single-02 yellow" />
    )
  },

  device: {
    metricIcon: getIcon('device', 'metric-card-kpi-custom-icon purple') || (
      <i className="tim-icons metric-card-kpi-icon icon-tablet-2 purple" />
    )
  },

  cta: {
    metricIcon: getIcon('cta', 'metric-card-dimension red') || (
      <i className="tim-icons metric-card-kpi-icon icon-shape-star red" />
    )
  },

  zone: {
    metricIcon: getIcon('zone', 'metric-card-kpi-custom-icon purple') || (
      <i className="tim-icons metric-card-kpi-icon icon-world purple" />
    )
  },

  redirection: {
    metricIcon: getIcon(
      'redirection',
      'metric-card-kpi-custom-icon yellow'
    ) || <i className="tim-icons metric-card-kpi-icon icon-user-run yellow" />
  },

  age: {
    metricIcon: getIcon('age', 'metric-card-kpi-custom-icon red') || (
      <i className="tim-icons metric-card-kpi-icon icon-single-02 red" />
    )
  },

  video: {
    metricIcon: getIcon('video', 'metric-card-kpi-custom-icon turquoise') || (
      <i className="tim-icons metric-card-kpi-icon icon-video-66 turquoise" />
    )
  },

  audience: {
    metricIcon: getIcon('audience', 'metric-card-kpi-custom-icon red') || (
      <i className="tim-icons metric-card-kpi-icon icon-world red" />
    )
  },

  taxonomy: {
    metricIcon: getIcon('taxonomy', 'metric-card-dimension purple') || (
      <i className="tim-icons metric-card-kpi-icon icon-world red" />
    )
  },

  funnel: {
    metricIcon: getIcon('taxonomy', 'metric-card-dimension purple') || (
      <i className="tim-icons metric-card-kpi-icon icon-world red" />
    )
  }
};
