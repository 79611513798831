import React, { useState, useEffect } from 'react';
import './UserPassword.scss';
import { FormGroup, Label, Input, Button, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import checkPasswordAndConfirmPassword from './UserPassword.service';
import { ModalBody } from 'reactstrap';
import { IPassword } from './types';

const UserPassword: React.FC<IPassword> = ({
  onUpdatePassword,
  isRestricted
}: IPassword) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordConfirmationError, setPasswordConfirmationError] =
    useState('');
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setPasswordError('');
    setPasswordConfirmationError('');
    setIsFormValid(true);
    if (
      oldPassword.length === 0 &&
      password.length === 0 &&
      passwordConfirmation.length === 0
    ) {
      setIsFormValid(false);
    } else {
      const errorList = checkPasswordAndConfirmPassword(
        password,
        passwordConfirmation
      );
      if (errorList.length > 0 || oldPassword === '') {
        setPasswordError(t(errorList[0]));
        setIsFormValid(false);
        setPasswordConfirmationError(
          errorList[0] === 'components.user_password.errors.password_match'
            ? t(errorList[0])
            : ''
        );
      } else {
        setIsFormValid(true);
      }
    }
  }, [password, passwordConfirmation, oldPassword]);

  const reset = () => {
    setIsFormValid(false);
  };

  const errorClassFor = (value: string) => {
    return value.length > 0 ? 'has-danger' : '';
  };

  const changePassword = (passwordParam: string) => {
    onUpdatePassword(oldPassword, passwordParam);
    reset();
  };

  const setContainer = (content: any) => {
    if (isRestricted) {
      return <ModalBody>{content}</ModalBody>;
    } else {
      return (
        <div className="user-password-container">
          <div className="user-password-card-body">{content}</div>
        </div>
      );
    }
  };

  return setContainer(
    <>
      <Row>
        <Col sm="8">
          <h4>{t('components.user_password.title').toUpperCase()}</h4>
        </Col>
      </Row>
      <div id="change_form">
        <FormGroup>
          <Label for="current_password">
            {t('components.user_password.current_password.title')}
          </Label>
          <Input
            type="password"
            name="current_password"
            id="cy-reset-password-input-current"
            value={oldPassword}
            placeholder={t(
              'components.user_password.current_password.placeholder'
            )}
            autoComplete="new-password"
            onChange={(event: React.FormEvent<HTMLInputElement>) => {
              setOldPassword(event.currentTarget.value);
            }}
          />
        </FormGroup>
        <FormGroup className={errorClassFor(passwordError)}>
          <Label for="new_password">
            {t('components.user_password.new_password.title')}
          </Label>
          <Input
            type="password"
            name="new_password"
            id="cy-reset-password-input-new"
            value={password}
            placeholder={t('components.user_password.new_password.placeholder')}
            autoComplete="new-password"
            onChange={(event: React.FormEvent<HTMLInputElement>) =>
              setPassword(event.currentTarget.value)
            }
          />
          {passwordError.length > 0 && (
            <span className="error">{passwordError}</span>
          )}
        </FormGroup>
        <FormGroup className={errorClassFor(passwordConfirmationError)}>
          <Label for="new_password_confirmation">
            {t('components.user_password.new_password_confirmation.title')}
          </Label>
          <Input
            type="password"
            name="new_password_confirmation"
            id="cy-reset-password-input-confirmation"
            value={passwordConfirmation}
            placeholder={t(
              'components.user_password.new_password_confirmation.placeholder'
            )}
            autoComplete="new-password"
            onChange={(event: React.FormEvent<HTMLInputElement>) =>
              setPasswordConfirmation(event.currentTarget.value)
            }
          />
          {passwordConfirmationError.length > 0 && (
            <span className="error">{passwordConfirmationError}</span>
          )}
        </FormGroup>
        <Button
          id="cy-reset-password-submit"
          variant="contained"
          color="secondary"
          type="submit"
          onClick={() => changePassword(password)}
          disabled={!isFormValid}
        >
          {t('components.user_password.button.save')}
        </Button>
      </div>
    </>
  );
};

export default UserPassword;
