import React, { useEffect, useState } from 'react';
import Audience from './audience';
import { filterAudiences } from './services';
import { toLowerKebabCase } from '../../../../../../../../../services/formatters/formatString';
import { TAudienceList } from './types';
import { TAudienceData } from './audience/types';
import './AudienceList.scss';

const AudienceList: React.FC<TAudienceList> = ({
  audienceData,
  currentSelection,
  filteredAudiences,
  onChangeAction
}) => {
  const [audiences, setAudiences] = useState<TAudienceData[]>(audienceData);

  useEffect(() => {
    setAudiences(filterAudiences(audienceData, filteredAudiences));
  }, [audienceData, filteredAudiences]);

  return (
    <section className="audiences-section">
      {Object.values(audiences).map((audience: TAudienceData) => {
        return (
          <Audience
            key={toLowerKebabCase(audience.audienceName)}
            audienceData={audience}
            selectedAudiences={currentSelection}
            onChangeAction={onChangeAction}
          />
        );
      })}
    </section>
  );
};

export default AudienceList;
