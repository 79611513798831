import { TRank } from './optimization_body/types';

const validateTarget = (line: TRank) => {
  return !!(
    (line.goal !== 'with_max_of' && line.goal !== 'with_min_of') ||
    ((line.goal === 'with_max_of' || line.goal === 'with_min_of') &&
      line.target &&
      line.target > 0)
  );
};

const validateLine = (line?: TRank) => {
  return (
    !!(
      line &&
      !!line.kpi?.length &&
      !!line.goal?.length &&
      validateTarget(line)
    ) ||
    (!line?.kpi && !line?.goal && !line?.target)
  );
};

export const validate = (lines?: TRank[]) => {
  const line1 = lines?.find((line) => line.rank === 1);
  const line2 = lines?.find((line) => line.rank === 2);
  const line1IsValid = validateLine(line1);
  const line2IsValid = validateLine(line2);
  return (line1IsValid && !line2) || (line1IsValid && line2IsValid);
};
