import classnames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardBody,
  InputGroup,
  InputGroupText,
  Input,
  Label,
  CardFooter,
  Button
} from 'reactstrap';

import signinBackground from '../../assets/vrr/images/Background_signin.svg';
import logoVRR from '../../assets/vrr/images/logo_vrr.svg';
import './ForgotPassword.scss';
import { IForgotPassword } from './types';

const ForgotPassword: React.FC<IForgotPassword> = ({
  onClick
}: IForgotPassword) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const onKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onClick(email);
    }
  };
  return (
    <Card className="card-login card-white text-center">
      <div className="background-compo">
        <img id="img-red" alt="..." src={signinBackground} />
        <img id="img-title" alt="..." src={logoVRR} />
      </div>
      <CardBody className="forgot-card-body">
        <div className="inside-card-body">
          <Label tag="h4">{t('components.forgot_password.title')}</Label>
          <InputGroup
            className={classnames({
              'input-group-focus': true
            })}
          >
            <InputGroupText>
              <i className="tim-icons icon-email-85" />
            </InputGroupText>
            <Input
              placeholder={t('components.signin.inputs.email.placeholder')}
              value={email}
              type="email"
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(ev.target.value);
              }}
              onKeyPress={(event) => onKeyUp(event as unknown as KeyboardEvent)}
            />
          </InputGroup>
        </div>
      </CardBody>
      <CardFooter>
        <Button
          block
          color="primary"
          className="btn-vrr-login mb-3"
          onClick={() => onClick(email)}
          size="lg"
        >
          {t('components.forgot_password.buttons.reset_password')}
        </Button>
      </CardFooter>
    </Card>
  );
};

export default ForgotPassword;
