import { TMediaPlan } from '../../../../redux/mediaPlanGallery/types';
import { IListState } from '../../../shared_components/vrr_dropdown/types';

export const prepareMediaPlan = (
  mediaPlanId?: number,
  mediaPlans?: TMediaPlan[]
): IListState | undefined => {
  if (mediaPlans?.length) {
    let newValue = undefined;
    mediaPlans?.forEach((mediaPlan) => {
      if (mediaPlan.id === mediaPlanId) {
        newValue = {
          label: mediaPlan.name,
          value: mediaPlan.id.toString()
        } as IListState;
      }
    });
    return newValue;
  }
  return undefined;
};

export const prepareMediaPlans = (mediaPlans?: TMediaPlan[]): IListState[] => {
  const newMediaPlans: IListState[] = [];
  if (mediaPlans?.length) {
    mediaPlans?.forEach((mediaPlan) => {
      if (mediaPlan.status === 'completed') {
        newMediaPlans.push({
          label: mediaPlan.name,
          value: mediaPlan.id.toString()
        } as IListState);
      }
    });
  }

  return newMediaPlans;
};
