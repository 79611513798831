import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchCampaign, fetchTableLines } from './managementActions';
import { TManagementInitialState } from './types';

const initialState: TManagementInitialState = {
  campaign: null,
  tableData: null
};

const managementSlice = createSlice({
  name: 'management',
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchCampaign.fulfilled,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          campaign: action.payload.data.data
        };
      }
    );
    builder.addCase(
      fetchTableLines.fulfilled,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          tableData: action.payload.data.data
        };
      }
    );
  }
});

export default managementSlice.reducer;
