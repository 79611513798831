import React from 'react';
import { useTranslation } from 'react-i18next';
import { PRODUCT_IMAGES } from '../../media_plan_details/media_plan_body/media_plan_config/services';
import { TMediaPlanCardHeader } from './types';
import './styles/MediaPlanCardHeader.scss';

const MediaPlanCardHeader: React.FC<TMediaPlanCardHeader> = ({
  name,
  createdAt
}: TMediaPlanCardHeader) => {
  const { t } = useTranslation();
  return (
    <div className="media-plan-card-header">
      <div className="media-plan-background" />
      <img
        className="media-plan-product"
        src={PRODUCT_IMAGES[Math.floor(Math.random() * PRODUCT_IMAGES.length)]}
        alt="product image"
      />
      <div className="media-plan-card-title">
        <p>{name}</p>
        <span>
          {t('media_plan.media_plan_card.header.created_at', { createdAt })}
        </span>
      </div>
    </div>
  );
};

export default MediaPlanCardHeader;
