import { TSection } from '../summary/types';

export const COOKIES_SECTIONS: TSection[] = [
  {
    id: 'section0',
    title: 'cookies.section0.title',
    content: 'cookies.section0.content'
  },
  {
    id: 'section1',
    title: 'cookies.section1.title',
    content: 'cookies.section1.content'
  },
  {
    id: 'section2',
    title: 'cookies.section2.title',
    content: 'cookies.section2.content'
  },
  {
    id: 'section3',
    title: 'cookies.section3.title',
    content: 'cookies.section3.content'
  },
  {
    id: 'section4',
    title: 'cookies.section4.title',
    content: 'cookies.section4.content'
  },
  {
    id: 'section5',
    title: 'cookies.section5.title',
    content: 'cookies.section5.content'
  },
  {
    id: 'section6',
    title: 'cookies.section6.title',
    content: 'cookies.section6.content'
  }
];
