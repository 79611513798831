import React from 'react';
import { useTranslation } from 'react-i18next';
import { VrrButton } from '../../shared_components';
import { STEP_ICONS } from './services';
import { TPipeline } from './types';
import './Pipeline.scss';

const Pipeline: React.FC<TPipeline> = ({ currentStep, onClickAction }) => {
  const { t } = useTranslation();

  return (
    <div className="launcher-pipeline">
      <div className="status">
        {STEP_ICONS.map((step, index) => (
          <React.Fragment key={`launcher-step-${index + 1}`}>
            <div
              className={`launcher-step ${currentStep !== undefined && index <= currentStep ? 'is-active' : ''}`}
            >
              <i
                className={`tim-icons ${step} circle-icon ${currentStep !== undefined && index <= currentStep ? 'is-active' : ''}`}
              />
              <span className="launcher-label">
                {t(`launcher.steps.${index}`).toUpperCase()}
              </span>
            </div>
            {index === STEP_ICONS.length - 1 ? null : (
              <div
                className={`step-bar ${currentStep !== undefined && index < currentStep ? 'is-active' : ''}`}
              />
            )}
          </React.Fragment>
        ))}
      </div>
      <div className="launcher-action">
        <VrrButton disabled={currentStep !== 3} onClick={onClickAction}>
          {t('launcher.action.launch')}
        </VrrButton>
      </div>
    </div>
  );
};

export default Pipeline;
