import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import GeneralInformationsContainer from './GeneralInformation';
import UserPasswordContainer from './UserPassword';
import ConnectedAccountContainer from './ConnectedAccount';
import Menu from '../../components/UserProfile/Menu';
import { getFeatureByPath } from '../../micro_front_end/routing/services';
import { getFeatures } from '../../redux/app/appActions';
import { MenuType } from './types';
import './UserProfileContainer.scss';

const UserProfileContainer: React.FC = () => {
  const { t } = useTranslation();
  const features = useSelector(getFeatures);
  const [menus, setMenus] = useState<MenuType[]>([
    {
      id: 'generalInformation',
      icon: 'icon-single-02',
      name: 'components.user_profile.menu.profile',
      className: 'btn-round btn-simple profile',
      component: <GeneralInformationsContainer />
    },
    {
      id: 'password',
      cyId: 'user-profile-password-security',
      icon: 'icon-lock-circle',
      name: 'components.user_profile.menu.password',
      className: 'btn-round btn-simple password',
      component: <UserPasswordContainer isRestricted={false} />
    },
    {
      id: 'connectedAccount',
      cyId: 'user-profile-connected-account',
      icon: 'icon-laptop',
      name: 'components.user_profile.menu.connectedAccount',
      className: 'btn-round btn-simple connected-account',
      component: <ConnectedAccountContainer />,
      authorizedStrategy: () =>
        getFeatureByPath(features, 'user_profile.connected_accounts')
    }
  ]);
  const getDefaultSelectedMenu = (): MenuType => {
    return (
      menus.find(
        (menu) =>
          menu.id === new URL(window.location.href).searchParams.get('subRoute')
      ) ?? menus[0]
    );
  };

  const [element, setElement] = useState<MenuType>(getDefaultSelectedMenu());

  useEffect(() => {
    const newMenu = menus.map((menu) => {
      if (menu.id === 'connectedAccount') {
        return {
          ...menu,
          authorizedStrategy: () =>
            getFeatureByPath(features, 'user_profile.connected_accounts')
        };
      }
      return menu;
    });
    setMenus([...newMenu]);
  }, [features]);

  const selectElement = (menuId: string) => {
    const elem = menus.find((menu) => menu.id === menuId);
    if (elem) setElement(elem);
  };

  return (
    <>
      <h4 data-cy-id="user-profile-title">
        {t('components.user_profile.title').toUpperCase()}
      </h4>
      <div className="user-profile-information">
        <h5>{t('components.user_profile.information')}</h5>
      </div>
      <div className="user-profile-components">
        <Menu
          menus={menus}
          onClickAction={selectElement}
          selectedElement={element.id}
        />
        <div className="user-profile-components-page">{element.component}</div>
      </div>
    </>
  );
};

export default UserProfileContainer;
