import React from 'react';
import { TVideoCardHeader } from './types';
import './VideoCardHeader.scss';

const VideoCardHeader: React.FC<TVideoCardHeader> = ({ name }) => {
  return (
    <div className="video-card-header">
      <div className="video-card-header-title">
        <span className="video-card-name">{name}</span>
      </div>
    </div>
  );
};

export default VideoCardHeader;
