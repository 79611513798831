import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getUserProfile } from '../../redux/userProfile/userProfileActions';
import Restriction from '../../components/Restriction';
import { TRestrictionC } from './types';

const RestrictionC: React.FC<TRestrictionC> = ({
  userProfile
}: TRestrictionC) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setOpen(userProfile?.restricted);
  }, [userProfile]);

  return <Restriction isOpen={isOpen} />;
};

const mapStateToProps = (state: any) => ({
  userProfile: getUserProfile(state)
});

const RestrictionContainer = connect(mapStateToProps)(RestrictionC);

export default RestrictionContainer;
