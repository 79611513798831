import React from 'react';
import { Link } from 'react-router-dom';
import { TItem } from './types';

const Item: React.FC<TItem> = ({ route, id }: TItem) => {
  const { mini, isActive, url, icon, name } = route;

  return url ? (
    <li key={id} className={isActive ? 'active' : ''} id={`cy-${id}-menu`}>
      <Link to={url} data-toggle="collapse" aria-expanded>
        {icon !== undefined ? (
          <>
            <i className={icon} />
            <p>{name}</p>
          </>
        ) : (
          <span className="sidebar-mini-icon">
            <i
              style={{
                backgroundImage: `url(${mini?.image})`,
                width: mini?.width,
                height: mini?.height
              }}
            />
            <p>{name}</p>
          </span>
        )}
      </Link>
    </li>
  ) : null;
};

export default Item;
