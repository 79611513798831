import metricsEN from './metrics/metricsEN.json';
import metricsFR from './metrics/metricsFR.json';
import overviewEN from './overviewEN.json';
import overviewFR from './overviewFR.json';
import globalMetricsFR from './global_metrics/globalMetricsFR.json';
import globalMetricsEN from './global_metrics/globalMetricsEN.json';

const OVERVIEW_EN = {
  ...globalMetricsEN,
  ...metricsEN,
  ...overviewEN
};
const OVERVIEW_FR = {
  ...globalMetricsFR,
  ...metricsFR,
  ...overviewFR
};

export { OVERVIEW_EN, OVERVIEW_FR };
