import { TMetricDetails } from 'redux/overview/metrics/types';
import { TMetricData } from '../metrics/metric_table/types';
import { isDefaultOption } from './metric_detail_header/kpi_dropdown/services';

export const METRIC_DETAIL_TYPE = {
  MODAL: 'modal',
  PIN: 'pin'
};

export const prepareData = (
  kpiSelections: string[],
  data: TMetricData
): TMetricData => {
  return data?.map((d) => {
    let s: any = {};
    Object.keys(d)?.forEach((dd) => {
      if (kpiSelections.includes(dd)) {
        s = {
          ...s,
          [dd]: d[dd]
        };
      }
    });
    return s;
  });
};

export const prepareKpis = (
  metricDataHeader: TMetricDetails['header'] | undefined,
  kpiSelections: string[] | undefined,
  mainKpi: string | undefined
): string[] => {
  const newValues: string[] = Array.isArray(kpiSelections)
    ? [...kpiSelections]
    : [];
  metricDataHeader?.forEach((kpi) => {
    if (
      isDefaultOption(kpi, mainKpi) &&
      !kpiSelections?.some((slt) => slt === kpi)
    ) {
      newValues.push(kpi);
    }
  });
  return [...newValues];
};

export const prepareTable = (
  kpiSelections: string[],
  metricData: TMetricDetails
): TMetricDetails => {
  return {
    header: metricData?.header?.filter((element1) => {
      return kpiSelections?.some((element2) => {
        return element1 === element2;
      });
    }),
    data: prepareData(kpiSelections, metricData.data)
  };
};
