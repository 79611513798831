import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import AudienceCategory from './audience_category';
import AudienceSelectAll from './audience_select_all';
import { VrrCollapse } from '../../../../../../../../../../shared_components';
import { isSelectAll } from './services';
import { TAudience } from './types';
import './Audience.scss';

const Audience: React.FC<TAudience> = ({
  audienceData,
  selectedAudiences,
  onChangeAction
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { audienceName, audienceCategories } = audienceData;

  return (
    <section className="audience-section">
      <h4 className="audience-title">
        <span className="audience-name">{audienceName}</span>
        <VrrCollapse
          isOpen={isOpen}
          onClick={() => setIsOpen(!isOpen)}
          sideIcon="down"
          closingSideIcon="up"
        />
      </h4>
      <Collapse isOpen={isOpen} className="audience-categories">
        <AudienceSelectAll
          audienceName={audienceName}
          numberAudiences={audienceCategories.length}
          isChecked={isSelectAll(selectedAudiences, audienceCategories)}
          onChangeAction={onChangeAction}
        />
        {Object.values(audienceCategories).map((audienceCategory) => {
          return (
            <AudienceCategory
              key={audienceCategory.parentCategory.criteriaId}
              categoryData={audienceCategory}
              selectedAudiences={selectedAudiences}
              onChangeAction={onChangeAction}
            />
          );
        })}
      </Collapse>
    </section>
  );
};

export default Audience;
