import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  editMediaPlanDetails,
  fetchMediaPlans,
  fetchMediaPlan,
  fetchPixels,
  addNewMediaPlan
} from './mediaPlanGalleryAction';
import { TMediaPlanGallerySliceInitialState } from './types';

const initialState: TMediaPlanGallerySliceInitialState = {
  mediaPlans: undefined,
  mediaPlan: undefined,
  pixels: undefined,
  isComplete: false,
  errors: undefined
};

const mediaPlanGallerySlice = createSlice({
  name: 'mediaPlanGallery',
  initialState: { ...initialState },
  reducers: {
    resetMediaPlan: () => {
      return {
        ...initialState
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMediaPlans.pending, (state) => {
      return {
        ...state,
        mediaPlans: undefined,
        mediaPlan: undefined
      };
    });
    builder.addCase(
      fetchMediaPlans.fulfilled,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          mediaPlans: action.payload || [],
          mediaPlan: undefined
        };
      }
    );
    builder.addCase(fetchMediaPlans.rejected, (state) => {
      return {
        ...state,
        mediaPlans: []
      };
    });
    builder.addCase(
      editMediaPlanDetails.fulfilled,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          mediaPlan: action.payload,
          errors: undefined,
          isComplete: true
        };
      }
    );
    builder.addCase(editMediaPlanDetails.pending, (state) => {
      return {
        ...state,
        isComplete: false
      };
    });
    builder.addCase(
      editMediaPlanDetails.rejected,
      (state, action: PayloadAction<any> & { error: any }) => {
        return {
          ...state,
          errors: JSON.parse(action.error.message).data?.message
        };
      }
    );
    builder.addCase(fetchMediaPlan.pending, (state) => {
      return {
        ...state,
        mediaPlan: undefined
      };
    });
    builder.addCase(
      fetchMediaPlan.fulfilled,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          mediaPlan: action.payload
        };
      }
    );
    builder.addCase(fetchMediaPlan.rejected, (state) => {
      return {
        ...state,
        mediaPlan: undefined
      };
    });
    builder.addCase(
      fetchPixels.fulfilled,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          pixels: action.payload || []
        };
      }
    );
    builder.addCase(addNewMediaPlan.pending, (state) => {
      return {
        ...state,
        mediaPlan: undefined
      };
    });
    builder.addCase(
      addNewMediaPlan.fulfilled,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          mediaPlan: action.payload
        };
      }
    );
  }
});

export const { resetMediaPlan } = mediaPlanGallerySlice.actions;

export default mediaPlanGallerySlice.reducer;
