import { UncontrolledTooltip as Tooltip } from 'reactstrap';
import i18next from 'i18next';
import currencyFormatter from './formatNumber';
import { adaptSymbolToType } from '../../../services/services';
import { v4 as uuid } from 'uuid';
import { TMetricDataUnit } from 'micro_front_end/overview/metrics/metrics/metric_table/types';

const MAX_LENGTH_URL = 24;
const DOTS_NUMBER = 3;
const END_URL = MAX_LENGTH_URL - DOTS_NUMBER;
const DOT = '.';

const urlExtract = (url: string): string => {
  if (url.length > MAX_LENGTH_URL) {
    return `${url.slice(0, END_URL)}${DOT.repeat(DOTS_NUMBER)}`;
  }
  return url;
};

const dataFormatter = (
  value: TMetricDataUnit,
  symbol: string,
  thousandsSeparator: string,
  decimalSeparator: string
): string | number | undefined | JSX.Element => {
  const cloneValue = value.value;
  const type = value.type;
  const decimal = value.decimal;
  let result: string | number | JSX.Element;
  const randNum = uuid();
  const isUrl: boolean = type === 'url';
  const url: JSX.Element = (
    <>
      <a
        href={value.options?.link}
        id={isUrl ? `redirection-${randNum}` : undefined}
        rel="noopener noreferrer"
        target="_blank"
      >
        {isUrl ? urlExtract(cloneValue.toString()) : cloneValue}
      </a>
      {isUrl && (
        <Tooltip
          id={`tooltipVrr-redirection-${randNum}`}
          placement="top-start"
          target={`redirection-${randNum}`}
          hideArrow
        >
          {cloneValue}
        </Tooltip>
      )}
    </>
  );

  const buildThumbnailWithLink: JSX.Element = (
    <a href={value.options?.link} target="_blank" rel="noopener noreferrer">
      <img
        alt={`thumbnail of video ${cloneValue}`}
        src={value.options?.thumbnail}
        title={cloneValue as string}
      />
    </a>
  );

  const formatSwitch: { [key: string]: JSX.Element | string } = {
    url:
      cloneValue !== ''
        ? url
        : (i18next.t('metrics.metrics.metrics_card.no_redirection') as string),
    thumbnail:
      value.options &&
      value.options.thumbnail &&
      value.options.thumbnail !== '' &&
      value.options.thumbnail !== 'unknown'
        ? buildThumbnailWithLink
        : url
  };

  result = formatSwitch[type as keyof { [key: string]: JSX.Element | string }];

  if (!result) {
    result = currencyFormatter(cloneValue as unknown as number, {
      thousandsSeparator,
      decimalSeparator,
      significantDigits: decimal,
      symbol: adaptSymbolToType(type, symbol)
    });
  }

  if (result === '') {
    result = currencyFormatter(cloneValue as unknown as number, {
      thousandsSeparator,
      decimalSeparator,
      significantDigits:
        (cloneValue as unknown as number).toString().split('.')[1]?.length || 0
    });
  }
  return result;
};

export const formatDataService = {
  dataFormatter
};

export default formatDataService;
