import React from 'react';
import { useTranslation } from 'react-i18next';
import DefaultAvatar from '../../../../assets/vrr/images/default-avatar.svg';
import './ViewerLine.scss';
import { TViewerLine, TViewerList } from './types';

const ViewerLine: React.FC<TViewerList & TViewerLine> = ({
  accountId,
  username,
  email,
  onClick
}: TViewerList & TViewerLine) => {
  const { t } = useTranslation();

  return (
    <div className="viewer-line">
      <div className="viewer-infos">
        <img className="photo" alt="avatar" src={DefaultAvatar} />
        <div className="viewer">
          <span className="username">{username}</span>
          <span className="email">{email}</span>
        </div>
      </div>
      <div className="viewer-action">
        <span className="role">{t('components.share.modal.viewers.role')}</span>
        <button
          id={`cy-overview-share-revoke-${username}`}
          type="button"
          onClick={() => {
            if (accountId) onClick(email, accountId);
          }}
          data-cy="overview-share-revoke-button"
        >
          <i className="tim-icons icon-simple-remove revoke" />
        </button>
      </div>
    </div>
  );
};

export default ViewerLine;
