import mediaPlanGalleryEN from './media_plan_gallery/mediaPlanGalleryEN.json';
import mediaPlanGalleryFR from './media_plan_gallery/mediaPlanGalleryFR.json';
import mediaPlanDetailsEN from './media_plan_details/mediaPlanDetailsEN.json';
import mediaPlanDetailsFR from './media_plan_details/mediaPlanDetailsFR.json';
import mediaPlanEditEN from './media_plan_details/edit/mediaPlanEditEN.json';
import mediaPlanEditFR from './media_plan_details/edit/mediaPlanEditFR.json';

const MEDIA_PLAN_EN = {
  ...mediaPlanGalleryEN,
  ...mediaPlanDetailsEN,
  ...mediaPlanEditEN
};
const MEDIA_PLAN_FR = {
  ...mediaPlanGalleryFR,
  ...mediaPlanDetailsFR,
  ...mediaPlanEditFR
};

export { MEDIA_PLAN_EN, MEDIA_PLAN_FR };
