import { FormEvent } from 'react';
import { TMediaPlanDetails } from '../../../../../types';

export type TCtaTable = {
  callToActions: TMediaPlanDetails['call_to_actions'] | undefined;
  onChangeAction: (values: TCallToActionRow[]) => void;
};

export type TCallToActionRow = {
  id: number | null;
  rowId: string;
  title: string;
  button: string;
};

export type THeaderId = 'title' | 'button';

export type TNumberOfCharacters = {
  [key: string]: number;
};

export const charactersLimit: TNumberOfCharacters = {
  title: 15,
  button: 10
};

export type THelperMessages = {
  error?: string;
  helper?: string;
};

export type TCtaTableActions = {
  onEditCta: (rowIdOnEditMode: string) => void;
  onRemoveCta: (rowIdToRemove: string) => void;
  onConfirmCta: (cta: TCallToActionRow) => void;
  onChangeInput: (
    event: FormEvent<HTMLInputElement>,
    rowId: string,
    column: THeaderId
  ) => void;
  onCancelEditCta: () => void;
};
