import { TKPIOption, TPrepareSelections } from './types';

export const DEFAULT_KPI = ['name', 'Spent', 'thumbnail', 'funnel', 'button'];
export const DEFAULT_MAIN_KPI = 'CPC';
export const SELECT_ALL_OPTION = {
  label: 'metrics.metric_detail_modal.kpi_dropdown.all',
  value: 'all'
};

export const isDefaultOption = (value: string, mainKpi?: string): boolean => {
  return DEFAULT_KPI.includes(value) || value === (mainKpi ?? DEFAULT_MAIN_KPI);
};
export const prepareValues = (
  options: TKPIOption[],
  values?: TKPIOption[],
  mainKpi?: string
): TKPIOption[] => {
  const newValues: TKPIOption[] = [];
  options.forEach((option) => {
    if (
      isDefaultOption(option.value, mainKpi) &&
      !values?.some((value) => value.value === option.value)
    ) {
      newValues.push({
        label: option.label,
        value: option.value
      });
    }
  });
  return [...newValues, ...(values ?? [])];
};

export const prepareOptions = (
  options: TKPIOption[],
  mainKpi?: string
): TKPIOption[] => {
  return options.map((option) => {
    return {
      ...option,
      ...(isDefaultOption(option.value, mainKpi) && { isDisabled: true })
    };
  });
};

/**
 * By default, returns up-to-date selections.
 * If the current selection is All, then returns the set of options and All.
 * If the current deselection is All, returns only required options.
 * If the current deselection concerns an option other than All,
 * returns the selections without the removed option as well as All
 *
 * @param selections TKPIOption[]
 * @param currentSelection TKPIOption
 * @param isCheck boolean
 * @param initialSelections TKPIOption[]
 * @param allOptions TKPIOption[]
 */
export const prepareSelections = (
  { selections, currentSelection, isCheck }: TPrepareSelections,
  initialSelections: TKPIOption[],
  allOptions: TKPIOption[]
): TKPIOption[] => {
  if (currentSelection.value === SELECT_ALL_OPTION.value) {
    if (isCheck) {
      return [SELECT_ALL_OPTION, ...allOptions];
    }
    return [...initialSelections];
  }

  if (
    currentSelection.value !== SELECT_ALL_OPTION.value &&
    !isCheck &&
    selections.some((slt) => slt.value === SELECT_ALL_OPTION.value)
  ) {
    return selections.filter((slt) => slt.value !== SELECT_ALL_OPTION.value);
  }
  return selections;
};
