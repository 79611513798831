import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col } from 'reactstrap';
import VrrCircleLoader from '../../../micro_front_end/shared_components/vrr_circle_loader';
import AdwordsAccountList from './AdwordsAccountList';
import './ConnectedAccount.scss';
import { TConnectedAccount } from './types';

const ConnectedAccount: React.FC<TConnectedAccount> = ({
  list,
  addConnectedAccountAction,
  removeConnectedAccountAction
}: TConnectedAccount) => {
  const { t } = useTranslation();

  return (
    <div className="card-container">
      <div className="card-body">
        <VrrCircleLoader isEnable={list === ''}>
          {list !== '' && (
            <>
              <Row>
                <Col sm="8">
                  <h4>{t('components.connected_account.title')}</h4>
                </Col>
              </Row>
              <Row>
                {typeof list === 'string' && (
                  <Col className="padded-col">
                    <div className="test">
                      {t('components.connected_account.no_account_available')}
                    </div>
                  </Col>
                )}
                {Array.isArray(list) && (
                  <Col>
                    <AdwordsAccountList
                      list={list}
                      addConnectedAccountAction={addConnectedAccountAction}
                      removeConnectedAccountAction={
                        removeConnectedAccountAction
                      }
                    />
                  </Col>
                )}
                {typeof list === 'string' && (
                  <Col className="padded-col">
                    <Button
                      data-cy-id="connect-account-button"
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        window.location.href = list;
                      }}
                    >
                      {t('components.connected_account.button.connect_account')}
                    </Button>
                  </Col>
                )}
              </Row>
            </>
          )}
        </VrrCircleLoader>
      </div>
    </div>
  );
};

export default ConnectedAccount;
