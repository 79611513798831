import moment, { Moment } from 'moment';
import LABEL_DATE_SELECTOR from '../RangeDate.constant';

/**
 * Example:
 *
 * Today : 2 Dec 2021
 *
 * Last 7 days : 25 Nov 2021 - 1 Dec 2021
 * Last 30 days : 2 Noc 2021 - 1 Dec 2021
 * Last 12 weeks : 6 Sep 2021 - 28 Nov 2021
 * Last 1 year : 1 Jan 2020 - 30 Dec 2020
 *
 * Month to date : 1 Dec 2021 - 2 Dec 2021
 * Quarter to date : 1 Oct 2021 - 2 Dec 2021
 * Years to date : 1 Jan 2021 - 2 Dec 2021
 *
 * @param label string
 */
const getDates = (label: string): { endDate: Moment; startDate: Moment } => {
  let startDate = moment();
  let endDate = moment();

  switch (label) {
    case LABEL_DATE_SELECTOR.LAST_7_DAYS:
      endDate = moment().subtract(1, 'days');
      startDate = moment(endDate).subtract(6, 'days');
      break;
    case LABEL_DATE_SELECTOR.LAST_30_DAYS:
      endDate = moment().subtract(1, 'days');
      startDate = moment(endDate).subtract(29, 'days');
      break;
    case LABEL_DATE_SELECTOR.LAST_12_WEEKS:
      endDate = moment().startOf('isoWeek').subtract(1, 'days');
      startDate = moment(endDate).subtract(83, 'days');
      break;
    case LABEL_DATE_SELECTOR.LAST_1_YEAR:
      endDate = moment().startOf('year').subtract(1, 'days');
      startDate = moment(endDate).startOf('year');
      break;
    case LABEL_DATE_SELECTOR.SINCE_START_MONTH:
      startDate = moment(endDate).startOf('month');
      break;
    case LABEL_DATE_SELECTOR.SINCE_START_QUARTER:
      startDate = moment(endDate).startOf('quarter');
      break;
    case LABEL_DATE_SELECTOR.SINCE_START_YEAR:
      startDate = moment(endDate).startOf('years');
      break;
    default:
      break;
  }

  return { endDate, startDate };
};

export default { getDates };
