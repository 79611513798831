import i18n from 'i18next';
import { TDefaultTargetBody, TTargetList } from './types';
import { IListState } from '../../../../../../shared_components/vrr_dropdown/types';
import { TVrrTag } from '../../../../../../shared_components/vrr_tag/types';

export const LIST: TTargetList = {
  genders: [
    {
      label: i18n.t(
        'media_plan_edit.media_plan_targets.default_target_modal.genders.female'
      ),
      value: 'female'
    },
    {
      label: i18n.t(
        'media_plan_edit.media_plan_targets.default_target_modal.genders.male'
      ),
      value: 'male'
    }
  ],
  ages: [
    { label: '18-24', value: '18_24' },
    { label: '25-34', value: '25_34' },
    { label: '35-44', value: '35_44' },
    { label: '45-54', value: '45_54' },
    { label: '55-64', value: '55_64' },
    { label: '65+', value: '65_UP' }
  ]
};
export const initializeData = (
  data: TDefaultTargetBody['mediaPlanData'],
  list: IListState[]
): IListState[] => {
  const newData: IListState[] = [];
  data.forEach((id: any) => {
    const findData = list.find((target: any) => target.value === id);
    if (findData) {
      newData.push(findData);
    }
  });
  return newData;
};

export const prepareRemoveTag = (
  currentValues: string[],
  tags: TVrrTag[],
  id?: string
): { newCurrentValues: string[]; newTags: TVrrTag[] } => {
  const newCurrentValues = id
    ? currentValues?.filter((currentValue) => currentValue !== id)
    : [];
  const newTags = id ? tags.filter((tag: TVrrTag) => tag.id !== id) : [];

  return { newCurrentValues, newTags };
};

export const prepareAddTag = (
  currentValues: string[],
  tags: TVrrTag[],
  ids: IListState[]
): { newCurrentValues: string[]; newTags: TVrrTag[] } => {
  const newTags: TVrrTag[] = [];
  const newCurrentValues: string[] = [];
  ids.forEach((id) => {
    newTags.push({
      label: id.label,
      id: id.value
    });
    newCurrentValues?.push(id.value);
  });

  return { newCurrentValues, newTags };
};
