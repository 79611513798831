import { TOptions } from './types';

export const SPENT = 'spent';
export const IMPRESSIONS = 'impressions';
export const VALUE = 'value';
export const COST = 'cost';
export const RATIO = 'ratio';

export const OPTIONS: TOptions[] = [
  {
    id: VALUE,
    name: 'components.chart.chartTypes.value'
  },
  {
    id: COST,
    name: 'components.chart.chartTypes.cost'
  },
  {
    id: RATIO,
    name: 'components.chart.chartTypes.ratio'
  }
];
