import { RootState } from '../../store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import apiVRR from '../../../libs/axios/apis';
import END_POINTS from '../../../services/endpoints';
import { notify } from '../../app/appSlice';
import { TCreativeImportVideo, TPreview, TVideo } from './types';

export const importVideosAction = createAsyncThunk(
  'creativeImportVideo/importVideosAction',
  async (videoUrls: string[], { getState }) => {
    const currentState = getState() as any;
    const currentAccountId =
      currentState.userPreferences.settings.vrr.current_account_id;

    const response = await apiVRR().get(
      END_POINTS.CREATIVE_ANALYTICS.CREATIVE_GALLERY.AD_ACCOUNT_IMPORT_VIDEOS(
        currentAccountId.toString()
      ),
      {
        params: { url_videos: videoUrls }
      }
    );

    return response.data.data;
  }
);

export const saveVideos = createAsyncThunk(
  'creativeImportVideo/saveVideos',
  async (videos: TVideo[], { dispatch, getState }) => {
    const currentState = getState() as any;
    const currentAccountId =
      currentState.userPreferences.settings.vrr.current_account_id;
    const response = await apiVRR().post(
      END_POINTS.CREATIVE_ANALYTICS.CREATIVE_GALLERY.AD_ACCOUNT_IMPORT_VIDEOS(
        currentAccountId
      ),
      { videos }
    );

    if (response.status >= 200) {
      dispatch(notify({ type: 'success' }));
    }

    return response.data.data;
  }
);

export const advancedPreviewAction = createAsyncThunk(
  'importVideo/advancedPreviewAction',
  async (videoUrls: string[], { getState }) => {
    const currentState = getState() as any;
    const currentAccountId =
      currentState.userPreferences.settings.vrr.current_account_id;

    const response = await apiVRR().get(
      END_POINTS.CREATIVE_ANALYTICS.CREATIVE_GALLERY.IMPORT_VIDEOS(
        currentAccountId.toString()
      ),
      {
        params: { url_videos: videoUrls }
      }
    );

    return response.data.data;
  }
);

export const advancedImportAction = createAsyncThunk(
  'importVideo/advancedImportAction',
  async (
    params: { brand: TCreativeImportVideo['brand']; videos: TPreview[] },
    { dispatch, getState }
  ) => {
    const currentState = getState() as any;
    const currentAccountId =
      currentState.userPreferences.settings.vrr.current_account_id;

    const response = await apiVRR().post(
      END_POINTS.CREATIVE_ANALYTICS.CREATIVE_GALLERY.IMPORT_VIDEOS(
        currentAccountId.toString()
      ),
      {
        brand: params.brand,
        videos: params.videos
      }
    );

    if (response.status >= 200) {
      dispatch(notify({ type: 'success' }));
    }

    return response.data.data;
  }
);

export const getVideoPreviews = (
  state: RootState
): TCreativeImportVideo['importVideos'] | undefined =>
  state.creativeImportVideo.importVideos;

export const getVideoErrors = (
  state: RootState
): TCreativeImportVideo['errors'] | undefined =>
  state.creativeImportVideo.errors;

export const getPreviews = (
  state: RootState
): TCreativeImportVideo['previews'] | undefined =>
  state.creativeImportVideo.previews;

export const getIsComplete = (
  state: RootState
): TCreativeImportVideo['isComplete'] => state.creativeImportVideo.isComplete;
