import React from 'react';
import { connect } from 'react-redux';
import {
  getSharerViewers,
  revokeViewer
} from '../../redux/overview/overviewActions';
import ViewerLine from '../../components/Share/ShareModal/ViewerLine';
import { TViewerLine } from './types';
import { getAccountId } from '../../redux/userPreferences/actions/userPreferencesActions';

export const ViewerLineC: React.FC<TViewerLine> = ({
  currentAccountId,
  viewers,
  revoke
}: TViewerLine) => {
  return (
    <>
      {viewers?.map((viewer) => (
        <ViewerLine
          accountId={currentAccountId}
          username={viewer.username}
          email={viewer.email}
          key={viewer.username}
          onClick={revoke}
        />
      ))}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentAccountId: getAccountId(state),
  viewers: getSharerViewers(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  revoke: (email: string, accountId: string | null) =>
    dispatch(revokeViewer({ email, accountId }))
});

const ViewerLineContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewerLineC);

export default ViewerLineContainer;
