import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select, { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import { VrrButton } from '../../shared_components';
import LabelModal from '../../user_profile/connected_account/label_modal';
import { PrivateComponent } from '../../permissions';
import useSettings from '../../../libs/settings/useSettings';
import { adaptLabelListPreference, findCurrentLabel } from './services';
import { getFeatureByPath } from '../../routing/services';
import { getCurrentLabel, getFeatures } from '../../../redux/app/appActions';
import { updateLabel } from '../../../redux/app/appSlice';
import {
  getAccountId,
  getCustomLabelsListByAccount
} from '../../../redux/userPreferences/actions/userPreferencesActions';
import {
  IListState,
  TChildrenListState
} from '../../shared_components/vrr_dropdown/types';
import { TLabels } from '../../../redux/userPreferences/types';
import './LabelDropdown.scss';

const LabelDropdown: React.FC = () => {
  const labelList: TLabels[] | null = useSelector(getCustomLabelsListByAccount);
  const label = useSelector(getCurrentLabel);
  const account = useSelector(getAccountId);
  const features = useSelector(getFeatures);
  const dispatch: any = useDispatch();
  const { t } = useTranslation();
  const { vrr } = useSettings();
  const [isOpen, setIsOpen] = useState(false);

  const vrrLabels: TChildrenListState[] = [
    {
      label: t('main_navbar.label_dropdown.vrr_labels.label'),
      options: [
        {
          label: t('main_navbar.label_dropdown.vrr_labels.options.all'),
          value: 'all'
        },
        {
          label: t('main_navbar.label_dropdown.vrr_labels.options.acquisition'),
          value: 'Vrr - Acquisition'
        },
        {
          label: t('main_navbar.label_dropdown.vrr_labels.options.activation'),
          value: 'Vrr - Activation'
        },
        {
          label: t('main_navbar.label_dropdown.vrr_labels.options.retention'),
          value: 'Vrr - Retention'
        }
      ]
    }
  ];

  const change = (e: IListState | null) => {
    if (e?.value) {
      dispatch(updateLabel(e.value));
    }
  };

  const groupOptionsName = t('main_navbar.label_dropdown.custom_labels.label');

  const formatCustomLabels = () => {
    if (labelList === null) return [];

    return adaptLabelListPreference(labelList, groupOptionsName);
  };

  const formattedLabels: TChildrenListState[] = [
    ...vrrLabels,
    ...formatCustomLabels()
  ];

  const formatValue = (): IListState | null =>
    findCurrentLabel(formattedLabels, label);

  const Menu = (props: any) => {
    return (
      <components.Menu {...props}>
        {props.children}
        <PrivateComponent
          authorizationStrategy={() =>
            getFeatureByPath(features, 'navbar.add_labels')
          }
        >
          <VrrButton
            className="account-dropdown-add-account"
            icon={{
              iconLabel: 'tim-icons icon-cloud-download-93'
            }}
            onClick={() => setIsOpen(true)}
          >
            {t('main_navbar.label_dropdown.button')}
          </VrrButton>
        </PrivateComponent>
      </components.Menu>
    );
  };

  return (
    <div className="label-container">
      <Select
        options={formattedLabels}
        onChange={change}
        value={formatValue()}
        className="vrr-dropdown"
        classNamePrefix="vrr-dropdown"
        components={{ Menu }}
      />
      <LabelModal
        id="overview-labels"
        account={account ?? ''}
        isOpen={isOpen}
        onCancelAction={() => setIsOpen(false)}
        onSubmitAction={(result) =>
          vrr({ custom_labels_list: { ...result } }, true)
        }
      />
    </div>
  );
};

export default LabelDropdown;
