import { StateLoadingData } from './ManagementCampaign.constante';
import formatDataService from '../../micro_front_end/services/formatters/formatData';
import { TManageTableCell } from './types';

const convertHeaderToHeaderExtendTable = (
  param: Array<string>
): Array<{
  Header: string;
  accessor: string;
}> => {
  return param.map((itemHeader) => {
    return {
      Header: itemHeader,
      accessor: itemHeader
    };
  });
};

const getLoadingObject = (
  paramState: StateLoadingData,
  currentNumberLoading?: number,
  totalNumberLoading?: number
): {
  enabled: boolean;
  label: string;
  currentNumber: number;
  totalNumber: number;
} => {
  switch (paramState) {
    case StateLoadingData.INITIALISATION: {
      return {
        enabled: true,
        label: 'Initialisation',
        currentNumber: 0,
        totalNumber: 0
      };
    }
    case StateLoadingData.LOADING_DATA: {
      return {
        enabled: true,
        label: 'Chargement des lignes',
        currentNumber: currentNumberLoading ?? 0,
        totalNumber: totalNumberLoading ?? 0
      };
    }
    case StateLoadingData.NONE:
    case StateLoadingData.DATA_READY:
    case StateLoadingData.DATA_ERROR:
    default: {
      return {
        enabled: false,
        label: '',
        currentNumber: 0,
        totalNumber: 0
      };
    }
  }
};

const formatManageData = (
  dataTable: Array<TManageTableCell>,
  symbol: string,
  thousandsSeparator: string,
  decimalSeparator: string
): any => {
  let result = [...dataTable];
  result = result.map((table: TManageTableCell) => {
    const tab = { ...table };
    const keys = Object.keys(tab);
    keys.map((key: string) => {
      if (tab[key]?.type && tab[key]?.type !== 'string') {
        tab[key] = {
          ...tab[key],
          value: tab[key].value
            ? (formatDataService.dataFormatter(
                tab[key],
                symbol,
                thousandsSeparator,
                decimalSeparator
              ) as number | string)
            : '-'
        };
      }
    });
    return tab;
  });
  return result;
};

export const ManagementPageService = {
  getLoadingObject,
  convertHeaderToHeaderExtendTable,
  formatManageData
};

export default ManagementPageService;
