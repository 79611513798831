import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n/i18n';
import Summary from '../summary';
import { VrrDropdown } from '../../shared_components';
import { LANGUAGES_LIST } from '../../../i18n/i18nConstants';
import { TPage } from './types';
import { IListState } from '../../shared_components/vrr_dropdown/types';
import './Page.scss';

const Page: React.FC<TPage> = ({ sections, pageTitle }) => {
  const { t } = useTranslation();

  const changeLanguage = (option: IListState) => {
    i18n.changeLanguage(option.value);
  };

  return (
    <div className="gdpr-page-container">
      <Summary sections={sections} />
      <div className="gdpr-page-content">
        <h1>{pageTitle}</h1>
        <VrrDropdown
          list={LANGUAGES_LIST}
          handleChangeAction={changeLanguage}
        />
        {sections.map((section) => (
          <section id={section.id} key={section.id}>
            <h2>{t(section.title)}</h2>
            <p dangerouslySetInnerHTML={{ __html: t(section.content ?? '') }} />
            {section.subtitles &&
              section.subtitles.map((subtitle) => (
                <div key={subtitle.id} id={subtitle.id}>
                  <h3>{t(subtitle.title)}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t(subtitle.content ?? '')
                    }}
                  />
                </div>
              ))}
          </section>
        ))}
      </div>
    </div>
  );
};

export default Page;
