import React from 'react';
import { TTableBody } from './types';
import VrrTableRow from './VrrTableRow';

const VrrTableBody: React.FC<TTableBody> = ({
  rowElements,
  actions,
  onClickLine
}) => {
  return (
    <tbody>
      {rowElements?.map((row, index) => (
        <VrrTableRow
          key={`row-${row[index]?.headerId || index}`}
          actions={actions}
          cellElements={row}
          rowId={index.toString()}
          onClickLine={onClickLine}
        />
      ))}
    </tbody>
  );
};

export default VrrTableBody;
