import React from 'react';
import VrrButton from 'micro_front_end/shared_components/vrr_button';
import { IVrrCollapse, TSideIcon } from './types';

const VrrCollapse: React.FC<IVrrCollapse> = ({
  isOpen,
  onClick,
  sideIcon,
  closingSideIcon = 'down'
}: IVrrCollapse) => {
  const getSideIcon = (icon: TSideIcon) => {
    return `icon-minimal-${icon}`;
  };
  return (
    <VrrButton className="btn-link vrr-collapse-button" onClick={onClick}>
      <i
        className={`tim-icons ${
          isOpen ? getSideIcon(closingSideIcon) : getSideIcon(sideIcon)
        }`}
      />
    </VrrButton>
  );
};

export default VrrCollapse;
