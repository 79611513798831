import { IListState } from '../../../../../../../shared_components/vrr_dropdown/types';
import { TPixelRank } from '../types';
import { TMediaPlanPixel } from '../../../../../../../../redux/mediaPlanGallery/types';

export const PIXEL_TYPE = [
  { label: 'Pixel Visits', value: 'pixel_visits' },
  { label: 'Pixel Event 1', value: 'pixel_event1' },
  { label: 'Pixel Event 2', value: 'pixel_event2' },
  { label: 'Pixel Carts', value: 'pixel_carts' },
  { label: 'Pixel Leads', value: 'pixel_leads' },
  { label: 'Pixel Installs', value: 'pixel_installs' },
  { label: 'Pixel Sales', value: 'pixel_sales' }
];

export const preparePixels = (pixels?: TMediaPlanPixel[]) => {
  const list: IListState[] = [];
  if (!!pixels?.length) {
    pixels.forEach((pixel) => {
      list.push({
        label: pixel.name,
        value: pixel.id.toString()
      });
    });
  }

  return list;
};

export const prepareList = (
  list: IListState[],
  type: string,
  rank: number,
  values?: TPixelRank[] | TPixelRank
) => {
  const valuesToRemove: string[] = [];
  if (values && Array.isArray(values)) {
    values?.forEach((node) => {
      if (node[type as keyof TPixelRank] && node.rank !== rank) {
        valuesToRemove.push(
          node[type as keyof TPixelRank]?.toString() as string
        );
      }
    });
  } else if (values) {
    valuesToRemove.push(values[type as keyof TPixelRank] as string);
  }
  if (!!valuesToRemove?.length) {
    return list.filter((node) => !valuesToRemove.includes(node.value));
  }
  return list;
};
