import { TInput, TTargetInput } from './target_input/types';
import {
  TCustomTargets,
  TMediaPlanDetails,
  TTarget
} from '../../../../../types';
import { TVrrTag } from '../../../../../../../shared_components/vrr_tag/types';
import { TFunnel } from './funnel_search_list/audience_list/types';

export const BOTTOM_INPUTS: TInput[] = [
  {
    id: 'target_custom_intent',
    label: 'Custom Intent',
    value: 0
  },
  {
    id: 'target_other',
    label: 'Other',
    value: 0
  }
];

export const DATA_INPUTS: TInput[] = [
  {
    id: 'target_your_data_yt_users',
    label: 'Youtube user',
    value: 0
  },
  {
    id: 'target_your_data_app_users',
    label: 'Website/App Users',
    value: 0
  },
  {
    id: 'target_your_data_clients',
    label: 'Clients',
    value: 0
  }
];

export const prepareTags = (
  funnel: string,
  selections?: TTarget[]
): TVrrTag[] => {
  const newTags: TVrrTag[] = [];
  if (!!selections?.length) {
    selections?.forEach((selection) => {
      if (selection.funnel === funnel) {
        newTags.push({
          label: selection.name,
          id: selection.id,
          icon: 'tim-icons icon-simple-remove'
        });
      }
    });
  }

  return newTags;
};

export const prepareCustomTargets = (
  inputs: TTargetInput['inputs'],
  customSelections?: TCustomTargets
): TVrrTag[] => {
  return inputs.map((input) => {
    return {
      ...input,
      value: customSelections?.[input.id as keyof TCustomTargets] ?? 0
    };
  });
};

export const prepareRemoveTag = (
  funnel: TFunnel,
  targets?: TMediaPlanDetails['targets'],
  id?: string
): TMediaPlanDetails['targets'] => {
  return id
    ? targets?.filter((tag) => {
        return (
          (tag.id !== id && tag.funnel === funnel) || tag.funnel !== funnel
        );
      }) ?? []
    : targets?.filter((tag) => {
        return tag.funnel !== funnel;
      }) ?? [];
};

export const prepareUpdateCustomTargets = (
  inputs: TInput[],
  id?: TInput['id'],
  value?: number
): TInput[] => {
  return inputs.map((input) => {
    if (input.id === id) {
      return {
        ...input,
        value
      };
    }
    return input;
  }) as TInput[];
};
