import VideoSelection from './video_selection';
import MediaPlanSelection from './media_plan_selection';
import Budget from './budget';
import Summary from './summary';
import { TLauncherDetails } from '../../../redux/launcher/types';

export const generateBody = (
  currentStep?: number,
  launch?: TLauncherDetails,
  onChangeStep?: (newStep?: number) => void
) => {
  switch (currentStep) {
    case 3:
      return <Summary />;
    case 2:
      return <Budget onChangeAction={onChangeStep} />;
    case 1:
      return (
        <MediaPlanSelection
          mediaPlanId={launch?.mediaPlan}
          onChangeAction={onChangeStep}
        />
      );
    case 0:
    default:
      return (
        <VideoSelection videos={launch?.videos} onChangeAction={onChangeStep} />
      );
  }
};
