import React, { MutableRefObject, useEffect, useRef } from 'react';
import { prepareChart } from './services';
import { TBudgetChart } from './types';

const BudgetChart: React.FC<TBudgetChart> = ({ values }) => {
  const ref: MutableRefObject<any> = useRef();

  useEffect(() => {
    prepareChart(ref, values);
  }, [values]);

  return <svg ref={ref} />;
};

export default BudgetChart;
