import React from 'react';
import { useTranslation } from 'react-i18next';
import LoaderGallery from '../../loader/loader_gallery';
import { TVrrGallery } from './types';
import './VrrGallery.scss';

const VrrGallery: React.FC<TVrrGallery> = ({
  data = [],
  childElement,
  className = 'vrr-gallery-grid',
  isLoading = true,
  message
}: TVrrGallery) => {
  const { t } = useTranslation();
  const renderElement = (
    Component: any,
    componentProps: any,
    index: string
  ) => {
    return (
      <Component
        key={`vrr-gallery-${(typeof Component).toLowerCase()}-${index}`}
        component={Component}
        {...componentProps}
      />
    );
  };

  const getContent = () => {
    return data.length ? (
      <div className={className}>
        {data.map((elementProps: any, index) =>
          renderElement(childElement, elementProps, index.toString())
        )}
      </div>
    ) : (
      <h2 className="no-items">
        {message
          ? t(message)
          : t('shared_components.vrr_gallery.message.no_data')}
      </h2>
    );
  };

  return isLoading ? <LoaderGallery /> : getContent();
};

export default VrrGallery;
