import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { LARGE_BREAK_POINT } from 'services/constants/break_points';

export const useClassNameState = <T extends string, U extends boolean>(
  colorClass: T,
  collapseOpen: U
): {
  currentClassName: string;
  setCurrentClassName: Dispatch<SetStateAction<string>>;
} => {
  const [currentClassName, setCurrentClassName] = useState<string>(colorClass);

  const updateCurrentClassName = () => {
    setCurrentClassName(
      window.innerWidth <= LARGE_BREAK_POINT && collapseOpen
        ? 'bg-white'
        : 'navbar-transparent'
    );
  };

  useEffect(() => {
    window.addEventListener('resize', updateCurrentClassName);
    return () => {
      window.removeEventListener('resize', updateCurrentClassName);
    };
  });

  return { currentClassName, setCurrentClassName };
};
