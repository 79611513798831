import React from 'react';
import { useTranslation } from 'react-i18next';
import { VrrInput } from '../../../../../../../../shared_components';
import { TTargetInput } from './types';
import './TargetInput.scss';

export const TargetInput: React.FC<TTargetInput> = ({
  title,
  inputs,
  onChangeAction,
  onRemoveAllAction
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="target-list-container">
        <div className="target-list-header">
          <p>{title}</p>
          <p
            className="clear-btn"
            onClick={() => (onRemoveAllAction ? onRemoveAllAction() : null)}
          >
            {t('media_plan_edit.media_plan_targets.target_list.clear_all')}
          </p>
        </div>
        <div className="target-list-inputs">
          {inputs.map((input) => {
            const { label, id, value } = input;

            return (
              <div
                className="target-list-input"
                key={`target-list-input-${id}`}
              >
                <span className="target-input">{label}</span>
                <VrrInput
                  id={id}
                  type="number"
                  value={value ?? ''}
                  onChangeAction={(e) =>
                    onChangeAction
                      ? onChangeAction(id, parseInt(e.currentTarget.value))
                      : null
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TargetInput;
