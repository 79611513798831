import React from 'react';
import { VrrSelections } from '../../../../shared_components';
import { useTranslation } from 'react-i18next';
import { prepareMetricsList, prepareSelectedMetricsList } from './services';
import { TMetricSelections } from './types';
import { TSelection } from '../../../../shared_components/vrr_selections/types';
import './MetricSelections.scss';

const MetricSelections: React.FC<TMetricSelections> = ({
  selectedMetricsList,
  onChangeAction
}: TMetricSelections) => {
  const { t } = useTranslation();

  const onChange = (selectedMetrics: TSelection[]) => {
    if (onChangeAction)
      onChangeAction(selectedMetrics.map((metric) => metric.id));
  };

  return (
    <div className="metric-selections">
      <VrrSelections
        itemsList={prepareMetricsList()}
        selectedItemsList={prepareSelectedMetricsList(selectedMetricsList)}
        selectedTitle={t('metrics.metric_selections.selectedTitle')}
        availableTitle={t('metrics.metric_selections.availableTitle')}
        onChange={onChange}
      />
    </div>
  );
};

export default MetricSelections;
