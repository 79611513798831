import React from 'react';
import { useTranslation } from 'react-i18next';
import { TMetricSelectionCard } from './types';
import { VrrCard, VrrTooltip } from '../../../../../shared_components';
import { METRICS } from './services';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import './MetricSelectionCard.scss';

const MetricSelectionCard: React.FC<TMetricSelectionCard> = ({
  id,
  target
}: TMetricSelectionCard) => {
  const { t } = useTranslation();

  return (
    <VrrCard
      className="vrr-card-container metric-selection-card-container"
      cardBody={{
        element: (
          <div className="metric-selection-card">
            <div>
              <span>{METRICS[id]?.icon}</span>
              <span>{t(METRICS[id]?.label)}</span>
            </div>
            <QuestionCircleFill
              id={`${target}-${id}`}
              className="metric-tooltip-icon"
            />
            {!!id.length && (
              <VrrTooltip target={`${target}-${id}`}>
                {t(METRICS[id]?.tooltip)}
              </VrrTooltip>
            )}
          </div>
        )
      }}
    />
  );
};

export default MetricSelectionCard;
