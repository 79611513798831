import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AlertOptions } from 'react-notification-alert';
import Notification from '../../components/Notification';
import { NOTIFICATIONS_TYPES } from '../../services/constants/notificationTypes';
import { INotificationC } from './types';
import { INotify, IOptions } from '../../libs/notifications/types';
import { getNotification } from '../../redux/app/appActions';

export const NotificationC: React.FC<INotificationC> = ({
  notification
}: INotificationC) => {
  const childRef = useRef(null);
  const { t } = useTranslation();
  const [result, setResult] = useState<INotify>(notification);
  const [isOpen, setIsOpen] = useState(true);

  const manageNotification = (notif: INotify) => {
    let options: IOptions;
    switch (notif.type) {
      case NOTIFICATIONS_TYPES.FORM_ERROR:
      case NOTIFICATIONS_TYPES.WRONG_PASSWORD:
      case NOTIFICATIONS_TYPES.SERVER_ERROR:
      case NOTIFICATIONS_TYPES.CONFLICT:
        options = {
          message: t(`components.notification.${notif.type}.message`),
          type: 'danger',
          icon: 'tim-icons icon-alert-circle-exc',
          autoDismiss: 3,
          place: 'tc'
        } as IOptions;
        break;
      case NOTIFICATIONS_TYPES.SUCCESS:
        options = {
          message:
            notif.options?.message ??
            t(`components.notification.${notif.type}.message`),
          type: 'success',
          icon: 'tim-icons icon-check-2',
          autoDismiss: 3,
          place: 'tc'
        } as IOptions;
        break;
      default:
        options = {
          ...notif.options
        } as IOptions;
        break;
    }

    (childRef?.current as any)?.notificationAlert({
      ...options
    } as AlertOptions);
  };

  useEffect(() => {
    if (result !== notification) {
      if (!isOpen) setIsOpen(true);
      setResult(notification);
    }
  }, [notification]);

  useEffect(() => {
    if (Object.values(result).length > 0 && result.type) {
      manageNotification(result);
    }
  }, [result]);

  return isOpen ? (
    <div id="vrr-div-toaster" className="rna-container">
      <Notification forwardedRef={childRef} />
    </div>
  ) : null;
};

const mapStateToProps = (state: any) => ({
  notification: getNotification(state)
});

const NotificationContainer = connect(mapStateToProps)(NotificationC);

export default NotificationContainer;
