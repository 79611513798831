import React from 'react';
import { useSelector } from 'react-redux';
import { PrivateComponent } from '../../../../../permissions';
import { VrrButton } from '../../../../../shared_components';
import { getFeatureByPath } from '../../../../../routing/services';
import { getMediaPlan } from '../../../../../../redux/mediaPlanGallery/mediaPlanGalleryAction';
import { getFeatures } from '../../../../../../redux/app/appActions';
import { TEditButton } from './types';
import './EditButton.scss';

const EditButton: React.FC<TEditButton> = ({
  onClickEditButton,
  titleId,
  label,
  iconLabel
}) => {
  const features = useSelector(getFeatures);
  const mediaPlan = useSelector(getMediaPlan);
  const classToApply = label
    ? 'btn-with-icon-and-label btn-space-around icon-left btn-icon-small-xs'
    : 'icon-btn';

  return mediaPlan?.status !== 'running' ? (
    <PrivateComponent
      authorizationStrategy={() =>
        getFeatureByPath(features, 'media_plan.edit')
      }
    >
      <VrrButton
        dataCy={`media-plan-detail-edit-${titleId}-button`}
        className={`btn-round ${classToApply} media-plan-details-edit-button`}
        onClick={() => {
          if (onClickEditButton) onClickEditButton(titleId);
        }}
        icon={{
          iconLabel: iconLabel
        }}
      >
        {label ? <span className="label">{label}</span> : <></>}
      </VrrButton>
    </PrivateComponent>
  ) : (
    <></>
  );
};

export default EditButton;
