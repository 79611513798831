import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  fetchAccountDates,
  getAccountDates
} from '../../../redux/app/appActions';
import { getAccountId } from '../../../redux/userPreferences/actions/userPreferencesActions';
import { Carousel, CarouselItem, CarouselCaption } from 'reactstrap';
import { toSnakeCase } from '../../services/formatters/formatString';
import './LastUpdateDate.scss';
import { VrrButton } from 'micro_front_end/shared_components';

const LastUpdateDate: React.FC = () => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const lastUpdate: any = useSelector(getAccountDates);
  const accountID = useSelector(getAccountId);

  useEffect(() => {
    if (accountID && accountID.length) {
      dispatch(fetchAccountDates(accountID));
    }
  }, [accountID]);

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = (activeIndex + 1) % 3;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = (activeIndex + 2) % 3;
    setActiveIndex(nextIndex);
  };

  return (
    lastUpdate &&
    lastUpdate.lastUpdateDate !== undefined && (
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        interval={false}
        slide={false}
      >
        <VrrButton
          className="btn-link carousel-previous-button"
          onClick={previous}
        >
          <i className="tim-icons icon-minimal-left" />
        </VrrButton>
        {Object.keys(lastUpdate).map((key: string) => {
          const value: string | undefined = lastUpdate[key];

          return (
            <CarouselItem
              key={`${value}-${key}`}
              onExiting={() => setAnimating(true)}
              onExited={() => setAnimating(false)}
            >
              <CarouselCaption
                className="last-update-text"
                captionText={t(
                  `main_navbar.account_dates.${toSnakeCase(key)}`,
                  {
                    date:
                      moment(value).format('LLL') !== 'Invalid date'
                        ? moment(value).format('LLL')
                        : t('main_navbar.account_dates.no_date')
                  }
                )}
              />
            </CarouselItem>
          );
        })}
        <VrrButton className="btn-link carousel-next-button" onClick={next}>
          <i className="tim-icons icon-minimal-right" />
        </VrrButton>
      </Carousel>
    )
  );
};

export default LastUpdateDate;
