import React, { useEffect, useState } from 'react';
import VrrModal from '../../../shared_components/vrr_modal';
import { ModalBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import AccountsTable from './accounts_table';
import VrrCircleLoader from '../../../shared_components/vrr_circle_loader';
import { TAccountModal } from './types';
import './AccountModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAvailableAccounts,
  getAvailableAccounts
} from '../../../../redux/userProfile/userProfileActions';
import { TAvailableAccount } from 'containers/UserProfile/ConnectedAccount/types';

const AccountModal: React.FC<TAccountModal> = ({
  id,
  isOpen,
  onCancelAction,
  onSubmitAction
}: TAccountModal) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const availableAccounts: TAvailableAccount[] | undefined =
    useSelector(getAvailableAccounts);
  const [accounts, setAccounts] = useState<string[]>([]);

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchAvailableAccounts());
    }
  }, [isOpen]);

  const onCancel = () => {
    setAccounts([]);
    if (onCancelAction) onCancelAction();
  };

  const onSubmit = () => {
    if (onSubmitAction)
      onSubmitAction(accounts?.map((item) => parseInt(item)) || []);
    onCancel();
  };

  return (
    <VrrModal
      className="available-accounts-modal"
      isOpen={isOpen}
      title={t('connected_account.account_modal.title')}
      modalBody={
        <ModalBody className="available-accounts-modal-body">
          <VrrCircleLoader isEnable={!availableAccounts}>
            {!availableAccounts?.length ? (
              t('connected_account.account_modal.body.no_available_accounts')
            ) : (
              <AccountsTable
                cyId="user-profile-connected-account-add-account-list"
                id={id}
                accounts={availableAccounts}
                onChangeAction={setAccounts}
              />
            )}
          </VrrCircleLoader>
        </ModalBody>
      }
      toggleModal={onCancel}
      toggleValidation={onSubmit}
      scrollable
      dataCyCancelButton="user-profile-add-accounts-cancel"
    />
  );
};

export default AccountModal;
