import { AxiosResponse } from 'axios';
import { Cookies } from 'react-cookie';
import { TVrrState } from '../redux/types';

const saveSession = async (
  response: AxiosResponse
): Promise<TVrrState['vrr']> => {
  const access_token = response.data.token;
  const cookies = new Cookies();

  if (access_token) {
    cookies.set('access_token', access_token, {
      path: '/',
      sameSite: 'strict',
      maxAge: response.data.expires_in,
      secure: true
    });

    localStorage.setItem(
      'vrr-adtech',
      JSON.stringify({
        session: response.data.resource_owner.email
      })
    );
  }
  return response.data.resource_owner;
};

const currentUserDataSession = () => {
  const rawUserData = localStorage.getItem('vrr-adtech');
  if (rawUserData) {
    const userdata = JSON.parse(rawUserData);
    return userdata?.session;
  }

  return null;
};

export { currentUserDataSession, saveSession };
