import React, { FormEvent, useState } from 'react';
import { VrrInput } from '../../../../../../../shared_components';
import { TRedirectionInput } from './types';

const RedirectionInput: React.FC<TRedirectionInput> = ({
  value,
  onChange
}: TRedirectionInput) => {
  const [newValue, setNewValue] = useState<string | undefined>(undefined);

  const changeAction = (e: FormEvent<HTMLInputElement>) => {
    setNewValue(e.currentTarget.value);
    onChange(e.currentTarget.value);
  };

  return (
    <VrrInput
      className="redirection-input"
      value={newValue ?? (value || '')}
      onChangeAction={changeAction}
      type="text"
    />
  );
};

export default RedirectionInput;
