import allZones from '../../../../../../../../libs/geo_targets/geoTargets.min.json';
import { TVrrTag } from '../../../../../../../shared_components/vrr_tag/types';
import { TMediaPlanDetails } from '../../../../../types';
import { IListState } from '../../../../../../../shared_components/vrr_dropdown/types';

export const prepareAddTag = (
  zoneList: TMediaPlanDetails['zones']
): { newCurrentValues: string[]; newTags: TVrrTag[] } => {
  const newTags: TVrrTag[] = [];
  const newCurrentValues: string[] = [];

  zoneList.forEach((zone) => {
    newTags.push({
      label:
        allZones.find((item: any) => zone.criteria_id === item.criteria_id)
          ?.name ?? '',
      id: zone.criteria_id
    });
    newCurrentValues?.push(zone.criteria_id);
  });

  return { newCurrentValues, newTags };
};

export const prepareRemoveTag = (
  currentValues: string[],
  tags: TVrrTag[],
  id?: string
): { newCurrentValues: string[]; newTags: TVrrTag[] } => {
  const newCurrentValues = id
    ? Object.assign(
        currentValues?.filter((currentValue) => currentValue !== id)
      )
    : [];
  const newTags = id
    ? Object.assign(
        tags?.filter((tag) => {
          return tag.id !== id;
        })
      )
    : [];

  return { newCurrentValues, newTags };
};

export const prepareChangeTag = (
  zoneList: IListState[] | string[]
): TMediaPlanDetails['zones'] => {
  const newCurrentZones: TMediaPlanDetails['zones'] = [];

  zoneList.forEach((zone: IListState | string) => {
    const value = typeof zone === 'string' ? zone : zone.value;
    newCurrentZones.push({
      criteria_id: value
    });
  });

  return newCurrentZones;
};
