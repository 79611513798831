import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import VrrModal from '../../../shared_components/vrr_modal';
import VrrCircleLoader from '../../../shared_components/vrr_circle_loader';
import { TLabelModal } from './types';
import {
  fetchAccountLabels,
  getAccountLabels,
  refreshAccountLabels
} from '../../../../redux/userProfile/userProfileActions';
import { TAccountLabel } from '../../../../redux/userProfile/types';
import LabelsTable from './labels_table/LabelsTable';
import LabelModalHeader from './label_modal_header';
import { getCustomLabels } from '../../../../redux/userPreferences/actions/userPreferencesActions';
import { prepareCustomLabelsList } from './services';

const LabelModal: React.FC<TLabelModal> = ({
  id,
  account,
  isOpen,
  onCancelAction,
  onSubmitAction
}: TLabelModal) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const accountLabels: TAccountLabel[] | undefined =
    useSelector(getAccountLabels);
  const customLabels = useSelector(getCustomLabels);
  const [labels, setLabels] = useState<TAccountLabel[]>([]);

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchAccountLabels(account));
    }
  }, [isOpen]);

  const onCancel = () => {
    setLabels([]);
    if (onCancelAction) onCancelAction();
  };

  const onSubmit = () => {
    const newLabels = prepareCustomLabelsList(account, labels, customLabels);
    if (onSubmitAction) onSubmitAction(newLabels);
    onCancel();
  };

  return (
    <VrrModal
      className="label-modal"
      isOpen={isOpen}
      modalHeader={
        <LabelModalHeader
          onClickRefresh={() => dispatch(refreshAccountLabels(account))}
          disableRefresh={!accountLabels}
        />
      }
      modalBody={
        <ModalBody className="label-modal-modal-body">
          <VrrCircleLoader isEnable={!accountLabels}>
            {!accountLabels?.length ? (
              t('connected_account.label_modal.no_labels')
            ) : (
              <LabelsTable
                id={id}
                labels={accountLabels}
                customLabels={
                  customLabels ? customLabels[parseInt(account)] : []
                }
                onChangeAction={setLabels}
              />
            )}
          </VrrCircleLoader>
        </ModalBody>
      }
      toggleModal={onCancel}
      toggleValidation={onSubmit}
      scrollable
    />
  );
};

export default LabelModal;
