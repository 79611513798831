import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { VrrButton, VrrDropdown } from '../../../../../../../shared_components';
import { PIXEL_TYPE, prepareList, preparePixels } from './services';
import {
  fetchPixels,
  getPixels
} from '../../../../../../../../redux/mediaPlanGallery/mediaPlanGalleryAction';
import { TPixelBody } from './types';
import { IListState } from '../../../../../../../shared_components/vrr_dropdown/types';
import { TPixelRank } from '../types';
import './PixelBody.scss';

const PixelBody: React.FC<TPixelBody> = ({
  lines,
  onChangeAction,
  onRemoveAction
}) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const pixels = useSelector(getPixels);

  const [pixelList, setPixelList] = useState<IListState[]>([]);
  const [main, setMain] = useState<TPixelRank | undefined>(undefined);
  const [other, setOther] = useState<TPixelRank[] | undefined>(undefined);
  const [ranks, setRanks] = useState<TPixelRank[] | undefined>(undefined);

  useEffect(() => {
    if (!pixels) {
      dispatch(fetchPixels());
    } else {
      setPixelList(preparePixels(pixels));
    }
  }, [pixels]);

  useEffect(() => {
    if (lines) {
      setRanks(lines);
      setMain(lines?.find((line) => line.rank === 0));
      setOther(lines?.filter((line) => line.rank !== 0));
    } else {
      setMain(undefined);
      setOther(undefined);
    }
  }, [lines]);

  const onRemove = (rank: number) => {
    if (onRemoveAction) onRemoveAction(rank);
  };

  const onChange = (type: string, rank: number, newValue?: string) => {
    if (onChangeAction) onChangeAction(type, rank, newValue);
  };

  return (
    <div className="pixel-body-action">
      <p>
        {t('media_plan_edit.media_plan_config.pixel.modal_body.description')}
      </p>
      <div className="pixel-actions">
        <div className="final-action">
          <span>
            {t(
              'media_plan_edit.media_plan_config.pixel.modal_body.final_action.first'
            )}
          </span>
          <div className="pixel-dropdown">
            <VrrDropdown
              list={prepareList(PIXEL_TYPE, 'type', main?.rank ?? 0, ranks)}
              handleChangeAction={(e: IListState) =>
                onChange('type', main?.rank ?? 0, e.value)
              }
              currentValue={main?.type}
            />
          </div>
          <span>
            {t(
              'media_plan_edit.media_plan_config.pixel.modal_body.final_action.second'
            )}
          </span>
          <VrrDropdown
            list={prepareList(pixelList, 'id', main?.rank ?? 0, ranks)}
            handleChangeAction={(e: IListState) =>
              onChange('id', main?.rank ?? 0, e.value)
            }
            currentValue={main?.id?.toString()}
          />
        </div>
        <div>
          {other?.map((line, index) => (
            <div className="other-action" key={line?.rank}>
              <span className="other-action-first">
                {t(
                  'media_plan_edit.media_plan_config.pixel.modal_body.final_action.first'
                )}
              </span>
              <div className="pixel-dropdown">
                <VrrDropdown
                  list={prepareList(
                    PIXEL_TYPE,
                    'type',
                    line?.rank ?? index,
                    ranks
                  )}
                  handleChangeAction={(e: IListState) =>
                    onChange('type', line?.rank ?? index, e.value)
                  }
                  currentValue={line?.type}
                />
              </div>
              <span className="other-action-second">→</span>
              <VrrDropdown
                list={prepareList(pixelList, 'id', line?.rank ?? index, ranks)}
                handleChangeAction={(e: IListState) =>
                  onChange('id', line?.rank ?? index, e.value)
                }
                currentValue={line?.id?.toString()}
              />
              <VrrButton
                className="btn-link pixel-remove"
                icon={{ iconLabel: 'tim-icons icon-simple-remove' }}
                onClick={() => onRemove(line?.rank)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PixelBody;
