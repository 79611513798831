import React, { useState } from 'react';
import { TBudgetListItem } from '../types';
import { Collapse } from 'reactstrap';
import '../BudgetListItem/BudgetListItem.scss';
import BudgetListItem from '../BudgetListItem';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';

const BudgetListTitle: React.FC<TBudgetListItem> = ({
  data,
  disabled,
  currentSplitTestId,
  handleChange,
  symbol
}: TBudgetListItem) => {
  const [isCollapseOpen, setIsCollapseOpen] = useState<boolean>(false);
  const displayOnlyList = [undefined, currentSplitTestId];

  return (
    <React.Fragment>
      <div className="budget-list-title">
        <div className="budget-list-main-item">
          <span className={`budget-circle circle-${data.name}`}></span>
          <BudgetListItem
            data={data}
            handleChange={handleChange}
            symbol={symbol}
          />
          {data.name !== 'weekly_budget' ? (
            <div
              role="button"
              tabIndex={0}
              onClick={() => setIsCollapseOpen(!isCollapseOpen)}
            >
              {isCollapseOpen ? <CaretUpFill /> : <CaretDownFill />}
            </div>
          ) : (
            ''
          )}
        </div>
        {data.subItems ? (
          <Collapse
            className="budget-boost-list-sub-container"
            isOpen={isCollapseOpen}
          >
            <div className="budget-boost-list-sub-item-grid"></div>
            <fieldset
              disabled={disabled}
              className="budget-boost-list-sub-list"
            >
              {data.subItems.map((subItem) => {
                if (displayOnlyList.includes(subItem.only)) {
                  return (
                    <BudgetListItem
                      key={`budgetListItem-${subItem.name}-${subItem.value}`}
                      data={subItem}
                      handleChange={handleChange}
                      symbol={symbol}
                    />
                  );
                }
              })}
            </fieldset>
          </Collapse>
        ) : (
          <></>
        )}
      </div>
    </React.Fragment>
  );
};

export default BudgetListTitle;
