import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import URLS from '../../micro_front_end/routing/urls';
import { ReactComponent as ErrorSVG } from '../../assets/vrr/images/error_page.svg';

import './NotFound.scss';

const NotFound: React.FC = () => {
  const { t } = useTranslation();
  const history = useNavigate();

  return (
    <div className="error-page-container">
      <ErrorSVG />
      <div className="error-page-text-container">
        <p>{t('components.error.notFoundMessage')}</p>
        <Button onClick={() => history(URLS.HOME)}>
          {t('components.error.goBackButton')}
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
