import { TStatus } from './quality_metric_section/percent_metric/types';
import { TStatus as TS } from './quality_metric_section/status_metric/types';
import { TScore } from './video_scoring_section/types';

export const getInfo = (
  percent: number,
  array: TStatus[] | TS[] | TScore[]
): TStatus | TS | TScore | undefined => {
  for (let i = 0; i < array.length; i++) {
    const metric = array[i];
    if (percent >= metric.min && percent <= metric.max) {
      return metric;
    }
  }
};
