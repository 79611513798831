import React from 'react';
import { useTranslation } from 'react-i18next';
import { VrrCard } from '../shared_components';
import NotificationTable from './notification_table';

const NotificationCenter: React.FC = () => {
  const { t } = useTranslation();

  return (
    <VrrCard
      cardBody={{
        element: (
          <>
            <h4>{t('notification_center.title').toUpperCase()}</h4>
            <NotificationTable />
          </>
        )
      }}
    />
  );
};

export default NotificationCenter;
