import { TVrrBreadcrumbItem } from '../../shared_components/vrr_breadcrumb/types';
import { TCreativeGalleryFolder } from '../../../redux/creativeAnalytics/creativeGallery/types';

export const prepareBreadcrumb = (
  breadcrumb: TCreativeGalleryFolder[] | undefined
): TVrrBreadcrumbItem[] => {
  return (
    breadcrumb?.map((node: TCreativeGalleryFolder) => {
      return {
        label: node?.name || 'CREATIVE GALLERY',
        id: node?.id?.toString()
      };
    }) ?? [
      {
        label: 'CREATIVE GALLERY',
        id: 'creative-video-breadcrumb-0'
      }
    ]
  );
};

export const updateSelections = (
  id: number,
  isCheck: boolean,
  selections: number[]
): number[] => {
  let newSelection = [...selections];

  if (isCheck && !newSelection.includes(id)) {
    newSelection.push(id);
  }

  if (!isCheck && newSelection.includes(id)) {
    newSelection = newSelection.filter((selection) => selection !== id);
  }
  return newSelection;
};

export const getFolderVideoIds = (
  videoSelections?: number[],
  folderSelections?: number[],
  folderList?: TCreativeGalleryFolder[]
): number[] => {
  const videoIds: number[] = [...(videoSelections ?? [])];
  if (folderSelections?.length) {
    folderSelections.forEach((folderId) =>
      folderList?.some((folder) => {
        if (folder.id === folderId) {
          videoIds.push(...folder.videoIdsList);
        }
      })
    );
  }
  return videoIds;
};
