import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PixelBody from './pixel_body';
import PixelHeader from './pixel_header';
import { VrrModal } from '../../../../../../shared_components';
import { addRank, prepareLines, removeLine, validateSubmit } from './services';
import {
  editMediaPlanDetails,
  getMediaPlan
} from '../../../../../../../redux/mediaPlanGallery/mediaPlanGalleryAction';
import { TPixelModal, TPixelRank } from './types';
import './PixelModal.scss';

const PixelModal: React.FC<TPixelModal> = ({ isOpen, onCancelAction }) => {
  const dispatch: any = useDispatch();
  const mediaPlan = useSelector(getMediaPlan);
  const [lines, setLines] = useState<TPixelRank[] | undefined>(undefined);

  useEffect(() => {
    if (isOpen && !!mediaPlan?.pixels?.length) {
      setLines(mediaPlan?.pixels);
    }
  }, [mediaPlan, isOpen]);

  const onCancel = () => {
    if (onCancelAction) onCancelAction();
  };

  const onSubmit = () => {
    dispatch(editMediaPlanDetails({ pixels: lines ?? [] }));
    if (onCancelAction) onCancelAction();
  };

  const onChange = (type: string, rank: number, newValue?: string) => {
    const newLines = prepareLines(type, rank, newValue, lines);
    setLines(newLines);
  };

  const onClear = () => {
    setLines(undefined);
  };

  const addLine = () => {
    if (!!lines?.length) {
      const newLines = addRank(lines);
      setLines(newLines);
    }
  };

  const onRemove = (rank: number) => {
    const newLines = removeLine(rank, lines);
    setLines(newLines);
  };

  return (
    <VrrModal
      className="pixel-modal"
      isOpen={isOpen}
      modalHeader={
        <PixelHeader canAdd onClickAction={addLine} onClearAction={onClear} />
      }
      modalBody={
        <div className="pixel-body" data-cy-id="media-plan-detail-edit-pixel">
          <PixelBody
            lines={lines}
            onChangeAction={onChange}
            onRemoveAction={onRemove}
          />
        </div>
      }
      toggleModal={onCancel}
      toggleValidation={onSubmit}
      enabledValidationButton={validateSubmit(lines)}
      dataCyCancelButton="media-plan-detail-edit-close-button"
    />
  );
};

export default PixelModal;
