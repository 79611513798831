import { TVideos } from './types';
import { TDevices } from '../../../../redux/creativeAnalytics/creativeVideoDetails/types';
import { TVideoCard } from './video_card/types';

export const prepareVideos = (
  videoId: number,
  device: string,
  isActive: boolean,
  devices?: TVideos[]
) => {
  let newVideos: TVideos[] = [...(devices ?? [])];
  const ids = newVideos?.map((video) => video.vrr_id);
  if (ids?.includes(videoId)) {
    newVideos = newVideos?.map((v) => {
      if (v.vrr_id === videoId) {
        return {
          ...v,
          devices: {
            ...v.devices,
            [device]: isActive
          }
        };
      }
      return v;
    });
  } else {
    newVideos.push({
      vrr_id: videoId,
      devices: {
        [device]: isActive
      } as TDevices
    });
  }

  return newVideos;
};

export const checkVideos = (devices?: TVideos[]) => {
  const ids: number[] = [];
  devices?.forEach((device) => {
    if (Object.values(device.devices).find((value) => value)) {
      return ids.push(device.vrr_id);
    }
  });
  return ids;
};

export const validateSubmit = (videos?: TVideoCard[], devices?: TVideos[]) => {
  const videoWithDevices: number[] = checkVideos(devices);
  if (!!videos?.length && !!videoWithDevices.length) {
    return videos.every((video) => videoWithDevices.includes(video.vrr_id));
  }
  return false;
};
