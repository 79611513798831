import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalHeader } from 'reactstrap';
import { PlusCircleFill } from 'react-bootstrap-icons';
import AddButton from '../import_body/url_form/add_button';
import { TImportHeader } from './types';

const ImportHeader: React.FC<TImportHeader> = ({ showButton, onAddAction }) => {
  const { t } = useTranslation();

  return (
    <ModalHeader
      className="vrr-modal-header"
      {...(showButton && {
        close: <AddButton onClickAction={onAddAction} />
      })}
    >
      <span>
        <PlusCircleFill size="1.5rem" />
      </span>
      <span>{t('creative_gallery.import_video.header')}</span>
    </ModalHeader>
  );
};

export default ImportHeader;
