import { MutableRefObject } from 'react';
import i18next from 'i18next';
import moment from 'moment/moment';
import type { ChartData } from 'chart.js';
import { IMPRESSIONS, RATIO, SPENT, VALUE } from './chart_options/services';
import formatDataService from '../../services/formatters/formatData';
import {
  ChartsColorsType,
  ColorsType,
  TChart,
  TChartData,
  TCharts,
  TRangePeriod
} from './types';
import { TGlobalMetric } from '../../../redux/userPreferences/types';
import { TDateRange } from '../../nav_bar/date_time_picker/types';
import { TCurrencyOptions } from '../../services/formatters/types';

export const VALUE_RANGE_PERIOD = {
  DAILY: { label: i18next.t('components.charts.days'), value: 'daily' },
  WEEKLY: { label: i18next.t('components.charts.weeks'), value: 'weekly' },
  MONTHLY: { label: i18next.t('components.charts.months'), value: 'monthly' }
};

const chartsColors: ChartsColorsType[] = [
  {
    name: 'red',
    colors: {
      base: '#ff4438',
      dark: 'rgba(255, 68, 56, 0.1)',
      medium: 'rgba(255, 68, 56, 0.05)',
      light: 'rgba(255, 68, 56, 0)'
    }
  },
  {
    name: 'turquoise',
    colors: {
      base: '#2cd3bf',
      dark: 'rgba(44, 211, 191, 0.1)',
      medium: 'rgba(44, 211, 191, 0.05)',
      light: 'rgba(44, 211, 191, 0)'
    }
  },
  {
    name: 'mauve',
    colors: {
      base: '#6c4cda',
      dark: 'rgba(108, 76, 218, 0.1)',
      medium: 'rgba(108, 76, 218, 0.05)',
      light: 'rgba(108, 76, 218, 0)'
    }
  },
  {
    name: 'yellow',
    colors: {
      base: '#ffc958',
      dark: 'rgba(255, 201, 88, 0.1)',
      medium: 'rgba(255, 201, 88, 0.05)',
      light: 'rgba(255, 201, 88, 0)'
    }
  }
];

export const colorOfChart = (color?: string): ColorsType => {
  const foundColorMetric =
    chartsColors.find((element) => element.name === color) ?? chartsColors[0];

  return foundColorMetric.colors;
};

export const getColorByType = (
  context: CanvasRenderingContext2D,
  color?: string
): { baseColor: string; gradient: CanvasGradient } => {
  const colors = colorOfChart(color);
  const baseColor = colors.base;
  const gradient = context?.createLinearGradient(0, 350, 0, 50);
  gradient?.addColorStop(1, colors.dark);
  gradient?.addColorStop(0.7, colors.medium);
  gradient?.addColorStop(0.2, colors.light);

  return { baseColor, gradient };
};

export const checkPeriod = (
  period: TDateRange | null,
  currentPeriod: TDateRange | null
): boolean => {
  return (
    period?.from !== currentPeriod?.from || period?.to !== currentPeriod?.to
  );
};

export const checkAccount = (
  currentAccountId: string | null,
  accountId: string | null
): boolean => {
  return currentAccountId !== accountId;
};

export const checkFunnel = (
  newFunnel: string | null,
  currentFunnel: string
): boolean => {
  return newFunnel !== currentFunnel;
};

export const checkRange = (
  previousRange: string,
  rangePeriod: string
): boolean => {
  return previousRange !== rangePeriod;
};

const getChartLabel = (
  chart: TChart,
  currentTypeData: keyof TChart
): string | number[] | TChartData[] => {
  return chart.type === SPENT ||
    currentTypeData === VALUE ||
    (chart.type === IMPRESSIONS && currentTypeData === RATIO)
    ? chart.name
    : chart[`${currentTypeData}Name` as keyof TChart] ?? '';
};

export const formatChartData = (
  data: {
    charts: Array<TChart>;
    labels: Array<string>;
  },
  localOptions: TCurrencyOptions
): any => {
  const { thousandsSeparator, decimalSeparator, symbol } = localOptions;
  const currency = symbol ? symbol : '';
  return {
    ...data,
    charts: {
      ...data.charts.map((dataChartIterate: any) => {
        return {
          ...dataChartIterate,
          value: dataChartIterate.value.map((v: any) =>
            formatDataService.dataFormatter(
              v,
              currency,
              thousandsSeparator,
              decimalSeparator
            )
          ),
          cost: dataChartIterate.cost
            ? dataChartIterate.cost.map((v: any) =>
                formatDataService.dataFormatter(
                  v,
                  currency,
                  thousandsSeparator,
                  decimalSeparator
                )
              )
            : [],
          ratio: dataChartIterate.ratio
            ? dataChartIterate.ratio.map((v: any) =>
                formatDataService.dataFormatter(
                  v,
                  currency,
                  thousandsSeparator,
                  decimalSeparator
                )
              )
            : []
        };
      })
    }
  };
};

export const getChartData = (
  chart: TChart,
  currentTypeData: keyof TChart
): Array<number | string> => {
  const result: Array<number | string> = chart[currentTypeData]
    ? (chart[currentTypeData] as TChartData[])?.map((v) => v.value)
    : [];
  return chart.type === SPENT ||
    currentTypeData === VALUE ||
    (chart.type === IMPRESSIONS && currentTypeData === RATIO)
    ? chart.value.map((v: any) => v.value)
    : result;
};

export const getScales = (
  charts: TCharts
): {
  [key: string]: {
    display: number;
  };
} => {
  let scales = {};
  Object.values(charts.charts).forEach((chart: TChart) => {
    scales = {
      ...scales,
      [`y${chart.name}`]: {
        display: 0
      }
    };
  });
  return scales;
};

export const getTooltipLabel = (
  tooltipItem: {
    dataset: {
      label: string;
      data: { [key: string]: number };
    };
    dataIndex: string;
  },
  charts: any,
  currentTypeData: keyof TChart
): string => {
  const {
    dataset: { label },
    dataIndex
  } = tooltipItem;
  let currentDataNumber: any = null;
  const chartsKey: Array<string> = Object.keys(charts);
  const index = currentTypeData === 'value' ? 'name' : `${currentTypeData}Name`;
  chartsKey.map((chartKey: any) => {
    if (label === 'Spent') {
      currentDataNumber = charts[0].value[dataIndex];
    }
    if (label === 'Impressions' && currentTypeData === 'ratio') {
      currentDataNumber = charts[3].value[dataIndex];
    }
    if (charts[chartKey][index] === label) {
      currentDataNumber = charts[chartKey][currentTypeData][dataIndex];
    }
  });
  return `${label}: ${currentDataNumber}`;
};

export const setDataService = (
  charts: TCharts,
  canvasRef: MutableRefObject<any>['current'],
  currentTypeData: keyof TChart,
  currentGlobalMetrics?: TGlobalMetric | null
): ChartData<'line'> => {
  if (Object.values(charts).length > 0) {
    return {
      labels: charts.labels,
      datasets: [
        ...Object.values(charts.charts).map((chart: TChart) => {
          const metric = currentGlobalMetrics?.metrics?.find(
            (m) => m.name.value === chart.name && m.color && m.color !== 'grey'
          );
          const { baseColor, gradient } = getColorByType(
            canvasRef?.ctx,
            metric?.color
          );

          return {
            label: getChartLabel(chart, currentTypeData) as string,
            pointBackgroundColor: baseColor,
            fill: true,
            borderColor: baseColor,
            backgroundColor: gradient,
            data: [...(getChartData(chart, currentTypeData) as any)],
            tension: 0.2,
            yAxisID: `y${chart.name}`,
            hidden: chart.type !== SPENT && !metric
          };
        })
      ]
    };
  }

  return {
    datasets: [],
    labels: []
  };
};

export const setDataOptions = (
  charts: {
    charts: Array<TChart>;
    labels: Array<string>;
  },
  currentTypeData: keyof TChart,
  localOptions: TCurrencyOptions
): {
  animations: boolean;
  responsive: boolean;
  aspectRatio: number;
  interaction: {
    mode: string;
    intersect: boolean;
  };
  scales: {
    [key: string]: {
      display: number;
    };
  };
  plugins: {
    legend: {
      display: boolean;
    };
    tooltip: {
      enabled: boolean;
      callbacks: {
        label: (tooltipItem: {
          dataset: {
            label: string;
            data: {
              [key: string]: number;
            };
          };
          dataIndex: string;
        }) => string;
      };
    };
  };
} | null => {
  if (Object.values(charts).length > 0) {
    const newChart = formatChartData(charts, localOptions);
    return {
      animations: false,
      responsive: true,
      aspectRatio: 6,
      interaction: {
        mode: 'index' as const,
        intersect: false
      },
      scales: {
        ...getScales(newChart)
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: true,
          callbacks: {
            label: (tooltipItem) =>
              getTooltipLabel(tooltipItem, newChart.charts, currentTypeData)
          }
        }
      }
    };
  }
  return null;
};

export const getDropdownValues = (period: TDateRange): TRangePeriod[] => {
  // Count the diff and not the total number of days
  const numberOfDays = moment(period.to).diff(period.from, 'days') + 1;

  const returnValues: TRangePeriod[] = [];

  if (numberOfDays <= 84) {
    returnValues.push(VALUE_RANGE_PERIOD.DAILY);
  }
  if (numberOfDays >= 7 && numberOfDays <= 588) {
    returnValues.push(VALUE_RANGE_PERIOD.WEEKLY);
  }
  if (numberOfDays >= 32) {
    returnValues.push(VALUE_RANGE_PERIOD.MONTHLY);
  }

  return returnValues;
};

export const getDefaultValue = (period: TDateRange): TRangePeriod => {
  // Count the diff and not the total number of days
  const numberOfDays = moment(period.to).diff(period.from, 'days') + 1;

  if (numberOfDays >= 197) {
    return VALUE_RANGE_PERIOD.MONTHLY;
  } else if (numberOfDays <= 49) {
    return VALUE_RANGE_PERIOD.DAILY;
  }

  return VALUE_RANGE_PERIOD.WEEKLY;
};
