import { useDispatch } from 'react-redux';
import { notify } from '../../redux/app/appSlice';
import { IOptions, INotify } from './types';

const useNotification: any = () => {
  const dispatch = useDispatch();

  return (type: string, options?: IOptions) => {
    dispatch(notify({ type, options } as INotify));
  };
};
export default useNotification;
