import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { Input } from 'reactstrap';
import { TKPIDropdown, TKPIOption } from './types';
import {
  prepareOptions,
  prepareSelections,
  prepareValues,
  SELECT_ALL_OPTION
} from './services';
import { useTranslation } from 'react-i18next';

const KPIDropdown: React.FC<TKPIDropdown> = ({
  options,
  mainKpi,
  values,
  onChangeAction
}: TKPIDropdown) => {
  const { t } = useTranslation();
  const [currentValues, setCurrentValues] = useState<TKPIOption[]>([]);

  useEffect(() => {
    if (options.length) {
      const newValues: TKPIOption[] = prepareValues(options, values, mainKpi);
      setCurrentValues(newValues);
    }
  }, [values, options, mainKpi]);

  const onChange = (selections: any, checkState: any) => {
    const opt = prepareSelections(
      {
        selections,
        currentSelection: checkState.option,
        isCheck: checkState.action === 'select-option'
      },
      prepareValues(options, [], mainKpi),
      options
    );
    setCurrentValues(opt);
    if (onChangeAction) onChangeAction(opt);
  };

  const Option = (props: any) => {
    const { value, isSelected, label } = props;

    return (
      <components.Option {...props}>
        <div className="vrr-dropdown-multi-option">
          <Input
            id={value}
            type="checkbox"
            onChange={onChange as any}
            checked={isSelected}
            className="multi-option-checkbox"
          />
          <label htmlFor={value} className="check">
            <span className="cyan-checkbox" />
            {label}
            {value === mainKpi && (
              <small>
                {t('metrics.metric_detail_modal.kpi_dropdown.main')}
              </small>
            )}
          </label>
        </div>
      </components.Option>
    );
  };

  return (
    <Select
      placeholder={t('metrics.metric_detail_modal.kpi_dropdown.placeholder')}
      className="vrr-dropdown"
      classNamePrefix="vrr-dropdown"
      menuPosition="fixed"
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      controlShouldRenderValue={false}
      isClearable={false}
      isMulti
      value={currentValues}
      options={[
        {
          label: '',
          options: [
            {
              ...SELECT_ALL_OPTION,
              label: t(SELECT_ALL_OPTION.label)
            }
          ]
        },
        {
          label: "KPI's",
          options: prepareOptions(options, mainKpi)
        }
      ]}
      onChange={onChange}
      components={{ Option }}
    />
  );
};
export default KPIDropdown;
