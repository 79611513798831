import React, { BaseSyntheticEvent } from 'react';
import { Input } from 'reactstrap';
import { TAudienceCategoryLine } from './types';
import { Funnels } from '../../../../../../../../../../types';
import './AudienceCategoryLine.scss';

const AudienceCategoryLine: React.FC<TAudienceCategoryLine> = ({
  categoryLine,
  isChecked,
  onChangeAction
}) => {
  const { name, criteriaId, funnel } = categoryLine;

  const onChange = (e: BaseSyntheticEvent) => {
    const { target } = e;
    if (onChangeAction) {
      onChangeAction(
        {
          id: target.id,
          name: target.name,
          funnel: funnel as Funnels
        },
        e.target.checked
      );
    }
  };

  return (
    <span className="audience-category-line">
      <Input
        id={criteriaId}
        name={name}
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
      />
      <label htmlFor={criteriaId} className="check">
        <span className="cyan-checkbox" />
        <span className="category-line-name">{name}</span>
      </label>
    </span>
  );
};

export default AudienceCategoryLine;
