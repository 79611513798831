import { TPixelRank } from './types';

export const prepareLines = (
  type: string,
  rank: number,
  newValue?: string,
  lines?: TPixelRank[]
): TPixelRank[] | undefined => {
  const newLines = [];
  if (!!lines?.length) {
    lines?.forEach((line) => {
      if (line.rank === rank) {
        newLines.push({
          ...line,
          [type]: newValue
        });
      } else {
        newLines.push({
          ...line
        });
      }
    });
  } else {
    newLines.push({
      [type]: newValue,
      rank
    });
  }

  newLines.sort((a, b) => a.rank - b.rank);
  return !!newLines.length ? newLines : undefined;
};

export const addRank = (lines: TPixelRank[]) => {
  const newLines = [...lines];
  newLines.push({
    rank: Math.max(...lines.map((line) => line.rank)) + 1
  });
  return newLines;
};

export const validateSubmit = (lines?: TPixelRank[]) => {
  return (
    !!lines?.every((line) => line.type && line.id) ||
    (lines?.length === 1 &&
      (!!(lines[0]?.type?.length && lines[0]?.id && !isNaN(lines[0].id)) ||
        !!(!lines[0]?.type?.length && lines[0]?.id && !isNaN(lines[0].id)))) ||
    !lines?.length
  );
};

export const removeLine = (rank: number, lines?: TPixelRank[]) => {
  return lines
    ?.filter((line) => line.rank !== rank)
    .sort((a, b) => a.rank - b.rank);
};
