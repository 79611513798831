import React, { BaseSyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'reactstrap';
import './Email.scss';
import { IEmail } from './types';

export const EMAIL_PATTERN = new RegExp(
  /^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*$/i
);

const Email: React.FC<IEmail> = ({
  placeholder,
  value,
  required,
  disabled = false,
  onChangeAction
}: IEmail) => {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState<boolean>(!!value);

  const validate = (event: BaseSyntheticEvent): void => {
    setIsValid(EMAIL_PATTERN.test(event.target.value));
    if (onChangeAction)
      onChangeAction(event, EMAIL_PATTERN.test(event.target.value));
  };

  return (
    <Input
      type="email"
      className="email-input"
      placeholder={
        placeholder ??
        t('components.general_informations.email_address.placeholder')
      }
      required={required && !disabled}
      value={value}
      onChange={validate}
      invalid={!isValid}
      disabled={disabled}
    />
  );
};

export default Email;
