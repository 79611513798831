import {
  TCreativeImportVideo,
  TImportUrl,
  TPreview
} from '../../../../redux/creativeAnalytics/creativeImportVideo/types';
import { v4 as uuid } from 'uuid';

const BRAND_REQUIRED_FIELD = ['vertical', 'principal_website', 'name'];

export const preparePreviews = (previews: TPreview[]): TPreview[] => {
  return previews.map((p) => {
    const newPreviews = {
      ...p
    };
    if (!p?.details?.objective) {
      newPreviews.details = {
        ...newPreviews.details,
        objective: 'branding'
      };
    }

    if (
      !p?.details?.devices ||
      !!Object.values(p?.details?.devices)?.filter((device) => device === null)
        .length
    ) {
      newPreviews.details = {
        ...newPreviews.details,
        devices: {
          mobile: true,
          connected_tv: false,
          desktop: false
        }
      };
    }

    return newPreviews;
  });
};

export const prepareUrls = (urls: TImportUrl[]): TImportUrl[] => {
  const newUrls = [...urls];
  newUrls.push({ linkValue: '', id: uuid() });
  return newUrls;
};

export const prepareVideos = (urls: TImportUrl[]): string[] => {
  const videos: string[] = [];
  urls.forEach((url) => {
    if (!!url?.linkValue?.length && !videos.includes(url.linkValue)) {
      videos.push(url.linkValue);
    }
  });
  return videos;
};

export const purgePreviews = (previews: TPreview[]): TPreview[] => {
  return previews.map((p) => {
    const newPreviews = {
      ...p,
      url: p.video?.url,
      video_id: p.video?.video_id
    };
    if (newPreviews?.video) delete newPreviews.video;
    return newPreviews;
  });
};

export const checkValidation = (
  brand: TCreativeImportVideo['brand'],
  previews: TPreview[]
): boolean => {
  const isValidBrand =
    !!brand &&
    BRAND_REQUIRED_FIELD.every((key) => key in brand) &&
    Object.values(brand).every((value) => !!value.length);

  const isValidPlatform = previews.every(
    (preview) => !!preview.details.platform?.length
  );

  const isValidLanguage = previews.every(
    (preview) => !!preview.details.language?.length
  );

  return isValidBrand && isValidPlatform && isValidLanguage;
};
