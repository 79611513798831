import React from 'react';
import NotificationItem from './notification_item';
import { TNotificationList } from './types';
import './NotificationList.scss';

const NotificationList: React.FC<TNotificationList> = ({
  notifications,
  onClickAction
}) => {
  return (
    <div className="notification-list">
      {notifications?.map((line) => (
        <NotificationItem
          notification={line}
          key={line.id}
          onClickAction={onClickAction}
        />
      ))}
    </div>
  );
};

export default NotificationList;
