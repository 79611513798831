import React from 'react';
import { TMediaPlanListContainer } from './types';
import { VrrGallery } from 'micro_front_end/shared_components';
import MediaPlanCard from '../media_plan_card';
import mediaPlanGalleryStyles from './MediaPlanListContainer.module.scss';

const MediaPlanListContainer: React.FC<TMediaPlanListContainer> = ({
  mediaPlanList,
  isLoading = false
}: TMediaPlanListContainer) => {
  return (
    <VrrGallery
      data={mediaPlanList}
      childElement={MediaPlanCard}
      isLoading={isLoading}
      message="media_plan.message.no_items"
      className={mediaPlanGalleryStyles.vrrMediaPlanGalleryGrid}
    />
  );
};

export default MediaPlanListContainer;
