import { TStatus } from './types';

export const METRIC_STATUS: TStatus[] = [
  {
    min: 0,
    max: 33,
    color: 'red',
    status: 'Weak'
  },
  {
    min: 33.1,
    max: 66,
    color: 'orange',
    status: 'Average'
  },
  {
    min: 66.1,
    max: 100,
    color: 'green',
    status: 'Strong'
  }
];
