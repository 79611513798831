import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ShareModal from '../../components/Share/ShareModal';
import {
  fetchViewers,
  getSharerViewers
} from '../../redux/overview/overviewActions';
import RightBarButton from '../../micro_front_end/overview/right_bar/right_bar_button';
import { TShareContainer } from './types';
import { addViewer } from '../../redux/userPreferences/actions/userPreferencesAPIs';
import { getAccountId } from '../../redux/userPreferences/actions/userPreferencesActions';

export const ShareC: React.FC<TShareContainer> = ({
  currentAccountId,
  share,
  getViewers
}: TShareContainer) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleSubmit = (emails: string[], updateViewersSettings: boolean) => {
    if (emails.length > 0 || updateViewersSettings) {
      share(emails, updateViewersSettings);
      setOpen(false);
    }
  };

  const handleClick = () => {
    if (currentAccountId) {
      getViewers(currentAccountId);
    }
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <RightBarButton
        onClick={handleClick}
        label={t('components.share.share_button_label')}
        icon="tim-icons icon-send"
        classNameValue="btn-share"
        cyId="overview-share-button"
      />
      <ShareModal
        isOpen={open}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentAccountId: getAccountId(state),
  viewers: getSharerViewers(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  share: (emails: string[], updateViewersSettings: boolean) =>
    dispatch(addViewer({ emails, updateViewersSettings })),
  getViewers: (accountId: string | null) =>
    dispatch(fetchViewers({ accountId }))
});

const ShareContainer = connect(mapStateToProps, mapDispatchToProps)(ShareC);

export default ShareContainer;
