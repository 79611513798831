import React from 'react';
import { Progress } from 'reactstrap';
import './ProgressBar.scss';
import { IProgressBar } from './types';

const ProgressBar: React.FC<IProgressBar> = ({
  total,
  currentNumber,
  label,
  children,
  enable
}: IProgressBar) => {
  const progressBarEnabled =
    enable &&
    currentNumber !== undefined &&
    total !== undefined &&
    currentNumber !== total;
  return (
    <div className="progress-bar-container">
      {progressBarEnabled && (
        <div className="progress-bar-component">
          <div className="progress-bar-component-content">
            {label && (
              <span className="progress-bar-component-content-label">
                {label}
              </span>
            )}
            {!isNaN(currentNumber) && !isNaN(total) && (
              <Progress
                className="cy-progress-bar"
                color="info"
                value={(currentNumber / total) * 100}
              />
            )}
            {!isNaN(currentNumber) && !isNaN(total) && (
              <span className="progress-bar-component-content-number">
                {currentNumber}/
                <span className="cy-progress-total-pages-indicator">
                  {total}
                </span>
              </span>
            )}
          </div>
        </div>
      )}
      <div className="progress-bar-content">{children}</div>
    </div>
  );
};

export default ProgressBar;
