import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  VrrButton,
  VrrDropdown,
  VrrFloatInput
} from '../../../../../../../shared_components';
import {
  getFirstLineGoals,
  getSecondLineGoals,
  KPI_COST,
  KPI_RATE,
  prepareKpiList,
  prepareRank
} from './services';
import { IListState } from '../../../../../../../shared_components/vrr_dropdown/types';
import { TOptimizationBody, TRank } from './types';
import './OptimizationBody.scss';

const OptimizationBody: React.FC<TOptimizationBody> = ({
  line1,
  line2,
  addNewRank = false,
  onRemoveAction,
  onChangeAction
}) => {
  const { t } = useTranslation();
  const [rank1, setRank1] = useState<TRank>({ rank: 1 });
  const [rank2, setRank2] = useState<TRank>({ rank: 2 });

  useEffect(() => {
    setRank1(line1 ?? { rank: 1 });
    setRank2(line2 ?? { rank: 2 });
  }, [line1, line2]);

  const onChange = (type: string, selection: string | number, rank: number) => {
    if (rank === 1) {
      const newRank = prepareRank(rank1, type as keyof TRank, selection);
      setRank1(newRank);
      if (onChangeAction) {
        const newLines = [newRank];
        if (Object.values(rank2).length > 1) newLines.push(rank2);
        onChangeAction(newLines);
      }
    }

    if (rank === 2) {
      const newRank = prepareRank(rank2, type as keyof TRank, selection);
      setRank2(newRank);
      if (onChangeAction) onChangeAction([rank1, newRank]);
    }
  };

  const onRemove = () => {
    setRank2({ rank: 2 });
    if (onRemoveAction) onRemoveAction([rank1]);
  };

  return (
    <div className="optimization-body-modal">
      <p>{t('media_plan_edit.media_plan_config.optimization.sentence')}</p>
      <div className="optimization-body">
        <div className="optimization-rank">
          <span>
            {t('media_plan_edit.media_plan_config.optimization.wanted')}
          </span>
          <VrrDropdown
            list={prepareKpiList(rank2.kpi)}
            handleChangeAction={(value: IListState) =>
              onChange('kpi', value.value, 1)
            }
            currentValue={rank1?.kpi ?? undefined}
          />
          <div className="optimization-dropdown">
            <VrrDropdown
              list={getFirstLineGoals()}
              handleChangeAction={(value: IListState) =>
                onChange('goal', value.value, 1)
              }
              currentValue={rank1?.goal}
            />
          </div>
          {(rank1?.goal === 'with_min_of' || rank1?.goal === 'with_max_of') && (
            <div className="optimization-input">
              <VrrFloatInput
                decimalNumber={2}
                withCurrency={KPI_COST.includes(rank1?.kpi as string)}
                withPercent={KPI_RATE.includes(rank1?.kpi as string)}
                onChangeAction={(value?: string | number) => {
                  if (value) onChange('target', value, 1);
                }}
                value={rank1?.target}
              />
            </div>
          )}
        </div>
        {addNewRank && (
          <div className="optimization-new-rank">
            <span>
              {t('media_plan_edit.media_plan_config.optimization.maintain')}
            </span>
            <VrrDropdown
              list={prepareKpiList(rank1.kpi)}
              handleChangeAction={(value: IListState) =>
                onChange('kpi', value.value, 2)
              }
              currentValue={rank2?.kpi}
            />
            <div className="optimization-dropdown">
              <VrrDropdown
                list={getSecondLineGoals()}
                handleChangeAction={(value: IListState) =>
                  onChange('goal', value.value, 2)
                }
                currentValue={rank2?.goal}
              />
            </div>
            <div className="optimization-input">
              <VrrFloatInput
                decimalNumber={2}
                withCurrency={KPI_COST.includes(rank2?.kpi as string)}
                withPercent={KPI_RATE.includes(rank2?.kpi as string)}
                onChangeAction={(value?: string | number) => {
                  if (value) onChange('target', value, 2);
                }}
                value={rank2?.target}
              />
            </div>
            <VrrButton
              className="optimization-remove"
              icon={{ iconLabel: 'tim-icons icon-simple-remove' }}
              onClick={onRemove}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default OptimizationBody;
