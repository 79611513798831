import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import GlobalMetricsSelections from './global_metrics_selections';
import { VrrModal } from '../../../../shared_components';
import { prepareMetrics } from '../../services';
import { getGMSettingsByCurrentAccount } from '../../../../../redux/userPreferences/actions/userPreferencesActions';
import { globalMetricsAll } from '../../../../../redux/overview/overviewActions';
import { TGlobalMetricsModal } from './types';
import { TSelectionMetric } from '../card_metric/types';
import './GlobalMetricsModal.scss';

const GlobalMetricsModal: React.FC<TGlobalMetricsModal> = ({
  isOpen,
  onSubmitAction,
  onCancelAction
}) => {
  const allMetrics = useSelector(globalMetricsAll);
  const metricsPreferences = useSelector(getGMSettingsByCurrentAccount);
  const [metrics, setMetrics] = useState<TSelectionMetric[] | undefined>(
    undefined
  );

  useEffect(() => {
    setMetrics(undefined);
    if (isOpen && allMetrics) {
      setMetrics(prepareMetrics(allMetrics, metricsPreferences));
    }
  }, [isOpen, metricsPreferences, allMetrics]);

  const onSubmit = () => {
    if (onSubmitAction) onSubmitAction(metrics);
  };

  const onChange = (newMetricSelections: TSelectionMetric[]) => {
    setMetrics(newMetricSelections);
  };

  const onCancel = () => {
    if (onCancelAction) onCancelAction();
  };

  return (
    <VrrModal
      className="global-metric-modal"
      isOpen={isOpen}
      modalBody={
        <div data-cy-id="overview-add-global">
          <GlobalMetricsSelections
            metrics={metrics}
            onChangeAction={onChange}
            selectedMetricsList={metrics?.filter((metric) => metric.selected)}
          />
        </div>
      }
      toggleModal={onCancel}
      toggleValidation={onSubmit}
      dataCyCancelButton="overview-add-global-cancel"
    />
  );
};

export default GlobalMetricsModal;
