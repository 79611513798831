import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import apiVRR from '../../libs/axios/apis';
import { notify } from '../app/appSlice';
import END_POINTS from '../../services/endpoints';
import { TEditLauncher, TLauncherDetails } from './types';

export const newLaunch = createAsyncThunk(
  'launcher/newLaunch',
  async (videoIds: number[], { getState }) => {
    const currentAccountId = (getState() as any).userPreferences.settings.vrr
      .current_account_id;
    const endPoint = END_POINTS.LAUNCHER.NEW_LAUNCH(currentAccountId);

    const response = await apiVRR().post(endPoint, {
      videoIds
    });

    return response.data.data;
  }
);

export const editLauncher = createAsyncThunk(
  'launcher/editLauncher',
  async (params: TEditLauncher, { getState }) => {
    const currentAccountId = (getState() as any).userPreferences.settings.vrr
      .current_account_id;
    const launcherId = (getState() as any).launcher.launcherDetails.id;
    const endpointURL = END_POINTS.LAUNCHER.LAUNCHER(
      currentAccountId,
      launcherId
    );
    const response = await apiVRR().patch(endpointURL, params);

    return response.data.data;
  }
);

export const launch = createAsyncThunk(
  'launcher/launch',
  async (_, { getState, dispatch }) => {
    const currentAccountId = (getState() as any).userPreferences.settings.vrr
      .current_account_id;
    const launcherId = (getState() as any).launcher.launcherDetails.id;
    const endpointURL = END_POINTS.LAUNCHER.LAUNCHER(
      currentAccountId,
      launcherId
    );
    const response = await apiVRR().post(endpointURL);

    if (response.status >= 200) {
      dispatch(notify({ type: 'success' }));
    }

    return response.data.data;
  }
);

export const getLaunch = (state: RootState): TLauncherDetails | undefined =>
  state.launcher.launcherDetails;
