import SigninPage from '../../pages/Signin';
import ForgotPassword from '../../pages/ForgotPassword';
import InsightsPage from '../../pages/Insights';
import ErrorPage from '../../pages/Error';
import Overview from '../overview';
import ManagementPage from '../../pages/Management';
import UserProfilePage from '../../pages/UserProfile';
import CreativeVideoGallery from '../creative_analytics/creative_video_gallery';
import NotificationCenter from '../notification_center';
import CreativeVideoDetails from '../creative_analytics/creative_video_details';
import MediaPlanDetails from '../media_plan/media_plan_details';
import Launcher from '../launcher';
import MediaPlanGallery from '../media_plan/media_plan_gallery';
import { Cookies, Privacy, Terms } from '../gdpr';
import URLS from './urls';
import { TRoute } from './types';

export const routesMap: TRoute[] = [
  {
    url: URLS.SIGNIN,
    component: SigninPage
  },
  {
    url: URLS.TERMS,
    component: Terms
  },
  {
    url: URLS.COOKIES,
    component: Cookies
  },
  {
    url: URLS.PRIVACY,
    component: Privacy
  },
  {
    url: URLS.FORGOT_PASSWORD,
    component: ForgotPassword
  },
  {
    url: URLS.INSIGHTS,
    component: InsightsPage,
    navBarElements: ['account', 'date', 'funnel'],
    requires: 'insights.access',
    isPrivate: true
  },
  {
    url: URLS.NOT_FOUND,
    component: ErrorPage,
    isPrivate: true
  },
  {
    url: URLS.OVERVIEW_ALL,
    component: Overview,
    navBarElements: ['account', 'date', 'funnel', 'lastUpdate'],
    requires: 'overview.access',
    isPrivate: true
  },
  {
    url: URLS.OVERVIEW_YOUTUBE,
    component: Overview,
    navBarElements: ['account', 'date', 'funnel', 'lastUpdate'],
    requires: 'overview.access',
    isPrivate: true
  },
  {
    url: URLS.MANAGEMENT_ALL,
    component: ManagementPage,
    navBarElements: ['account', 'date'],
    requires: 'manage.access',
    isPrivate: true
  },
  {
    url: URLS.MANAGEMENT_YOUTUBE,
    component: ManagementPage,
    navBarElements: ['account', 'date'],
    requires: 'manage.access',
    isPrivate: true
  },
  {
    url: URLS.USER_PROFILE,
    component: UserProfilePage,
    navBarElements: ['account'],
    isPrivate: true
  },
  {
    url: URLS.CREATIVE_ANALYTICS,
    component: CreativeVideoGallery,
    navBarElements: ['account'],
    requires: 'creative.access',
    isPrivate: true
  },
  {
    url: URLS.LAUNCHER,
    component: Launcher,
    navBarElements: ['account'],
    requires: 'creative.launch',
    isPrivate: true
  },
  {
    url: URLS.VIDEO,
    component: CreativeVideoDetails,
    navBarElements: ['account'],
    requires: 'creative.detail',
    isPrivate: true
  },
  {
    url: URLS.MEDIA_PLAN_GALLERY,
    component: MediaPlanGallery,
    navBarElements: ['account'],
    requires: 'media_plan.access',
    isPrivate: true
  },
  {
    url: URLS.MEDIA_PLAN,
    component: MediaPlanDetails,
    requires: 'media_plan.detail',
    navBarElements: ['account'],
    isPrivate: true
  },
  {
    url: URLS.NOTIFICATION_CENTER,
    component: NotificationCenter,
    requires: 'notifications.access',
    navBarElements: [],
    isPrivate: true
  }
];
