import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';
import apiVRR from '../../libs/axios/apis';
import moment from 'moment';
import { RootState } from '../store';
import endPoints from '../../services/endpoints';
import { notify } from '../app/appSlice';
import { TBoostModalValues } from '../../components/Budget/Boost/BoostModal/types';
import { TDateRange } from '../../micro_front_end/nav_bar/date_time_picker/types';
import { TViewerList } from 'components/Share/ShareModal/ViewerLine/types';
import { TSelectionMetric } from '../../micro_front_end/overview/global_metrics/deck_metrics/card_metric/types';

export const fetchViewers = createAsyncThunk(
  'account/fetchViewers',
  async ({ accountId }: any) => {
    const response = await apiVRR().get(
      endPoints.ACCOUNTS_ENDPOINTS.VIEWERS(accountId)
    );
    return response.data.data.list;
  }
);

export const revokeViewer = createAsyncThunk(
  'account/revokeViewer',
  async ({ email, accountId }: any, { dispatch }) => {
    const response = await apiVRR().post(
      endPoints.ACCOUNTS_ENDPOINTS.VIEWERS(accountId),
      {
        email
      }
    );

    dispatch(fetchViewers({ accountId }));
    return response;
  }
);

export const fetchMetrics = createAsyncThunk(
  'accounts/fetchMetrics',
  async (
    param: { accountId: string; period: TDateRange; p: string },
    { getState }
  ) => {
    return apiVRR().get(endPoints.OVERVIEW.GLOBAL_METRICS, {
      params: {
        start: moment(param.period.from).format('YYYY-MM-DD'),
        end: moment(param.period.to).format('YYYY-MM-DD'),
        id: param.accountId,
        label: (getState() as any).app.funnel,
        provider: param.p
      }
    });
  }
);

export const updateMetrics = createAsyncThunk(
  'accounts/updateMetrics',
  async (newMetrics: TSelectionMetric[]) => {
    return {
      data: {
        data: newMetrics
      }
    };
  }
);

export const fetchCharts = createAsyncThunk(
  'accounts/fetchCharts',
  async (
    param: {
      accountId: string;
      period: TDateRange;
      rangePeriod: string;
      p: string;
    },
    { getState }
  ) => {
    return apiVRR().get(endPoints.OVERVIEW.CHARTS, {
      params: {
        id: param.accountId,
        start: moment(param.period.from).format('YYYY-MM-DD'),
        end: moment(param.period.to).format('YYYY-MM-DD'),
        period: param.rangePeriod || 'weekly',
        label: (getState() as any).app.funnel,
        provider: param.p
      }
    });
  }
);

export const fetchBudgets = createAsyncThunk(
  'accounts/budgets',
  async (param: { accountId: string }) => {
    const endPoint = endPoints.OVERVIEW.BUDGETS.replace(
      '{account_id}',
      param.accountId
    );

    return apiVRR().get(endPoint);
  }
);

export const optimizeCampaigns = createAsyncThunk(
  'account/optimize_campaigns',
  async (_param: never, { getState, dispatch }) => {
    const account_id = (getState() as any).userPreferences.settings.vrr
      .current_account_id;
    const response = await apiVRR().post(
      endPoints.OVERVIEW.OPTIMIZATION.replace('{account_id}', account_id)
    );

    if (response.status >= 200 && response.status < 400) {
      const number_of_jobs = response.data.data.launched_jobs;
      const notif_type = number_of_jobs === 0 ? 'danger' : 'success';

      // Type success skips icon option
      dispatch(
        notify({
          type: notif_type,
          options: {
            type: notif_type,
            message: i18next.t(
              `components.optimize.notifications.number_of_job`,
              { count: number_of_jobs }
            ),
            icon: 'tim-icons icon-alert-circle-exc',
            place: 'tc'
          }
        })
      );
    }
    return response;
  }
);

export const fetchSplitTests = createAsyncThunk(
  'account/fetchSplitTests',
  async (param: { accountId: string }) => {
    return apiVRR().get(endPoints.OVERVIEW.SPLIT_TESTS(param.accountId));
  }
);

export const fetchSplitTestDetails = createAsyncThunk(
  'accounts/SplitTestDetails',
  async (param: { accountId: string; splitTestId: string }) => {
    return apiVRR().get(
      endPoints.OVERVIEW.SPLIT_TEST_DETAILS(param.accountId, param.splitTestId)
    );
  }
);

export const updateSplitTestBudgets = createAsyncThunk(
  'account/updateSplitTestBudgets',
  async (
    param: {
      accountId: string;
      splitTestId: string;
      updatedBudgetValues: TBoostModalValues;
    },
    { dispatch }
  ) => {
    const response = await apiVRR().put(
      endPoints.OVERVIEW.SPLIT_TEST_BUDGET_UPDATE(
        param.accountId,
        param.splitTestId
      ),
      {
        totalTop: param.updatedBudgetValues.totalTop,
        totalMiddle: param.updatedBudgetValues.totalMiddle,
        totalBottom: param.updatedBudgetValues.totalBottom,
        endDate: moment(param.updatedBudgetValues.endDate).format('YYYY-MM-DD')
      }
    );

    if (response.status >= 200 && response.status < 400) {
      dispatch(notify({ type: 'success' }));
    }

    return param.updatedBudgetValues;
  }
);

export const getSharerViewers = (state: RootState): TViewerList[] =>
  state.overview.viewers;

export const globalMetricsAll = (
  state: RootState
): TSelectionMetric[] | undefined => state.overview.globalMetrics;

export const getCharts = (state: RootState): any => state.overview.charts;

export const getBudgets = (state: RootState): any => state.overview.budgets;

export const getSplitTests = (state: RootState): any =>
  state.overview.split_tests;
