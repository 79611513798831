import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import { Col, Input, Button, Row, FormGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Email from '../../../Inputs/Email';
import { IEditForm } from './types';

const EditForm: React.FC<IEditForm> = ({ user, onSave }: IEditForm) => {
  const [email, setEmail] = useState<string>(user.email);
  const [isValid, setIsValid] = useState(!!user.email);
  const [username, setUsername] = useState<string>(user.username);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const { t } = useTranslation();

  const hasInfosChanged = () => {
    return email !== user.email || username !== user.username;
  };

  const areFieldBlank = () => {
    return email === '' || username === '';
  };

  useEffect(() => {
    const disableFlag = !hasInfosChanged() || areFieldBlank() || !isValid;
    setIsSaveDisabled(disableFlag);
  }, [email, username]);

  const updateUser = () => {
    if (hasInfosChanged()) {
      onSave({
        email,
        username
      });
    }
  };

  const onKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      updateUser();
    }
  };

  return (
    <div className="content">
      <Row className="user-general-infos-row">
        <Col xs="6">
          <p>{t('components.general_informations.email_address.title')}</p>
        </Col>
        <Col xs="6">
          <FormGroup>
            <Email
              placeholder={t(
                'components.general_informations.email_address.placeholder'
              )}
              value={email}
              onChangeAction={(
                e: BaseSyntheticEvent,
                isValidEmail: boolean
              ) => {
                setEmail(e.currentTarget.value.trim());
                setIsValid(isValidEmail);
              }}
              disabled
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="user-general-infos-row">
        <Col xs="6">
          <p>{t('components.general_informations.username.title')}</p>
        </Col>
        <Col xs="6">
          <FormGroup>
            <Input
              name="userName"
              placeholder={t(
                'components.general_informations.username.placeholder'
              )}
              value={username}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setUsername(e.currentTarget.value.trim())
              }
              inputprops={{ onKeyPress: onKeyUp }}
            />
          </FormGroup>
        </Col>
      </Row>

      <div className="user-general-infos-button">
        <Button
          id="cy-user-save-username"
          name="submitUserInfo"
          onClick={updateUser}
          disabled={isSaveDisabled}
        >
          {t('components.general_informations.button.save')}
        </Button>
      </div>
    </div>
  );
};

export default EditForm;
