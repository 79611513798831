import React from 'react';
import useAuthorize from './hooks/useAuthorize';
import { TPrivateSectionProps } from './types';

export const PrivateSection: React.FC<TPrivateSectionProps> = ({
  requires,
  authorizationStrategy,
  children
}: TPrivateSectionProps) => {
  const isAuthorized = useAuthorize(requires, authorizationStrategy);

  return children({ isAuthorized });
};
