import React from 'react';
import { useTranslation } from 'react-i18next';
import LABEL_DATE_SELECTOR from '../RangeDate.constant';
import services from './services';
import { ISubMenu } from './types';
import './SubMenu.scss';
import { toLowerKebabCase } from '../../../../services/formatters/formatString';

const SubMenu: React.FC<ISubMenu> = ({
  setStartDate,
  setEndDate,
  setLabel
}: ISubMenu) => {
  const { t } = useTranslation();

  const setDateWithLabel = (labelSelector: string) => {
    const { startDate, endDate } = services.getDates(
      LABEL_DATE_SELECTOR[labelSelector as keyof typeof LABEL_DATE_SELECTOR]
    );

    setStartDate(startDate);
    setEndDate(endDate);
    setLabel(labelSelector);
  };

  return (
    <div className="controls">
      <ul>
        {Object.keys(LABEL_DATE_SELECTOR)
          .filter((key) => key.toString() !== 'DEFAULT')
          .map((labelDateSelector: string) => {
            return (
              <li
                key={
                  LABEL_DATE_SELECTOR[
                    labelDateSelector as keyof typeof LABEL_DATE_SELECTOR
                  ]
                }
              >
                <button
                  id={toLowerKebabCase(
                    `cy-datepicker-range-${labelDateSelector}`
                  )}
                  type="submit"
                  onClick={() => {
                    setDateWithLabel(labelDateSelector);
                  }}
                >
                  {t(
                    LABEL_DATE_SELECTOR[
                      labelDateSelector as keyof typeof LABEL_DATE_SELECTOR
                    ]
                  )}
                </button>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default SubMenu;
