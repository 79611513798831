import React, { SyntheticEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Button, Card, UncontrolledTooltip as Tooltip } from 'reactstrap';
import { Send } from 'react-bootstrap-icons';
import { PrivateComponent } from '../../../micro_front_end/permissions';
import { capitalize } from '../../../micro_front_end/services/capitalize';
import { INSIGHTS_CARD_STATUS } from '../../../services/constants/status';
import formatDataService from '../../../micro_front_end/services/formatters/formatData';
import { getConvActTranslationKey } from '../../../services/services';
import { getFeatureByPath } from '../../../micro_front_end/routing/services';
import { getFeatures } from '../../../redux/app/appActions';
import { ReactComponent as EyeIconSVG } from '../../../assets/vrr/images/eye_icon.svg';
import { ReactComponent as EyeIconCrossedSVG } from '../../../assets/vrr/images/eye_icon_crossed.svg';
import { ReactComponent as SendIconCrossed } from '../../../assets/vrr/images/send_icon_crossed.svg';
import { TDifferenceCard, TDifferenceCardActions } from './types';
import { TTag } from '../PublishModal/types';
import './DifferenceCard.scss';

const DifferenceCard: React.FC<TDifferenceCard & TDifferenceCardActions> = ({
  cardId,
  status,
  cardColor,
  symbol,
  value,
  name,
  tag,
  onClickButton,
  onClickPublish
}) => {
  const { t } = useTranslation();
  const features = useSelector(getFeatures);
  const formattedValue = formatDataService.dataFormatter(
    value,
    symbol,
    t('local.separators.thousands'),
    t('local.separators.decimal')
  ) as number;
  const onClickButtonAction = (e: SyntheticEvent) => {
    e?.stopPropagation();
    if (onClickButton) onClickButton(cardId, status);
  };

  const onClickPublishAction = (e: SyntheticEvent) => {
    e?.stopPropagation();
    if (onClickPublish) onClickPublish(cardId, status);
  };

  const secondsToMinutesAndSeconds = (
    seconds: string | undefined
  ): string | undefined => {
    if (seconds === undefined || seconds === '') {
      return;
    }

    return moment.utc(parseInt(seconds) * 1000).format('mm:ss');
  };

  const getTagTranslation = (location: string, target: TTag): string => {
    let tagTranslation = `components.tag_attribution.tag_name.${target?.name}`;

    if (target?.name.startsWith('duration')) {
      tagTranslation =
        location == 'description'
          ? `components.insights.tags.${target?.name}`
          : `components.insights.tags.${target?.name}_tag`;
    }

    if (target?.name === 'all')
      tagTranslation = 'containers.insights.filterContainer.dropdown.all';

    return tagTranslation;
  };

  const tag_badge_text = t(getTagTranslation('tag_badge', tag), {
    average_duration: secondsToMinutesAndSeconds(tag.averageVideosDuration)
  });

  const tooltipRender = (target: string, content: string) => (
    <Tooltip
      id={`tooltipVrr-${target}`}
      placement="top"
      target={target}
      hideArrow
    >
      {content}
    </Tooltip>
  );

  return (
    <Card className="difference-card-container">
      <div className={`difference-card-header ${cardColor}`}>
        <div className="header-content">
          <p
            className={`header-value ${(value.value as number) > 0 ? 'up' : 'down'}`}
          >
            {formattedValue.toString().replace(/-/g, '')}
          </p>
          <p>
            {t(`components.insights.differenceCard.rate`, {
              name: capitalize(t(getConvActTranslationKey(name)))
            })}
          </p>
        </div>
      </div>
      <div className="difference-card-middle">
        <div className="difference-card-middle-left">
          <PrivateComponent
            authorizationStrategy={() =>
              getFeatureByPath(features, 'insights.edit')
            }
          >
            <Button
              color="default"
              className={`middle-hide-button ${
                status === INSIGHTS_CARD_STATUS.HIDDEN && 'hidden'
              }`}
              onClick={onClickButtonAction}
            >
              {status === INSIGHTS_CARD_STATUS.HIDDEN ? (
                <EyeIconCrossedSVG className="eye-icon-crossed" />
              ) : (
                <EyeIconSVG className="eye-icon" />
              )}
            </Button>
            <Button
              color="default"
              className="middle-publish-button"
              onClick={onClickPublishAction}
            >
              {status === INSIGHTS_CARD_STATUS.PUBLISHED ? (
                <SendIconCrossed className="send-icon-crossed" />
              ) : (
                <Send className="send-icon" />
              )}
            </Button>
          </PrivateComponent>
        </div>
        <Button
          className={`btn-round middle-tags ${tag?.color}`}
          id={`tag-badge-${cardId}`}
        >
          {tag_badge_text}
        </Button>
        {tooltipRender(`tag-badge-${cardId}`, tag_badge_text)}
      </div>
      <div className="difference-card-footer">
        <Trans>
          {value.value == 0
            ? t('components.insights.differenceCard.not_enough_data', {
                name: t(getConvActTranslationKey(name)),
                tagName: t(getTagTranslation('description', tag), {
                  average_duration: secondsToMinutesAndSeconds(
                    tag.averageVideosDuration
                  )
                })
              })
            : t('components.insights.differenceCard.information', {
                tagName: t(getTagTranslation('description', tag), {
                  average_duration: secondsToMinutesAndSeconds(
                    tag.averageVideosDuration
                  )
                }),
                value: formattedValue,
                name: t(getConvActTranslationKey(name)),
                indicator:
                  (value.value as number) > 0
                    ? t('components.insights.differenceCard.indicator.higher')
                    : t('components.insights.differenceCard.indicator.lower')
              })}
        </Trans>
      </div>
    </Card>
  );
};

export default DifferenceCard;
