import React, { useEffect, useState } from 'react';
import { TVrrButtonGroup } from './types';
import { Button, ButtonGroup } from 'reactstrap';
import './VrrButtonGroup.scss';

const VrrButtonGroup: React.FC<TVrrButtonGroup> = ({
  options,
  defaultId,
  onClickAction
}: TVrrButtonGroup) => {
  const [selected, setSelected] = useState<string>(defaultId ?? '');

  useEffect(() => {
    if (defaultId) {
      setSelected(defaultId);
    }
  }, [defaultId]);

  const onClickButton = (id: string) => {
    if (onClickAction) onClickAction(id);
    setSelected(id);
  };

  return (
    <>
      <ButtonGroup className="vrr-button-group">
        {options.map((button) => {
          return (
            <Button
              key={`vrr-button-group-element-${button.id}`}
              className={button.id === selected ? `active` : `inactive`}
              onClick={() => onClickButton(button.id)}
              {...button.buttonProps}
            >
              <>{button.label}</>
            </Button>
          );
        })}
      </ButtonGroup>
    </>
  );
};

export default VrrButtonGroup;
