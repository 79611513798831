import {
  FOOTER_COLOR,
  TAssetCTA,
  TAssetRedirection,
  TAssetsCardType
} from './types';

const footerColor = (type: TAssetsCardType): string => {
  return FOOTER_COLOR[type] ? `footer-${FOOTER_COLOR[type]}` : 'footer-purple';
};

const footerRender = (assets: Array<TAssetRedirection | TAssetCTA>): string => {
  return assets?.length ? 'interior' : 'outline';
};

const assetsCardsServices = {
  footerColor,
  footerRender
};

export default assetsCardsServices;
