import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Container, Col } from 'reactstrap';
import URLS from '../../micro_front_end/routing/urls';
import ForgotPassword from '../../components/ForgotPassword';
import {
  forgotPassword,
  getForgotPasswordState
} from '../../redux/app/appActions';
import { connect } from 'react-redux';
import NOTIFICATIONS_TYPES from '../../services/constants/notificationTypes';
import './ForgotPasswordContainer.scss';
import { TForgotPasswordC } from './types';

const ForgotPasswordC: React.FC<TForgotPasswordC> = ({
  forgotPasswordAction,
  forgotPasswordState
}) => {
  const [redirectSignin, setRedirectSignin] = React.useState(false);

  useEffect(() => {
    if (forgotPasswordState === NOTIFICATIONS_TYPES.SUCCESS) {
      setRedirectSignin(true);
    }
  }, [forgotPasswordState]);
  return redirectSignin ? (
    <Navigate to={URLS.SIGNIN} />
  ) : (
    <div className="content forgot-password-container">
      <Container>
        <Col className="ml-auto mr-auto forgot-password" lg="4" md="6">
          <ForgotPassword onClick={forgotPasswordAction} />
        </Col>
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  forgotPasswordAction: (email: string) => dispatch(forgotPassword({ email }))
});

const mapStateToProps = (state: any) => ({
  forgotPasswordState: getForgotPasswordState(state)
});

const ForgotPasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordC);

export default ForgotPasswordContainer;
