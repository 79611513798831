import connectedAccountEN from './connected_account/connectedAccountEN.json';
import connectedAccountFR from './connected_account/connectedAccountFR.json';

const CONNECTED_ACCOUNT_EN = {
  ...connectedAccountEN
};
const CONNECTED_ACCOUNT_FR = {
  ...connectedAccountFR
};

export { CONNECTED_ACCOUNT_EN, CONNECTED_ACCOUNT_FR };
