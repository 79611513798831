import React from 'react';
import { Container, Col } from 'reactstrap';
import SignIn from '../../components/SignIn';
import './SigninPage.scss';

const SigninPage: React.FC = () => {
  const buildId = process.env.REACT_APP_BUILD_ID;

  return (
    <>
      <div className="content-signin-card">
        <Container>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <SignIn />
          </Col>
        </Container>
      </div>

      {buildId && (
        <div className="build-footer">
          <a
            href={`https://console.cloud.google.com/cloud-build/builds;region=global/${buildId}?project=voltaic-bridge-361808`}
            target="_blank"
          >
            Build {buildId}
          </a>
        </div>
      )}
    </>
  );
};

export default SigninPage;
