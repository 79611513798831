import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CardList from '../../../components/Insights/CardList';
import VrrCircleLoader from '../../../micro_front_end/shared_components/vrr_circle_loader';
import { INSIGHTS_CARD_STATUS } from '../../../services/constants/status';
import useSettings from '../../../libs/settings/useSettings';
import {
  checkPreferences,
  prepareFilterInsights,
  prepareInsights
} from './services';
import { getCurrentLabel, getFeatures } from '../../../redux/app/appActions';
import {
  getAccountId,
  getInsightsCardsByCurrentAccount,
  getInsightsFiltersByCurrentAccount,
  getPeriod
} from '../../../redux/userPreferences/actions/userPreferencesActions';
import {
  getInsights,
  fetchInsights,
  getFilters
} from '../../../redux/insights/insightsActions';
import { TCardInsightsPreference } from '../FilterContainer/types';
import { TCardsContainer } from '../CardsContainer/types';
import { TDateRange } from '../../../micro_front_end/nav_bar/date_time_picker/types';
import './CardListContainer.scss';

const CardListContainer: React.FC = () => {
  const { insights } = useSettings();
  const dispatch: any = useDispatch();
  const filters = useSelector(getFilters);
  const insightsList = useSelector(getInsights);
  const period = useSelector(getPeriod);
  const accountID = useSelector(getAccountId);
  const insightsFiltersPreferences = useSelector(
    getInsightsFiltersByCurrentAccount
  );
  const cardPreferences = useSelector(getInsightsCardsByCurrentAccount);
  const funnel = useSelector(getCurrentLabel);
  const features = useSelector(getFeatures);
  const [currentPeriod, setCurrentPeriod] = useState<TDateRange | null>();
  const [currentAccount, setCurrentAccount] = useState<string | null>(null);
  const [list, setList] = useState<TCardsContainer[]>([]);
  const [insightsListFiltered, setInsightsListFiltered] = useState<
    TCardsContainer[]
  >([]);

  useEffect(() => {
    if (
      period?.from !== currentPeriod?.from ||
      period?.to !== currentPeriod?.to ||
      accountID !== currentAccount
    ) {
      setCurrentAccount(accountID);
      setCurrentPeriod(period);
    }
  }, [period, accountID]);

  useEffect(() => {
    if (
      currentAccount &&
      currentAccount !== '' &&
      currentPeriod?.from &&
      currentPeriod?.to
    ) {
      dispatch(fetchInsights());
    }
  }, [currentPeriod, currentAccount, funnel]);

  useEffect(() => {
    if (insightsList) {
      setList(prepareInsights(insightsList, cardPreferences?.status ?? []));
    }
  }, [insightsList, cardPreferences]);

  useEffect(() => {
    setInsightsListFiltered(
      prepareFilterInsights(
        list,
        checkPreferences(insightsFiltersPreferences, features),
        cardPreferences?.status ?? []
      )
    );
  }, [list, insightsFiltersPreferences, features]);

  const updateCardsStatus = (cardId: string, newStatus: string) => {
    let status = cardPreferences?.status ?? [];
    status = status.filter((stat) => stat.id !== cardId);

    if (newStatus !== INSIGHTS_CARD_STATUS.UNPUBLISHED) {
      status.push({
        id: cardId,
        status: newStatus as TCardInsightsPreference['status']
      });
    }

    return status;
  };

  const changeStatus = (cardId: string, newStatus: string) => {
    insights('cards', { status: updateCardsStatus(cardId, newStatus) });
  };

  return (
    <>
      <div className="spacer" />
      <VrrCircleLoader isEnable={insightsList === null}>
        {filters && (
          <CardList
            list={insightsListFiltered}
            changeStatusAction={changeStatus}
            filters={filters}
          />
        )}
      </VrrCircleLoader>
    </>
  );
};

export default CardListContainer;
