import { IListState } from 'micro_front_end/shared_components/vrr_dropdown/types';
import { TMapFunnels } from './types';
import i18next from 'i18next';

const funnelSearchList: TMapFunnels = {
  all: {
    label: i18next.t(
      'media_plan_edit.media_plan_targets.target_modal.funnels.audiences.select_all'
    ),
    value: 'all'
  },
  top: {
    label: i18next.t(
      'media_plan_edit.media_plan_targets.target_modal.funnels.top'
    ),
    value: 'top'
  },
  middle: {
    label: i18next.t(
      'media_plan_edit.media_plan_targets.target_modal.funnels.middle'
    ),
    value: 'middle'
  }
};

export const formatFunnelSearchList = (): IListState[] => {
  return Object.values(funnelSearchList).map((value) => {
    return value;
  });
};
