import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import LauncherFooter from '../launcher_footer';
import VideoCard from './video_card';
import SectionTitle from '../../../creative_analytics/creative_video_details/section_title';
import { prepareVideos, validateSubmit } from './services';
import { editLauncher } from '../../../../redux/launcher/launcherActions';
import { TVideos, TVideoSelection } from './types';
import './VideoSelection.scss';

const VideoSelection: React.FC<TVideoSelection> = ({
  videos,
  onChangeAction
}) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const [devices, setDevices] = useState<TVideos[] | undefined>();

  const onChange = (videoId: number, device: string, isActive: boolean) => {
    const newVideos = prepareVideos(videoId, device, isActive, devices);
    setDevices(newVideos);
  };

  const onNext = () => {
    if (onChangeAction) onChangeAction(1);
    if (devices) {
      dispatch(editLauncher({ videos: devices }));
    }
  };

  const onCancel = () => {
    if (onChangeAction) onChangeAction(undefined);
  };

  return (
    <>
      <div className="video-selection">
        <SectionTitle
          title={t('launcher.video_selection.title')}
          iconClassName="tim-icons icon-atom"
        />
        <div className="video-selection-grid">
          {videos?.map((video, index) => (
            <VideoCard
              key={`video-line-${index + 1}`}
              vrr_id={video.vrr_id}
              status={video.status}
              thumbnail={video.thumbnail}
              name={video.name}
              devices={
                devices?.find((device) => device.vrr_id === video.vrr_id)
                  ?.devices
              }
              score={video.score}
              onChangeAction={onChange}
            />
          ))}
        </div>
      </div>
      <LauncherFooter
        currentStep={0}
        onCancelAction={onCancel}
        onSubmitAction={onNext}
        submitDisabled={!validateSubmit(videos, devices)}
      />
    </>
  );
};

export default VideoSelection;
