import React from 'react';
import CreativeFolder from './creative_folder';
import CreativeCard from './creative_card';
import { TCreativeList } from './types';
import './CreativeList.scss';
import { useTranslation } from 'react-i18next';

const CreativeList: React.FC<TCreativeList> = ({
  videos,
  folders,
  onSelectVideoAction,
  onSelectFolderAction,
  onDropAction,
  onGoFolderAction
}) => {
  const { t } = useTranslation();

  const onSelectVideo = (videoId: number, isCheck: boolean) => {
    if (onSelectVideoAction) onSelectVideoAction(videoId, isCheck);
  };

  const onSelectFolder = (folderId: number, isCheck: boolean) => {
    if (onSelectFolderAction) onSelectFolderAction(folderId, isCheck);
  };

  const onDrop = (videoId: number, folderId: number) => {
    if (onDropAction) onDropAction(videoId, folderId);
  };

  return (
    <>
      {folders?.length === 0 && videos?.length === 0 && (
        <h3 className="no-data-found" data-cy-id="creative-gallery-no-data">
          {t('creative_gallery.creative_list.no_data')}
        </h3>
      )}
      <div className="creative-list">
        {folders?.map((folder) => {
          const { id, name, childrenCount, updatedDate } = folder;
          return (
            <CreativeFolder
              id={id}
              key={id}
              updatedDate={updatedDate}
              title={name}
              itemCount={childrenCount}
              onCheckAction={onSelectFolder}
              onDropAction={onDrop}
              onGoFolderAction={onGoFolderAction}
              dataCy="creative-gallery-folder"
            />
          );
        })}
        {videos?.map((video) => {
          const { id, name, duration, thumbnail, urlVideo, updatedDate } =
            video;
          return (
            <CreativeCard
              key={id}
              url={urlVideo}
              title={name}
              {...{ id, thumbnail, duration, updatedDate }}
              onChangeAction={onSelectVideo}
            />
          );
        })}
      </div>
    </>
  );
};

export default CreativeList;
