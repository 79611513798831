import React from 'react';
import { DropdownItem, NavLink } from 'reactstrap';
import { TAvatarDropdownItem } from './types';

export const AvatarDropdownItem: React.FC<TAvatarDropdownItem> = ({
  itemId,
  classNameItem,
  onClick,
  children
}: TAvatarDropdownItem) => {
  return (
    <NavLink tag="li">
      <DropdownItem id={itemId} onClick={onClick} className={classNameItem}>
        {children}
      </DropdownItem>
    </NavLink>
  );
};

export default AvatarDropdownItem;
