import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ModalBody } from 'reactstrap';
import TargetList from '../../target_list';
import TargetInput from './target_input';
import FunnelSearchList from './funnel_search_list';
import {
  BOTTOM_INPUTS,
  DATA_INPUTS,
  prepareUpdateCustomTargets,
  prepareRemoveTag,
  prepareTags,
  prepareCustomTargets
} from './services';
import { TVrrTag } from '../../../../../../../shared_components/vrr_tag/types';
import { TCustomTargets, TMediaPlanDetails } from '../../../../../types';
import { TFunnel } from './funnel_search_list/audience_list/types';
import { TCustomFunnel, TInput, TTargetInput } from './target_input/types';
import { TTargetBodyModal } from './types';
import { TEditTarget } from '../types';
import './TargetBodyModal.scss';

export const TargetBodyModal: React.FC<TTargetBodyModal> = ({
  selections,
  customSelections,
  onChangeAction
}) => {
  const [targets, setTargets] = useState<
    TMediaPlanDetails['targets'] | undefined
  >([]);
  const [topTags, setTopTags] = useState<TVrrTag[]>([]);
  const [middleTags, setMiddleTags] = useState<TVrrTag[]>([]);
  const [bottomInputs, setBottomInputs] =
    useState<TTargetInput['inputs']>(BOTTOM_INPUTS);
  const [dataInputs, setDataInputs] =
    useState<TTargetInput['inputs']>(DATA_INPUTS);

  const updateTargets = (newSelections?: TMediaPlanDetails['targets']) => {
    const newTopTags = prepareTags('top', newSelections);
    const newMiddleTags = prepareTags('middle', newSelections);
    setTopTags(newTopTags);
    setMiddleTags(newMiddleTags);
    setTargets(newSelections);
  };

  const updateCustomTargets = (newCustomSelections?: TCustomTargets) => {
    const newBottomInputs = prepareCustomTargets(
      bottomInputs,
      newCustomSelections
    );
    const newDataInputs = prepareCustomTargets(dataInputs, newCustomSelections);
    setBottomInputs(newBottomInputs);
    setDataInputs(newDataInputs);
  };

  useEffect(() => {
    updateCustomTargets(customSelections);
  }, [customSelections]);

  useEffect(() => {
    updateTargets(selections);
  }, [selections]);

  const onChangeSelections = (newTargets: TMediaPlanDetails['targets']) => {
    updateTargets(newTargets);
    if (onChangeAction)
      onChangeAction({
        targets: newTargets?.map((target) => target.id)
      } as TEditTarget);
  };

  const onChangeCustomTargets = (
    inputs: TTargetInput['inputs'],
    callback: Dispatch<SetStateAction<TTargetInput['inputs']>>,
    id?: TInput['id'],
    value?: number
  ) => {
    const newCustomTargets = prepareUpdateCustomTargets(inputs, id, value);
    callback(newCustomTargets);
    if (onChangeAction)
      onChangeAction({ [id as keyof TCustomTargets]: value } as TCustomTargets);
  };

  const onRemoveTags = (funnel: TFunnel, id?: string) => {
    const newTargets = prepareRemoveTag(funnel, targets, id);
    onChangeSelections(newTargets);
  };

  const onRemoveCustomTargets = (
    funnel: TCustomFunnel,
    callback: Dispatch<SetStateAction<TTargetInput['inputs']>>
  ) => {
    const inputs = funnel === 'bottom' ? bottomInputs : dataInputs;
    const newCustomTargets = prepareCustomTargets(inputs, undefined);
    callback(newCustomTargets);
    let newInputs = {};
    inputs.forEach((input) => {
      newInputs = {
        ...newInputs,
        [input.id as keyof TCustomTargets]: 0
      };
    });
    if (onChangeAction) {
      onChangeAction(newInputs);
    }
  };

  return (
    <ModalBody
      className="target-body-modal"
      data-cy-id="media-plan-detail-edit-targets"
    >
      <FunnelSearchList
        selections={targets}
        onChangeAction={onChangeSelections}
      />
      <div className="target-list-side">
        <TargetList
          title="Top funnel"
          tags={topTags}
          onClickAction={(id?: string) => onRemoveTags('top', id)}
          onRemoveAllAction={() => onRemoveTags('top')}
        />
        <TargetList
          title="Middle funnel"
          tags={middleTags}
          onClickAction={(id?: string) => onRemoveTags('middle', id)}
          onRemoveAllAction={() => onRemoveTags('middle')}
        />
        <TargetInput
          title="Bottom funnel"
          inputs={bottomInputs}
          onChangeAction={(id?: string, value?: number) => {
            onChangeCustomTargets(bottomInputs, setBottomInputs, id, value);
          }}
          onRemoveAllAction={() =>
            onRemoveCustomTargets('bottom', setBottomInputs)
          }
        />
      </div>
    </ModalBody>
  );
};

export default TargetBodyModal;
