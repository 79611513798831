export enum StateLoadingData {
  NONE,
  INITIALISATION,
  LOADING_DATA,
  DATA_READY,
  DATA_ERROR
}

export default StateLoadingData;

export const COST_KPIS = ['spent', 'cpm', 'cpv', 'cpc'];
export const RATIO_KPIS = [
  'vtr',
  'ctr_impression',
  'ctr_vue',
  'performance contribution'
];
