import React, { useState } from 'react';
import { TVrrTooltip } from './types';
import { Tooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const VrrTooltip: React.FC<TVrrTooltip> = ({
  translationKey,
  target,
  children,
  placement,
  ...rest
}: TVrrTooltip) => {
  const { t } = useTranslation();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const targetIsFilled: boolean =
    typeof target === 'string' && target.length > 0;

  return targetIsFilled || typeof target !== 'string' ? (
    <Tooltip
      target={target}
      placement={placement}
      isOpen={tooltipOpen}
      toggle={() => {
        setTooltipOpen(!tooltipOpen);
      }}
      hideArrow
      {...rest}
    >
      {translationKey ? t(translationKey) : children}
    </Tooltip>
  ) : null;
};

export default VrrTooltip;
