import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import VrrRoutes from './micro_front_end/routing';
import NotificationContainer from './containers/Notification/NotificationContainer';
import { PermissionsProvider } from './micro_front_end/permissions';
import { prepareCurrency } from './services/services';
import { getFeatureByPath } from './micro_front_end/routing/services';
import { fetchUserPreferences } from './redux/userPreferences/actions/userPreferencesAPIs';
import {
  fetchUserInformation,
  getCurrencyCode,
  getCurrentSession,
  getFeatures,
  getIsLoading,
  getUserId
} from './redux/app/appActions';
import {
  fetchUserProfile,
  getUserProfile
} from './redux/userProfile/userProfileActions';
import './App.scss';

export const App: React.FC = () => {
  const dispatch: any = useDispatch();
  const currentSession = useSelector(getCurrentSession);
  const currencyCode = useSelector(getCurrencyCode);
  const features = useSelector(getFeatures);
  const isLoading = useSelector(getIsLoading);
  const currentUserProfile = useSelector(getUserProfile);
  const userId = useSelector(getUserId);

  useEffect(() => {
    if (!currentUserProfile.username?.length && userId) {
      dispatch(fetchUserProfile());
    }
  }, [currentUserProfile, userId]);

  useEffect(() => {
    if (currentSession && currentSession !== '') {
      if (!isLoading && !features) {
        dispatch(fetchUserInformation());
      }
      dispatch(fetchUserPreferences());
    }
  }, [currentSession]);

  useEffect(() => {
    if (currencyCode) {
      prepareCurrency(currencyCode);
    }
  }, [currencyCode]);

  return (
    <PermissionsProvider
      permissions={features}
      authorizationStrategy={getFeatureByPath}
    >
      <BrowserRouter>
        <VrrRoutes />
        <NotificationContainer />
      </BrowserRouter>
    </PermissionsProvider>
  );
};

export default App;
