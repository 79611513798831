import { TAudienceData } from './audience/types';
import { TCategoryData } from './audience/audience_category/types';
import { TCategoryLine } from './audience/audience_category/audience_category_line/types';

const filterAudience = (
  audience: TAudienceData,
  filters: TCategoryLine[]
): TAudienceData => {
  const clonedAudience: TAudienceData = { ...audience };
  const clonedAudienceCategories: TCategoryData[] = [
    ...clonedAudience.audienceCategories
  ];

  const filteredBySubCategories: TCategoryData[] = clonedAudienceCategories.map(
    (category: TCategoryData) => {
      const sub: TCategoryLine[] = category.subCategories.filter(
        (subCategory: TCategoryLine) => {
          return filters?.some(
            (filter) =>
              filter.name === subCategory.name &&
              filter.criteriaId === subCategory.criteriaId
          );
        }
      );
      return { parentCategory: category.parentCategory, subCategories: sub };
    }
  );

  const filteredAudiences: TCategoryData[] = filteredBySubCategories.filter(
    (category: TCategoryData) => {
      const isParentCategoryPresent = filters?.some(
        (fil) =>
          fil.name === category.parentCategory.name &&
          fil.criteriaId === category.parentCategory.criteriaId
      );
      return isParentCategoryPresent || category.subCategories.length;
    }
  );

  return {
    ...clonedAudience,
    audienceCategories: filteredAudiences
  };
};

export const filterAudiences = (
  audiences: TAudienceData[],
  filters: TCategoryLine[]
): TAudienceData[] => {
  if (filters.length) {
    return audiences.map((audience: TAudienceData) => {
      return filterAudience(audience, filters);
    });
  } else {
    return audiences;
  }
};
