import { createSlice } from '@reduxjs/toolkit';
import { logout } from '../app/appSlice';
import {
  fetchUserPreferences,
  updateUserPreferences
} from './actions/userPreferencesAPIs';
import { TInitialState } from './types';

export const initialState: TInitialState = {
  settings: {
    vrr: {
      language: 'en',
      period: {
        from: undefined,
        to: undefined
      },
      current_account_id: null,
      custom_labels_list: null
    },
    overview: {
      global_metrics: [],
      global_insights: []
    },
    insights: {
      filters: [],
      cards: []
    }
  }
};

export const userPreferencesSlice = createSlice({
  name: 'userPreferences',
  initialState: { ...initialState },
  reducers: {
    resetCurrentAccountId: (state) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          vrr: {
            ...state.settings.vrr,
            current_account_id: null
          }
        }
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(updateUserPreferences.fulfilled, (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...payload.settings
        }
      };
    });
    builder.addCase(fetchUserPreferences.fulfilled, (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...payload.settings
        }
      };
    });
    builder.addCase(logout, () => {
      return {
        ...initialState
      };
    });
  }
});

export const { resetCurrentAccountId } = userPreferencesSlice.actions;

export default userPreferencesSlice.reducer;
