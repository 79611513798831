import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import GlobalMetrics from './global_metrics';
import Chart from './chart';
import MetricPins from './metrics/metric_pins';
import ShareContainer from '../../containers/Share';
import OptimizeContainer from '../../containers/Optimize';
import BoostModalContainer from '../../containers/Budget/Boost/BoostModalContainer';
import RightBar from './right_bar';
import Metrics from './metrics/metrics/metrics';
import { PrivateComponent } from '../permissions';
import { getFeatureByPath } from '../routing/services';
import { getFeatures } from '../../redux/app/appActions';
import './Overview.scss';

const Overview: React.FC = () => {
  const { t } = useTranslation();
  const features = useSelector(getFeatures);

  return (
    <main className="overview-main-section">
      <section
        className="global-metrics-part"
        data-cy-id="overview-global-chart"
      >
        <h4>{t('overview.page.titles.global_metrics').toUpperCase()}</h4>
        <GlobalMetrics />
        <Chart />
      </section>
      <section className="global-insights-part">
        <h4>{t('overview.page.titles.global_insights').toUpperCase()}</h4>
        <MetricPins />
        <Metrics />
      </section>
      <aside className="right-bar-position">
        <RightBar>
          <PrivateComponent
            authorizationStrategy={() =>
              getFeatureByPath(features, 'overview.boost')
            }
          >
            <BoostModalContainer />
          </PrivateComponent>
          <PrivateComponent
            authorizationStrategy={() =>
              getFeatureByPath(features, 'overview.share')
            }
          >
            <ShareContainer />
          </PrivateComponent>
          <PrivateComponent
            authorizationStrategy={() =>
              getFeatureByPath(features, 'overview.optimize')
            }
          >
            <OptimizeContainer />
          </PrivateComponent>
        </RightBar>
      </aside>
    </main>
  );
};

export default Overview;
