import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VrrFilter } from '../../../shared_components';
import { getFolderVideoIds } from '../services';
import CreativeFilterActions from './creative_filter_actions/CreativeFilterActions';
import { TVrrSortByValue } from '../../../shared_components/vrr_sort_by/types';
import { TCreativeVideoFolder } from '../creative_modal/types';
import { TCreativeFilter } from './types';
import './CreativeFilter.scss';

const CreativeFilter: React.FC<TCreativeFilter> = ({
  videoSelections,
  folderSelections,
  folderList,
  onSortAction,
  onSearchAction,
  onNewFolderAction,
  onArchiveAction,
  onAddTagAction,
  onLaunchAction
}) => {
  const { t } = useTranslation();
  const [enableNewFolder, setEnableNewFolder] = useState(true);
  const [enableAddTag, setEnableAddTag] = useState(false);
  const [enableArchive, setEnableArchive] = useState(false);
  const [enableLaunch, setEnableLaunch] = useState(false);

  const hasVideos = () => {
    const videoIds: number[] = getFolderVideoIds(
      videoSelections,
      folderSelections,
      folderList
    );
    return !!videoIds.length;
  };

  useEffect(() => {
    setEnableNewFolder(!folderSelections.length);
    setEnableAddTag(hasVideos());
    setEnableLaunch(hasVideos());
    setEnableArchive(!!folderSelections.length || !!videoSelections.length);
  }, [videoSelections, folderSelections]);

  const sortOptions: TVrrSortByValue[] = [
    {
      value: 'name',
      label: t('creative_gallery.creative_filter.sort.options.name')
    },
    {
      value: 'updatedDate',
      label: t('creative_gallery.creative_filter.sort.options.date')
    }
  ];

  const onNewFolder = (newFolder: TCreativeVideoFolder) => {
    if (onNewFolderAction) onNewFolderAction(newFolder);
  };

  const onArchive = () => {
    if (onArchiveAction) onArchiveAction();
  };

  const onAddTag = () => {
    if (onAddTagAction) onAddTagAction();
  };

  return (
    <VrrFilter
      classNames="creative-filter"
      sortProps={{
        sortOptions,
        onSortAction
      }}
      searchProps={{
        onSearchAction
      }}
      otherActions={
        <CreativeFilterActions
          enableNewFolder={enableNewFolder}
          enableAddTag={enableAddTag}
          enableArchive={enableArchive}
          enableLaunch={enableLaunch}
          onNewFolderAction={onNewFolder}
          onArchiveAction={onArchive}
          onAddTagAction={onAddTag}
          onLaunchAction={onLaunchAction}
        />
      }
    />
  );
};

export default CreativeFilter;
