import React from 'react';
import { TMediaPlanCardFooter } from './types';
import './styles/MediaPlanCardFooter.scss';

const MediaPlanCardFooter: React.FC<TMediaPlanCardFooter> = ({
  genders,
  ages,
  zones
}: TMediaPlanCardFooter) => {
  return (
    <div className="media-plan-card-footer">
      <span>
        <i className="tim-icons icon-single-02" />
        <div className="media-plan-card-footer-insight-wrapper">
          {genders
            ?.slice(0, 3)
            .map((gender) => <p key={`gender-${gender}`}>{gender}</p>)}
        </div>
      </span>
      <span>
        <i className="tim-icons icon-single-02" />
        <div className="media-plan-card-footer-insight-wrapper">
          {ages?.slice(0, 3).map((age) => <p key={`age-${age}`}>{age}</p>)}
        </div>
      </span>
      <span>
        <i className="tim-icons icon-world" />
        <div className="media-plan-card-footer-insight-wrapper">
          {zones?.slice(0, 3).map((zone) => <p key={`zone-${zone}`}>{zone}</p>)}
        </div>
      </span>
    </div>
  );
};

export default MediaPlanCardFooter;
