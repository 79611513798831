import { TCustomTargets } from '../../../../types';
import { TEditTarget } from './types';

export const prepareValues = (
  editTargets?: TEditTarget,
  newTargets?: TEditTarget | TCustomTargets
): TEditTarget & TCustomTargets => {
  return {
    ...editTargets,
    ...newTargets
  };
};
