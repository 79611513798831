import React, { useState } from 'react';
import { Button, CardBody, CardFooter, CardTitle, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Folder, InfoCircle } from 'react-bootstrap-icons';
import { ReactComponent as FolderSvg } from '../../../../../assets/vrr/images/creative_folder_icon.svg';
import { VrrCard } from '../../../../shared_components';
import { TCreativeFolder } from './types';
import CreativeModal from '../../creative_modal';
import { getAccountId } from '../../../../../redux/userPreferences/actions/userPreferencesActions';
import {
  fetchFolder,
  updateFolder
} from '../../../../../redux/creativeAnalytics/creativeGallery/actions/creativeGalleryAPIs';
import './CreativeFolder.scss';

const CreativeFolder: React.FC<TCreativeFolder> = ({
  id,
  title,
  updatedDate,
  itemCount,
  onCheckAction,
  onDropAction,
  onGoFolderAction,
  dataCy
}) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const accountId = useSelector(getAccountId);
  const [isOpen, setIsOpen] = useState(false);

  const onCheck = (e: any) => {
    if (onCheckAction) onCheckAction(id, e.target.checked);
  };

  const onUpdateFolder = (folder: any) => {
    if (accountId && folder) {
      dispatch(
        updateFolder({
          id,
          name: folder.name
        })
      );
    }
  };

  const goToFolder = () => {
    if (onGoFolderAction) onGoFolderAction();
    dispatch(fetchFolder(id.toString()));
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (onDropAction) onDropAction(+e.dataTransfer.getData('text'), id);
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => e.preventDefault();

  return (
    <label htmlFor={`folder-${id}`}>
      <VrrCard
        dataCy={dataCy}
        onDrop={onDrop}
        onDragOver={onDragOver}
        className="video-gallery-folder"
        cardHeader={
          <div className="checkbox-card">
            <Input type="checkbox" id={`folder-${id}`} onChange={onCheck} />
            <label htmlFor={`folder-${id}`} className="check folder-label">
              <span
                data-cy={`creative-checkbox-${title
                  .toLowerCase()
                  .replace(/\s/g, '-')}`}
                className="checkbox"
              />
            </label>
          </div>
        }
        cardBody={{
          element: (
            <CardBody onClick={goToFolder} className="folder-info">
              <div className="folder-svg-container">
                <FolderSvg className="creative-folder-svg" />
              </div>
              <CardTitle
                data-cy={`creative-folder-title-${title
                  .toLocaleLowerCase()
                  .replace(/\s/g, '-')}`}
                className="folder-name"
              >
                {title}
              </CardTitle>
            </CardBody>
          )
        }}
        cardFooter={
          <CardFooter className="folder-footer">
            <Button
              onClick={goToFolder}
              className="folder-items folder-info"
              data-cy-id="inside-folder"
            >
              <Folder className="footer-folder-icon" />
              {t('creative_gallery.folder.items', {
                count: itemCount
              })}
            </Button>
            <Button
              onClick={() => setIsOpen(true)}
              className="btn-link folder-info-circle"
              data-cy-id="creative-gallery-rename-folder-button"
            >
              <InfoCircle />
            </Button>
          </CardFooter>
        }
      />
      <CreativeModal
        isOpen={isOpen}
        folder={{ id, name: title, updatedDate }}
        onCancelAction={() => setIsOpen(false)}
        onSubmitAction={onUpdateFolder}
        dataCy="rename-folder-form"
        dataCyCancel="rename-folder-cancel"
      />
    </label>
  );
};

export default CreativeFolder;
