import { TSection } from '../summary/types';

export const PRIVACY_SECTIONS: TSection[] = [
  {
    id: 'section0',
    title: 'privacy.section0.title',
    content: 'privacy.section0.content'
  },
  {
    id: 'section1',
    title: 'privacy.section1.title',
    content: 'privacy.section1.content'
  },
  {
    id: 'section2',
    title: 'privacy.section2.title',
    content: 'privacy.section2.content'
  },
  {
    id: 'section3',
    title: 'privacy.section3.title',
    content: 'privacy.section3.content'
  },
  {
    id: 'section4',
    title: 'privacy.section4.title',
    content: 'privacy.section4.content'
  },
  {
    id: 'section5',
    title: 'privacy.section5.title',
    content: 'privacy.section5.content'
  },
  {
    id: 'section6',
    title: 'privacy.section6.title',
    content: 'privacy.section6.content'
  },
  {
    id: 'section7',
    title: 'privacy.section7.title',
    content: 'privacy.section7.content'
  },
  {
    id: 'section8',
    title: 'privacy.section8.title',
    content: 'privacy.section8.content'
  },
  {
    id: 'section9',
    title: 'privacy.section9.title',
    content: 'privacy.section9.content'
  },
  {
    id: 'section10',
    title: 'privacy.section10.title',
    content: 'privacy.section10.content'
  }
];
