import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Label, Input } from 'reactstrap';
import MultiEmails from '../../Inputs/MultiEmails';
import ViewerLineContainer from '../../../containers/ViewerLine';
import './ShareModal.scss';
import { ModalBody } from 'reactstrap';
import { VrrButton } from 'micro_front_end/shared_components';
import { TShareModal } from './types';

const ShareModal: React.FC<TShareModal> = ({
  isOpen = false,
  onSubmit,
  onCancel
}: TShareModal) => {
  const { t } = useTranslation();
  const [emails, setEmails] = useState<string[]>([]);
  const [updateViewersSettings, setUpdateViewersSettings] =
    useState<boolean>(false);

  const onChange = (allEmails: string[]) => {
    setEmails([...allEmails]);
  };

  const handleUpdateViewersSettings = () => {
    setUpdateViewersSettings(!updateViewersSettings);
  };

  const handleCancel = () => {
    setEmails([]);
    onCancel();
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (emails.length > 0 || updateViewersSettings) {
      onSubmit(emails, updateViewersSettings);
      setEmails([]);
    }
  };

  const isDisabledSubmitButton: boolean =
    emails.length < 1 && !updateViewersSettings;

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleCancel}
      size="lg"
      centered
      returnFocusAfterClose={false}
      scrollable
    >
      <ModalBody>
        <h4 className="modal-share-title">
          {t('components.share.modal.title')}
        </h4>

        <form onSubmit={handleSubmit}>
          <div className="modal-share-form-input" data-cy-id="overview-share">
            <MultiEmails
              idValue="cy-overview-share-modal-input"
              onChangeAction={onChange}
            />
            <div className="viewer-list">
              <ViewerLineContainer />
            </div>
          </div>

          <div className="modal-share-footer">
            <div className="share-options">
              <Label check>
                <Input
                  type="checkbox"
                  id="cy-overview-share-update-checkbox"
                  defaultChecked={updateViewersSettings}
                  onChange={handleUpdateViewersSettings}
                />
                {t('components.share.modal.buttons.update_viewers_settings')}
              </Label>
            </div>
            <div>
              <VrrButton
                data-cy-id="overview-share-cancel"
                type="button"
                className="btn-link"
                onClick={handleCancel}
              >
                {t('components.share.modal.buttons.cancel')}
              </VrrButton>
              <VrrButton
                disabled={isDisabledSubmitButton}
                id="cy-overview-share-modal-confirm"
                type="submit"
              >
                {t('components.share.modal.buttons.send')}
              </VrrButton>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default ShareModal;
