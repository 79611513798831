export const toSnakeCase = (str?: string): string | null => {
  if (str) {
    return (
      str
        .match(
          /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
        )
        ?.map((x: string) => x.toLowerCase())
        .join('_') ?? null
    );
  }
  return null;
};

export const toLowerKebabCase = (str: string): string => {
  if (!str) return str;

  const matches = str.match(/([^\W_]+)/gi);

  if (matches) {
    return matches.map((match: string) => match.toLowerCase()).join('-');
  }

  return str;
};

export const capitalize = (str: string): string => {
  if (!str) return str;

  return str.trim().replace(/^\w/, (c) => c.toUpperCase());
};
