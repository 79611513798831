import React, { useEffect, useState } from 'react';
import VrrButton from '../../vrr_button';
import { DEFAULT_LIMIT_ROW } from '../services';
import { TVrrTablePagination } from './types';
import './VrrTablePagination.scss';

const VrrTablePagination: React.FC<TVrrTablePagination> = ({
  dataLength,
  onChangePage,
  limitRow,
  page
}) => {
  const [pageRange, setPageRange] = useState<number[]>([]);
  const calculateRange = () => {
    const range = [];
    const num = Math.ceil(dataLength / (limitRow ?? DEFAULT_LIMIT_ROW));
    for (let i = 1; i <= num; i++) {
      range.push(i);
    }
    return range;
  };

  useEffect(() => {
    if (dataLength > 0) {
      const range = calculateRange();
      setPageRange([...range]);
    }
  }, [dataLength]);

  const onChange = (el: any) => {
    if (onChangePage) onChangePage(el);
  };

  return (
    <div className="vrr-table-pagination">
      {pageRange.map((el) => (
        <VrrButton
          key={el}
          className="btn-simple"
          size="sm"
          disabled={page === el}
          onClick={() => onChange(el)}
        >
          {el.toString()}
        </VrrButton>
      ))}
    </div>
  );
};

export default VrrTablePagination;
