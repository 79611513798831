import React from 'react';
import i18next from 'i18next';
import CtaInput from '../cta_input/CtaInput';
import { VrrButton, VrrTooltip } from '../../../../../../../shared_components';
import { toLowerKebabCase } from '../../../../../../../services/formatters/formatString';
import { ReactComponent as Info } from '../../../../../../../../assets/vrr/images/info_icon.svg';
import ctaButtonTooltip from '../../../../../../../../assets/vrr/images/cta_tooltip_wip.png';
import {
  THeaderId,
  TNumberOfCharacters,
  TCtaTableActions,
  TCallToActionRow
} from './types';
import {
  TTableBody,
  TTableHeader,
  TTableRow
} from '../../../../../../../shared_components/vrr_table/types';

export const NEW_INDEX = '-1';

const HEADER_TOOLTIP = {
  title: 'Lorem ipsum',
  button: (
    <div className="cta-button-tooltip">
      <p>
        {i18next.t(
          'media_plan_edit.media_plan_assets.call_to_actions.header_table.tooltip.button'
        )}
      </p>
      <img id="cta-button-tooltip" alt="cta-tooltip" src={ctaButtonTooltip} />
    </div>
  )
};

export const isDuplicated = (
  ctas: TCallToActionRow[],
  editedCta: TCallToActionRow
): boolean => {
  const filteredCtas: TCallToActionRow[] = ctas.filter(
    (cta: TCallToActionRow) => {
      return cta.title === editedCta.title && cta.button === editedCta.button;
    }
  );

  return filteredCtas?.length >= 1;
};

export const formatHeaderTable = (
  headers: THeaderId[]
): TTableHeader['elements'] => {
  return headers.map((header) => {
    const tooltip = `cta-${header}-tooltip-icon-id`;
    return {
      component: (
        <div>
          <p>
            {i18next.t(
              `media_plan_edit.media_plan_assets.call_to_actions.header_table.${header}`
            )}
          </p>
          <Info id={tooltip} />
          <VrrTooltip
            className={`cta-${header}-tooltip`}
            target={tooltip}
            children={HEADER_TOOLTIP[header]}
          />
        </div>
      ),
      id: header
    };
  });
};

const numberOfCharactersTyped: TNumberOfCharacters = {
  title: 0,
  button: 0
};

const setNumberOfCharaters = (
  numberOfInputCharcters: number,
  column: string
) => {
  numberOfCharactersTyped[column] = numberOfInputCharcters;
};

const isDisabled = (): boolean => {
  return (
    numberOfCharactersTyped.title === 0 || numberOfCharactersTyped.button === 0
  );
};

const editLine = (
  cta: TCallToActionRow,
  titleHeaderId: THeaderId,
  buttonHeaderId: THeaderId,
  isCtaDuplicated: boolean,
  onChangeInput: TCtaTableActions['onChangeInput'],
  onConfirmCta: TCtaTableActions['onConfirmCta'],
  onCancelEditCta: TCtaTableActions['onCancelEditCta']
): TTableRow['cellElements'] => {
  const inputTitleValue = cta[titleHeaderId satisfies keyof TCallToActionRow];
  setNumberOfCharaters(inputTitleValue.length, titleHeaderId);
  const inputButtonValue = cta[buttonHeaderId satisfies keyof TCallToActionRow];
  setNumberOfCharaters(inputButtonValue.length, buttonHeaderId);
  const titlePlaceholder =
    inputTitleValue.length > 0
      ? ''
      : i18next.t(
          'media_plan_edit.media_plan_assets.call_to_actions.header_table.title'
        );
  const buttonPlaceholder =
    inputButtonValue.length > 0
      ? ''
      : i18next.t(
          'media_plan_edit.media_plan_assets.call_to_actions.header_table.button'
        );

  return [
    {
      value: cta.title,
      headerId: titleHeaderId,
      component: (
        <CtaInput
          inputValue={inputTitleValue}
          headerId={titleHeaderId}
          onChangeInput={onChangeInput}
          isCtaDuplicated={isCtaDuplicated}
          ctaIndex={cta.rowId}
          numberOfCharactersTyped={numberOfCharactersTyped}
          setNumberOfCharaters={setNumberOfCharaters}
          placeholder={titlePlaceholder}
        />
      ),
      id: toLowerKebabCase(cta.title)
    },
    {
      value: cta.button,
      headerId: buttonHeaderId,
      component: (
        <CtaInput
          inputValue={inputButtonValue}
          headerId={buttonHeaderId}
          onChangeInput={onChangeInput}
          isCtaDuplicated={isCtaDuplicated}
          ctaIndex={cta.rowId}
          numberOfCharactersTyped={numberOfCharactersTyped}
          setNumberOfCharaters={setNumberOfCharaters}
          placeholder={buttonPlaceholder}
        />
      ),
      id: toLowerKebabCase(cta.button)
    },
    {
      value: '',
      headerId: '',
      component: (
        <VrrButton
          className=""
          icon={{ iconLabel: 'tim-icons icon-check-2' }}
          disabled={isDisabled()}
          dataCy={'confirm-cta'}
          onClick={() =>
            onConfirmCta({
              id: cta.id,
              rowId: cta.rowId,
              title: cta.title,
              button: cta.button
            })
          }
        />
      ),
      id: undefined
    },
    {
      value: '',
      headerId: '',
      component: (
        <VrrButton
          className="btn-link"
          icon={{ iconLabel: 'tim-icons icon-simple-remove' }}
          dataCy={'cancel-edit-cta'}
          onClick={onCancelEditCta}
        />
      ),
      id: undefined
    }
  ];
};

const readLine = (
  cta: TCallToActionRow,
  titleHeaderId: THeaderId,
  buttonHeaderId: THeaderId,
  onEditCta: TCtaTableActions['onEditCta'],
  onRemoveCta: TCtaTableActions['onRemoveCta']
): TTableRow['cellElements'] => {
  return [
    {
      value: cta.title,
      headerId: titleHeaderId,
      component: <p>{cta[titleHeaderId satisfies keyof TCallToActionRow]}</p>,
      id: toLowerKebabCase(cta.title)
    },
    {
      value: cta.button,
      headerId: buttonHeaderId,
      component: <p>{cta[buttonHeaderId satisfies keyof TCallToActionRow]}</p>,
      id: toLowerKebabCase(cta.button)
    },
    {
      value: '',
      headerId: '',
      component: (
        <VrrButton
          className="btn-link"
          icon={{ iconLabel: 'tim-icons icon-pencil' }}
          onClick={() => onEditCta(cta.rowId)}
          dataCy={'edit-cta'}
        />
      ),
      id: undefined
    },
    {
      value: '',
      headerId: '',
      component: (
        <VrrButton
          className="btn-link"
          icon={{ iconLabel: 'tim-icons icon-simple-remove' }}
          onClick={() => onRemoveCta(cta.rowId)}
          dataCy={'remove-cta'}
        />
      ),
      id: undefined
    }
  ];
};

const getNewRowId = (ctas: TCallToActionRow[]): string => {
  const rowIds = ctas?.map((cta) => parseInt(cta.rowId));
  const newRowId = rowIds.length ? Math.max(...rowIds) + 1 : 0;
  return newRowId.toString();
};

export const formatBodyTable = (
  ctas: TCallToActionRow[],
  titleHeaderId: THeaderId,
  buttonHeaderId: THeaderId,
  callbacks: {
    onEditCta: TCtaTableActions['onEditCta'];
    onRemoveCta: TCtaTableActions['onRemoveCta'];
    onConfirmCta: TCtaTableActions['onConfirmCta'];
    onChangeInput: TCtaTableActions['onChangeInput'];
    onCancelEditCta: TCtaTableActions['onCancelEditCta'];
  },
  isCtaDuplicated: boolean,
  ctaOnEditMode?: string
): TTableBody['rowElements'] => {
  const {
    onEditCta,
    onRemoveCta,
    onConfirmCta,
    onChangeInput,
    onCancelEditCta
  } = callbacks;
  const newCtas: TCallToActionRow[] = [...Object.values(ctas)];
  if (ctaOnEditMode === NEW_INDEX) {
    const newRowId: string = getNewRowId(newCtas);
    newCtas.push({
      id: null,
      rowId: newRowId,
      title: '',
      button: ''
    });

    ctaOnEditMode = newRowId;
  }

  return newCtas.map((cta: TCallToActionRow) => {
    return cta.rowId === ctaOnEditMode
      ? editLine(
          cta,
          titleHeaderId,
          buttonHeaderId,
          isCtaDuplicated,
          onChangeInput,
          onConfirmCta,
          onCancelEditCta
        )
      : readLine(cta, titleHeaderId, buttonHeaderId, onEditCta, onRemoveCta);
  });
};
