import React from 'react';
import { useTranslation } from 'react-i18next';
import { VrrButton } from '../../../shared_components';
import { TLauncherFooter } from './types';
import './LauncherFooter.scss';

const LauncherFooter: React.FC<TLauncherFooter> = ({
  currentStep,
  submitDisabled = false,
  onCancelAction,
  onSubmitAction
}) => {
  const { t } = useTranslation();

  const onSubmit = () => {
    if (onSubmitAction) onSubmitAction(currentStep);
  };

  const onCancel = () => {
    if (onCancelAction) onCancelAction(currentStep);
  };

  return (
    <div className="launcher-footer">
      <VrrButton onClick={onCancel} className="btn-link">
        {t(`launcher.action.${currentStep === 0 ? 'cancel' : 'previous'}`)}
      </VrrButton>
      <VrrButton disabled={submitDisabled} onClick={onSubmit}>
        {t('launcher.action.next')}
      </VrrButton>
    </div>
  );
};

export default LauncherFooter;
