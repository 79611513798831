import { Action, AnyAction, createAsyncThunk } from '@reduxjs/toolkit';
import { TDateRange } from '../../../micro_front_end/nav_bar/date_time_picker/types';
import apiVRR from '../../../libs/axios/apis';
import moment from 'moment';
import { RootState } from '../../store';
import endPoints from '../../../services/endpoints';
import { TMetricKey, TSetOfMetrics, TMetricDetails } from './types';
import { AxiosResponse } from 'axios';

export const fetchOneMetric: (param: {
  accountId: string;
  period: TDateRange;
  funnel: string;
  provider: string;
  insight?: string;
}) => Promise<AxiosResponse<any>> = (param: {
  accountId: string;
  period: TDateRange;
  funnel: string;
  provider: string;
  insight?: string;
}) => {
  const {
    accountId,
    period: { from, to },
    funnel,
    provider,
    insight
  } = param;

  return apiVRR().get(endPoints.OVERVIEW.GLOBAL_INSIGHTS, {
    params: {
      id: accountId,
      start: moment(from).format('YYYY-MM-DD'),
      end: moment(to).format('YYYY-MM-DD'),
      label: funnel,
      provider,
      insight
    }
  });
};

export const fetchAgeMetric = createAsyncThunk(
  'accounts/fetchOneMetric/age',
  async (
    param: {
      accountId: string;
      period: TDateRange;
      provider: string;
      insight: TMetricKey;
    },
    { getState }
  ) => {
    return fetchOneMetric({
      ...param,
      funnel: (getState() as any).app.funnel
    });
  }
);

export const fetchGenderMetric = createAsyncThunk(
  'accounts/fetchOneMetric/gender',
  async (
    param: {
      accountId: string;
      period: TDateRange;
      provider: string;
      insight: TMetricKey;
    },
    { getState }
  ) => {
    return fetchOneMetric({
      ...param,
      funnel: (getState() as any).app.funnel
    });
  }
);

export const fetchAudienceMetric = createAsyncThunk(
  'accounts/fetchOneMetric/audience',
  async (
    param: {
      accountId: string;
      period: TDateRange;
      provider: string;
      insight: TMetricKey;
    },
    { getState }
  ) => {
    return fetchOneMetric({
      ...param,
      funnel: (getState() as any).app.funnel
    });
  }
);

export const fetchDeviceMetric = createAsyncThunk(
  'accounts/fetchOneMetric/device',
  async (
    param: {
      accountId: string;
      period: TDateRange;
      provider: string;
      insight: TMetricKey;
    },
    { getState }
  ) => {
    return fetchOneMetric({
      ...param,
      funnel: (getState() as any).app.funnel
    });
  }
);

export const fetchRedirectionMetric = createAsyncThunk(
  'accounts/fetchOneMetric/redirection',
  async (
    param: {
      accountId: string;
      period: TDateRange;
      provider: string;
      insight: TMetricKey;
    },
    { getState }
  ) => {
    return fetchOneMetric({
      ...param,
      funnel: (getState() as any).app.funnel
    });
  }
);

export const fetchVideoMetric = createAsyncThunk(
  'accounts/fetchOneMetric/video',
  async (
    param: {
      accountId: string;
      period: TDateRange;
      provider: string;
      insight: TMetricKey;
    },
    { getState }
  ) => {
    return fetchOneMetric({
      ...param,
      funnel: (getState() as any).app.funnel
    });
  }
);

export const fetchZoneMetric = createAsyncThunk(
  'accounts/fetchOneMetric/zone',
  async (
    param: {
      accountId: string;
      period: TDateRange;
      provider: string;
      insight: TMetricKey;
    },
    { getState }
  ) => {
    return fetchOneMetric({
      ...param,
      funnel: (getState() as any).app.funnel
    });
  }
);

export const fetchTaxonomyMetric = createAsyncThunk(
  'accounts/fetchOneMetric/taxonomy',
  async (
    param: {
      accountId: string;
      period: TDateRange;
      provider: string;
      insight: TMetricKey;
    },
    { getState }
  ) => {
    return fetchOneMetric({
      ...param,
      funnel: (getState() as any).app.funnel
    });
  }
);

export const fetchCallToActionMetric = createAsyncThunk(
  'accounts/fetchOneMetric/call_to_action',
  async (
    param: {
      accountId: string;
      period: TDateRange;
      provider: string;
      insight: TMetricKey;
    },
    { getState }
  ) => {
    return fetchOneMetric({
      ...param,
      funnel: (getState() as any).app.funnel
    });
  }
);

export const fetchFunnelMetric = createAsyncThunk(
  'accounts/fetchOneMetric/funnel',
  async (
    param: {
      accountId: string;
      period: TDateRange;
      provider: string;
      insight: TMetricKey;
    },
    { getState }
  ) => {
    return fetchOneMetric({
      ...param,
      funnel: (getState() as any).app.funnel
    });
  }
);

export const prepareInsights = createAsyncThunk(
  'fetchInsights',
  async (
    param: {
      accountId: string;
      period: TDateRange;
      provider: string;
    },
    { dispatch }
  ) => {
    const list: any = {
      age: fetchAgeMetric,
      gender: fetchGenderMetric,
      audience: fetchAudienceMetric,
      device: fetchDeviceMetric,
      redirection: fetchRedirectionMetric,
      video: fetchVideoMetric,
      zone: fetchZoneMetric,
      taxonomy: fetchTaxonomyMetric,
      call_to_action: fetchCallToActionMetric,
      funnel: fetchFunnelMetric
    };

    Object.keys(list).map((key) => {
      dispatch(list[key]({ ...param, insight: key }));
    });
  }
);

export const fetchMetricFulfilled = (action: AnyAction): action is Action => {
  return (
    action.type.startsWith('accounts/fetchOneMetric/') &&
    action.type.endsWith('fulfilled')
  );
};

export const fetchMetricPending = (action: AnyAction): action is Action => {
  return (
    action.type.startsWith('accounts/fetchOneMetric/') &&
    action.type.endsWith('pending')
  );
};

export const fetchMetricRejected = (action: AnyAction): action is Action => {
  return (
    action.type.startsWith('accounts/fetchOneMetric/') &&
    action.type.endsWith('rejected')
  );
};

export const getAllMetrics = (state: RootState): TSetOfMetrics =>
  state.metrics.allMetrics;

export const getOneMetric =
  (metric: string) =>
  (state: RootState): TMetricDetails | undefined =>
    (state.metrics.allMetrics as any)[metric as keyof TSetOfMetrics];

export const getMetricKpis =
  (metric: string) =>
  (state: RootState): string[] | undefined =>
    state.metrics.allMetrics[metric as keyof TSetOfMetrics]?.header;
