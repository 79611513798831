import React, { useEffect, useState } from 'react';
import { ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import VrrModal from '../../../shared_components/vrr_modal';
import TagTable from './tag_table/TagTable';
import { getAllTags } from '../../../../redux/creativeAnalytics/creativeGallery/actions/creativeGalleryActions';
import {
  fetchAllTags,
  updateTags
} from '../../../../redux/creativeAnalytics/creativeGallery/actions/creativeGalleryAPIs';
import { TCreativeTagModal, TTagList } from './types';
import './CreativeTagModal.scss';

const CreativeTagModal: React.FC<TCreativeTagModal> = ({
  isOpen,
  videos,
  onCloseAction
}) => {
  const dispatch: any = useDispatch();
  const tags = useSelector(getAllTags);
  const [data, setData] = useState<TTagList[]>([]);

  useEffect(() => {
    if (!!videos.length) {
      dispatch(fetchAllTags());
    }
  }, [videos]);
  const onSubmit = () => {
    dispatch(updateTags(data));
    if (onCloseAction) onCloseAction();
  };

  const onChange = (videoTags: number[], tagId: number, videoId: number) => {
    const newData = [...data];
    newData.map((d) => {
      if (d && d.id === videoId) {
        d.tags = videoTags;
      }
    });

    if (!newData.find((d) => d.id === videoId)) {
      newData.push({
        id: videoId,
        tags: videoTags
      });
    }

    setData(newData);
  };

  return (
    <VrrModal
      className="tag-modal"
      centered
      isOpen={isOpen}
      toggle={onCloseAction}
      modalBody={
        <ModalBody className="tag-modal-body">
          <TagTable tags={tags} videos={videos} onChangeAction={onChange} />
        </ModalBody>
      }
      toggleModal={onCloseAction}
      toggleValidation={onSubmit}
      dataCyCancelButton="add-tag-cancel"
    />
  );
};

export default CreativeTagModal;
