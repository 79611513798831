import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import './SingleButtonPublish.scss';
import { TSingleButtonPublish } from './types';

const SingleButtonPublish: React.FC<TSingleButtonPublish> = ({
  onClickSingleButton
}: TSingleButtonPublish) => {
  const { t } = useTranslation();

  return (
    <div>
      <Button
        id="cy-insights-unhide-all-button"
        className={'btn-round publish-unhide-all'}
        onClick={onClickSingleButton}
      >
        {t('components.insights.singleButtonPublish.unHideAll')}
      </Button>
    </div>
  );
};

export default SingleButtonPublish;
