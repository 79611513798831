import React from 'react';
import { Card, Row } from 'reactstrap';
import { TVrrFilter } from './types';
import VrrSortBy from '../vrr_sort_by';
import VrrSearch from '../vrr_search/VrrSearch';
import { useTranslation } from 'react-i18next';
import './VrrFilter.scss';

const VrrFilter: React.FC<TVrrFilter> = ({
  displaySort = true,
  displaySearch = true,
  sortProps,
  searchProps,
  otherActions,
  classNames = ''
}: TVrrFilter) => {
  const { t } = useTranslation();

  return (
    <Card className={`vrr_filter-container ${classNames}`}>
      <Row>
        {displaySort && sortProps && (
          <div className="vrr_filter-sort">
            <h5>
              {t('shared_components.vrr_filter.sort.title').toUpperCase()}
            </h5>
            <VrrSortBy {...sortProps} />
          </div>
        )}
        {displaySearch && searchProps && (
          <div className="vrr_filter-search">
            <h5>
              {t('shared_components.vrr_filter.search.title').toUpperCase()}
            </h5>
            <VrrSearch {...searchProps} />
          </div>
        )}
        {otherActions}
      </Row>
    </Card>
  );
};

export default VrrFilter;
