import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ZoneBodyModal from './zone_body_modal';
import { VrrModal } from '../../../../../../shared_components';
import {
  editMediaPlanDetails,
  getMediaPlanZones
} from '../../../../../../../redux/mediaPlanGallery/mediaPlanGalleryAction';
import { TZoneModal } from './types';
import { TMediaPlanDetails } from '../../../../types';
import zoneModalStyles from './ZoneModal.module.scss';

const ZoneModal: React.FC<TZoneModal> = ({ isOpen, onCancelAction }) => {
  const dispatch: any = useDispatch();
  const [currentZones, setCurrentZones] = useState<TMediaPlanDetails['zones']>(
    []
  );
  const zones = useSelector(getMediaPlanZones);
  const { t } = useTranslation();

  useEffect(() => {
    if (zones) {
      setCurrentZones(zones);
    }
  }, [zones]);

  const onSubmitAction = () => {
    if (currentZones) {
      dispatch(editMediaPlanDetails({ zones: currentZones }));
    }
    if (onCancelAction) onCancelAction();
  };

  const onChange = (values: TMediaPlanDetails['zones']) => {
    setCurrentZones(values);
  };

  const onCancel = () => {
    setCurrentZones(zones ?? []);
    if (onCancelAction) {
      onCancelAction();
    }
  };

  return (
    <VrrModal
      className="zones-modal"
      cssModule={zoneModalStyles}
      isOpen={isOpen}
      modalBody={
        <ZoneBodyModal zones={currentZones} onChangeAction={onChange} />
      }
      toggleModal={onCancel}
      title={t(
        'media_plan_edit.media_plan_targets.zone_modal.modal_header.title'
      )}
      icon={<i className="tim-icons icon-spaceship circle-icon" />}
      toggle={onCancel}
      toggleValidation={onSubmitAction}
      dataCyCancelButton="media-plan-detail-edit-close-button"
    />
  );
};

export default ZoneModal;
