import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import useSettings from '../../../libs/settings/useSettings';
import PublishTabs from '../../../components/Insights/PublishTabs';
import SingleButtonPublish from '../../../components/Insights/SingleButtonPublish';
import { INSIGHTS_CARD_STATUS } from '../../../services/constants/status';
import { getConvActTranslationKey } from '../../../services/services';
import filterContainerServices from './services';
import { checkPreferences } from '../CardListContainer/services';
import {
  fetchFilters,
  getFilters
} from '../../../redux/insights/insightsActions';
import {
  getAccountId,
  getInsightsFiltersByCurrentAccount,
  getPeriod
} from '../../../redux/userPreferences/actions/userPreferencesActions';
import { getCurrentLabel, getFeatures } from '../../../redux/app/appActions';
import { unHideAllInsightsCards } from '../../../redux/userPreferences/actions/userPreferencesAPIs';
import { TFilterTag } from './types';
import { TDateRange } from '../../../micro_front_end/nav_bar/date_time_picker/types';
import { IListState } from '../../../micro_front_end/shared_components/vrr_dropdown/types';
import './FilterContainer.scss';
import { VrrDropdown } from '../../../micro_front_end/shared_components';

const FilterContainer: React.FC = () => {
  const { t } = useTranslation();
  const features = useSelector(getFeatures);
  const filters = useSelector(getFilters);
  const period = useSelector(getPeriod);
  const accountID = useSelector(getAccountId);
  const filtersSettings = useSelector(getInsightsFiltersByCurrentAccount);
  const funnel = useSelector(getCurrentLabel);
  const [currentPeriod, setCurrentPeriod] = useState<TDateRange | null>();
  const [currentAccount, setCurrentAccount] = useState<string | null>(null);
  const [tags, setTags] = useState<TFilterTag[]>([]);
  const [kpis, setKpis] = useState<IListState[]>([]);
  const { insights } = useSettings();
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (
      period?.from !== currentPeriod?.from ||
      period?.to !== currentPeriod?.to ||
      accountID !== currentAccount
    ) {
      setCurrentAccount(accountID);
      setCurrentPeriod(period);
    }
  }, [period, accountID]);

  useEffect(() => {
    if (
      currentAccount &&
      currentAccount !== '' &&
      currentPeriod?.from &&
      currentPeriod?.to
    ) {
      setTags([]);
      setKpis([]);
      dispatch(fetchFilters());
    }
  }, [currentPeriod, currentAccount, funnel]);

  useEffect(() => {
    if (Object.values(filters)?.length > 0) {
      const newTags = filters?.tags;
      setTags([
        {
          id: -999999,
          name: 'all',
          translation_key: 'all'
        },
        ...newTags
      ]);
      setKpis(filterContainerServices.getKpis(kpis, filters?.kpis));
    }
  }, [filters]);

  const filterAction = (filterName: string, filterValue?: string) => {
    if (filterValue && filterName) {
      insights('filters', {
        [filterName]: filterValue
      });
    }
  };

  const unHideAllCardsAction = () => {
    dispatch(unHideAllInsightsCards());
  };

  return (
    <Card className="insights-filter-container">
      <div className="publish-tabs-container">
        <PublishTabs
          currentTab={checkPreferences(filtersSettings, features)?.status}
          onClickGroupButton={(id) => filterAction('status', id)}
        />
      </div>
      <div className="filter-container-dropdowns">
        <h5 className="filter-container-title">
          {t('containers.insights.filterContainer.filter')}
        </h5>
        <div className="filter-container-kpi-dropdowns">
          <VrrDropdown
            placeholder={t('containers.insights.filterContainer.kpi.title')}
            noOptionsMessage={t(
              'containers.insights.filterContainer.noOptions'
            )}
            currentValue={filtersSettings?.kpi}
            list={[
              {
                value: 'all',
                label: t('containers.insights.filterContainer.dropdown.all')
              },
              ...kpis.map((kpi) => {
                return {
                  value: kpi.value,
                  label: t(getConvActTranslationKey(kpi.label))
                };
              })
            ]}
            handleChangeAction={(e: IListState) =>
              filterAction('kpi', e?.value)
            }
          />
          <VrrDropdown
            placeholder={t('containers.insights.filterContainer.tag.title')}
            noOptionsMessage={t(
              'containers.insights.filterContainer.noOptions'
            )}
            currentValue={filtersSettings?.tag}
            list={tags.map((tag) => {
              let translationString = t(
                `components.tag_attribution.tag_name.${tag.translation_key}`
              );

              if (tag.translation_key.startsWith('duration')) {
                translationString = t(
                  `components.tag_attribution.tag_name.${tag.translation_key}`,
                  { duration: filters?.duration?.toString() }
                );
              }

              if (tag.translation_key === 'all')
                translationString = t(
                  'containers.insights.filterContainer.dropdown.all'
                );

              return {
                value: tag.translation_key,
                label: translationString
              };
            })}
            handleChangeAction={(e: IListState) =>
              filterAction('tag', e?.value)
            }
          />
        </div>
      </div>
      <div className="single-button-publish-container">
        {filtersSettings?.status &&
          filtersSettings?.status === INSIGHTS_CARD_STATUS.HIDDEN && (
            <SingleButtonPublish onClickSingleButton={unHideAllCardsAction} />
          )}
      </div>
    </Card>
  );
};

export default FilterContainer;
