const PREFIX = '/users';

const getEndPoint = (userId: string, url: string) =>
  `${PREFIX}/${userId}/${url}`;

const ACCOUNTS_ENDPOINTS = {
  CONNECTED_ACCOUNT_PROMPT: (userId: string): string =>
    getEndPoint(userId, 'connected_accounts/prompt'),
  CONNECTED_ACCOUNT_CALLBACK: (userId: string): string =>
    getEndPoint(userId, 'connected_accounts/callback'),
  CONNECTED_ACCOUNT_ADD_ACCOUNT: (userId: string): string =>
    getEndPoint(userId, 'connected_accounts/add_accounts'),
  CONNECTED_ACCOUNT_DELETE_ACCOUNT: (userId: string): string =>
    getEndPoint(userId, 'connected_accounts/delete_accounts'),
  ACCOUNT_DATES: (ad_account_id: string): string =>
    `/ad_accounts/${ad_account_id}/last_update_date`,
  REFRESH_LABELS: (ad_account_id: string): string =>
    `/ad_accounts/${ad_account_id}/labels/refresh`,
  LABELS: (ad_account_id: string): string =>
    `/ad_accounts/${ad_account_id}/labels`,
  VIEWERS: (ad_account_id: string): string =>
    `/ad_accounts/${ad_account_id}/viewers`
};

export default ACCOUNTS_ENDPOINTS;
