import React from 'react';
import { useTranslation } from 'react-i18next';
import SectionTitle from '../section_title';
import StatusMetric from './status_metric';
import PercentMetric from './percent_metric';
import { TQualityMetricSection } from './types';
import './QualityMetricSection.scss';

const QualityMetricSection: React.FC<TQualityMetricSection> = ({
  keyElementsVisibility,
  hookEffectiveness,
  brandAwareness,
  clickAbility,
  salesPitchEfficiency,
  ctasEvaluation,
  marketAdequacy,
  audienceTargeting,
  assetsQualityCheck,
  // emotionalEngagement,
  narrativeConsistency
}) => {
  const { t } = useTranslation();

  return (
    <>
      <SectionTitle
        title={t('creative_video_details.section_title.quality_metric_section')}
        iconClassName="tim-icons icon-atom"
      />
      <div className="quality-metrics">
        <div className="quality-metric-column">
          <PercentMetric
            metricPercent={hookEffectiveness}
            metricText={t('creative_video_details.quality_metric.hook')}
          />
        </div>
        <div className="quality-metric-column">
          <PercentMetric
            metricPercent={brandAwareness}
            metricText={t('creative_video_details.quality_metric.brand')}
          />
        </div>
        <div className="quality-metric-column">
          <PercentMetric
            metricPercent={clickAbility}
            metricText={t('creative_video_details.quality_metric.click')}
          />
        </div>
        <div className="quality-metric-column">
          <PercentMetric
            metricPercent={ctasEvaluation}
            metricText={t('creative_video_details.quality_metric.cta')}
          />
        </div>
        <div className="quality-metric-column">
          <StatusMetric
            metricPercent={salesPitchEfficiency}
            metricText={t('creative_video_details.quality_metric.sales')}
          />
        </div>
        <div className="quality-metric-column">
          <StatusMetric
            metricPercent={keyElementsVisibility}
            metricText={t('creative_video_details.quality_metric.visibility')}
          />
        </div>
        {/*<div className="quality-metric-column">
          <StatusMetric
            metricPercent={emotionalEngagement}
            metricText={t('creative_video_details.quality_metric.engagement')}
          />
        </div>*/}
        <div className="quality-metric-column">
          <StatusMetric
            metricPercent={narrativeConsistency}
            metricText={t('creative_video_details.quality_metric.narrative')}
          />
        </div>
        <div className="quality-metric-column">
          <StatusMetric
            metricPercent={marketAdequacy}
            metricText={t('creative_video_details.quality_metric.market')}
          />
        </div>
        <div className="quality-metric-column">
          <StatusMetric
            metricPercent={audienceTargeting}
            metricText={t('creative_video_details.quality_metric.audience')}
          />
        </div>
        <div className="quality-metric-column">
          <StatusMetric
            metricPercent={assetsQualityCheck}
            metricText={t('creative_video_details.quality_metric.assets')}
          />
        </div>
      </div>
    </>
  );
};

export default QualityMetricSection;
