const OVERVIEW_ENDPOINTS = {
  GLOBAL_METRICS: '/overview/global_metrics',
  CHARTS: '/overview/charts',
  SHARE: '/overview/share',
  GLOBAL_INSIGHTS: '/overview/global_insights',
  EXPORT_FILE: '/overview/export_file',
  BUDGETS: '/ad_accounts/{account_id}/budgets',
  UPDATE_BUDGETS: '/ad_accounts/{account_id}/budgets',
  OPTIMIZATION: '/ad_accounts/{account_id}/campaigns_optimization',
  SPLIT_TESTS: (ad_account_id: string): string =>
    `/ad_accounts/${ad_account_id}/split_tests`,
  SPLIT_TEST_DETAILS: (ad_account_id: string, split_test_id: string): string =>
    `/ad_accounts/${ad_account_id}/split_tests/${split_test_id}`,
  SPLIT_TEST_BUDGET_UPDATE: (
    ad_account_id: string,
    split_test_id: string
  ): string =>
    `/ad_accounts/${ad_account_id}/split_tests/${split_test_id}/budgets`
};

export default OVERVIEW_ENDPOINTS;
