import React from 'react';
import { useTranslation } from 'react-i18next';
import { VrrCard } from '../../../../../shared_components';
import EditButton from '../../edit/edit_button';
import assetsCardsServices from './services';
import { TMediaPlanAssetsCard } from './types';
import './MediaPlanAssetsCard.scss';

const MediaPlanAssetsCard: React.FC<TMediaPlanAssetsCard> = ({
  type,
  assets,
  onClickEditButton
}: TMediaPlanAssetsCard) => {
  const { t } = useTranslation();

  const makeAssetsHeader = () => {
    return (
      <h4 className="title-with-icon asset-text">
        <i className="tim-icons icon-spaceship circle-icon" />
        <span className="title-text">
          {t(`media_plan_details.media_plan_assets_card.${type}.title`)}
        </span>
      </h4>
    );
  };

  const makeAssetsBodyText = () => {
    return (
      <p className="asset-content asset-text">
        {t(`media_plan_details.media_plan_assets_card.${type}.text`)}
      </p>
    );
  };

  const makeAssetsCardFooter = () => {
    return (
      <>
        <footer
          className={`media-plan-assets-card-footer classic-asset ${assetsCardsServices.footerColor(
            type
          )} ${assetsCardsServices.footerRender(assets)}`}
        >
          <EditButton
            onClickEditButton={onClickEditButton}
            titleId={type}
            iconLabel="tim-icons icon-pencil"
          />
          <span className="asset-count">
            {assets?.length ? assets?.length : '-'}
          </span>
          <span className="asset-name">
            {assets?.length > 1
              ? t(`media_plan_details.media_plan_assets_card.${type}.footers`)
              : t(`media_plan_details.media_plan_assets_card.${type}.footer`)}
          </span>
        </footer>
      </>
    );
  };

  return (
    <VrrCard
      className="media-plan-assets-card"
      cardHeader={makeAssetsHeader()}
      cardBody={{
        element: makeAssetsBodyText()
      }}
      cardFooter={makeAssetsCardFooter()}
    />
  );
};

export default MediaPlanAssetsCard;
