import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import {
  VrrButton,
  VrrCollapse,
  VrrInput
} from '../../../../../../shared_components';
import DetailPreview from '../detail_preview';
import { TVideoPreview } from './types';
import './VideoPreview.scss';

const VideoPreview: React.FC<TVideoPreview> = ({
  videoIndex,
  url,
  title,
  thumbnailUrl,
  details,
  onChangeAction,
  onRemoveAction
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      <div className="preview-title">
        <span>
          {t('creative_gallery.import_video.preview.video.title.url')}
        </span>
        <hr />
      </div>
      <div className="video-preview-url">
        <VrrInput isDisabled value={url} />
        {!!onRemoveAction && (
          <VrrButton
            className="btn-link video-preview-remove-button"
            icon={{
              iconLabel: 'tim-icons icon-simple-remove'
            }}
            onClick={() => {
              onRemoveAction(videoIndex);
            }}
          />
        )}
        <VrrCollapse
          sideIcon="left"
          isOpen={isOpen}
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>
      <Collapse isOpen={isOpen}>
        <div className="preview-title">
          <span>
            {t('creative_gallery.import_video.preview.video.title.information')}
          </span>
          <hr />
        </div>
        <VrrInput isDisabled value={title} />
        <div className="preview-title">
          <span>
            {t('creative_gallery.import_video.preview.video.title.thumbnail')}
          </span>
          <hr />
        </div>
        <img src={thumbnailUrl} alt={thumbnailUrl} />
        <DetailPreview details={details} onChangeAction={onChangeAction} />
      </Collapse>
    </>
  );
};

export default VideoPreview;
