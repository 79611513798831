import i18next from 'i18next';

export const VERTICALS = [
  {
    label: '---',
    value: '---'
  },
  {
    label: i18next.t(
      'creative_gallery.import_video.preview.brand.verticals.automotive'
    ),
    value: 'automotive'
  },
  {
    label: i18next.t(
      'creative_gallery.import_video.preview.brand.verticals.beauty'
    ),
    value: 'beauty'
  },
  {
    label: i18next.t(
      'creative_gallery.import_video.preview.brand.verticals.business'
    ),
    value: 'business'
  },
  {
    label: i18next.t(
      'creative_gallery.import_video.preview.brand.verticals.consumer'
    ),
    value: 'consumer'
  },
  {
    label: i18next.t(
      'creative_gallery.import_video.preview.brand.verticals.education'
    ),
    value: 'education'
  },
  {
    label: i18next.t(
      'creative_gallery.import_video.preview.brand.verticals.fashion'
    ),
    value: 'fashion'
  },
  {
    label: i18next.t(
      'creative_gallery.import_video.preview.brand.verticals.food'
    ),
    value: 'food'
  },
  {
    label: i18next.t(
      'creative_gallery.import_video.preview.brand.verticals.healthcare'
    ),
    value: 'healthcare'
  },
  {
    label: i18next.t(
      'creative_gallery.import_video.preview.brand.verticals.home'
    ),
    value: 'home'
  },
  {
    label: i18next.t(
      'creative_gallery.import_video.preview.brand.verticals.logistics'
    ),
    value: 'logistics'
  },
  {
    label: i18next.t(
      'creative_gallery.import_video.preview.brand.verticals.media'
    ),
    value: 'media'
  },
  {
    label: i18next.t(
      'creative_gallery.import_video.preview.brand.verticals.pet'
    ),
    value: 'pet'
  },
  {
    label: i18next.t(
      'creative_gallery.import_video.preview.brand.verticals.professional'
    ),
    value: 'professional'
  },
  {
    label: i18next.t(
      'creative_gallery.import_video.preview.brand.verticals.retail'
    ),
    value: 'retail'
  },
  {
    label: i18next.t(
      'creative_gallery.import_video.preview.brand.verticals.software'
    ),
    value: 'software'
  },
  {
    label: i18next.t(
      'creative_gallery.import_video.preview.brand.verticals.travel'
    ),
    value: 'travel'
  },
  {
    label: i18next.t(
      'creative_gallery.import_video.preview.brand.verticals.nonprofits'
    ),
    value: 'nonprofits'
  }
];
