import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IVrrModal } from './types';
import VrrButton from '../vrr_button';
import './styles/VrrModal.scss';
import vrrModalFooterStyles from './styles/VrrModalFooter.module.scss';

const VrrModal: React.FC<IVrrModal> = ({
  isOpen,
  toggleModal,
  enabledValidationButton,
  toggleValidation,
  validationText,
  modalHeader,
  modalBody,
  modalFooter,
  children,
  icon,
  title,
  cancelText,
  scrollable,
  toggle,
  withClose,
  className,
  dataCyCancelButton,
  cssModule
}: IVrrModal) => {
  const { t } = useTranslation();
  const defaultProps = {
    modalHeader: (
      <ModalHeader
        className="vrr-modal-header"
        {...(withClose && { toggle: toggleModal })}
      >
        <span>{icon}</span>
        <span>{title}</span>
      </ModalHeader>
    ),
    modalBody: <ModalBody>{children}</ModalBody>,
    modalFooter: (
      <ModalFooter
        className="vrr-modal-footer"
        cssModule={vrrModalFooterStyles}
      >
        <div className="vrr-modal-footer-actions">
          <VrrButton onClick={toggleModal} data-cy-id={dataCyCancelButton}>
            {cancelText ?? t('shared.vrr_modal.buttons.cancel')}
          </VrrButton>
          <VrrButton
            className="btn-with-icon-and-label icon-left"
            disabled={enabledValidationButton === false}
            onClick={() => {
              if (toggleValidation) toggleValidation();
            }}
            icon={{ iconLabel: 'tim-icons icon-check-2' }}
          >
            {validationText ?? t('shared.vrr_modal.buttons.submit')}
          </VrrButton>
        </div>
      </ModalFooter>
    )
  };

  return (
    <Modal
      isOpen={isOpen}
      centered
      scrollable={scrollable}
      toggle={toggle}
      className={className}
      cssModule={cssModule}
    >
      {modalHeader ? modalHeader : defaultProps.modalHeader}
      {modalBody ? modalBody : children}
      {modalFooter ? modalFooter : defaultProps.modalFooter}
    </Modal>
  );
};

export default VrrModal;
