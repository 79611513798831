import { createAsyncThunk } from '@reduxjs/toolkit';
import END_POINTS from '../../../../services/endpoints';
import apiVRR from '../../../../libs/axios/apis';
import { notify } from '../../../app/appSlice';
import { TTagList } from '../../../../micro_front_end/creative_analytics/creative_video_gallery/creative_tag_modal/types';

export const fetchRoot = createAsyncThunk(
  'creativeGallery/fetchRoot',
  async (currentAccountId: string) => {
    const endpointURL =
      END_POINTS.CREATIVE_ANALYTICS.CREATIVE_GALLERY.AD_ACCOUNT_FOLDERS(
        currentAccountId
      );
    const response = await apiVRR().get(endpointURL);

    return response.data.data;
  }
);

export const fetchFolder = createAsyncThunk(
  'creativeGallery/fetchFolder',
  async (folderId: string, { getState }) => {
    const currentState = getState() as any;
    const currentAccountId =
      currentState.userPreferences.settings.vrr.current_account_id;
    const endpoint =
      END_POINTS.CREATIVE_ANALYTICS.CREATIVE_GALLERY.AD_ACCOUNT_SPECIFIC_FOLDER(
        currentAccountId,
        folderId
      );

    const response = await apiVRR().get(endpoint);

    return response.data.data;
  }
);

export const moveVideo = createAsyncThunk(
  'creativeGallery/moveVideo',
  async (
    params: { folderDestination: number; videos?: Array<number> },
    { getState }
  ) => {
    const currentState = getState() as any;
    const currentAccountId =
      currentState.userPreferences.settings.vrr.current_account_id;
    let lastResponse = undefined;
    params.videos?.forEach((video, index) => {
      const endpoint =
        END_POINTS.CREATIVE_ANALYTICS.CREATIVE_GALLERY.AD_ACCOUNT_VIDEO(
          currentAccountId,
          video.toString()
        );

      const response = apiVRR().put(endpoint, {
        parent_id: params.folderDestination
      });

      if (params.videos?.length === index + 1) {
        lastResponse = response;
      }
    });

    return lastResponse;
  }
);

export const addFolder = createAsyncThunk(
  'creativeGallery/addFolder',
  async (
    params: {
      name: string;
      videos: number[];
    },
    { dispatch, getState }
  ) => {
    const currentState = getState() as any;
    const currentAdAccountId =
      currentState.userPreferences.settings.vrr.current_account_id;
    const rootId = currentState.creativeGallery.rootId;
    const endpointURL =
      END_POINTS.CREATIVE_ANALYTICS.CREATIVE_GALLERY.AD_ACCOUNT_CREATE_FOLDER(
        currentAdAccountId
      );

    const response = await apiVRR().post(endpointURL, {
      parentId: rootId,
      name: params.name,
      videos: params.videos
    });

    if (response.status >= 200) {
      dispatch(notify({ type: 'success' }));
    }

    return response.data.data;
  }
);

export const updateFolder = createAsyncThunk(
  'creativeGallery/updateFolder',
  async (
    params: {
      id: number;
      name: string;
    },
    { dispatch, getState }
  ) => {
    const currentAccountId = (getState() as any).userPreferences.settings.vrr
      .current_account_id;
    const endpoint =
      END_POINTS.CREATIVE_ANALYTICS.CREATIVE_GALLERY.AD_ACCOUNT_SPECIFIC_FOLDER(
        currentAccountId,
        params.id.toString()
      );

    const response = await apiVRR().put(endpoint, params);

    if (response.status >= 200) {
      dispatch(notify({ type: 'success' }));
    }

    return response.data.data;
  }
);

export const archive = createAsyncThunk(
  'creativeGallery/archive',
  async (
    params: {
      data: {
        folderId: number;
        videosIds: number[];
        foldersIds: number[];
      };
    },
    { dispatch, getState }
  ) => {
    const currentState = getState() as any;
    const currentAccountId =
      currentState.userPreferences.settings.vrr.current_account_id;
    const endpoint =
      END_POINTS.CREATIVE_ANALYTICS.CREATIVE_GALLERY.AD_ACCOUNT_ARCHIVE(
        currentAccountId.toString()
      );

    const response = await apiVRR().delete(endpoint, params);

    if (response.status >= 200) {
      dispatch(notify({ type: 'success' }));
    }

    return response.data.data;
  }
);

export const fetchVideoById = createAsyncThunk(
  'creativeGallery/fetchVideoById',
  async (videoIds: number[], { getState }) => {
    const currentState = getState() as any;
    const currentAccountId =
      currentState.userPreferences.settings.vrr.current_account_id;
    const endPoint =
      END_POINTS.CREATIVE_ANALYTICS.CREATIVE.ALL_VIDEOS(currentAccountId);

    const response = await apiVRR().get(endPoint, {
      params: { video_ids: videoIds.join(',') }
    });

    return response.data.data;
  }
);

export const fetchAllTags = createAsyncThunk(
  'creativeGallery/fetchAllTags',
  async (_, { getState }) => {
    const currentState = getState() as any;
    const currentAccountId =
      currentState.userPreferences.settings.vrr.current_account_id;
    const endPoint =
      END_POINTS.CREATIVE_ANALYTICS.CREATIVE.ALL_TAGS(currentAccountId);

    const response = await apiVRR().get(endPoint);

    return response.data.data;
  }
);

export const updateTags = createAsyncThunk(
  'creativeGallery/updateTags',
  async (videos: TTagList[], { dispatch, getState }) => {
    const currentState = getState() as any;
    const currentAccountId =
      currentState.userPreferences.settings.vrr.current_account_id;
    const endPoint =
      END_POINTS.CREATIVE_ANALYTICS.CREATIVE.UPDATE_TAGS(currentAccountId);

    const response = await apiVRR().put(endPoint, {
      videos
    });

    if (response.status >= 200 && response.status < 400) {
      dispatch(notify({ type: 'success' }));
    }

    return response.data.data;
  }
);
