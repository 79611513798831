import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VrrFloatInput } from '../../../../shared_components';
import { TBudgets, TFunnels } from './types';
import './Inputs.scss';

const Inputs: React.FC<TFunnels> = ({
  isReadOnly = false,
  values,
  onChangeAction
}) => {
  const { t } = useTranslation();
  const [budget, setBudget] = useState<TBudgets | undefined>(undefined);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (values) {
      setBudget(values);
      const newTotal = Object.values(values).reduce((a, b) => a + b, 0);
      setTotal(newTotal);
    }
  }, [values]);

  const onChange = (funnel: string, value?: number) => {
    const newBudget: TBudgets = { ...budget };
    newBudget[funnel as keyof TBudgets] = value ?? 0;
    setBudget(newBudget);
    const newTotal: number = Object.values(newBudget).reduce(
      (a, b) => a + b,
      0
    );
    setTotal(newTotal);
    if (onChangeAction) onChangeAction(newBudget, newTotal);
  };

  return (
    <div className="budget-inputs">
      <div className="budget-input">
        <div className="budget-funnel-label">
          <div className={`budget-funnel total`} />
          <span>{t('launcher.budget.funnel.total')}</span>
        </div>
        <VrrFloatInput
          decimalNumber={2}
          isDisabled
          withCurrency
          value={total}
        />
      </div>
      {budget &&
        Object.keys(budget)?.map((key, index) => (
          <div className="budget-input" key={`budget-${index + 1}`}>
            <div className="budget-funnel-label">
              <div className={`budget-funnel ${key}`} />
              <span>{t(`launcher.budget.funnel.${key}`)}</span>
            </div>
            <VrrFloatInput
              withCurrency
              decimalNumber={2}
              value={budget[key as keyof TBudgets] as number}
              isDisabled={isReadOnly}
              onChangeAction={(value) => onChange(key, value as number)}
            />
          </div>
        ))}
    </div>
  );
};

export default Inputs;
