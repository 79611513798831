import React, { useEffect, useRef, useState } from 'react';
import { TMetricDetailTableHeaders } from './types';
import {
  toLowerKebabCase,
  toSnakeCase
} from 'micro_front_end/services/formatters/formatString';
import { ReactComponent as Info } from '../../../../../../assets/vrr/images/info_icon.svg';
import { VrrTooltip } from 'micro_front_end/shared_components';
import { Popover, PopoverBody } from 'reactstrap';
import { findTranslationKeyForTooltip } from './services';
import { useTranslation } from 'react-i18next';
import { formatHeader } from 'micro_front_end/overview/metrics/metrics/metric_table/services';

const MetricDetailTableHeaders: React.FC<TMetricDetailTableHeaders> = ({
  header,
  metric,
  mainKpi,
  onClickHeader
}: TMetricDetailTableHeaders) => {
  const [pop, setPop] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const handleOnClickHeader = () => {
    if (onClickHeader) onClickHeader(header);
  };

  const handleClickOutside = (event: Event) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node) &&
      pop
    ) {
      setPop(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const formatedHeader = toLowerKebabCase(header);
  const snackedHeader = toSnakeCase(header) ?? '';
  const tooltipId = `${metric}-metric-${formatedHeader}-kpi-tooltip`;
  const translationKeyTooltip = findTranslationKeyForTooltip(snackedHeader);

  const popId = `kpi-${toLowerKebabCase(header)}`;

  return (
    <div>
      <p id={popId}>
        {header === mainKpi ? (
          <span className="main_kpi">{formatHeader(header)}</span>
        ) : (
          formatHeader(header)
        )}
      </p>
      {onClickHeader && (
        <div ref={wrapperRef}>
          <Popover
            id={`cy-${toLowerKebabCase(header)}`}
            placement="top"
            isOpen={pop}
            target={popId}
            toggle={() => setPop((current) => !current)}
            className="popover-table-header"
            onClick={handleOnClickHeader}
          >
            <PopoverBody>
              {t('metrics.metric_detail_modal.table.popover')}
            </PopoverBody>
          </Popover>
        </div>
      )}
      {translationKeyTooltip && (
        <>
          <Info id={tooltipId} />
          <VrrTooltip target={tooltipId} children={t(translationKeyTooltip)} />
        </>
      )}
    </div>
  );
};

export default MetricDetailTableHeaders;
