import React from 'react';
import { TVrrProgress } from './types';
import { Progress } from 'reactstrap';
import './VrrProgress.scss';

const VrrProgress: React.FC<TVrrProgress> = ({
  vrrBarColor,
  ...rest
}: TVrrProgress) => {
  return (
    <Progress {...(vrrBarColor && { barClassName: vrrBarColor })} {...rest} />
  );
};

export default VrrProgress;
