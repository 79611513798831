import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import SimpleDate from '../../simple_date';
import { VrrDropdown } from '../../../../../../micro_front_end/shared_components';
import { TBoostModalDates } from './types';
import './BoostModalDates.scss';

const BoostModalDates: React.FC<TBoostModalDates> = ({
  data,
  splitTestsList,
  currentSplitTestId,
  onEndDateChanged,
  setCurrentSplitTestId
}: TBoostModalDates) => {
  const { t } = useTranslation();

  const isValidDate = (current: Date): boolean => {
    return !moment(current).isBefore(data.startDate);
  };

  const getList = (): { label: string; value: string }[] => {
    return splitTestsList?.map((item) => {
      return { label: item.name, value: item.id.toString() };
    });
  };

  const defaultLabel = [{ label: 'All', value: 'all' }];

  const isEditable = currentSplitTestId !== 'all';

  return (
    <>
      <div className="modal-boost-dates-start">
        <p className="modal-boost-dates-text disabled">
          {t('components.budget.boost.modal.starting_date')}
        </p>
        <SimpleDate
          date={moment(data.startDate)}
          dateValidator={isValidDate}
          editable={false}
        />
      </div>
      <div className="modal-boost-dates-end">
        <p
          className={`modal-boost-dates-text${!isEditable ? ' disabled' : ''}`}
        >
          {t('components.budget.boost.modal.end_date')}
        </p>
        <SimpleDate
          date={moment(data.endDate)}
          onDateChange={onEndDateChanged}
          dateValidator={isValidDate}
          editable={isEditable}
        />
      </div>
      <div className="modal-boost-split-test">
        <p className="modal-boost-split-test-text">
          {t('components.budget.boost.modal.split_test')}
        </p>
        <VrrDropdown
          list={[...defaultLabel, ...getList()]}
          handleChangeAction={(item: { label: string; value: string }) => {
            setCurrentSplitTestId(item.value);
          }}
          currentValue={currentSplitTestId}
        />
      </div>
    </>
  );
};

export default BoostModalDates;
