import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SectionTitle from '../section_title';
import { getInfo } from '../services';
import { SCORE } from './services';
import ScoringChart from './scoring_chart';
import { TScore, TVideoScoringSection } from './types';
import './VideoScoringSection.scss';

const VideoScoringSection: React.FC<TVideoScoringSection> = ({ percent }) => {
  const { t } = useTranslation();

  const [metric, setMetric] = useState<TScore | undefined>(undefined);

  useEffect(() => {
    setMetric(getInfo(Math.ceil(percent), SCORE) as TScore);
  }, [percent]);

  return (
    <>
      <SectionTitle
        title={t('creative_video_details.section_title.video_scoring_section')}
        iconClassName="tim-icons icon-atom"
      />
      <div className="video-scoring-container">
        <div>
          <p className="video-scoring-title">
            {t(
              'creative_video_details.video_scoring_section.title'
            ).toUpperCase()}
          </p>
          <div>{metric?.status}</div>
          <div className="scoring-more">
            <i>{metric?.more ? t(metric?.more) : '-'}</i>
          </div>
        </div>
        <ScoringChart percent={Math.ceil(percent)} color={metric?.color} />
      </div>
    </>
  );
};

export default VideoScoringSection;
