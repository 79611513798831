import React from 'react';
import { ReactComponent as CtaIcon } from '../../../../../../assets/vrr/images/cta_icon.svg';
import { ReactComponent as FunnelIcon } from '../../../../../../assets/vrr/images/funnel_icon.svg';
import { TMetric } from './types';

export const METRICS: TMetric = {
  gender: {
    icon: <i className="tim-icons metric-icon icon-single-02 yellow" />,
    label: 'metrics.metrics_selection_card.metric.gender',
    tooltip: 'metrics.metrics_selection_card.tooltip.gender'
  },
  device: {
    icon: <i className="tim-icons metric-icon icon-tablet-2 purple" />,
    label: 'metrics.metrics_selection_card.metric.device',
    tooltip: 'metrics.metrics_selection_card.tooltip.device'
  },
  cta: {
    icon: <CtaIcon className="custom-icon metric-icon red" />,
    label: 'metrics.metrics_selection_card.metric.cta',
    tooltip: 'metrics.metrics_selection_card.tooltip.cta'
  },
  zone: {
    icon: <i className="tim-icons metric-icon icon-world purple" />,
    label: 'metrics.metrics_selection_card.metric.zone',
    tooltip: 'metrics.metrics_selection_card.tooltip.zone'
  },
  redirection: {
    icon: <i className="tim-icons metric-icon icon-user-run yellow" />,
    label: 'metrics.metrics_selection_card.metric.landing_page',
    tooltip: 'metrics.metrics_selection_card.tooltip.landing_page'
  },
  age: {
    icon: <i className="tim-icons metric-icon icon-single-02 red" />,
    label: 'metrics.metrics_selection_card.metric.age',
    tooltip: 'metrics.metrics_selection_card.tooltip.age'
  },
  video: {
    icon: <i className="tim-icons metric-icon icon-video-66 turquoise" />,
    label: 'metrics.metrics_selection_card.metric.video',
    tooltip: 'metrics.metrics_selection_card.tooltip.video'
  },
  audience: {
    icon: <i className="tim-icons metric-icon icon-world red" />,
    label: 'metrics.metrics_selection_card.metric.targeting',
    tooltip: 'metrics.metrics_selection_card.tooltip.targeting'
  },
  taxonomy: {
    icon: <FunnelIcon className="custom-icon metric-icon purple" />,
    label: 'metrics.metrics_selection_card.metric.targeting_type',
    tooltip: 'metrics.metrics_selection_card.tooltip.targeting_type'
  },
  funnel: {
    icon: <FunnelIcon className="custom-icon metric-icon purple" />,
    label: 'metrics.metrics_selection_card.metric.conversion_funnel',
    tooltip: 'metrics.metrics_selection_card.tooltip.conversion_funnel'
  }
};
