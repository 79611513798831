import React, { useEffect, useState } from 'react';
import AudienceList from './audience_list';
import SearchCategories from './search_categories';
import {
  getAudiencesByFunnel,
  prepareAudiencesForSearch,
  prepareCurrentSelections
} from './services';
import search from '../../../../../../../../services/search';
import { TFunnelSearchList } from './types';
import { TFunnel } from './audience_list/types';
import { TAudienceData } from './audience_list/audience/types';
import { TCategoryLine } from './audience_list/audience/audience_category/audience_category_line/types';
import { TMediaPlanDetails, TTarget } from '../../../../../../types';
import './FunnelSearchList.scss';

const FunnelSearchList: React.FC<TFunnelSearchList> = ({
  selections,
  onChangeAction
}) => {
  const [currentFunnel, setCurrentFunnel] = useState<TFunnel>('top');
  const [currentSelection, setCurrentSelection] = useState<
    TMediaPlanDetails['targets']
  >([]);
  const [currentAudiences, setCurrentAudiences] = useState<TAudienceData[]>(
    getAudiencesByFunnel('top')
  );
  const [filteredAudiences, setFilteredAudiences] = useState<TCategoryLine[]>(
    []
  );
  const audiencesForSearch = prepareAudiencesForSearch(currentAudiences);

  useEffect(() => {
    setCurrentSelection(selections ?? []);
  }, [selections]);

  useEffect(() => {
    if (currentFunnel) {
      const newCurrentFunnel = getAudiencesByFunnel(currentFunnel);
      setCurrentAudiences(newCurrentFunnel);
    }
  }, [currentFunnel]);

  const onFunnelFilter = (value: string, filter: TFunnel) => {
    let audiencesAfterFiltering: TCategoryLine[] = [];
    if (value && value.trim() !== '') {
      audiencesAfterFiltering = search(audiencesForSearch, 'name', value);
    }
    setFilteredAudiences(audiencesAfterFiltering);

    setCurrentFunnel(filter);
  };

  const onChangeCategory = (selection: TTarget, isChecked: boolean) => {
    const newCurrentSelection = prepareCurrentSelections(
      currentSelection,
      selection,
      isChecked
    );
    setCurrentSelection(newCurrentSelection);
    if (onChangeAction) onChangeAction(newCurrentSelection);
  };

  return (
    <section className="funnel-search-list-section">
      <SearchCategories
        onSearchAction={(value, filter) =>
          onFunnelFilter(value as string, filter as TFunnel)
        }
      />
      <AudienceList
        audienceData={currentAudiences}
        currentSelection={currentSelection}
        filteredAudiences={filteredAudiences}
        onChangeAction={onChangeCategory}
      />
    </section>
  );
};

export default FunnelSearchList;
