const CREATIVE_ENDPOINT = {
  ALL_VIDEOS: (ad_account_id: string): string =>
    `/ad_accounts/${ad_account_id}/videos`,
  ALL_TAGS: (ad_account_id: string): string =>
    `/ad_accounts/${ad_account_id}/tags`,
  UPDATE_TAGS: (ad_account_id: string): string =>
    `/ad_accounts/${ad_account_id}/videos`
};

export default CREATIVE_ENDPOINT;
