import i18next from 'i18next';
import moment, { Moment } from 'moment';
import React, { useState, useEffect } from 'react';
import DatetimeRangePicker from 'react-datetime-range-picker';
import { FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import SubMenu from './sub_menu';
import { IRangeDate } from './types';
import LABEL_DATE_SELECTOR from './RangeDate.constant';
import DateRangeService from './RangeDate.service';
import 'moment/locale/fr';
import './RangeDate.scss';

const RangeDate: React.FC<IRangeDate> = ({
  onStartDateChange,
  onEndDateChange,
  onUpdate,
  format = 'YYYY-MM-DD',
  datePickerPeriod,
  className
}: IRangeDate) => {
  const [startDate, setStartDate] = useState<Moment | undefined>(
    datePickerPeriod?.from
  );
  const [endDate, setEndDate] = useState<Moment | undefined>(
    datePickerPeriod?.to
  );
  const [openDatePicker, setOpenDatePicker] = useState<boolean | undefined>(
    false
  );

  const [label, setLabel] = useState<string>(LABEL_DATE_SELECTOR.DEFAULT);

  const setPropsAndStateStartDate = (startDateChange: Moment) => {
    setStartDate(startDateChange);
    if (onStartDateChange) onStartDateChange(startDateChange);
  };

  const setPropsAndStateEndDate = (endDateChange: Moment | undefined) => {
    setEndDate(endDateChange);
    if (onEndDateChange) onEndDateChange(endDateChange);
  };

  const renderViewCustom = (
    mode: string,
    renderDefault: () => React.ReactElement
  ) => {
    if (mode === 'time') return renderDefault();
    return (
      <div className="wrapper">
        <SubMenu
          setStartDate={setPropsAndStateStartDate}
          setEndDate={setPropsAndStateEndDate}
          setLabel={setLabel}
        />
        {renderDefault()}
      </div>
    );
  };

  const ref = React.useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenDatePicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  useEffect(() => {
    if (datePickerPeriod?.from && datePickerPeriod?.to) {
      setStartDate(datePickerPeriod.from);
      setEndDate(datePickerPeriod.to);
    }
  }, [datePickerPeriod]);

  useEffect(() => {
    if (endDate) {
      DateRangeService.sendRangeToApi(
        moment(startDate).startOf('day'),
        moment(endDate).startOf('day'),
        format,
        onUpdate,
        datePickerPeriod
      );
      setOpenDatePicker(false);
    }
  }, [startDate, endDate]);

  const { t } = useTranslation();
  return (
    <FormGroup
      className={`range-date-picker ${className} ${
        startDate && endDate ? 'date-selected' : ''
      }`}
    >
      <Label
        onClick={() => {
          setOpenDatePicker(true);
        }}
        className={`range-date-picker-control ${
          openDatePicker ? 'active' : ''
        }`}
      >
        <div className="period-container">
          {label !== LABEL_DATE_SELECTOR.DEFAULT
            ? t(LABEL_DATE_SELECTOR[label as keyof typeof LABEL_DATE_SELECTOR])
            : t(LABEL_DATE_SELECTOR.DEFAULT, {
                startDate: moment(startDate).format(format),
                endDate: moment(endDate).format(format)
              })}
        </div>
        <div className="icons-container">
          <span className="separator" />
          <span className="icon-calendar">
            <i className="tim-icons icon-calendar-60" />
          </span>
        </div>
      </Label>
      <div className="date-time-picker" ref={ref}>
        <DatetimeRangePicker
          timeFormat={false}
          input
          inputProps={{
            open: openDatePicker,
            locale: i18next.language,
            renderView: (mode: any, renderDefault: any) =>
              renderViewCustom(mode, renderDefault)
          }}
          startDate={startDate}
          endDate={endDate ?? startDate}
          moment={moment.locale(i18next.language)}
          onStartDateChange={(dateToChange: Moment) => {
            DateRangeService.clickDateToChange(
              dateToChange,
              setPropsAndStateEndDate,
              setPropsAndStateStartDate,
              setLabel,
              startDate,
              endDate
            );
          }}
        />
      </div>
    </FormGroup>
  );
};

export default RangeDate;
