import { TNotification } from '../../../../redux/types';

export const sortByDate = (notifications: TNotification[]): TNotification[] => {
  const newNotifications = [...notifications];
  return newNotifications.sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    const last = dateA > dateB ? -1 : 0;
    return dateA < dateB ? 1 : last;
  });
};
