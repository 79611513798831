import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ButtonGroup, Button } from 'reactstrap';
import { PrivateComponent } from '../../../micro_front_end/permissions';
import { INSIGHTS_CARD_STATUS } from '../../../services/constants/status';
import { getFeatureByPath } from '../../../micro_front_end/routing/services';
import { getFeatures } from '../../../redux/app/appActions';
import { TPublishTabs, TTab } from './types';
import './PublishTabs.scss';

const PublishTabs: React.FC<TPublishTabs> = ({
  currentTab,
  onClickGroupButton
}) => {
  const { t } = useTranslation();
  const features = useSelector(getFeatures);
  const [tab, setTab] = useState<string>(INSIGHTS_CARD_STATUS.PUBLISHED);
  const [tabs, setTabs] = useState<TTab[]>([
    {
      id: 'unpublished',
      label: 'components.insights.publishTabs.buttons.unpublished',
      isActive: true
    },
    {
      id: 'published',
      label: 'components.insights.publishTabs.buttons.published',
      isActive: false
    },
    {
      id: 'hidden',
      label: 'components.insights.publishTabs.buttons.hidden',
      isActive: false
    }
  ]);

  const updateActiveTabs = () => {
    const newTabs: TTab[] = tabs.map((tb: TTab) => {
      return {
        ...tb,
        isActive: tb.id === tab
      };
    });

    setTabs([...newTabs]);
  };

  useEffect(() => {
    if (currentTab && currentTab !== tab) {
      setTab(currentTab);
    }
  }, [currentTab]);

  useEffect(() => {
    if (tab) {
      updateActiveTabs();
    }
  }, [tab]);

  const onClickAction = (id: string) => {
    setTab(id);

    if (onClickGroupButton) onClickGroupButton(id);
  };

  const children = (tb: TTab, isAuthorized: any) => {
    return (
      <Button
        key={tb.id}
        disabled={tb.id !== INSIGHTS_CARD_STATUS.PUBLISHED && !isAuthorized}
        className={`btn-round ${tb.id} ${tb.isActive ? 'active' : 'inactive'}`}
        onClick={() => onClickAction(tb.id)}
      >
        {t(tb.label)}
      </Button>
    );
  };

  return (
    <div>
      <ButtonGroup className="publish-tabs-component">
        {tabs.map((tb: TTab) => {
          return (
            <PrivateComponent
              key={tb.id}
              authorizationStrategy={() =>
                getFeatureByPath(features, 'insights.edit')
              }
              useCallback
            >
              {({ isAuthorized }: any) => children(tb, isAuthorized)}
            </PrivateComponent>
          );
        })}
      </ButtonGroup>
    </div>
  );
};

export default PublishTabs;
