import React from 'react';
import { Spinner } from 'reactstrap';
import { TVrrCircleLoader } from './types';
import './VrrCircleLoader.scss';

const VrrCircleLoader: React.FC<TVrrCircleLoader> = ({
  isEnable,
  children,
  displayContentWhenLoad
}: TVrrCircleLoader) => {
  return (
    <div className={`circle-loader circle-loader${isEnable ? '-active' : ''}`}>
      {isEnable && (
        <Spinner data-cy="circle-loader" className="vrr-spinner" sm="1rem" />
      )}
      {isEnable && displayContentWhenLoad && children && (
        <div className="circle-loader-content">{children}</div>
      )}
      {!isEnable && children && (
        <div className="circle-loader-content">{children}</div>
      )}
    </div>
  );
};

export default VrrCircleLoader;
