import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Collapse, Nav, Navbar } from 'reactstrap';
import moment from 'moment';
import i18next from 'i18next';
import 'moment/locale/fr';
import DateTimePicker from './date_time_picker';
import AvatarButton from './avatar_button';
import KebabButton from './kebab_button';
import { useClassNameState } from './useClassNameState';
import AccountDropdown from './account_dropdown';
import LabelDropdown from './label_dropdown';
import LastUpdateDate from './last_update_date';
import { PrivateComponent } from '../permissions';
import NotificationWidget from './notification_widget';
import { getFeatureByPath } from '../routing/services';
import { getUserProfile } from '../../redux/userProfile/userProfileActions';
import { getFeatures } from '../../redux/app/appActions';
import { logout } from '../../redux/app/appSlice';
import { INavBar } from './types';
import './NavBar.scss';

export const NavBar: React.FC<INavBar> = ({ elements }) => {
  moment.locale(i18next.language);
  const [collapseOpen, setCollapseOpen] = useState<boolean>(false);
  const { currentClassName, setCurrentClassName } = useClassNameState(
    'navbar-transparent',
    collapseOpen
  );
  const dispatch: any = useDispatch();
  const features = useSelector(getFeatures);
  const currentUserProfile = useSelector(getUserProfile);

  const elementList = {
    account: () => <AccountDropdown key="navbar-account" />,
    date: () => <DateTimePicker key="navbar-date" />,
    funnel: () => <LabelDropdown key="navbar-funnel" />,
    lastUpdate: () => (
      <PrivateComponent
        key="navbar-lastUpdate"
        authorizationStrategy={() =>
          getFeatureByPath(features, 'overview.optimize')
        }
      >
        <LastUpdateDate />
      </PrivateComponent>
    )
  };

  const toggleCollapse = () => {
    setCurrentClassName(collapseOpen ? 'navbar-transparent' : 'bg-white');
    setCollapseOpen(!collapseOpen);
  };

  return (
    <Navbar className={`vrr-navbar ${currentClassName}`} expand="lg">
      <Container fluid className="vrr-navbar-container">
        <div className="navbar-wrapper">
          {Object.keys(elementList).map((element) => {
            if (elements?.includes(element)) {
              return (elementList as any)[element]();
            }
          })}
        </div>
        <KebabButton onOpen={toggleCollapse} />
        <Collapse navbar isOpen={collapseOpen} className="vrr-navbar-collapse">
          <Nav className="ml-auto" navbar>
            <PrivateComponent
              key="navbar-lastUpdate"
              authorizationStrategy={() =>
                getFeatureByPath(features, 'notifications.access')
              }
            >
              <NotificationWidget />
            </PrivateComponent>
            <AvatarButton
              username={currentUserProfile.username}
              logoutAction={() => {
                dispatch(logout());
              }}
            />
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
