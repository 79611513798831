import React from 'react';
import { useTranslation } from 'react-i18next';
import { getInfo } from '../../../../../creative_analytics/creative_video_details/services';
import { SCORE } from '../../../../../creative_analytics/creative_video_details/video_scoring_section/services';
import { TVideoCardBody } from './types';
import { TScore } from '../../../../../creative_analytics/creative_video_details/video_scoring_section/types';
import './VideoCardBody.scss';

const VideoCardBody: React.FC<TVideoCardBody> = ({ score, thumbnail }) => {
  const { t } = useTranslation();
  return (
    <div className="video-card-body">
      <img src={thumbnail} alt="thumbnail" />
      <div className="video-card-right-section">
        <div>
          <div className="right-title">
            <p className="right-section">
              {t('launcher.video_selection.video_card.body.score')}
            </p>
          </div>
          <p
            className={`right-score ${getInfo(Math.ceil(score), SCORE as TScore[])?.color}`}
          >
            {score ?? '-'} / 100
          </p>
        </div>
        <div>
          <div className="right-title">
            <p className="right-section">
              {t('launcher.video_selection.video_card.body.platform')}
            </p>
          </div>
          <p className="right-platform">Youtube friendly</p>
        </div>
      </div>
    </div>
  );
};

export default VideoCardBody;
