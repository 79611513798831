import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'reactstrap';
import { ReactComponent as Mobile } from '../../../../../../assets/vrr/images/mobile.svg';
import { ReactComponent as Desktop } from '../../../../../../assets/vrr/images/desktop.svg';
import { ReactComponent as Tv } from '../../../../../../assets/vrr/images/tv.svg';
import { TVideoCardFooter } from './types';
import './VideoCardFooter.scss';

const VideoCardFooter: React.FC<TVideoCardFooter> = ({
  id,
  devices,
  onChangeAction
}) => {
  const { t } = useTranslation();

  const onChange = (device: string, isActive: boolean) => {
    if (device && onChangeAction) {
      onChangeAction(device, isActive);
    }
  };

  return (
    <div className="video-card-footer">
      <div>
        <Input
          id={`mobile-${id}`}
          type="checkbox"
          checked={devices?.mobile}
          className="card-footer-checkbox mobile"
          onChange={() => onChange('mobile', !devices?.mobile)}
        />
        <label htmlFor={`mobile-${id}`} className="check">
          <span
            className={`card-footer-device ${devices?.mobile ? 'is-active' : ''}`}
          >
            <Mobile />
          </span>
          {t('creative_video_details.information_section.devices.mobile')}
        </label>
      </div>
      <div>
        <Input
          id={`desktop-${id}`}
          type="checkbox"
          checked={devices?.desktop}
          className="card-footer-checkbox desktop"
          onChange={() => onChange('desktop', !devices?.desktop)}
        />
        <label htmlFor={`desktop-${id}`} className="check">
          <span
            className={`card-footer-device ${devices?.desktop ? 'is-active' : ''}`}
          >
            <Desktop />
          </span>
          {t('creative_video_details.information_section.devices.desktop')}
        </label>
      </div>
      <div>
        <Input
          id={`connected_tv-${id}`}
          type="checkbox"
          checked={devices?.connected_tv}
          className="card-footer-checkbox connected-tv"
          onChange={() => onChange('connected_tv', !devices?.connected_tv)}
        />
        <label htmlFor={`connected_tv-${id}`} className="check">
          <span
            className={`card-footer-device ${devices?.connected_tv ? 'is-active' : ''}`}
          >
            <Tv />
          </span>
          {t('creative_video_details.information_section.devices.tv')}
        </label>
      </div>
    </div>
  );
};

export default VideoCardFooter;
