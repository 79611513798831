import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MetricDetailHeader from './metric_detail_header';
import MetricDetailTable from './metric_detail_table';
import MetricDetailModal from './metric_detail_modal';
import MetricDetailPin from './metric_detail_pin';
import useSettings from '../../../../libs/settings/useSettings';
import { METRIC_DETAIL_TYPE, prepareKpis, prepareTable } from './services';
import {
  getKpiSelections,
  getMainKpi,
  getPinedMetrics
} from '../../../../redux/userPreferences/actions/userPreferencesActions';
import { getOneMetric } from '../../../../redux/overview/metrics/MetricsActions';
import { TMetricDetailHeaderModal } from './metric_detail_header/types';
import { TMetricDetail } from './types';
import { TMetricDetails } from 'redux/overview/metrics/types';

const MetricDetail: React.FC<TMetricDetail> = ({
  type,
  metric,
  isOpen = false,
  onCloseAction
}) => {
  const { PIN, MODAL } = METRIC_DETAIL_TYPE;
  const metricData = useSelector(getOneMetric(metric));
  const kpiSelections = useSelector(getKpiSelections);
  const mainKpi = useSelector(getMainKpi);
  const pinedMetrics = useSelector(getPinedMetrics) ?? [];
  const { overview } = useSettings();

  const [table, setTable] = useState<TMetricDetails>({ header: [], data: [] });

  useEffect(() => {
    const kpis = prepareKpis(metricData?.header, kpiSelections, mainKpi);
    if (metricData && metric) {
      setTable(prepareTable(kpis, metricData));
    }
  }, [kpiSelections, metricData, metric]);

  const removePinedMetric = () => {
    if (pinedMetrics.includes(metric)) {
      overview('global_insights', {
        pinnedMetrics: pinedMetrics.filter((m) => m !== metric)
      });
    }
  };

  const onClose = () => {
    if (type === PIN) {
      removePinedMetric();
    }
    if (onCloseAction) onCloseAction();
  };

  const Header = () => {
    return (
      <MetricDetailHeader
        withPin={type === MODAL}
        metric={metric as TMetricDetailHeaderModal['metric']}
        onCloseAction={onClose}
      />
    );
  };

  const Body = () => {
    return (
      <MetricDetailTable
        metricData={table?.data || []}
        headers={table?.header || []}
        metric={metric as TMetricDetailHeaderModal['metric']}
      />
    );
  };

  return type === MODAL ? (
    <MetricDetailModal isOpen={isOpen} header={<Header />} body={<Body />} />
  ) : (
    <MetricDetailPin header={<Header />} body={<Body />} />
  );
};
export default MetricDetail;
