import React, { useState, useEffect } from 'react';
import { ListGroupItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import './BudgetListItem.scss';
import { TBudgetListItem } from '../types';
import { normalFormatter } from '../../../../../../micro_front_end/services/formatters/formatNumber';
import { Input } from 'reactstrap';

const BudgetListItem: React.FC<TBudgetListItem> = ({
  data,
  handleChange,
  symbol
}: TBudgetListItem) => {
  const { type, name, value, editable, payloadName, error } = data;
  const { t } = useTranslation();
  const itemName = t(`components.budget.boost.budget_list.${name}`);
  const [edit, setEdit] = useState<boolean>();
  const significantDigits = 2;

  useEffect(() => {
    if (editable === true) setEdit(false);
  }, []);

  return (
    <ListGroupItem className={`budget-boost-list-${type}`}>
      <div className="budget-boost-list-sub-item">
        {type === 'title' ? <h4>{itemName}</h4> : <p>{itemName}</p>}
        {edit && payloadName ? (
          <div className="boost-input">
            <Input
              autoFocus
              className={error ? 'error' : ''}
              type="number"
              defaultValue={Number(value).toFixed(significantDigits)}
              onBlur={(e: any) => {
                setEdit(false);
                handleChange(+e.target.value, payloadName);
              }}
            />
          </div>
        ) : (
          <p
            role="button"
            className={error ? 'error' : ''}
            onClick={() => {
              if (editable === true) setEdit(true);
            }}
          >
            {`${normalFormatter(Number(value), {
              thousandsSeparator: t('local.separators.thousands'),
              decimalSeparator: t('local.separators.decimal'),
              significantDigits
            })} ${symbol}`}
          </p>
        )}
      </div>
    </ListGroupItem>
  );
};

export default BudgetListItem;
