import React from 'react';
import { useSelector } from 'react-redux';
import Budget from '../budget';
import MediaPlanBody from '../../../media_plan/media_plan_details/media_plan_body';
import { getMediaPlan } from '../../../../redux/mediaPlanGallery/mediaPlanGalleryAction';
import { getLaunch } from '../../../../redux/launcher/launcherActions';
import './Summary.scss';

const Summary: React.FC = () => {
  const mediaPlan = useSelector(getMediaPlan);
  const launch = useSelector(getLaunch);

  return (
    <div className="launcher-summary">
      <Budget
        budgetValues={launch?.details.budgets}
        budgetDates={{
          startDate: launch?.details.startDate,
          endDate: launch?.details.endDate
        }}
        isReadOnly
      />
      <div className="media-plan-details-container-wrapper">
        {mediaPlan && <MediaPlanBody mediaPlanDetails={mediaPlan} />}
      </div>
    </div>
  );
};

export default Summary;
