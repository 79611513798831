import React, { PropsWithChildren, ReactElement } from 'react';
import Menu from '../menu';
import Footer from '../footer';
import NavBar from '../nav_bar';
import RestrictionContainer from '../../containers/Restriction';
import './Layout.scss';

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="vrr-layout">
      <Menu />
      <NavBar
        elements={
          (children as ReactElement)?.props?.routerProps?.navBarElements
        }
      />
      <div className="vrr-content">
        <div className="locate-children">{children}</div>
        <Footer />
      </div>
      <RestrictionContainer />
    </div>
  );
};

export default Layout;
