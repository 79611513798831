import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TCreativeImportVideo } from './types';
import {
  importVideosAction,
  advancedPreviewAction,
  advancedImportAction
} from './creativeImportVideoActions';

const initialState: TCreativeImportVideo = {
  isComplete: false,
  errors: undefined,
  importVideos: undefined,
  previews: undefined
};

const creativeImportVideoSlice = createSlice({
  name: 'creativeImportVideo',
  initialState: { ...initialState },
  reducers: {
    initializePreviews: (state) => {
      return {
        ...state,
        importVideos: undefined,
        previews: undefined,
        errors: undefined,
        isComplete: false
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      importVideosAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          importVideos: action.payload,
          errors: undefined
        };
      }
    );
    builder.addCase(
      importVideosAction.rejected,
      (state, action: PayloadAction<any> & { error: any }) => {
        return {
          ...state,
          importVideos: undefined,
          errors: JSON.parse(action.error.message).data?.error
        };
      }
    );
    builder.addCase(
      advancedPreviewAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          previews: action.payload,
          errors: undefined
        };
      }
    );
    builder.addCase(
      advancedPreviewAction.rejected,
      (state, action: PayloadAction<any> & { error: any }) => {
        return {
          ...state,
          previews: undefined,
          errors: JSON.parse(action.error.message).data?.error
        };
      }
    );
    builder.addCase(
      advancedImportAction.rejected,
      (state, action: PayloadAction<any> & { error: any }) => {
        return {
          ...state,
          errors: JSON.parse(action.error.message).data?.message
        };
      }
    );
    builder.addCase(advancedImportAction.fulfilled, (state) => {
      return {
        ...state,
        isComplete: true
      };
    });
  }
});

export const { initializePreviews } = creativeImportVideoSlice.actions;

export default creativeImportVideoSlice.reducer;
