import React from 'react';
import moment from 'moment';
import { NOTIFICATIONS } from './services';
import { TNotificationItem, TNotifications } from './types';
import { VrrButton } from '../../../../shared_components';
import './NotificationItem.scss';

const NotificationItem: React.FC<TNotificationItem> = ({
  notification,
  onClickAction
}) => {
  const { id, type, name, createdAt, isRead, accountName, resourceUrl } =
    notification;

  const icon = NOTIFICATIONS[type as keyof TNotifications];

  const onUpdate = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (onClickAction) onClickAction(id);
  };

  return (
    <div
      className={`notification-item${
        isRead ? '' : '-read'
      } notification-${type}`}
    >
      <VrrButton {...(!isRead && { onClick: (e) => onUpdate(e) })}>
        <div className="notification-container">
          <div className="notification-text">
            <span className="notification-icon">{icon}</span>
            <div>
              {!!resourceUrl?.length ? (
                <a
                  className="notification-link"
                  href={resourceUrl}
                  target="_blank"
                >
                  <span className="notification-name" title={name}>
                    {name}
                  </span>
                </a>
              ) : (
                <span className="notification-name" title={name}>
                  {name}
                </span>
              )}
              <span>
                {moment(createdAt, 'YYYY-MM-DD HH:mm').fromNow()}
                {accountName ? ` (${accountName})` : ''}
              </span>
            </div>
          </div>
          {!isRead ? <div className="notification-status" /> : null}
        </div>
      </VrrButton>
    </div>
  );
};

export default NotificationItem;
