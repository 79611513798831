import React from 'react';
import './VrrBreadcrumb.scss';
import { TVrrBreadcrumb } from './types';

const VrrBreadCrumb: React.FC<TVrrBreadcrumb> = ({
  items,
  onClickItem
}: TVrrBreadcrumb) => {
  return (
    <div className="vrr_breadcrumb-container">
      {items.map((item, index) => {
        return (
          <React.Fragment key={`vrr-breadcrumb-item-${item.id}`}>
            <h4 className="vrr_breadcrumb-item">
              <span
                className={`${
                  onClickItem && index < items.length - 1
                    ? 'vrr-breadcrumb-action'
                    : ''
                } `}
                onClick={() => {
                  if (onClickItem && index < items.length - 1)
                    onClickItem(item);
                }}
              >
                {item.label}
              </span>
              {index !== items.length - 1 ? <span>{' > '}</span> : null}
            </h4>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default VrrBreadCrumb;
