import React from 'react';
import { TMediaPlanCard } from './types';
import { VrrCard } from '../../../shared_components';
import MediaPlanCardHeader from './MediaPlanCardHeader';
import MediaPlanCardBody from './MediaPlanCardBody';
import MediaPlanCardFooter from './MediaPlanCardFooter';
import './styles/MediaPlanCard.scss';

const MediaPlanCard: React.FC<TMediaPlanCard> = ({
  name,
  createdAt,
  offer,
  funnels,
  videosCount,
  genders,
  ages,
  zones,
  onCardClick
}: TMediaPlanCard) => {
  return (
    <VrrCard
      dataCy="media-plan-gallery-card"
      className="media-plan-card"
      cardHeader={<MediaPlanCardHeader {...{ name, createdAt }} />}
      cardBody={{
        element: <MediaPlanCardBody {...{ offer, funnels, videosCount }} />
      }}
      cardFooter={<MediaPlanCardFooter {...{ genders, ages, zones }} />}
      onClick={onCardClick}
    />
  );
};

export default MediaPlanCard;
