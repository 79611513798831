import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CardBody, CardImg, Input } from 'reactstrap';
import moment from 'moment/moment';
import { VrrButton, VrrCard, VrrTooltip } from '../../../../shared_components';
import { PrivateComponent } from '../../../../permissions';
import URLS from '../../../../routing/urls';
import { getFeatureByPath } from '../../../../routing/services';
import { getAccountId } from '../../../../../redux/userPreferences/actions/userPreferencesActions';
import { getFeatures } from '../../../../../redux/app/appActions';
import { ReactComponent as Icon } from '../../../../../assets/vrr/images/adsicon.svg';
import { TCreativeCard } from './types';
import './CreativeCard.scss';

const CreativeCard: React.FC<TCreativeCard> = ({
  id,
  url,
  thumbnail,
  title,
  duration,
  updatedDate,
  onChangeAction,
  onDragAction
}) => {
  const accountId = useSelector(getAccountId);
  const features = useSelector(getFeatures);
  const history = useNavigate();

  const goToDetail = () => {
    if (accountId?.length) {
      history({
        pathname: URLS.VIDEO.replace(
          ':accountId/:videoId',
          `${accountId}/${id}`
        )
      });
    }
  };

  const onChange = (e: any) => {
    if (onChangeAction) onChangeAction(id, e.target.checked);
  };

  const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.setData('text', id.toString());
    if (onDragAction) onDragAction(true);
  };

  const onDragEnd = () => {
    if (onDragAction) onDragAction(false);
  };

  return (
    <label htmlFor={`video-${id}`}>
      <VrrCard
        draggable
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        className="video-gallery-card"
        cardHeader={
          <>
            <CardImg draggable top src={thumbnail} />
            <div className="checkbox-card">
              <Input
                data-cy-id="creative-video-checkbox"
                type="checkbox"
                id={`video-${id}`}
                readOnly
                onChange={onChange}
              />
              <label htmlFor={`video-${id}`} className="check">
                <span className="checkbox" />
              </label>
            </div>
          </>
        }
        cardBody={{
          element: (
            <CardBody className="video-gallery-card-body">
              <PrivateComponent
                authorizationStrategy={() =>
                  getFeatureByPath(features, 'creative.detail')
                }
                useCallback
              >
                {({ isAuthorized }: { isAuthorized: boolean }) => (
                  <VrrButton
                    className={`creative-video-detail-button${
                      !isAuthorized ? ' non-authorized' : ''
                    }`}
                    onClick={goToDetail}
                  >
                    <Icon />
                  </VrrButton>
                )}
              </PrivateComponent>
              <div className="about-video">
                <div id={`video-title-${id}`} className="status-video">
                  <span className="video-title-text">
                    <a href={url} target="_blank">
                      {title}
                    </a>
                  </span>
                </div>
                <VrrTooltip target={`video-title-${id}`} children={title} />
                <div className="about-time">
                  <div className="time-ago">
                    {moment(updatedDate).fromNow()}
                  </div>
                  <div className="duration">
                    {moment.utc(duration * 1000).format('mm:ss')}
                  </div>
                </div>
              </div>
            </CardBody>
          )
        }}
      />
    </label>
  );
};

export default CreativeCard;
