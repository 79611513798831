import React from 'react';
import VrrButton from '../vrr_button';
import VrrSearch from '../vrr_search';
import { ReactComponent as Filter } from '../../../assets/vrr/images/top_down.svg';
import { THeader, TTableHeader } from './types';
import './styles/VrrTableHeader.scss';

const Header: React.FC<THeader> = ({ element, onSort, onFilter }: THeader) => {
  return (
    <div>
      <div className={`table-header ${element?.rowClassName ?? ''}`}>
        {element?.component}
        <VrrButton
          className={`btn-link ${
            onSort ? 'table-header-sort' : 'hide-table-header-sort'
          }`}
          onClick={() => {
            if (onSort && element?.id) onSort(element.id);
          }}
        >
          <Filter />
        </VrrButton>
      </div>
      <div
        className={
          onFilter ? 'table-header-filter' : 'hide-table-header-filter'
        }
      >
        {onFilter ? (
          <VrrSearch
            onSearchAction={(searchValue) => onFilter(searchValue, element?.id)}
            withIcon={false}
          />
        ) : null}
      </div>
    </div>
  );
};

const VrrTableHeader: React.FC<TTableHeader> = ({
  elements,
  onSort,
  onFilter,
  isFixed = false,
  withActions = false
}: TTableHeader) => {
  return (
    <thead>
      <tr>
        {elements?.map((element) => (
          <th key={element.id} className={isFixed ? 'fix-header' : ''}>
            <Header onSort={onSort} onFilter={onFilter} element={element} />
          </th>
        ))}
        {withActions && <th />}
      </tr>
    </thead>
  );
};

export default VrrTableHeader;
