import { Button } from 'reactstrap';
import { TVrrButton } from './types';
import './VrrButton.scss';

const VrrButton: React.FC<TVrrButton> = (props) => {
  const { dataCy, ...rest } = props;
  return (
    <span className="vrr-button" data-cy-id={dataCy}>
      <Button {...rest}>
        {props.icon && <span className={`${props.icon.iconLabel}`} />}
        {props.children}
      </Button>
    </span>
  );
};

export default VrrButton;
