export const SIDES = {
  left: 'left',
  right: 'right'
};

export const TARGETS = {
  zones: 'zones',
  genders: 'genders',
  ages: 'ages',
  targets: 'targets'
};

export const SECTIONS = [
  {
    titleId: TARGETS.zones,
    descriptionSide: SIDES.right,
    values: undefined
  },
  {
    titleId: TARGETS.genders,
    descriptionSide: SIDES.right,
    values: undefined
  },
  {
    titleId: TARGETS.ages,
    descriptionSide: SIDES.right,
    values: undefined
  },
  {
    titleId: TARGETS.targets,
    descriptionSide: SIDES.left,
    values: undefined
  }
];

export type TMediaPlanTargets = {
  targetsValues?: TTargetsValues;
  onClickEditButton?: (target: any) => void;
};

export type TGendersSection = {
  genders: string[];
};

export type TZonesSection = {
  zones: string[];
};

export type TAgesSection = {
  ages: string[];
};

export type TDefaultSection = {
  data: TAgesSection['ages'] | TZonesSection['zones'];
};

export type TTargetsSection = {
  targets: TTargets;
};

export type TTargetsValues = TTargetsSection &
  TAgesSection &
  TZonesSection &
  TGendersSection;

export type TTargets = {
  top: number;
  middle: number;
  bottom: number;
};

export type TMediaPlanTargetsSection = {
  titleId: string;
  descriptionSide?: 'left' | 'right';
  values?: string[] | TTargets;
};

export type TOnClickEditButton = {
  onClickEditButton?: (target: string) => void;
};
