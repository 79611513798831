import moment, { Moment } from 'moment';
import { TDateRange } from '../types';
import LABEL_DATE_SELECTOR from './RangeDate.constant';

const DateRangeService = {
  clickDateToChange: (
    dateToChange: Moment,
    setEndDate: (value: Moment | undefined) => void,
    setStartDate: (value: Moment) => void,
    setLabel: (value: string) => void,
    startDate?: Moment | undefined,
    endDate?: Moment
  ): void => {
    let startDateToChange: Moment | undefined = startDate;
    let endDateToChange: Moment | undefined = endDate;
    if (endDate === undefined) {
      if (moment(dateToChange).isBefore(moment(startDate))) {
        startDateToChange = moment(dateToChange).subtract(1, 'days');
      }
      if (moment(dateToChange).isAfter(moment(startDate))) {
        endDateToChange = moment(dateToChange).subtract(1, 'days');
      }
    } else {
      startDateToChange = dateToChange;
      if (moment(dateToChange).isAfter(moment(endDateToChange))) {
        startDateToChange = moment(dateToChange).subtract(1, 'days');
      }
      endDateToChange = undefined;
    }
    if (startDateToChange) setStartDate(startDateToChange);
    setEndDate(endDateToChange);
    setLabel(LABEL_DATE_SELECTOR.DEFAULT);
  },
  sendRangeToApi: (
    startDate: Moment,
    endDate: Moment,
    format: string,
    onUpdate?: (datePeriod: TDateRange) => void,
    period?: TDateRange
  ): void => {
    if (
      !period ||
      period.from?.format(format) !== startDate.format(format) ||
      period.to?.format(format) !== endDate.format(format)
    ) {
      if (onUpdate) onUpdate({ from: startDate, to: endDate });
    }
  }
};

export default DateRangeService;
