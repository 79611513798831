import React from 'react';
import { VrrModal } from '../../../../shared_components';
import { ModalBody } from 'reactstrap';
import './MetricDetailModal.scss';
import { TMetricDetailModal } from './types';

const MetricDetailModal: React.FC<TMetricDetailModal> = ({
  isOpen,
  body,
  header
}) => {
  return (
    <VrrModal
      className="metric-detail-modal"
      isOpen={isOpen}
      modalHeader={header}
      modalBody={
        <ModalBody className="metric-detail-modal-body">{body}</ModalBody>
      }
      modalFooter={<></>}
      scrollable
    />
  );
};
export default MetricDetailModal;
