import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import GoalList from './goal_list';
import { VrrInput, VrrModal } from '../../../../../../shared_components';
import { toSnakeCase } from '../../../../../../services/formatters/formatString';
import {
  editMediaPlanDetails,
  getMediaPlan
} from '../../../../../../../redux/mediaPlanGallery/mediaPlanGalleryAction';
import { TObjectiveModal, TObjectives } from './types';
import './ObjectiveModal.scss';

const ObjectiveModal: React.FC<TObjectiveModal> = ({
  isOpen,
  onCancelAction
}) => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();
  const mediaPlanObjectives = useSelector(getMediaPlan);
  const [currentValues, setCurrentValues] = useState<TObjectives>({
    name: mediaPlanObjectives?.name ?? '',
    goal:
      (toSnakeCase(mediaPlanObjectives?.goal) as TObjectives['goal']) ??
      'impressions'
  });

  useEffect(() => {
    if (mediaPlanObjectives && Object.values(mediaPlanObjectives).length) {
      setCurrentValues({
        name: mediaPlanObjectives.name,
        goal:
          (toSnakeCase(mediaPlanObjectives.goal) as TObjectives['goal']) ||
          'impressions'
      });
    }
  }, [mediaPlanObjectives]);

  const onChangeName = (e: React.FormEvent<HTMLInputElement>) => {
    const value: TObjectives['name'] = e.currentTarget.value;
    setCurrentValues({
      ...currentValues,
      name: value
    });
  };

  const onSubmitAction = () => {
    dispatch(editMediaPlanDetails({ ...currentValues }));
    if (onCancelAction) onCancelAction();
  };

  const resetSelection = () => {
    setCurrentValues({
      name: mediaPlanObjectives?.name ?? '',
      goal:
        (toSnakeCase(mediaPlanObjectives?.goal) as TObjectives['goal']) ??
        'impressions'
    });
  };

  return (
    <VrrModal
      className="objective-modal"
      isOpen={isOpen}
      icon={<i className="tim-icons icon-spaceship circle-icon" />}
      title={t('media_plan_edit.media_plan_config.objective.title')}
      modalBody={
        <div className="objective-modal-body" data-cy="objective-body-modal">
          <VrrInput value={currentValues?.name} onChangeAction={onChangeName} />
          <GoalList
            selected={currentValues?.goal}
            onSelectAction={(goalId: TObjectives['goal']) =>
              setCurrentValues({ ...currentValues, goal: goalId })
            }
          />
        </div>
      }
      toggleModal={() => {
        resetSelection();
        if (onCancelAction) onCancelAction();
      }}
      toggleValidation={onSubmitAction}
      enabledValidationButton={!!currentValues?.name?.length}
      dataCyCancelButton={`cancel-button-media-plan-details-objective-modal`}
    />
  );
};

export default ObjectiveModal;
