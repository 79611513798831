import React, { useEffect, useState } from 'react';
import VrrTable from '../../../../shared_components/vrr_table';
import { MAX_COLUMN, prepareBody, prepareHeader } from './services';
import { TTagTable } from './types';
import { TCreativeVideo } from '../../../../../redux/creativeAnalytics/creativeGallery/types';
import './TagTable.scss';

const TagTable: React.FC<TTagTable> = ({ videos, tags, onChangeAction }) => {
  const [dataTable, setDataTable] = useState(videos);
  const [currentData, setCurrentData] = useState<TCreativeVideo[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);

  useEffect(() => {
    if (videos) {
      setDataTable(videos);
    }
  }, [videos]);

  const prepareVideos = (page: number) => {
    setIsLastPage(Math.ceil(dataTable.length / MAX_COLUMN) === page);
    return dataTable.slice((page - 1) * MAX_COLUMN, page * MAX_COLUMN);
  };

  useEffect(() => {
    if (dataTable) {
      setCurrentData(prepareVideos(currentPage));
    }
  }, [dataTable]);

  const onChange = (tagId: number, videoId: number, isChecked: boolean) => {
    let newData = [...dataTable];
    let allVideoTags: number[] = [];
    newData = newData.map((d) => {
      if (d && d.VRRId === videoId) {
        const newD = { ...d };
        if (isChecked && !d.tagsId?.includes(tagId)) {
          newD.tagsId = [...newD.tagsId, tagId];
        } else if (!isChecked && d.tagsId.includes(tagId)) {
          const newTagIds = newD.tagsId?.filter((t: any) => t !== tagId);
          newD.tagsId = [...newTagIds];
        }
        allVideoTags = newD.tagsId;
        return newD;
      }
      return d;
    });

    setDataTable(newData);
    if (onChangeAction) onChangeAction(allVideoTags, tagId, videoId);
  };

  const onChangePage = (slide: 'next' | 'previous') => {
    const newPage = slide === 'next' ? currentPage + 1 : currentPage - 1;
    setCurrentPage(newPage);
    setCurrentData(prepareVideos(newPage));
  };

  return (
    <VrrTable
      header={prepareHeader(
        currentData,
        currentPage,
        isLastPage,
        dataTable.length,
        onChangePage
      )}
      body={prepareBody(onChange, currentData, tags)}
      tableProps={{
        borderless: true,
        className: 'tag-table'
      }}
    />
  );
};

export default TagTable;
