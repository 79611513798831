import React, {
  FormEvent,
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import { VrrTable } from '../../../../../../../shared_components';
import {
  formatHeaderTable,
  formatBodyTable,
  NEW_INDEX,
  isDuplicated
} from './services';
import {
  TCallToActionRow,
  TCtaTable,
  THeaderId,
  charactersLimit,
  TCtaTableActions
} from './types';
import ctaTableStyles from './CtaTable.module.scss';
import './CtaTable.scss';

export const CtaTable = (
  { callToActions, onChangeAction }: TCtaTable,
  ref: Ref<any>
): JSX.Element => {
  const [ctas, setCtas] = useState<TCallToActionRow[]>([]);
  const [originCtas, setOriginCtas] = useState<TCallToActionRow[]>([]);
  const [ctaOnEditMode, setCtaOnEditMode] = useState<string | undefined>(
    undefined
  );
  const [isCtaDuplicated, setIsCtaDuplicated] = useState<boolean>(false);

  const addNewCta = () => {
    setCtas(originCtas);
    setCtaOnEditMode(NEW_INDEX);
  };

  useImperativeHandle(ref, () => {
    return {
      addNewLine: () => {
        addNewCta();
      }
    };
  });

  useEffect(() => {
    if (callToActions?.length) {
      const newData: TCallToActionRow[] = callToActions.map(
        (callToAction, index: number) => {
          const id = callToAction.id;
          return {
            id: id,
            rowId: index.toString() || '',
            title: callToAction.title,
            button: callToAction.button
          };
        }
      );
      setCtas(newData);
      setOriginCtas(newData);
    }
  }, [callToActions]);

  const titleHeaderId: THeaderId = 'title';
  const buttonHeaderId: THeaderId = 'button';

  const headers: THeaderId[] = [titleHeaderId, buttonHeaderId];

  const onCancelEditCta: TCtaTableActions['onCancelEditCta'] = () => {
    setCtas(originCtas);
    setCtaOnEditMode(undefined);
    setIsCtaDuplicated(false);
  };

  const onEditCta: TCtaTableActions['onEditCta'] = (
    rowIdOnEditMode: string
  ) => {
    setCtas(originCtas);
    setCtaOnEditMode(rowIdOnEditMode);
  };

  const onRemoveCta: TCtaTableActions['onRemoveCta'] = (
    rowIdToRemove: string
  ) => {
    const clonedCtas: TCallToActionRow[] = [...Object.values(ctas)];
    const indexOfCtaToRemove = clonedCtas.findIndex(
      (cta) => cta.rowId === rowIdToRemove
    );
    clonedCtas.splice(indexOfCtaToRemove, 1);
    const clonedOriginCtas: TCallToActionRow[] = [...Object.values(originCtas)];
    clonedOriginCtas.splice(indexOfCtaToRemove, 1);
    setCtas(clonedCtas);
    setOriginCtas(clonedOriginCtas);
    onChangeAction(clonedCtas);
  };

  const onChangeInput: TCtaTableActions['onChangeInput'] = (
    event: FormEvent<HTMLInputElement>,
    rowId: string,
    column: THeaderId
  ) => {
    if (event.currentTarget.value.length > charactersLimit[column]) {
      event.preventDefault();
    }

    const clonedCtas: TCallToActionRow[] = [...Object.values(ctas)];
    if (!clonedCtas.find((cta) => cta.rowId === rowId)) {
      clonedCtas.push({
        id: null,
        rowId: rowId,
        title: column === 'title' ? event.currentTarget.value : '',
        button: column === 'button' ? event.currentTarget.value : ''
      });
      setCtaOnEditMode(rowId);
    } else {
      const indexOfCtaToUpdate: number = clonedCtas.findIndex(
        (cta) => cta.rowId === rowId
      );
      const ctaToUpdate: TCallToActionRow = {
        ...clonedCtas[indexOfCtaToUpdate]
      };
      ctaToUpdate[column] = event.currentTarget.value;
      clonedCtas[indexOfCtaToUpdate] = ctaToUpdate;
    }
    setCtas({ ...clonedCtas });
  };

  const trimCta = (editedCta: TCallToActionRow): TCallToActionRow => {
    return {
      ...editedCta,
      title: editedCta.title.trim(),
      button: editedCta.button.trim()
    };
  };

  const onConfirmCta: TCtaTableActions['onConfirmCta'] = (
    editedCta: TCallToActionRow
  ) => {
    const trimedEditedCta: TCallToActionRow = trimCta(editedCta);
    if (isDuplicated(originCtas, trimedEditedCta)) {
      setIsCtaDuplicated(true);
      return;
    }
    setIsCtaDuplicated(false);

    const clonedCtas: TCallToActionRow[] = [...Object.values(ctas)];
    const indexOfCtaEdited: number = clonedCtas.findIndex(
      (cta) => cta.rowId === editedCta.rowId
    );

    clonedCtas[indexOfCtaEdited] = { ...trimedEditedCta };
    if (!!trimedEditedCta.title.length && !!trimedEditedCta.button.length) {
      onChangeAction(clonedCtas);
      setCtaOnEditMode(undefined);
    } else {
      setCtas({ ...clonedCtas });
    }
  };

  return (
    <VrrTable
      header={formatHeaderTable(headers)}
      body={formatBodyTable(
        ctas,
        titleHeaderId,
        buttonHeaderId,
        {
          onEditCta,
          onRemoveCta,
          onConfirmCta,
          onChangeInput,
          onCancelEditCta
        },
        isCtaDuplicated,
        ctaOnEditMode
      )}
      withSort
      tableProps={{
        borderless: true,
        className: 'cta-table',
        cssModule: ctaTableStyles
      }}
    />
  );
};

export default forwardRef(CtaTable);
