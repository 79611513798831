import { TCurrencyOptions, TFormat } from './types';

const defaultOptions: TCurrencyOptions = {
  thousandsSeparator: ',',
  decimalSeparator: '.'
};

const normalFormatter = (value: number, options: TFormat): string => {
  const currentOptions = { ...defaultOptions, ...options };
  let normValue = '';
  if (
    currentOptions.significantDigits !== undefined &&
    typeof value === 'number'
  ) {
    normValue = value.toFixed(currentOptions.significantDigits).toString();
  } else {
    normValue = value.toString();
  }

  const [currency, decimal] = normValue.split('.');
  return `${currency.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    currentOptions.thousandsSeparator
  )}${decimal !== undefined ? currentOptions.decimalSeparator : ''}${
    decimal || ''
  }`;
};

const currencyFormatter = (value: number, options: TFormat): string => {
  const currentOptions = { ...defaultOptions, ...options };
  return currentOptions.symbol
    ? `${normalFormatter(value, options)} ${currentOptions.symbol}`
    : normalFormatter(value, currentOptions);
};

const integerFormatter = (value: number, options: TFormat): string => {
  const currentOptions = { ...defaultOptions, ...options };

  return `${value
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, currentOptions.thousandsSeparator)}`;
};

export type { TCurrencyOptions };
export { normalFormatter, integerFormatter };
export default currencyFormatter;
