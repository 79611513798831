import i18next from 'i18next';
import { TVrrSortByValue } from '../../../shared_components/vrr_sort_by/types';
import { IListState } from '../../../shared_components/vrr_dropdown/types';

export const SORT_OPTIONS: TVrrSortByValue[] = [
  {
    value: 'name',
    label: i18next.t('media_plan.media_plan_filter.sortName.name')
  }
];

export const SEARCH_LIST: IListState[] = [
  {
    value: 'name',
    label: i18next.t('media_plan.media_plan_filter.searchName.name')
  }
];
