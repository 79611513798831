import { TBudgetListItemData } from './types';

export const BUDGETLIST: TBudgetListItemData[] = [
  {
    type: 'title',
    name: 'total_budget',
    payloadName: 'totalBudget',
    subItems: [
      {
        type: 'sub-item',
        name: 'top_funnel',
        editable: true,
        payloadName: 'totalTop'
      },
      {
        type: 'sub-item',
        name: 'middle_funnel',
        editable: true,
        payloadName: 'totalMiddle'
      },
      {
        type: 'sub-item',
        name: 'bottom_funnel',
        editable: true,
        payloadName: 'totalBottom'
      },
      {
        type: 'sub-item',
        name: 'other_campaigns',
        editable: false,
        payloadName: 'totalOther',
        only: 'all'
      }
    ]
  },
  {
    type: 'title',
    name: 'available_budget',
    payloadName: 'availableBudget',
    subItems: [
      {
        type: 'sub-item',
        name: 'top_funnel',
        editable: true,
        payloadName: 'availableTop'
      },
      {
        type: 'sub-item',
        name: 'middle_funnel',
        editable: true,
        payloadName: 'availableMiddle'
      },
      {
        type: 'sub-item',
        name: 'bottom_funnel',
        editable: true,
        payloadName: 'availableBottom'
      },
      {
        type: 'sub-item',
        name: 'other_campaigns',
        editable: false,
        payloadName: 'availableOther',
        only: 'all'
      }
    ]
  },
  {
    type: 'title',
    name: 'spent_budget',
    payloadName: 'spentBudget',
    subItems: [
      {
        type: 'sub-item',
        name: 'top_funnel',
        payloadName: 'spentTop'
      },
      {
        type: 'sub-item',
        name: 'middle_funnel',
        payloadName: 'spentMiddle'
      },
      {
        type: 'sub-item',
        name: 'bottom_funnel',
        payloadName: 'spentBottom'
      },
      {
        type: 'sub-item',
        name: 'other_campaigns',
        editable: false,
        payloadName: 'spentOther',
        only: 'all'
      }
    ]
  }
];
