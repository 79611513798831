import VrrInput from 'micro_front_end/shared_components/vrr_input';
import React from 'react';
import { charactersLimit, THelperMessages } from '../cta_table/types';
import { TCtaInput } from './types';
import { useTranslation } from 'react-i18next';
import './CtaInput.scss';

export const CtaInput: React.FC<TCtaInput> = ({
  inputValue,
  headerId,
  onChangeInput,
  ctaIndex,
  isCtaDuplicated,
  numberOfCharactersTyped,
  setNumberOfCharaters,
  placeholder
}: TCtaInput) => {
  const { t } = useTranslation();
  const nbCharactersLimit = charactersLimit[headerId];
  setNumberOfCharaters(inputValue.length, headerId);
  const helperText = t(
    'media_plan_edit.media_plan_assets.call_to_actions.body_table.helper_text',
    {
      numberOfCharactersTyped: numberOfCharactersTyped[headerId],
      nbCharactersLimit: nbCharactersLimit
    }
  );

  const helperMessage: THelperMessages = {
    error: undefined,
    helper: undefined
  };

  if (numberOfCharactersTyped[headerId] === 0) {
    helperMessage.error = helperText;
  } else {
    helperMessage.helper = helperText;
  }

  if (isCtaDuplicated) {
    helperMessage.error = t(
      'media_plan_edit.media_plan_assets.call_to_actions.body_table.duplicated_text'
    );
    helperMessage.helper = undefined;
  }

  return (
    <VrrInput
      type="text"
      value={inputValue}
      onChangeAction={(event) => onChangeInput(event, ctaIndex, headerId)}
      maxLength={nbCharactersLimit}
      errorMessage={helperMessage.error}
      helperText={helperMessage.helper}
      placeholder={placeholder}
    />
  );
};

export default CtaInput;
