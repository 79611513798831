import i18next from 'i18next';
import { NavigateFunction } from 'react-router-dom';
import search from '../../services/search';
import sort from '../../services/sort';
import URLS from '../../routing/urls';
import { TVrrBreadcrumb } from '../../shared_components/vrr_breadcrumb/types';
import { TMediaPlan } from './media_plan_list_container/types';
import { TFeatures } from '../../../redux/types';
import { getFeatureByPath } from '../../routing/services';

export const BREADCRUMB: TVrrBreadcrumb['items'] = [
  {
    label: i18next.t('media_plan.breadcrumb.main')?.toUpperCase(),
    id: 'media-plan-breadcrumb-0'
  }
];

export const goToDetail = (
  history: NavigateFunction,
  id: number,
  accountId: string | null
) => {
  if (accountId?.length) {
    history({
      pathname: URLS.MEDIA_PLAN.replace(
        ':accountId/:mediaPlanId',
        `${accountId}/${id}`
      )
    });
  }
};

export const prepareMediaPlans = (
  type: string,
  sortOrder: string,
  value: string,
  accountId: string | null,
  history: NavigateFunction,
  searchFilter?: string,
  mediaPlans?: TMediaPlan[],
  features?: TFeatures
) => {
  let result = mediaPlans ?? [];

  if (type === 'search' && searchFilter) {
    result = search(result, searchFilter as string, value);
  }

  if (!!result?.length) {
    result = sort(result as Array<{ name: string } & TMediaPlan>, sortOrder);

    return result?.map((mediaPlan) => {
      return {
        ...mediaPlan,
        ...(getFeatureByPath(features, 'media_plan.detail')
          ? { onCardClick: () => goToDetail(history, mediaPlan.id, accountId) }
          : null)
      };
    });
  }

  return result;
};
