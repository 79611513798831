import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { TBoostModalActions } from './types';

const BoostModalActions: React.FC<TBoostModalActions> = ({
  onCancel,
  onClickUpdate,
  disabled
}: TBoostModalActions) => {
  const { t } = useTranslation();

  return (
    <>
      <Button
        data-cy-id="overview-boost-cancel"
        type="button"
        className="btn-link modal-boost-button"
        onClick={onCancel}
      >
        {t('components.budget.boost.modal.buttons.cancel')}
      </Button>
      <Button
        type="submit"
        className="modal-boost-button"
        onClick={onClickUpdate}
        disabled={disabled}
      >
        {t('components.budget.boost.modal.buttons.update')}
      </Button>
    </>
  );
};

export default BoostModalActions;
