import React, { useEffect, useState } from 'react';
import { TVrrFloatInput } from './types';
import VrrInput from '../VrrInput';
import toFloat from '../../../services/dataFormat';

const VrrFloatInput: React.FC<TVrrFloatInput> = ({
  decimalNumber,
  id,
  className = 'vrr-input-text',
  placeholder = '',
  isRequired,
  value,
  withCurrency,
  withPercent,
  onChangeAction,
  isValid = true,
  isDisabled = false,
  errorMessage
}: TVrrFloatInput) => {
  const [inputValue, setInputValue] = useState<string | number | undefined>(
    undefined
  );
  const [error, setError] = useState(errorMessage);
  const [validInput, setValidInput] = useState(isValid);

  const resetInternalStates = () => {
    setValidInput(true);
    setError(undefined);
  };

  useEffect(() => {
    if (value !== undefined) {
      setInputValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (errorMessage?.length) {
      setValidInput(false);
      setError(errorMessage);
    } else {
      resetInternalStates();
    }
  }, [errorMessage]);

  const checkValidInput = (input: string) => {
    const convertedValue = Number(input.replace(/,/g, '.'));
    if (isNaN(convertedValue)) {
      return '';
    }
    return convertedValue;
  };

  const changeAction = (e: React.FormEvent<HTMLInputElement>) => {
    const input = e.currentTarget.value;
    setInputValue(input);
  };

  const onSubmit = () => {
    let validFormattedValue = undefined;
    if (inputValue) {
      const validValue = checkValidInput(inputValue.toString() as string);
      validFormattedValue = toFloat(validValue as number, decimalNumber);
    }
    setInputValue(validFormattedValue);
    if (onChangeAction) {
      onChangeAction(parseFloat(validFormattedValue as string) || 0);
    }
    resetInternalStates();
  };

  const prepareValue = () => {
    if (inputValue !== undefined) {
      return isDisabled && typeof inputValue === 'number'
        ? toFloat(inputValue as number, decimalNumber)
        : inputValue;
    }
    return undefined;
  };

  return (
    <VrrInput
      id={id}
      className={className}
      placeholder={placeholder}
      isRequired={isRequired}
      withCurrency={withCurrency}
      withPercent={withPercent}
      value={prepareValue()}
      onChangeAction={changeAction}
      onBlurAction={onSubmit}
      isValid={validInput}
      errorMessage={error}
      isDisabled={isDisabled}
    />
  );
};

export default VrrFloatInput;
