import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import AudienceCategoryLine from './audience_category_line';
import { VrrCollapse } from '../../../../../../../../../../../shared_components';
import { TAudienceCategory } from './types';
import './AudienceCategory.scss';

const AudienceCategory: React.FC<TAudienceCategory> = ({
  categoryData,
  selectedAudiences,
  onChangeAction
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { parentCategory, subCategories } = categoryData;

  return (
    <section className="audience-category-section">
      <p className="parent-audience-category">
        <AudienceCategoryLine
          categoryLine={parentCategory}
          isChecked={
            !!selectedAudiences?.length &&
            !!selectedAudiences?.find(
              (audience) => parentCategory.criteriaId === audience.id
            )
          }
          onChangeAction={onChangeAction}
        />
        <span className="subcategory-count">{subCategories.length}</span>
        <VrrCollapse
          isOpen={isOpen}
          onClick={() => setIsOpen(!isOpen)}
          sideIcon="down"
          closingSideIcon="up"
        />
      </p>
      <Collapse isOpen={isOpen} className="subcategories-section">
        {Object.values(subCategories).map((subCategory) => {
          return (
            <p className="wrapper-category-line" key={subCategory.criteriaId}>
              <AudienceCategoryLine
                categoryLine={subCategory}
                isChecked={
                  !!selectedAudiences?.length &&
                  !!selectedAudiences?.find(
                    (audience) => subCategory.criteriaId === audience.id
                  )
                }
                onChangeAction={onChangeAction}
              />
            </p>
          );
        })}
      </Collapse>
    </section>
  );
};

export default AudienceCategory;
