import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import URLS from './urls';
import { generateRoutes } from './services';
import {
  getCurrentSession,
  getFeatures,
  getIsLoading
} from '../../redux/app/appActions';

const VrrRoutes: React.FC = () => {
  const session = useSelector(getCurrentSession);
  const isLoading = useSelector(getIsLoading);
  const features = useSelector(getFeatures);

  return (
    <Routes>
      {generateRoutes(session, features, isLoading)}
      <Route path={URLS.HOME} element={<Navigate to={URLS.OVERVIEW_ALL} />} />
      <Route path="*" element={<Navigate to={URLS.NOT_FOUND} />} />
    </Routes>
  );
};

export default VrrRoutes;
