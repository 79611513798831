import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { VrrTable } from '../../../../shared_components';
import {
  filterDataOnKpisClaim,
  prepareHeaders,
  prepareBodies
} from './services';
import {
  getMainKpi,
  getMainKpiSort
} from '../../../../../redux/userPreferences/actions/userPreferencesActions';
import useSettings from '../../../../../libs/settings/useSettings';
import { SORT_BY } from '../../../../shared_components/vrr_sort_by/constants';
import { TMetricData, TMetricTable } from './types';
import MetricTableStyles from './MetricTable.module.scss';

const MetricTable: React.FC<TMetricTable> = ({
  id,
  data,
  metricName,
  limitColumnKeys,
  limitRow
}: TMetricTable) => {
  const { overview } = useSettings();
  const mainKpi: string | undefined = useSelector(getMainKpi);
  const mainKpiSort: string | undefined = useSelector(getMainKpiSort);

  const [tableData, setTableData] = useState<TMetricData>([]);

  useEffect(() => {
    const newTableData: TMetricData = filterDataOnKpisClaim(
      data,
      limitColumnKeys,
      metricName
    );
    setTableData(newTableData);
  }, [data]);

  const preparedHeaders = prepareHeaders(limitColumnKeys);

  const preparedBodies = prepareBodies(tableData, limitColumnKeys);

  const onSort = (sortId: string, sortType: string) => {
    if (sortId === mainKpi) {
      overview('global_insights', {
        mainKpiSort: sortType
      });
    }
  };

  return (
    <div id={id} className="metric-table-wrapper">
      <VrrTable
        cyId="overview-details-table"
        body={preparedBodies}
        header={preparedHeaders}
        defaultSort={{ id: mainKpi ?? '', type: mainKpiSort ?? SORT_BY.asc }}
        withSort
        onSortAction={onSort}
        limitRow={limitRow}
        tableProps={{
          className: 'metric-table',
          cssModule: MetricTableStyles
        }}
      />
    </div>
  );
};

export default MetricTable;
