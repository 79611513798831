import { TPreview } from '../../../../../../redux/creativeAnalytics/creativeImportVideo/types';

export const prepareRemovePreviews = (
  index: number,
  previews?: TPreview[]
): TPreview[] => {
  if (!!previews?.length) {
    return previews.filter((item) => previews.indexOf(item) !== index);
  }
  return [];
};

export const prepareUpdatePreviews = (
  node: keyof TPreview,
  value: TPreview['details'],
  index: number,
  previews?: TPreview[]
): TPreview[] => {
  if (!!previews?.length) {
    const newPreviews = [...previews];
    return newPreviews.map((preview, idx: number) => {
      if (index === idx) {
        return {
          ...preview,
          [node]: value
        };
      }
      return preview;
    });
  }
  return [];
};
