import React from 'react';
import { useTranslation } from 'react-i18next';
import { VrrButton } from '../../../../../../shared_components';
import { TAddButton } from './types';
import './AddButton.scss';

const AddButton: React.FC<TAddButton> = ({ onClickAction }) => {
  const { t } = useTranslation();

  return (
    <VrrButton
      className="btn-round url-form-add-button"
      onClick={onClickAction}
      icon={{
        iconLabel: 'tim-icons icon-simple-add'
      }}
    >
      {t('creative_gallery.import_video.url_form.add_button')}
    </VrrButton>
  );
};

export default AddButton;
