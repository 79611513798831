import { useContext } from 'react';
import PermissionsContext from '../provider/PermissionsContext';

type Permissions = {
  permissions: any;
  authorizationStrategy: any;
};

const usePermissions = (): Permissions => useContext(PermissionsContext);

export default usePermissions;
