import React from 'react';
import { VrrTooltip } from '../../../../../shared_components';
import { TVideoCell } from './types';
import './VideoCell.scss';

const VideoCell: React.FC<TVideoCell> = ({ id, title, thumbnail, url }) => {
  return (
    <div className="video-cell">
      <a className="video-cell-link" href={url} target="_blank">
        <img className="video-cell-img" src={thumbnail} alt={title} />
        <p className="video-cell-title" id={`tooltip-${id}`}>
          {title}
        </p>
      </a>
      {!!id?.toString().length && (
        <VrrTooltip target={`tooltip-${id}`} placement="bottom">
          {title}
        </VrrTooltip>
      )}
    </div>
  );
};
export default VideoCell;
