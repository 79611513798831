import React, { BaseSyntheticEvent } from 'react';
import {
  THeader,
  TTableBody,
  TTableRow
} from '../../../../shared_components/vrr_table/types';
import i18next from 'i18next';
import { Input } from 'reactstrap';
import { TAvailableAccount } from 'containers/UserProfile/ConnectedAccount/types';
import { TAccount, TAvailableAccountRow } from './types';

export const prepareHeader = (): THeader['element'][] => {
  return [
    {
      id: 'checkbox',
      component: <p>#</p>
    },
    {
      id: 'id',
      component: (
        <p>
          {i18next
            .t('connected_account.accounts_table.header.id')
            .toUpperCase()}
        </p>
      )
    },
    {
      id: 'label',
      component: (
        <p>
          {i18next
            .t('connected_account.accounts_table.header.label')
            .toUpperCase()}
        </p>
      )
    }
  ];
};

export const prepareAccount = (account: TAvailableAccount): TAccount => {
  return {
    id: account.id,
    label: account.label
  };
};

export const prepareLine = (
  account: TAccount,
  rowId: string
): TAvailableAccountRow[] => {
  const header = prepareHeader();
  return Object.values(account)?.map((cell, index) => ({
    headerId: header[index + 1].id,
    rowId: rowId,
    value: cell
  }));
};

const getLine = (
  id: string,
  line: TAvailableAccountRow[],
  changeAction?: (value: string, isChecked: boolean) => void
): TTableRow['cellElements'] => {
  const newLine = line.map((cell) => {
    return {
      headerId: cell.headerId,
      id: cell.rowId,
      component: (
        <label className="account-value" htmlFor={`${id}-${line[0].rowId}`}>
          <span>{cell.value}</span>
        </label>
      ),
      value: cell.value
    };
  });
  newLine.unshift({
    headerId: 'checkbox',
    id: line[0].rowId,
    component: (
      <Input
        id={`${id}-${line[0].rowId}`}
        type="checkbox"
        className="account-input"
        onClick={(e: BaseSyntheticEvent) => {
          if (changeAction) changeAction(line[0].value, e.target.checked);
        }}
      />
    ),
    value: ''
  });

  return newLine;
};

export const prepareBody = (
  id: string,
  accounts?: TAvailableAccount[],
  changeAction?: (value: string, isChecked: boolean) => void
): TTableBody['rowElements'] => {
  const newBody: TTableBody['rowElements'] = [];
  accounts?.forEach((account) => {
    const formattedAccount = prepareAccount(account);
    const line = prepareLine(formattedAccount, account.id);
    newBody.push(getLine(id, line, changeAction));
  });

  return newBody;
};
