import React from 'react';
import i18next from 'i18next';
import moment from 'moment';
import {
  THeader,
  TTableBody,
  TTableRow
} from '../../shared_components/vrr_table/types';
import {
  TNotificationLine,
  TNotificationRow,
  TNotificationTable
} from './types';
import { TNotification } from '../../../redux/types';
import { NOTIFICATIONS } from '../../nav_bar/notification_widget/notification_list/notification_item/services';
import { TNotifications } from '../../nav_bar/notification_widget/notification_list/notification_item/types';

export const prepareHeader = (): THeader['element'][] => {
  return [
    {
      id: 'icon',
      component: <></>
    },
    {
      id: 'name',
      component: <p>{i18next.t('notification_center.table.header.name')}</p>
    },
    {
      id: 'type',
      component: <p>{i18next.t('notification_center.table.header.type')}</p>
    },
    {
      id: 'date',
      component: <p>{i18next.t('notification_center.table.header.date')}</p>
    },
    {
      id: 'time',
      component: <p>{i18next.t('notification_center.table.header.time')}</p>
    },
    {
      id: 'account',
      component: <p>{i18next.t('notification_center.table.header.account')}</p>
    },
    {
      id: 'read',
      component: <></>
    }
  ];
};

const prepareLink = (
  notification: TNotificationLine,
  headerId: string
): string | JSX.Element | undefined => {
  if (headerId === 'name') {
    return !!notification.resourceUrl?.length ? (
      <a
        className="notification-link"
        href={notification.resourceUrl}
        target="_blank"
      >
        <span title={notification.name}>{notification.name}</span>
      </a>
    ) : (
      <span title={notification.name}>{notification.name}</span>
    );
  }
  return notification[headerId as keyof TNotificationLine];
};

const prepareLine = (notification: TNotificationLine): TNotificationRow[] => {
  const header = prepareHeader();
  return Object.values(header)?.map((head, index: number) => ({
    headerId: header[index].id,
    rowId: notification.id,
    value: notification[head.id as keyof TNotificationLine],
    component: prepareLink(notification, head.id)
  }));
};

const getReadLine = (
  notification: TNotificationRow[]
): TTableRow['cellElements'] => {
  return notification.map((cell: TNotificationRow) => ({
    headerId: cell.headerId,
    id: cell.rowId,
    component: <span>{cell.component}</span>,
    value: cell.value as string
  }));
};

export const prepareNotification = (
  notification: TNotification
): TNotificationLine => {
  const { id, name, type, createdAt, isRead, accountName, resourceUrl } =
    notification;
  const icon = NOTIFICATIONS[type as keyof TNotifications];

  return {
    icon: <span className={`notification-icon ${type}`}>{icon}</span>,
    name,
    type: i18next.t(`notification_center.table.type.${type}`),
    date: moment(createdAt).format('MMMM D, y'),
    time: moment(createdAt).format('HH:mm'),
    account: accountName,
    read: <div className={`notification-status${isRead ? ' read' : ''}`} />,
    resourceUrl,
    id: id.toString()
  };
};

export const prepareBody = (
  notifications: TNotificationTable['notifications'] | undefined
): TTableBody['rowElements'] => {
  const newBody: TTableBody['rowElements'] = [];
  notifications?.forEach((notification) => {
    const formattedNotification = prepareNotification(notification);
    const line = prepareLine(formattedNotification);
    newBody.push(getReadLine(line));
  });

  return newBody;
};
