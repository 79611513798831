import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MediaPlanFilter from './media_plan_filter';
import MediaPlanListContainer from './media_plan_list_container';
import { VrrBreadCrumb } from '../../shared_components';
import { BREADCRUMB, goToDetail, prepareMediaPlans } from './services';
import { SORT_BY } from '../../shared_components/vrr_sort_by/constants';
import { getAccountId } from '../../../redux/userPreferences/actions/userPreferencesActions';
import {
  addNewMediaPlan,
  fetchMediaPlans,
  getMediaPlan,
  getMediaPlans
} from '../../../redux/mediaPlanGallery/mediaPlanGalleryAction';
import { getFeatures } from '../../../redux/app/appActions';
import { resetMediaPlan } from '../../../redux/mediaPlanGallery/mediaPlanGallerySlice';
import { TMediaPlan } from './media_plan_list_container/types';
import './MediaPlanGallery.scss';

const MediaPlanGallery: React.FC = () => {
  const history = useNavigate();
  const dispatch: any = useDispatch();

  const accountId = useSelector(getAccountId);
  const mediaPlans = useSelector(getMediaPlans);
  const mediaPlan = useSelector(getMediaPlan);
  const features = useSelector(getFeatures);

  const [mediaPlanGalleryData, setMediaPlanGalleryData] = useState<
    TMediaPlan[] | undefined
  >(undefined);
  const [isGallery, setIsGallery] = useState(true);
  const [sortOption, setSortOption] = useState(SORT_BY.asc);

  useEffect(() => {
    if (!isGallery && mediaPlan?.id) {
      goToDetail(history, mediaPlan.id, accountId);
    }
  }, [isGallery, mediaPlan]);

  useEffect(() => {
    if (!!accountId?.length) {
      dispatch(fetchMediaPlans());
      setMediaPlanGalleryData(undefined);
    }
  }, [accountId]);

  const onFilter = (
    type: string,
    sortOrder: string,
    value = '',
    searchFilter?: string
  ) => {
    const newMediaPlans = prepareMediaPlans(
      type,
      sortOrder,
      value,
      accountId,
      history,
      searchFilter,
      mediaPlans as TMediaPlan[],
      features
    );

    setMediaPlanGalleryData(newMediaPlans as TMediaPlan[]);
  };

  useEffect(() => {
    if (!!mediaPlans) {
      onFilter('sort', 'name', sortOption);
    }
  }, [mediaPlans]);

  const addMediaPlan = () => {
    setIsGallery(false);
    dispatch(addNewMediaPlan());
  };

  const goGallery = () => {
    setIsGallery(true);
    dispatch(resetMediaPlan());
  };

  return (
    <>
      <VrrBreadCrumb items={BREADCRUMB} onClickItem={goGallery} />
      <MediaPlanFilter
        onSearchAction={(value, filter) =>
          onFilter('search', sortOption, value, filter)
        }
        onSortAction={(value, sortOrder) => {
          setSortOption(sortOrder);
          onFilter('sort', sortOrder, undefined, value);
        }}
        onNewMediaPlan={addMediaPlan}
      />
      <div className="spacer" />
      <MediaPlanListContainer
        mediaPlanList={mediaPlanGalleryData as Array<TMediaPlan>}
        isLoading={mediaPlanGalleryData === undefined}
      />
    </>
  );
};

export default MediaPlanGallery;
