import { IListState } from '../micro_front_end/shared_components/vrr_dropdown/types';

const LANG = ['en', 'fr'];

export const LANGUAGES_LIST: IListState[] = [
  { label: 'English', value: 'en' },
  { label: 'Français', value: 'fr' }
];

export default LANG;
