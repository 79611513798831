import React from 'react';
import {
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupText
} from 'reactstrap';
import { getCurrentCurrency } from 'services/services';
import { TVrrInput } from './types';
import './VrrInput.scss';

const VrrInput: React.FC<TVrrInput> = ({
  id,
  type = 'text',
  className = 'vrr-input-text',
  placeholder = '',
  isRequired,
  value,
  withCurrency = false,
  withPercent = false,
  onChangeAction,
  onBlurAction,
  isValid = true,
  isDisabled = false,
  errorMessage,
  helperText,
  maxLength
}: TVrrInput) => {
  return (
    <FormGroup className="vrr-input-container">
      <InputGroup
        className={
          withCurrency || withPercent ? 'with-currency' : 'no-currency'
        }
      >
        {(withCurrency || withPercent) && (
          <InputGroupText className="vrr-currency-input">
            {withCurrency ? getCurrentCurrency() : '%'}
          </InputGroupText>
        )}
        <Input
          id={id}
          type={type}
          className={className}
          placeholder={placeholder}
          inputprops={{ required: isRequired ? 'required' : undefined }}
          value={value}
          onChange={onChangeAction}
          onBlur={onBlurAction}
          invalid={!isValid || !!errorMessage}
          disabled={isDisabled}
          maxLength={maxLength}
        />
      </InputGroup>
      {errorMessage && (
        <FormFeedback className="input-error-message">
          {errorMessage}
        </FormFeedback>
      )}
      {helperText && <FormText>{helperText}</FormText>}
    </FormGroup>
  );
};

export default VrrInput;
