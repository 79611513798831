import './OptimizeContainer.scss';
import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  getSplitTests,
  optimizeCampaigns
} from '../../redux/overview/overviewActions';
import { ThreeDots } from 'react-bootstrap-icons';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { TOptimizeContainer } from './types';

export const OptimizeC: React.FC<TOptimizeContainer> = ({
  splitTestsList,
  launchOptimization
}: TOptimizeContainer) => {
  const { t } = useTranslation();

  const disabled =
    splitTestsList && splitTestsList.length !== 0 ? '' : 'disabled';

  return (
    <UncontrolledDropdown>
      <div className="btn-right-bar-container">
        <DropdownToggle
          caret
          color="link"
          className={`btn-simple btn-right-bar btn-actions ${disabled}`}
        >
          <ThreeDots data-cy-id="overview-actions-button" />
        </DropdownToggle>
      </div>
      <DropdownMenu className="dropdown-actions" end direction="down" tag="ul">
        <DropdownItem
          data-cy-id="overview-optimize-button"
          onClick={launchOptimization}
          className="actions-item"
        >
          {t('components.optimize.optimize_button_label')}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const mapStateToProps = (state: any) => ({
  splitTestsList: getSplitTests(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  launchOptimization: () => dispatch(optimizeCampaigns())
});

const OptimizeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OptimizeC);

export default OptimizeContainer;
