import React from 'react';
import { IKebabButton } from './types';

const KebabButton: React.FC<IKebabButton> = (props: IKebabButton) => {
  const { onOpen } = props;
  return (
    <button
      className="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navigation"
      aria-expanded="false"
      aria-label="Toggle navigation"
      onClick={onOpen}
    >
      <span className="navbar-toggler-bar navbar-kebab" />
      <span className="navbar-toggler-bar navbar-kebab" />
      <span className="navbar-toggler-bar navbar-kebab" />
    </button>
  );
};

export default KebabButton;
