import React from 'react';
import { VrrButton, VrrCard } from '../index';
import './VrrAddCard.scss';
import { useTranslation } from 'react-i18next';
import { TVrrAddCard } from './types';

const VrrAddCard: React.FC<TVrrAddCard> = ({
  label,
  dataCy,
  onClickAction
}) => {
  const { t } = useTranslation();

  return (
    <VrrCard
      className="vrr-add-card"
      cardBody={{
        element: (
          <VrrButton
            dataCy={dataCy}
            onClick={onClickAction}
            className="btn-link btn-with-icon-and-label icon-bottom vrr-add-card-button"
            icon={{
              iconLabel: 'tim-icons icon-simple-add vrr-add-card-icon'
            }}
          >
            {label ?? t('shared.vrr_add_card.title')}
          </VrrButton>
        )
      }}
    />
  );
};

export default VrrAddCard;
