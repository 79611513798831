import React from 'react';
import PermissionsContext from './PermissionsContext';
import { TPermissionsProvider } from './types';

const PermissionsProvider: React.FC<TPermissionsProvider> = ({
  children,
  ...rest
}) => {
  return (
    <PermissionsContext.Provider value={rest}>
      {children}
    </PermissionsContext.Provider>
  );
};

export default PermissionsProvider;
