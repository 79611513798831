import React from 'react';
import {
  TAllMetricsPreferences,
  TGlobalMetric,
  TInsightsFilters
} from '../redux/userPreferences/types';
import {
  IAccountAPI,
  IListState
} from 'micro_front_end/shared_components/vrr_dropdown/types';
import { ReactComponent as spent } from '../assets/vrr/images/spent_icon.svg';
import { ReactComponent as views } from '../assets/vrr/images/views_icon.svg';
import { ReactComponent as cta } from '../assets/vrr/images/cta_icon.svg';
import { ReactComponent as taxonomy } from '../assets/vrr/images/funnel_icon.svg';
import { TCardInsightsPreference } from '../containers/Insights/FilterContainer/types';

export const prepareAccountList = (list: IAccountAPI[]): IListState[] => {
  return list.map((item: IAccountAPI) => {
    const { account_name, ...rest } = item;
    return { ...rest, label: account_name, value: item.id };
  });
};

export const filterByAccount = (
  settings: any,
  account_id: string | null
): TGlobalMetric | TAllMetricsPreferences | TInsightsFilters | null => {
  const settingsFiltered:
    | TGlobalMetric
    | TAllMetricsPreferences
    | TInsightsFilters
    | null = settings.find(
    (setting: TGlobalMetric | TAllMetricsPreferences | TInsightsFilters) =>
      setting?.current_account_id === account_id
  );

  return settingsFiltered ?? null;
};

export const prepareSettingsByCurrentAccount = (
  current_account_id: string | null,
  settings:
    | TGlobalMetric[]
    | TAllMetricsPreferences[]
    | TInsightsFilters[]
    | TCardInsightsPreference[]
):
  | TGlobalMetric
  | TAllMetricsPreferences
  | TInsightsFilters
  | TCardInsightsPreference
  | null => {
  if (settings?.length > 0) {
    const setting = filterByAccount(settings, current_account_id);

    return setting ?? null;
  }
  return null;
};

export const getCurrencySymbol = (currencyCode: string): string => {
  switch (currencyCode) {
    case 'USD':
      return '$';
    case 'EUR':
    default:
      return '€';
  }
};

export const adaptSymbolToType = (t: string, symbol: string): string => {
  switch (t) {
    case 'cost':
      return symbol;
    case 'ratio':
      return '%';
    case 'ca_ratio':
      return '%';
    default:
      return '';
  }
};

export const prepareCurrency = (currencyCode: string): void => {
  if (currencyCode) {
    const data = JSON.parse(localStorage.getItem('vrr-adtech') as any);
    localStorage.setItem(
      'vrr-adtech',
      JSON.stringify({
        ...data,
        currency: getCurrencySymbol(currencyCode)
      })
    );
  }
};

export const getCurrentCurrency = (): string => {
  const data = JSON.parse(localStorage.getItem('vrr-adtech') as any);
  return data?.currency || '€';
};

export const getIcon = (type: string, className?: string): React.Component => {
  const components: any = { spent, views, cta, taxonomy };
  const IconSVG = components[type] || false;

  return IconSVG && <IconSVG className={className} />;
};

export const capitalize = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getConvActTranslationKey = (convActName: string): string => {
  if (['default', 'views', 'clicks'].includes(convActName)) {
    return `components.insights.conversion_actions.${convActName}`;
  } else {
    return convActName;
  }
};
