import React from 'react';
import { Card, CardBody, CardImg, CardText, CardTitle } from 'reactstrap';
import { IVrrCard } from './types';
import './VrrCard.scss';

const VrrCard: React.FC<IVrrCard> = ({
  cardHeader,
  cardBody,
  cardFooter,
  dataCy,
  ...rest
}: IVrrCard) => {
  return (
    <Card
      data-cy-id={dataCy}
      className={rest.className ?? 'vrr-card-container'}
      {...rest}
    >
      {cardHeader}
      <CardBody>
        {cardBody?.element ? (
          cardBody.element
        ) : (
          <>
            <CardTitle>{cardBody?.title}</CardTitle>
            {cardBody?.img ? <CardImg {...cardBody.img} /> : <></>}
            <CardText>{cardBody?.text}</CardText>
          </>
        )}
      </CardBody>
      {cardFooter}
    </Card>
  );
};

export default VrrCard;
