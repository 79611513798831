import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';
import { VrrDropdown, VrrButton } from '../../shared_components';
import AccountModal from '../../user_profile/connected_account/account_modal';
import {
  getCurrentProvider,
  providers
} from '../../../services/provider/getCurrentProvider';
import { PrivateComponent } from '../../permissions';
import useSettings from '../../../libs/settings/useSettings';
import { getFeatureByPath } from '../../routing/services';
import {
  getAccounts,
  fetchAccounts,
  getUserId,
  getFeatures
} from '../../../redux/app/appActions';
import { getAccountId } from '../../../redux/userPreferences/actions/userPreferencesActions';
import { updateLabel, updateRole } from '../../../redux/app/appSlice';
import { addConnectedAccount } from '../../../redux/userProfile/userProfileActions';
import {
  IAccountState,
  IListState
} from '../../shared_components/vrr_dropdown/types';
import './AccountDropdown.scss';

const AccountDropdown: React.FC = () => {
  const { t } = useTranslation();
  const { vrr } = useSettings();
  const dispatch: any = useDispatch();
  const features = useSelector(getFeatures);
  const accountId = useSelector(getAccountId);
  const userId = useSelector(getUserId);
  const accounts: IAccountState[] = useSelector(getAccounts);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const currentProvider = getCurrentProvider();
    if (providers.includes(currentProvider) && userId) {
      dispatch(fetchAccounts(currentProvider));
    }
    return () => setIsOpen(false);
  }, [userId]);

  const onChange = (account: IListState | null) => {
    const data = JSON.parse(localStorage.getItem('vrr-adtech') as any);
    localStorage.setItem(
      'vrr-adtech',
      JSON.stringify({
        ...data
      })
    );

    dispatch(updateRole(account?.role ?? false));
    vrr({ current_account_id: account?.value });
    dispatch(updateLabel('all'));
  };

  const Menu = (props: any) => {
    return (
      <components.Menu {...props}>
        {props.children}
        <PrivateComponent
          authorizationStrategy={() =>
            getFeatureByPath(features, 'navbar.add_accounts')
          }
        >
          <VrrButton
            className="account-dropdown-add-account"
            icon={{
              iconLabel: 'tim-icons icon-cloud-download-93'
            }}
            onClick={() => setIsOpen(true)}
          >
            {t('main_navbar.account_dropdown.button')}
          </VrrButton>
        </PrivateComponent>
      </components.Menu>
    );
  };

  return (
    <>
      <VrrDropdown
        placeholder={t('main_navbar.account_dropdown.placeholder')}
        noOptionsMessage={t('main_navbar.account_dropdown.no_options_message')}
        list={accounts || []}
        currentValue={accountId}
        handleChangeAction={onChange}
        id="cy-accounts-dropdown"
        customComponents={{ Menu }}
      />
      <AccountModal
        id="accountDropdown"
        isOpen={isOpen}
        onCancelAction={() => setIsOpen(false)}
        onSubmitAction={(accountList) =>
          dispatch(addConnectedAccount(accountList))
        }
      />
    </>
  );
};

export default AccountDropdown;
