import React from 'react';
import { useTranslation } from 'react-i18next';
import CardsContainer from '../../../containers/Insights/CardsContainer';
import { TCardsContainer } from '../../../containers/Insights/CardsContainer/types';
import './CardList.scss';
import { TCardList, TCardListActions } from './types';

const CardList: React.FC<TCardList & TCardListActions> = ({
  list,
  changeStatusAction,
  filters
}: TCardList & TCardListActions) => {
  const { t } = useTranslation();

  return list.length > 0 ? (
    <div className="card-list-container">
      {list.map((cardInfo: TCardsContainer) => {
        return (
          <CardsContainer
            filters={filters}
            key={cardInfo.card.id}
            cardSettings={cardInfo.cardSettings}
            card={cardInfo.card}
            changeStatusAction={changeStatusAction}
          />
        );
      })}
    </div>
  ) : (
    <h2 className="no-insights">{t(`components.insights.noInsights`)}</h2>
  );
};
export default CardList;
