import { BarChart, Ubuntu, Gear } from 'react-bootstrap-icons';
import { TNotifications } from './types';

export const NOTIFICATIONS: TNotifications = {
  optimization: <Gear className="notification-optimization" />,
  refresh: <BarChart className="notification-refresh" />,
  user_action: <Ubuntu className="notification-user_action" />,
  platform: <Gear className="notification-optimization" />,
  setup: <Gear className="notification-setup" />
};
