import { createSlice } from '@reduxjs/toolkit';
import { TBoostModalApiData } from 'containers/Budget/Boost/types';
import { TBoostModalValues } from '../../components/Budget/Boost/BoostModal/types';
import { TOverviewInitialState } from './types';
import {
  fetchBudgets,
  fetchCharts,
  fetchMetrics,
  fetchSplitTestDetails,
  fetchSplitTests,
  fetchViewers,
  updateMetrics,
  updateSplitTestBudgets
} from './overviewActions';

export const initialState: TOverviewInitialState = {
  charts: [],
  globalMetrics: undefined,
  viewers: [],
  budgets: { payload: null, status: 'pending' },
  split_tests: []
};

const FetchBudgetRejectionData: TBoostModalApiData = {
  id: 0,
  name: 'N/A',
  totalTop: 0,
  totalMiddle: 0,
  totalBottom: 0,
  totalBudget: 0,
  availableTop: 0,
  availableMiddle: 0,
  availableBottom: 0,
  availableBudget: 0,
  spentTop: 0,
  spentMiddle: 0,
  spentBottom: 0,
  spentBudget: 0,
  startDate: new Date('1989-03-12'),
  endDate: new Date('1989-03-12')
};

export const overviewSlice = createSlice({
  name: 'overview',
  initialState: { ...initialState },
  reducers: {
    resetOverview: () => {
      return {
        ...initialState
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchViewers.fulfilled, (state, { payload }) => {
      return {
        ...state,
        viewers: payload
      };
    });
    builder.addCase(fetchMetrics.fulfilled, (state, { payload }) => {
      return {
        ...state,
        globalMetrics: (payload as any).data.data
      };
    });
    builder.addCase(fetchCharts.pending, (state) => {
      return {
        ...state,
        charts: []
      };
    });
    builder.addCase(fetchCharts.fulfilled, (state, { payload }) => {
      return {
        ...state,
        charts: (payload as any).data.data
      };
    });
    builder.addCase(updateMetrics.fulfilled, (state, { payload }) => {
      return {
        ...state,
        globalMetrics: payload.data.data
      };
    });
    builder.addCase(fetchBudgets.pending, (state) => {
      return {
        ...state,
        budgets: { payload: null, status: 'pending' }
      };
    });
    builder.addCase(fetchBudgets.fulfilled, (state, { payload }) => {
      return {
        ...state,
        budgets: { payload: (payload as any).data.data, status: 'success' }
      };
    });
    builder.addCase(fetchBudgets.rejected, (state) => {
      return {
        ...state,
        budgets: { payload: FetchBudgetRejectionData, status: 'rejected' }
      };
    });
    builder.addCase(fetchSplitTests.pending, (state) => {
      return {
        ...state,
        split_tests: []
      };
    });
    builder.addCase(fetchSplitTests.fulfilled, (state, { payload }) => {
      return {
        ...state,
        split_tests: (payload as any).data.data
      };
    });
    builder.addCase(fetchSplitTestDetails.pending, (state) => {
      return {
        ...state,
        budgets: { payload: null, status: 'pending' }
      };
    });
    builder.addCase(fetchSplitTestDetails.fulfilled, (state, { payload }) => {
      return {
        ...state,
        budgets: { payload: (payload as any).data.data, status: 'success' }
      };
    });
    builder.addCase(fetchSplitTestDetails.rejected, (state) => {
      return {
        ...state,
        budgets: { payload: FetchBudgetRejectionData, status: 'rejected' }
      };
    });
    builder.addCase(updateSplitTestBudgets.fulfilled, (state, { payload }) => {
      return {
        ...state,
        budgets: { payload: payload as TBoostModalValues, status: 'success' }
      };
    });
  }
});

export const { resetOverview } = overviewSlice.actions;

export default overviewSlice.reducer;
