import React from 'react';
import { Button } from 'reactstrap';
import { TRightBarButton } from './types';

const RightBarButton: React.FC<TRightBarButton> = ({
  onClick,
  label,
  icon,
  classNameValue,
  idValue,
  children,
  cyId
}: TRightBarButton) => {
  return (
    <Button
      data-cy-id={cyId}
      id={idValue}
      className={`btn-simple btn-right-bar ${classNameValue}`}
      onClick={onClick}
    >
      {label && <span className="btn-right-bar-label">{label}</span>}
      {icon && <i className={icon} />}
      {children}
    </Button>
  );
};

export default RightBarButton;
