import { TStatus } from './types';

export const METRIC_STATUS: TStatus[] = [
  {
    min: 0,
    max: 24.99,
    color: 'red',
    status: 'Need Improvement'
  },
  {
    min: 25,
    max: 50.99,
    color: 'orange',
    status: 'Can be optimized'
  },
  {
    min: 51,
    max: 75.99,
    color: 'cyan',
    status: 'On target'
  },
  {
    min: 76,
    max: 100,
    color: 'cyan',
    status: 'Excellent'
  }
];
