import USER_ENDPOINTS from './userEndpoints';
import OVERVIEW_ENDPOINTS from './overviewEndpoints';
import MANAGEMENT_ENDPOINT from './managementEndPoints';
import INSIGHTS_ENDPOINT from './insightsEndPoints';
import ACCOUNTS_ENDPOINTS from './accountsEndpoints';
import MEDIA_PLAN_GALLERY_ENDPOINTS from './mediaPlanGalleryEndPoint';
import CREATIVE_ANALYTICS_END_POINTS from './creative_analytics';
import LAUNCHER_ENDPOINT from './launcherEndPoints';

const END_POINTS = {
  USER: { ...USER_ENDPOINTS },
  OVERVIEW: { ...OVERVIEW_ENDPOINTS },
  MANAGEMENT: { ...MANAGEMENT_ENDPOINT },
  INSIGHTS: { ...INSIGHTS_ENDPOINT },
  ACCOUNTS_ENDPOINTS: { ...ACCOUNTS_ENDPOINTS },
  CREATIVE_ANALYTICS: { ...CREATIVE_ANALYTICS_END_POINTS },
  MEDIA_PLAN_GALLERY: { ...MEDIA_PLAN_GALLERY_ENDPOINTS },
  LAUNCHER: { ...LAUNCHER_ENDPOINT }
};

export default END_POINTS;
