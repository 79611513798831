const isInclude = (name: string, words: string[]): boolean => {
  return words.every((word) => {
    const regex = new RegExp(`\\b${word}\\b`, 'i');
    return regex.test(name);
  });
};

export const search = <T>(
  payload: Array<T>,
  key: string,
  typedWord = ''
): Array<T> | [] => {
  if (!key.length && !typedWord.length) {
    return payload;
  }

  let result: Array<T> = [];
  const wordsToMatch = typedWord.trim().split(' ');

  switch (typeof typedWord) {
    case 'string':
      if (!!payload.length) {
        result = payload.filter((item: any) => {
          if (isInclude(item[key], wordsToMatch)) {
            return item;
          }
        });
      }
      break;
    default:
      return result;
  }

  return result;
};

export default search;
