export const NOTIFICATIONS_TYPES = {
  WRONG_PASSWORD: 'wrongPassword',
  SESSION_TIMEOUT: 'sessionTimeout',
  SERVER_ERROR: 'serverError',
  SUCCESS: 'success',
  WRONG_EMAIL: 'wrongEmail',
  CONFLICT: 'conflict',
  FORM_ERROR: 'formError'
};

export default NOTIFICATIONS_TYPES;
