import { TScore } from './types';

export const SCORE: TScore[] = [
  {
    min: 0,
    max: 24.99,
    color: 'red',
    status: 'Need Improvement',
    more: 'creative_video_details.video_scoring_section.more.red'
  },
  {
    min: 25,
    max: 50.99,
    color: 'orange',
    status: 'Moving in the right direction',
    more: 'creative_video_details.video_scoring_section.more.orange'
  },
  {
    min: 51,
    max: 75.99,
    color: 'cyan',
    status: 'Meeting goals',
    more: 'creative_video_details.video_scoring_section.more.cyan'
  },
  {
    min: 76,
    max: 100,
    color: 'mauve',
    status: 'Superb',
    more: 'Astounding performance, well done !'
  }
];
